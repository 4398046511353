import request from '@/Network/Requests/request'
import Service from '@/Network/Services/Service'
import { CrmValidateRequest } from '../Types/Requests/CfmRequests'
import { DoctorCrmResponse } from '../Types/Responses/CfmResponses'

export default class CfmService extends Service {
  prefix: string
  constructor() {
    super()
    this.prefix = '/cfm'
  }

  async validateCrm(payload: CrmValidateRequest): Promise<DoctorCrmResponse> {
    const { data } = await request.get<DoctorCrmResponse>(this.getCompletePath(''), payload)
    return data
  }
}
