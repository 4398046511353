import { createSSRApp, createApp, h } from 'vue'
import { createPinia } from 'pinia'
import isSSR from '@/_base/isSSR'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Maska from 'maska'
import VCalendar from 'v-calendar'
import Toast from 'vue-toastification'
import { toastOptions } from './config/toastConfig'
import App from './App.vue'
import { router } from './router'
import * as nativeStore from './store/useNativeStore'
import * as vuexStore from './store/useVuexStore'
import '@/assets/css/tailwind.css'
import 'v-calendar/dist/style.css'
import 'vue-toastification/dist/index.css'
import '@/assets/css/toast.css'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import VueGoodTablePlugin from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
const customTheme = {
  dark: false,
  colors: {
    primary5: '#D8EEF3',
    primary20: '#3CA2B9',
    primary30: '#2F7F91',
    secondary50: '#16161D',
    secondary: '#16161D',
    neutral50: '#323232',
    alert: '#F18F1B',
    warning: '#F1C21B',
    info: '#F1C21B',
    success: '#198038',
    negative: '#DA1E28',
    error: '#DA1E28',
    primary: '#2F7F91',
    textPrimary: '#16161D',
    primary10: '#84C8D7',
    tertiary: '#E37796',
    light: '#3CA2B9',
    neutralLight: '#BCBCCD',
    feedBackSuccess: '#198038',
    feedBackAlert: '#F18F1B',
    lightGray30: '#D8D8E2',
    background: '#FFFFFF',
    iconGrey: '#68688D'
  }
}

import DashboardLayoutVue from './components/DashboardLayout.vue'
import EmptyLayoutVue from './components/EmptyLayout.vue'
import { VDataTable } from 'vuetify/labs/VDataTable'
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader'

export default function (args: any) {
  const rootComponent = {
    render: () => h(App),
    components: { App },
    setup() {
      nativeStore.provideStore(args.nativeStore)
      vuexStore.provideStore(args.vuexStore)
    }
  }

  const pinia = createPinia()

  const vuetify = createVuetify({
    theme: {
      defaultTheme: 'customTheme',
      themes: {
        customTheme
      }
    },
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi
      }
    },
    components: {
      VDataTable,
      VSkeletonLoader
    }
  })

  const app = (isSSR ? createSSRApp : createApp)(rootComponent)

  // remove this to disable the template layout dashboard
  app.component('default-layout', DashboardLayoutVue)
  app.component('empty-layout', EmptyLayoutVue)
  app.component('v-select', vSelect)

  if (!isSSR) {
    app.use(VCalendar, {})
  }
  app.use(pinia)
  app.use(vuetify)
  app.use(router)
  app.use(args.vuexStore)
  app.use(Maska)
  app.use(Toast, toastOptions)
  app.use(VueGoodTablePlugin)

  return {
    app,
    router
  }
}
