<template>
  <div class="flex justify-center">
    <div
      v-if="uploadStatus === 'idle'"
      @click="triggerAttachFile"
      class="w-3/4 upload-container cursor-pointer flex flex-col items-center"
    >
      <img width="30" src="@/assets/icons/upload-file.svg" />
      <p>Clique para anexar o arquivo</p>
      <input @change="onAttachFile" ref="attachFileElement" class="hidden" type="file" />
    </div>
    <div v-else class="flex flex-col md:flex-row items-center w-full gap-2">
      <img width="20" src="@/assets/icons/file.svg" />
      <p>{{ fileName }}</p>
      <div class="progress-bar-container">
        <div :style="`width: ${fileUploadPercentage}px`" class="progress-bar"></div>
      </div>
      <SuccessIcon v-if="uploadStatus === 'uploaded'" />
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import SuccessIcon from '@/assets/icons/success.vue'
import { useStore } from 'vuex'

export default {
  name: 'AttachFile',
  props: ['uploadStatus', 'fileUploadPercentage', 'validateField'],
  emits: ['setUploadedStatus', 'setFileUploadPercentage'],
  components: {
    SuccessIcon
  },
  setup(props, { emit }) {
    const store = useStore()

    const newProtocol = computed(() => store.state.protocol.newProtocol)

    const attachFileElement = ref('')
    const fileName = ref('')

    const triggerAttachFile = () => {
      attachFileElement.value.click()
    }

    const onAttachFile = () => {
      if (attachFileElement.value?.files?.length > 0) {
        fileName.value = attachFileElement.value.files.item(0).name
        emit('setUploadedStatus', 'uploading')

        // for now it simulates the upload, this logic can be used when integrating by api
        const INCREASE_UPLOAD_TIME = 20 // 0.02 seconds
        const UPLOAD_PERCENTAGE = 10 // px
        const PROGRESS_BAR_WIDTH = 350 // px

        const uploadInterval = setInterval(() => {
          if (props.fileUploadPercentage >= PROGRESS_BAR_WIDTH) {
            clearInterval(uploadInterval)
            store.dispatch('setNewProtocol', { property: 'file', value: fileName.value })
            emit('setUploadedStatus', 'uploaded')
            emit('validateField', 'file', true)
          } else {
            emit('setFileUploadPercentage', props.fileUploadPercentage + UPLOAD_PERCENTAGE)
          }
        }, INCREASE_UPLOAD_TIME)
      }
    }

    return {
      attachFileElement,
      onAttachFile,
      triggerAttachFile,
      newProtocol,
      fileName
    }
  }
}
</script>

<style scoped>
.progress-bar-container {
  height: 5px;
  border-radius: 8px;
  background-color: #bcbccd;
  min-width: 350px;
  max-width: 350px;
}
.progress-bar {
  background-color: #198038;
  height: 5px;
  border-radius: 8px;
  width: 0px;
}
.upload-container {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%232F7F91FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 14px;
  padding: 10px;
}
</style>
