<template>
  <VDatePicker v-on:update:model-value="addFilter" v-model="date">
    <template #default="{ togglePopover }">
      <span @click="togglePopover" class="filter-btn"
        ><calendar-icon :width="16" :height="16" />
        <p>Data</p>
      </span>
    </template>
  </VDatePicker>
</template>

<script lang="ts">
import { startOfWeek, endOfWeek } from 'date-fns'
import CalendarIcon from '@/assets/icons/calendar.vue'
import { ref } from 'vue'
import { useStationStore } from '@/pinia-store/useStationStore'

export default {
  components: {
    CalendarIcon
  },
  setup() {
    const stationStore = useStationStore()
    const date = ref<string>(stationStore.listStationsQuery.startDate)

    const addFilter = async (val: Date) => {
      const dateStartOfWeek = startOfWeek(val)
      dateStartOfWeek.setMilliseconds(-1)

      const dateEndOfWeek = endOfWeek(val)
      dateEndOfWeek.setHours(0, 0, 0)

      await stationStore.setDateFilter({
        startDate: dateStartOfWeek,
        endDate: dateEndOfWeek
      })
    }

    return {
      addFilter,
      date
    }
  }
}
</script>

<style></style>
