<template>
  <modal-vue @close="close" :modalActive="modalStateProp">
    <template #header>
      <p class="font-bold">Excluir usuário</p>
    </template>
    <p class="text-base font-normal">
      Tem certeza que deseja excluir o usuário <strong>{{ props.user?.name }}</strong
      >?<br />Essa ação não poderá ser desfeita!
    </p>
    <template #footer>
      <div class="flex justify-center gap-4">
        <outline-button width="119px" @click="close" text="Cancelar" />
        <filled-button text="Excluir" width="102px" @click="handleDelete" />
      </div>
    </template>
  </modal-vue>
  <modal-vue
    @close="
      () => {
        showModalDeleteSuccess = false
        close()
      }
    "
    :modalActive="showModalDeleteSuccess"
  >
    <template #header>
      <div class="flex justify-center">
        <success-icon />
      </div>
    </template>
    <p class="text-base font-normal">
      O <strong>{{ props.user?.name }}</strong> foi excluido<br />com sucesso!
    </p>
  </modal-vue>
</template>

<script setup lang="ts">
import { defineProps, ref, toRefs, defineEmits } from 'vue'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import SuccessIcon from '@/assets/icons/success.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import { useStore } from 'vuex'

const store = useStore()
const props = defineProps(['modalStateProp', 'user'])
const emit = defineEmits(['close'])

const { modalStateProp } = toRefs(props)

const showModalDeleteSuccess = ref(false)

const close = () => {
  emit('close')
}

const handleDelete = () => {
  try {
    store.commit('deleteUser', props.user)

    showModalDeleteSuccess.value = true
  } catch (error) {
    alert(error)
  }
}
</script>
