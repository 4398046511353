<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 15C6.28287 15 2.24223 12.1091 0.539284 8C2.24223 3.89095 6.28287 1 11 1C15.7171 1 19.7578 3.89095 21.4607 8C19.7578 12.1091 15.7171 15 11 15ZM14 8C14 6.34386 12.6561 5 11 5C9.34386 5 8 6.34386 8 8C8 9.65614 9.34386 11 11 11C12.6561 11 14 9.65614 14 8Z"
      :stroke="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: '22',
      type: String
    },
    height: {
      default: '16',
      type: String
    },
    color: {
      default: '#323232',
      type: String
    }
  }
}
</script>

<style></style>
