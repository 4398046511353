<template>
  <div class="flex w-full h-[70px]" style="background: #fef3e7">
    <div class="bg-feedBackAlert min-w-[12px] w-[12px]"></div>
    <div class="w-full flex justify-center items-center">
      <span class="text-[10px] text-feedBackAlert font-bold text-ellipsis"> Vaga aberta </span>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'

// eslint-disable-next-line no-shadow
enum ETableType {
  default = 'default',
  warning = 'warning',
  neutral = 'neutral',
  success = 'success',
  alert = 'alert'
}
export default defineComponent({
  props: {
    tableType: {
      type: String as PropType<ETableType>,
      default: ETableType.alert
    },
    name: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    minimal: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    solid: {
      type: Boolean,
      default: false
    },
    crm: {
      type: String,
      default: ''
    },
    success: {
      type: Boolean,
      default: true
    },
    error: {
      type: Boolean,
      default: true
    },
    waiting: {
      type: Boolean,
      default: true
    },
    pendences: {
      type: Boolean,
      default: true
    },
    photo: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    function defineHeight() {
      if (props.solid || (!props.text && !props.minimal)) {
        return '78px'
      }
      if (props.minimal) {
        return '25px'
      }
      if (props.flat) {
        return '30px'
      }
      return '38px'
    }
    function defineTextColor() {
      if (props.text && props.tableType === 'alert') {
        if (props.solid) return 'text-white font-semibold'
        if (props.minimal) return 'text-feedBackAlert text-xs font-semibold'
        return props.flat
          ? 'text-feedBackAlert font-semibold text-xs'
          : 'text-feedBackAlert font-semibold'
      }
      if (props.minimal) {
        return 'text-gray-800 text-xs ml-1'
      }
      return 'text-gray-800'
    }
    function defineBgColor() {
      const colors = {
        default: { stripe: 'bg-primary10', bg: '#F7FCFD' },
        warning: { stripe: 'bg-tertiary', bg: '#FDF7F8' },
        neutral: { stripe: 'bg-neutralLight', bg: '#FAFAFA' },
        success: { stripe: 'bg-feedBackSuccess', bg: '#F6FDF9' },
        alert: {
          stripe: 'bg-feedBackAlert',
          bg: props.solid ? '#F18F1B' : '#FEF3E7'
        }
      }

      return colors[props.tableType]
    }
    function defineClass() {
      if (props.photo || props.flat) {
        return `${defineBgColor().stripe} w-3`
      }
      if (props.minimal) {
        return `${defineBgColor().stripe} w-2`
      }
      return `${defineBgColor().stripe} w-5`
    }

    function defineClassIcons() {
      if (props.photo) return 'px-2'
      if (props.minimal) return 'px-1 flex-col'
      return 'px-4 flex-col'
    }
    function truncateString(text: string, limit: number) {
      return `${text.substring(0, limit)}`
    }
    const nameTruncated = computed(() => truncateString(props.name, 13))

    const colorDefination = ref({ stripe: 'bg-primary10', bg: '#F7FCFD' })

    onMounted(() => {
      colorDefination.value = defineBgColor()
    })

    return {
      colorDefination,
      defineClass,
      defineBgColor,
      defineTextColor,
      defineHeight,
      defineClassIcons,
      nameTruncated
    }
  }
})
</script>

<style scoped>
.row-table {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.decoration-left {
  width: 18px !important;
}
.btn-green,
.btn-red,
.btn-yellow {
  width: 14px;
  height: 14px;
}
.btn-green {
  border: 1px #198038 solid;
}
.btn-red {
  border: 1px theme('colors.primary10') solid;
}
.btn-yellow {
  border: 1px #f1c21b solid;
}
.group-buttons {
  gap: 1px;
}
</style>
