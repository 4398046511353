<template>
  <div class="protocol py-5 flex flex-row justify-between">
    <p>
      <span class="protocol-name">{{ protocol.name }}</span
      >: {{ protocol.speciality }}
    </p>
    <div class="flex flex-row gap-5">
      <a :href="protocol.file" download>
        <DownloadIcon />
      </a>
      <DeleteIcon class="cursor-pointer" @click="openDeleteModal" />
    </div>
  </div>
</template>

<script>
import DeleteIcon from '@/assets/icons/delete.vue'
import DownloadIcon from '@/assets/icons/download.vue'

export default {
  name: 'Protocol',
  props: ['protocol'],
  emits: ['openDeleteModal'],
  components: {
    DeleteIcon,
    DownloadIcon
  },
  setup(props, { emit }) {
    const openDeleteModal = () => {
      emit('openDeleteModal')
    }

    return {
      openDeleteModal
    }
  }
}
</script>

<style scoped>
.protocol-name {
  color: #2f7f91;
}

.protocol {
  border-bottom: 1px solid #e6e6e6;
}
</style>
