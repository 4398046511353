import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShowIcon = _resolveComponent("ShowIcon")!
  const _component_HideIcon = _resolveComponent("HideIcon")!
  const _component_GenericInput = _resolveComponent("GenericInput")!

  return (_openBlock(), _createBlock(_component_GenericInput, _mergeProps({ ..._ctx.$props, ..._ctx.$attrs }, {
    value: _ctx.modelValue,
    type: _ctx.visible ? 'text' : 'password',
    onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('validate', _ctx.modelValue))),
    onKeyup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('validate', _ctx.modelValue))),
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    label: "Senha"
  }), {
    "append-inner": _withCtx(() => [
      _createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = !_ctx.visible)),
        class: "cursor-pointer"
      }, [
        (!_ctx.visible)
          ? (_openBlock(), _createBlock(_component_ShowIcon, { key: 0 }))
          : (_openBlock(), _createBlock(_component_HideIcon, { key: 1 }))
      ])
    ]),
    _: 1
  }, 16, ["value", "type"]))
}