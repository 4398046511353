<template>
  <v-form
    ref="professionalDataFormRef"
    id="formDoctorSignUpPesonalData"
    class="w-full flex gap-7 items-center flex-col rounded overflow-hidden mt-5 mb-4"
  >
    <div class="w-full flex flex-col">
      <h1 class="text-left font-bold text-2xl">Dados Profissionais</h1>
      <p class="text-left text-sm text-gray-500">Seu CRM será validado pelo sistema do CFM.</p>
    </div>

    <div class="flex flex-col w-full gap-2">
      <v-text-field
        v-model="professionalDataForm.crm"
        v-maska="'######'"
        label="* CRM"
        class="w-full"
        color="primary20"
        variant="outlined"
        :error="!!cfmResponse"
        :rules="[rules.required]"
        @update:modelValue="searchCrm"
        validate-on="lazy input"
      >
        <template v-slot:loader>
          <v-progress-linear
            :active="isLoadingCfm"
            color="primary"
            height="7"
            indeterminate
          ></v-progress-linear>
        </template>
      </v-text-field>
      <v-autocomplete
        v-model="professionalDataForm.crmUf"
        label="* Estado"
        color="primary"
        :error="!!cfmResponse"
        variant="outlined"
        :items="ufs"
        :rules="[rules.required, rules.uf, rules.ufLen]"
        @update:modelValue="searchCrm"
      >
        <template v-slot:item="{ props, item }">
          <v-list-item
            v-bind="props"
            :title="item?.raw?.value"
            :subtitle="item?.raw?.title"
          ></v-list-item>
        </template>
      </v-autocomplete>
      <v-text-field
        v-model="professionalDataForm.name"
        label="* Nome"
        class="w-full"
        color="primary20"
        variant="outlined"
        disabled
      />
      <v-alert v-if="cfmResponse" :text="cfmResponse" type="error" />
    </div>
  </v-form>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import axios from 'axios'
import { useDoctorRegistryStore } from '@/pinia-store/useDoctorRegistryStore'
import ufs from '@/config/StatesFromBR'
import { rules } from '@/utils/rules'
import Network from '@/Network'

const doctorRegistryStore = useDoctorRegistryStore()
const { professionalDataForm, professionalDataFormRef } = storeToRefs(doctorRegistryStore)

const cfmResponse = ref<string | null>(null)
const isLoadingCfm = ref(false)

const searchCrm = async () => {
  cfmResponse.value = ''
  professionalDataForm.value.name = ''
  if (!professionalDataForm.value.crm || !professionalDataForm.value.crmUf) return
  const crm = `${professionalDataForm.value.crm}/${professionalDataForm.value.crmUf}`

  try {
    isLoadingCfm.value = true
    const data = await Network.cfm.validateCrm({
      crm: professionalDataForm.value.crm,
      crmUf: professionalDataForm.value.crmUf
    })

    if (data.status !== 'Ativo') {
      cfmResponse.value = `CRM: ${crm} não está ativo no CFM`
      return
    }
    professionalDataForm.value.name = data.name
    cfmResponse.value = ''
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        cfmResponse.value = `CRM: ${crm} não encontrado no CFM`
        return
      }

      if (error.response?.status === 400) {
        cfmResponse.value = `CRM: ${crm} já está sendo utilizado no sistema`
        return
      }
    }
    cfmResponse.value = 'Erro ao buscar CRM no CFM'
  } finally {
    isLoadingCfm.value = false
  }
}
</script>
