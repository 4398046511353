import axios, { AxiosRequestConfig } from 'axios'
import Network from '..'
const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = Network.auth.getLocalToken()
  const companies = Network.company.getLocalTokenCompanyList()

  if (token) {
    if (config.headers) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`
      config.headers.company_ids = companies
    }
  }

  return config
})

api.interceptors.response.use(
  (response) => response,
  (e) => {
    if (e.response?.data?.statusCode === 401) {
      localStorage.clear()

      window.location.href = '/'

      // eslint-disable-next-line no-alert
      // alert(
      //   'Você não possui autorização para acessar essa página, por favor contate o responsável pela empresa.'
      // )
    }

    return Promise.reject(e)
  }
)

export default api
