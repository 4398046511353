<template>
  <modal-vue @close="handleToggleModal" :modalActive="modals[modalName]">
    <template #header>
      <div class="text-left px-[16px] flex">
        <strong>Legendas</strong>
      </div>
    </template>
    <v-row>
      <v-col cols="12" md="6">
        <legend-table :legendColor="ELegendColor.alert" text="Aberta" />
      </v-col>
      <v-col cols="12" md="6">
        <legend-table :legendColor="ELegendColor.default" text="Semanal" />
      </v-col>
      <v-col cols="12" md="6">
        <legend-table :legendColor="ELegendColor.warning" text="Cobertura" />
      </v-col>
      <v-col cols="12" md="6">
        <legend-table :legendColor="ELegendColor.success" text="Extra" />
      </v-col>
      <v-col cols="12" md="6">
        <legend-table
          :legendIcon="mdiClockAlertOutline"
          :legendColor="ELegendColor.neutral"
          text="Não confirmado"
        />
      </v-col>
      <v-col cols="12" md="6">
        <legend-table
          :legendIcon="mdiClockCheckOutline"
          :legendColor="ELegendColor.success"
          text="Confirmado"
        />
      </v-col>
      <v-col cols="12" md="6">
        <legend-table
          :legendIcon="mdiTextBoxCheck"
          :legendColor="ELegendColor.warning"
          text="Possui observações"
        />
      </v-col>
      <v-col cols="12" md="6">
        <legend-table
          :legendIcon="mdiAccountAlert"
          :legendColor="ELegendColor.default"
          text="Médico pendente"
        />
      </v-col>
      <v-col cols="12" class="text-left">
        <span class="text-sm font-bold">Status do checks do plantão:</span>
      </v-col>
      <v-col cols="12" md="6">
        <legend-table
          :dynamicComponent="
            doctorStationStatusTranslate[DoctorStationStatus.DISPONIBILIZADO_PARA_CHECKIN].icon
          "
          :legendColor="ELegendColor.primary"
          :text="
            doctorStationStatusTranslate[DoctorStationStatus.DISPONIBILIZADO_PARA_CHECKIN].text
          "
        />
      </v-col>
      <v-col cols="12" md="6">
        <legend-table
          :dynamicComponent="
            doctorStationStatusTranslate[DoctorStationStatus.CHECKIN_NAO_REALIZADO].icon
          "
          :legendColor="ELegendColor.primary"
          :text="doctorStationStatusTranslate[DoctorStationStatus.CHECKIN_NAO_REALIZADO].text"
        />
      </v-col>
      <v-col cols="12" md="6">
        <legend-table
          :dynamicComponent="
            doctorStationStatusTranslate[DoctorStationStatus.CHECKIN_REALIZADO].icon
          "
          :legendColor="ELegendColor.primary"
          :text="doctorStationStatusTranslate[DoctorStationStatus.CHECKIN_REALIZADO].text"
        />
      </v-col>
      <v-col cols="12" md="6">
        <legend-table
          :dynamicComponent="
            doctorStationStatusTranslate[DoctorStationStatus.CHECKIN_REALIZADO_COM_ATRASO].icon
          "
          :legendColor="ELegendColor.primary"
          :text="
            doctorStationStatusTranslate[DoctorStationStatus.CHECKIN_REALIZADO_COM_ATRASO].text
          "
        />
      </v-col>
      <v-col cols="12" md="6">
        <legend-table
          :dynamicComponent="
            doctorStationStatusTranslate[DoctorStationStatus.DISPONIBILIZADO_PARA_CHECKOUT].icon
          "
          :legendColor="ELegendColor.primary"
          :text="
            doctorStationStatusTranslate[DoctorStationStatus.DISPONIBILIZADO_PARA_CHECKOUT].text
          "
        />
      </v-col>
      <v-col cols="12" md="6">
        <legend-table
          :dynamicComponent="
            doctorStationStatusTranslate[DoctorStationStatus.CHECKOUT_NAO_REALIZADO].icon
          "
          :legendColor="ELegendColor.primary"
          :text="doctorStationStatusTranslate[DoctorStationStatus.CHECKOUT_NAO_REALIZADO].text"
        />
      </v-col>
      <v-col cols="12" md="6">
        <legend-table
          :dynamicComponent="
            doctorStationStatusTranslate[DoctorStationStatus.CHECKOUT_REALIZADO].icon
          "
          :legendColor="ELegendColor.primary"
          :text="doctorStationStatusTranslate[DoctorStationStatus.CHECKOUT_REALIZADO].text"
        />
      </v-col>
      <v-col cols="12" md="6">
        <legend-table
          :dynamicComponent="
            doctorStationStatusTranslate[DoctorStationStatus.CHECKOUT_REALIZADO_COM_ANTECEDENCIA]
              .icon
          "
          :legendColor="ELegendColor.primary"
          :text="
            doctorStationStatusTranslate[DoctorStationStatus.CHECKOUT_REALIZADO_COM_ANTECEDENCIA]
              .text
          "
        />
      </v-col>
    </v-row>

    <template #footer>
      <v-row>
        <v-col cols="12">
          <ghost-button @click="handleToggleModal" width="100%" text="Voltar" />
        </v-col>
      </v-row>
    </template>
  </modal-vue>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import GhostButton from '@/components/designSystem/Buttons/GhostButton/index.vue'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import {
  DoctorStationStatus,
  doctorStationStatusTranslate
} from '@/translations/doctor.station.status'
import {
  mdiTextBoxCheck,
  mdiAccountAlert,
  mdiClockAlertOutline,
  mdiClockCheckOutline
} from '@mdi/js'
import LegendTable, { ELegendColor } from '@/components/others/Legend.vue'
/* ******** Modal Props and Actions Default *********** */
const modalName = EModals.LEGENDS
const { modals } = storeToRefs(useModalStore())
const modalStore = useModalStore()
const handleToggleModal = () => {
  modalStore.toggleModal(modalName)
}
/* **************************************************** */
</script>
