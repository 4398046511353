<template>
  <ModalVue @close="setShowEditContractModal(false)" :modalActive="showEditContractModal">
    <template #header>
      <p class="font-bold px-[8px] mb-5 text-left">Editar Contrato</p>
    </template>
    <p class="text-base font-normal mb-8">
      <Input
        :isError="showContractNameInvalidMessage"
        @input="onChangeEditContractName"
        errorMessage="Contrato inválido"
        label="Editar Contrato"
        width="100%"
        :value="editContractName"
        name="editContractName"
        required
      />
    </p>
    <div class="flex justify-end gap-5">
      <OutlineButton width="119px" @click="setShowEditContractModal(false)" text="Cancelar" />
      <FilledButton text="Confirmar" width="119px" @click="editContract" />
    </div>
  </ModalVue>
</template>

<script>
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import Input from '@/components/designSystem/Inputs/Input.vue'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { isEmpty } from '@/utils/validation'

export default {
  name: 'EditContractModal',
  props: ['contractIndex', 'setShowEditContractModal', 'showEditContractModal'],
  components: {
    ModalVue,
    OutlineButton,
    FilledButton,
    Input
  },
  setup(props) {
    const store = useStore()
    const showContractNameInvalidMessage = ref(false)

    const editContractName = computed(() => store.state.contract.editContractName)

    const onChangeEditContractName = (e) => {
      store.dispatch('setEditContractName', e.target.value)
    }

    const validateEditContractName = () => {
      if (isEmpty(editContractName.value)) {
        showContractNameInvalidMessage.value = true
        return false
      }
      return true
    }

    const editContract = () => {
      if (validateEditContractName()) {
        store.dispatch('editContract', props.contractIndex)
        props.setShowEditContractModal(false)
        showContractNameInvalidMessage.value = false
      }
    }

    return {
      editContractName,
      showContractNameInvalidMessage,
      onChangeEditContractName,
      validateEditContractName,
      editContract
    }
  }
}
</script>
