<template>
  <BasicData />
  <CreateContract />
  <div v-if="contracts.length > 0">
    <Contract
      v-for="(contract, index) in contracts"
      :key="index"
      :contract="contract"
      :contractIndex="index"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import BasicData from './components/BasicData/BasicData.vue'
import CreateContract from './components/CreateContract/CreateContract.vue'
import Contract from './components/Contract/Contract.vue'

export default {
  name: 'Contracts',
  components: {
    BasicData,
    CreateContract,
    Contract
  },
  setup() {
    const store = useStore()

    const contracts = computed(() => store.state.contract.contracts)

    return {
      contracts
    }
  }
}
</script>

<style scoped></style>
