import {
  HistoryDate,
  HistoryDateRequest,
  PatchStationsRequest,
  StationTypes
} from '@/Network/Types/Requests/StationRequests'
import { reactive } from 'vue'
import * as yup from 'yup'
import Network from '@/Network/'
import {
  getHistoriesRequest,
  getStartDateAndEndDate
} from '../../Dashboard/modals/createStation/helpers'

type States = {
  schedule?: number | null
  healthUnit?: number | null
  company?: number | null
  doctor?: number | null
  responsible?: string
  date?: string
  startHour?: string
  endHour?: string
  stationType?: string
  inCash?: boolean
  advanceRequested?: boolean
  advancePaid?: boolean
  timezone?: string
  value?: number
  ourTeam?: boolean
  isLoading: boolean
  histories?: HistoryDate[]
  hasErrorDate: boolean
}

export type FilterStates = {
  company?: number | null
  healthUnits?: number[] | null
  schedules?: number[] | null
  doctors?: number[] | null
  startDate?: string | null
  endDate?: string | null
  crmStates?: string[] | null
  crms?: string[] | null
  valueMin?: number | null
  valueMax?: number | null
  withCheckin?: boolean | null
  withCheckout?: boolean | null
  stationType?: string | null
  inCash?: boolean
  advanceRequested?: boolean
  advancePaid?: boolean
  ourTeam?: boolean
  status?: string[] | null
}

export const UpdateStationSchema = yup.object({
  ids: yup.array(yup.number().required()).required().min(1),
  doctor: yup.string().optional().nullable(),
  responsible: yup.string().optional().nullable(),
  date: yup.string().optional(),
  startHour: yup.string().optional(),
  endHour: yup.string().optional(),
  timezone: yup.string(),
  ourTeam: yup.boolean().optional(),
  inCash: yup.boolean().optional(),
  stationType: yup.mixed<StationTypes>().oneOf(['COBERTURA', 'SEMANAL']).optional(),
  value: yup.number().optional(),
  schedule: yup.number().optional(),
  advancePaid: yup.boolean().optional(),
  advanceRequested: yup.boolean().optional()
})

export const filterStates: FilterStates = reactive({
  company: undefined,
  healthUnits: undefined,
  schedules: undefined,
  doctors: undefined,
  startDate: undefined,
  endDate: undefined,
  crmStates: undefined,
  crms: undefined,
  valueMin: undefined,
  valueMax: undefined,
  withCheckin: undefined,
  withCheckout: undefined,
  inCash: undefined,
  advanceRequested: undefined,
  advancePaid: undefined,
  status: undefined,
  stationType: undefined
})

export const formStates: States = reactive({
  company: undefined,
  healthUnit: undefined,
  schedule: undefined,
  doctor: undefined,
  date: undefined,
  endHour: undefined,
  ourTeam: undefined,
  inCash: undefined,
  advancePaid: undefined,
  advanceRequested: undefined,
  responsible: undefined,
  startHour: undefined,
  stationType: undefined,
  timezone: undefined,
  value: undefined,
  histories: undefined,
  isLoading: false,
  hasErrorDate: false
})

export function clearFilters() {
  filterStates.company = undefined
  filterStates.healthUnits = undefined
  filterStates.schedules = undefined
  filterStates.doctors = undefined
  filterStates.startDate = undefined
  filterStates.endDate = undefined
  filterStates.crmStates = undefined
  filterStates.crms = undefined
  filterStates.valueMin = undefined
  filterStates.valueMax = undefined
  filterStates.withCheckin = undefined
  filterStates.withCheckout = undefined
  filterStates.inCash = undefined
  filterStates.advanceRequested = undefined
  filterStates.advancePaid = undefined
  filterStates.status = undefined
  filterStates.ourTeam = undefined
  filterStates.stationType = undefined
}

export function clear() {
  formStates.company = undefined
  formStates.healthUnit = undefined
  formStates.schedule = undefined
  formStates.doctor = undefined
  formStates.responsible = undefined
  formStates.date = undefined
  formStates.startHour = undefined
  formStates.endHour = undefined
  formStates.timezone = undefined
  formStates.stationType = undefined
  formStates.value = undefined
  formStates.ourTeam = undefined
  formStates.inCash = undefined
  formStates.advancePaid = undefined
  formStates.advanceRequested = undefined
  formStates.isLoading = false
  formStates.hasErrorDate = false
  formStates.histories = []
}

export function validateDate() {
  const allFieldsEmpty =
    !formStates.date && !formStates.startHour && !formStates.endHour && !formStates.timezone
  const allFieldsUpdate =
    formStates.date && formStates.startHour && formStates.endHour && formStates.timezone
  if (allFieldsEmpty || allFieldsUpdate) {
    return true
  }
  formStates.hasErrorDate = true
  return false
}

export async function executeSubmit(ids?: number[]) {
  const data = UpdateStationSchema.validateSync({ ids, ...formStates })
  const validDate = validateDate()
  if (!validDate)
    throw {
      response: {
        data: { message: 'Para atualizar data e horário de plantões, altere todos os campos' }
      }
    }
  const hasConfig = data.date && data.startHour && data.endHour
  let historiesRequest: HistoryDateRequest[] | undefined = undefined
  let config:
    | {
        date: Date
        startHour: Date
        endHour: Date
        timezone?: string
      }
    | undefined = undefined

  if (hasConfig) {
    const { startDate, endDate } = getStartDateAndEndDate({
      date: data.date ?? '',
      endHour: data.endHour ?? '',
      startHour: data.startHour ?? '',
      timezone: data.timezone
    })

    config = {
      date: startDate,
      endHour: endDate,
      startHour: startDate,
      timezone: data.timezone
    }
    if (formStates.histories)
      historiesRequest = getHistoriesRequest(formStates.histories, startDate, endDate)
  }

  const payload: PatchStationsRequest = {
    config,
    histories: historiesRequest,
    ourTeam: data.ourTeam,
    stationType: data.stationType,
    doctorId: data.doctor ? +data.doctor : undefined,
    value: data.value,
    schedule: data.schedule,
    inCash: data.inCash
  }
  await Network.station.patchStations(data.ids, payload)
}

export function handleUpdateChecks() {
  clear()
}
