<!-- eslint-disable vue/no-v-for-template-key-on-child -->
<template>
    <GenericButton v-bind="{ ...$props, ...$attrs }"
        class="text-primary hover:text-primary30 hover:bg-primary5 border-2 border-primary30">
        <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
            <slot :key="slot" :name="slot" v-bind="scope" />
        </template>
    </GenericButton>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import GenericButton from '@/components/designSystem/Buttons/GenericButton/index.vue'

export default defineComponent({
    extends: GenericButton,
    inheritAttrs: false,
    components: {
        GenericButton
    },
    props: {
        icon: { type: Boolean, default: false },
        text: { type: String, default: 'Button' },
        width: { type: String, default: '300px' },
        variant: { type: String, default: 'outlined' },
        disabled: { type: Boolean, default: false }
    },
    setup() {
        return {}
    }
})
</script>
<style scoped></style>
