<template>
  <div class="flex gap-1 p-2.5 text-primary text-xs border border-primary rounded-full">
    {{ text }}
    <close-icon v-if="shouldRemove" @click="$emit('onRemove', $event)" class="cursor-pointer" />
  </div>
</template>

<script>
import CloseIcon from '@/assets/icons/close.vue'

export default {
  components: {
    CloseIcon
  },
  props: {
    queryParamKey: {
      type: String,
      default: 'param'
    },
    text: {
      type: String,
      default: ''
    },
    shouldRemove: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style></style>
