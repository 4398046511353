<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 my-8">
      <Input
        :isError="isErrorUserName"
        @input="onChangeUserName"
        errorMessage="Nome de usuário inválido"
        width="100%"
        label="Nome"
        pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        :value="userName"
        required
      />

      <Input
        :isError="isErrorUserName"
        @input="onChangeUserName"
        errorMessage="CNPJ inválido"
        width="100%"
        label="CNPJ"
        pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        :value="userName"
        required
      />

      <Input
        :isError="isErrorUserName"
        @input="onChangeUserName"
        errorMessage="Razão Social inválido"
        width="100%"
        label="Razão Social"
        pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        :value="userName"
        required
      />
      <Input
        :v-model="email"
        @input="onChangeEmail"
        :isError="isErrorEmail"
        errorMessage="E-mail inválido!"
        pattern="[a-z0-9]+@[a-z]+\.[a-z]{2,3}"
        type="email"
        width="100%"
        label="Email"
        required
      />

      <Input
        :v-model="phone1"
        @input="onChangePhone1"
        :isError="isErrorPhone1"
        errorMessage="Telefone inválido"
        width="100%"
        type="tel"
        pattern="(\(\d{2}\)\s)(\d{4,5}-\d{4})"
        label="Telefone 01"
        mask="(##) #####-####"
        required
      />

      <Input
        :v-model="phone1"
        @input="onChangePhone1"
        :isError="isErrorPhone1"
        errorMessage="Telefone inválido"
        width="100%"
        type="tel"
        pattern="(\(\d{2}\)\s)(\d{4,5}-\d{4})"
        label="Telefone 02"
        mask="(##) #####-####"
        required
      />
    </div>
  </div>

  <div class="flex justify-end mt-10">
    <FilledButton
      text="Salvar alterações"
      width="180px"
      @click="() => toast.success('Dados alterados com sucesso!')"
    />
  </div>
</template>

<script>
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import Input from '@/components/designSystem/Inputs/Input.vue'
import { useToast } from 'vue-toastification'

export default {
  name: 'DataPanel',
  components: {
    Input,
    FilledButton
  },
  setup() {
    const toast = useToast()

    return { toast }
  }
}
</script>
