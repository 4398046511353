<template>
  <div class="w-full flex justify-between items-center mt-4">
    <div class="relative w-2/4">
      <Input v-model="search" label="Buscar protocolo" />
      <SearchIcon class="absolute right-3 top-3" color="#323232" />
    </div>
    <div class="pl-4">
      <FilledButton @click="openModal" text="Criar protocolo" width="180px" />
    </div>
  </div>

  <div class="w-full mt-4">
    <div v-if="filteredProtocols.length > 0">
      <Protocol
        v-for="(protocol, index) in filteredProtocols"
        :key="index"
        :protocol="protocol"
        @openDeleteModal="openDeleteModal(protocol.id)"
      />
    </div>
    <EmptyList v-else />
  </div>

  <CreateProtocolModal @closeCreateModal="closeCreateModal" :modalActive="modalActive" />

  <ModalVue @close="closeDeleteProtocolModal" :modalActive="showDeleteProtocolModal">
    <template #header>
      <p class="font-bold px-[8px] mb-5">Excluir protocolo</p>
    </template>
    <p class="text-base font-normal mb-8">
      Tem certeza que deseja excluir o protocolo?<br />
      Essa ação não poderá ser desfeita!
    </p>
    <div class="flex justify-center gap-5">
      <OutlineButton width="119px" @click="closeDeleteProtocolModal" text="Cancelar" />
      <FilledButton text="Excluir" width="119px" @click="deleteProtocol" />
    </div>
  </ModalVue>

  <ModalVue @close="closeDeleteProtocolSuccessModal" :modalActive="showDeleteProtocolSuccessModal">
    <template #header>
      <p class="font-bold px-[8px] flex justify-center">
        <SuccessIcon width="50px" height="50px" />
      </p>
    </template>
    <p class="text-base font-normal">
      O protocolo foi excluido<br />
      com sucesso!
    </p>
  </ModalVue>
</template>

<script>
import { computed, watch, ref } from 'vue'

import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import Input from '@/components/designSystem/Inputs/Input.vue'
import EmptyList from '@/components/EmptyList.vue'
import { useStore } from 'vuex'
import SuccessIcon from '@/assets/icons/success.vue'
import SearchIcon from '@/assets/icons/search.vue'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import CreateProtocolModal from './components/CreateProtocolModal/Index.vue'
import Protocol from './components/Protocol/Protocol.vue'

export default {
  name: 'Protocols',
  components: {
    Input,
    CreateProtocolModal,
    FilledButton,
    OutlineButton,
    EmptyList,
    Protocol,
    ModalVue,
    SuccessIcon,
    SearchIcon
  },
  setup() {
    const store = useStore()

    const protocols = computed(() => store.state.protocol.protocols)
    const filteredProtocols = ref(protocols.value)
    const search = ref('')

    const modalActive = ref(false)
    const showDeleteProtocolModal = ref(false)
    const showDeleteProtocolSuccessModal = ref(false)

    watch(search, () => {
      if (search.value === '') {
        filteredProtocols.value = protocols.value
      }
      filteredProtocols.value = protocols.value.filter((protocol) =>
        protocol.name.includes(search.value)
      )
    })

    watch(protocols, () => {
      filteredProtocols.value = protocols.value.filter((protocol) =>
        protocol.name.includes(search.value)
      )
    })

    const openModal = () => {
      modalActive.value = true
    }

    const openDeleteModal = (protocolId) => {
      showDeleteProtocolModal.value = true
      store.dispatch('setDeleteProtocolId', protocolId)
    }

    const openShowDeleteProtocolSuccessModal = () => {
      showDeleteProtocolSuccessModal.value = true
      setTimeout(() => {
        showDeleteProtocolSuccessModal.value = false
      }, 1200)
    }

    const closeDeleteProtocolModal = () => {
      showDeleteProtocolModal.value = false
      store.dispatch('setDeleteProtocolId', null)
    }

    const closeDeleteProtocolSuccessModal = () => {
      showDeleteProtocolSuccessModal.value = false
    }

    const onChangeSearchProtocol = (e) => {
      search.value = e.target.value
    }

    const closeCreateModal = () => {
      modalActive.value = false
      store.dispatch('clearNewProtocol')
    }

    const deleteProtocol = () => {
      store.dispatch('deleteProtocol')
      closeDeleteProtocolModal()
      openShowDeleteProtocolSuccessModal()
    }

    return {
      filteredProtocols,
      showDeleteProtocolModal,
      showDeleteProtocolSuccessModal,
      openModal,
      openDeleteModal,
      openShowDeleteProtocolSuccessModal,
      modalActive,
      closeCreateModal,
      closeDeleteProtocolModal,
      closeDeleteProtocolSuccessModal,
      onChangeSearchProtocol,
      deleteProtocol,
      search
    }
  }
}
</script>
