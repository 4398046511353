<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.73 11.7705C16.73 11.8405 16.73 11.9005 16.73 11.9705C16.73 14.3105 14.78 17.4105 10.73 21.1105C6.67998 17.4105 4.72998 14.3205 4.72998 11.9705C4.72998 8.34047 7.37998 5.77047 10.73 5.77047C11.07 5.77047 11.41 5.80047 11.73 5.85047V3.83047C11.4 3.79047 11.07 3.77047 10.73 3.77047C6.52998 3.77047 2.72998 6.99047 2.72998 11.9705C2.72998 15.2905 5.39998 19.2205 10.73 23.7705C16.06 19.2205 18.73 15.2905 18.73 11.9705C18.73 11.9005 18.73 11.8405 18.73 11.7705H16.73Z"
      fill="#9E9EB7"
    />
    <path
      d="M10.73 13.7705C11.8345 13.7705 12.73 12.875 12.73 11.7705C12.73 10.6659 11.8345 9.77047 10.73 9.77047C9.62541 9.77047 8.72998 10.6659 8.72998 11.7705C8.72998 12.875 9.62541 13.7705 10.73 13.7705Z"
      fill="#9E9EB7"
    />
    <path
      d="M21.27 3.65047L19.85 2.23047L17.73 4.36047L15.61 2.23047L14.19 3.65047L16.32 5.77047L14.19 7.89047L15.61 9.31047L17.73 7.18047L19.85 9.31047L21.27 7.89047L19.14 5.77047L21.27 3.65047Z"
      fill="#9E9EB7"
    />
    <rect x="14" y="2" width="8" height="8" fill="white" />
    <path
      d="M23.5 6C23.5 9.00064 20.8521 11.5 17.5 11.5C14.1479 11.5 11.5 9.00064 11.5 6C11.5 2.99936 14.1479 0.5 17.5 0.5C20.8521 0.5 23.5 2.99936 23.5 6Z"
      stroke="#9E9EB7"
    />
    <rect x="17" y="8" width="1.5" height="1.5" fill="#9E9EB7" />
    <rect x="17" y="2" width="1.5" height="4.8" fill="#9E9EB7" />
  </svg>
</template>

<script>
export default {}
</script>
