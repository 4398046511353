import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "filter-btn" }
const _hoisted_2 = { class: "max-h-[285px] py-[16px]" }
const _hoisted_3 = {
  key: 0,
  class: "flex gap-1 items-center w-full text-[16px] font-normal px-[16px] pb-[16px]"
}
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "text-[14px] scrolbar-filter overflow-y-scroll pl-[16px] pr-[8px] max-h-[190px] text-left w-full h-full flex flex-col items-center font-normal" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_icon = _resolveComponent("search-icon")!
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createBlock(_component_Popper, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.withSearch)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_search_icon, { class: "absolute left-[25px]" }),
              _withDirectives(_createElementVNode("input", {
                placeholder: `Buscas ${_ctx.title}`,
                class: "py-[16px] pr-[5px] pl-[40px] w-full rounded-md bg-[#F0F0F4] focus-visible:outline-0",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event))
              }, null, 8, _hoisted_4), [
                [_vModelText, _ctx.searchValue]
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsFiltered, (item) => {
            return (_openBlock(), _createElementBlock("span", {
              key: item.value,
              onClick: ($event: any) => (_ctx.$emit('clicked', item)),
              class: "w-full cursor-pointer p-[6px] hover:bg-gray-200"
            }, _toDisplayString(item.title), 9, _hoisted_6))
          }), 128))
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon))),
        _createElementVNode("p", null, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    _: 1
  }))
}