<template>
  <div class="w-full flex flex-col gap-4">
    <!-- Search -->
    <div class="w-full flex flex-row justify-between items-start gap-4">
      <div class="w-4/5">
        <SearchInput
          v-if="tab == 1"
          v-model="searchInput"
          width="80%"
          label="Buscar pelo nome, CRM ou CRMUF"
          :searchFunction="getCompanyDoctors"
        />
        <SearchInput
          v-if="tab == 2"
          v-model="searchInput"
          width="80%"
          label="Buscar pelo nome, CRM ou CRMUF"
          :searchFunction="getCompanyPendingDoctors"
        />
      </div>
      <div class="w-1/5">
        <FilledButton
          @click="() => (createInviteModalProps = true)"
          text="Criar convite"
          width="100%"
          height="56px"
        />
      </div>
      <!-- <div v-show="tab === 1">
        <div class="w-full flex sm:flex-row flex-col gap-4">
          <SearchInput
            v-model="searchInput"
            width="100%"
            label="Buscar pelo nome, CRM ou CRMUF"
          />
          <div class="sm:w-2/5 w-full min-h-[52px]">
            <simple-select
              class="h-[52px]"
              :opts="[
                {
                  label: 'CRM/UF',
                  value: 0,
                  placeholder: true
                },
                {
                  label: 'MG',
                  value: 1
                },
                {
                  label: 'SP',
                  value: 2
                },
                {
                  label: 'BA',
                  value: 3
                }
              ]"
              :required="true"
            />
          </div>
        </div>
      </div>
      <div v-show="tab === 2">
        <div class="flex justify-between">
          <div class="md:max-w-[500px] w-full">
            <Input v-model="searchInputCRM" width="100%" label="Buscar pelo CRM" />
          </div>
          <div class="w-full md:max-w-[120px] hidden md:inline">
            <FilledButton
              @click="() => (createInviteModalProps = true)"
              text="Criar convite"
              width="100%"
            />
          </div>
        </div>
      </div> -->
    </div>

    <!-- Tabs -->
    <ul
      class="sm:gap-14 gap-0 border-b flex sm:justify-start justify-between text-white select-none"
    >
      <li>
        <button
          class="w-max"
          @click="() => (tab = 1)"
          :class="tab === 1 ? activeClass : inactiveClass"
        >
          Meus Médicos
        </button>
      </li>

      <li>
        <button
          class="w-max"
          @click="() => (tab = 2)"
          :class="tab === 2 ? activeClass : inactiveClass"
        >
          Médicos pendentes
        </button>
      </li>
    </ul>

    <!-- Content -->
    <div class="flex flex-col gap-10 sm:h-[570px] justify-between">
      <div v-if="tab === 1">
        <CardsTab :companyDoctors="companyDoctors" />
      </div>
      <div v-if="tab === 2">
        <OutstandingDoctors :companyDoctors="companyDoctors" />
        <div class="w-full md:max-w-[120px] inline md:hidden">
          <FilledButton
            @click="() => (createInviteModalProps = true)"
            text="Criar convite"
            width="100%"
          />
        </div>
      </div>
      <!-- <Paginate /> -->
    </div>
  </div>
  <CreateInviteModal
    :modalStateProp="createInviteModalProps"
    :updateListFunction="getCompanyDoctors"
    @close="() => (createInviteModalProps = false)"
  />
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import { useStore } from 'vuex'
// import Paginate from '@/components/designSystem/Paginate/index.vue'
import { CreateInviteModal } from './components/atoms'
import { CardsTab, OutstandingDoctors } from './components/organisms/index'
import SearchInput from '@/components/designSystem/Inputs/SearchInput.vue'
import Network from '@/Network'
import { DoctorCompanyResponse } from '@/Network/Types/Responses/CompanyResponses'
import { CompanyDoctorInviteStatusEnum } from '@/Network/Types/Requests/CompanyRequests'

const store = useStore()
const activeClass = ref('inline-block py-4  border-b-2 border-primary text-black font-semibold')
const inactiveClass = ref('inline-block py-4 text-gray-500')
const tab = ref(1)
const searchInput = ref('')
const searchInputCRM = ref('')
const createInviteModalProps = ref(false)
const companyDoctors = ref<DoctorCompanyResponse[]>()

watch(searchInput, () => {
  store.dispatch('filterCards', searchInput.value)
})

watch(searchInputCRM, () => {
  store.dispatch('filterCrm', searchInputCRM.value)
})

const getCompanyDoctors = async () => {
  try {
    const { data } = await Network.company.getCompanyDoctors({
      search: searchInput.value,
      inviteStatus: CompanyDoctorInviteStatusEnum.ACCEPTED
    })
    companyDoctors.value = data
  } catch (error) {
    console.error(error)
  }
}

const getCompanyPendingDoctors = async () => {
  try {
    const { data } = await Network.company.getCompanyDoctors({
      search: searchInput.value,
      inviteStatus: CompanyDoctorInviteStatusEnum.PENDING
    })
    console.log(data)
    companyDoctors.value = data
  } catch (error) {
    console.error(error)
  }
}
</script>

<style scoped>
.title {
  font-size: 1.25rem;
}

.tabs {
  border-bottom-width: 2px;
}

.tabs > li > button {
  min-width: 80px;
  text-align: start;
}
</style>
