interface address {
  cep: string
  uf: string
  city: string
  street: string
  number: string
}

interface card {
  photo: string
  name: string
  email: string
  phone: string
  specialty: string
  code: string
  amountOfHour: number
  address: address
  rating: number
  documents: string[]
}

interface crm {
  name: string
  url: string
}

interface IStates {
  cards: card[]
  mockCrm: crm[]
  filteredMock: card[]
  filteredMockCrm: crm[]
}

export default {
  state(): IStates {
    return {
      cards: [
        {
          photo: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png',
          name: 'Marli Sara Vieira',
          email: 'marlisaravieira@iega.com.br',
          phone: '(11) 00000-0000',
          specialty: 'Clínica médica',
          code: '000000-SP',
          amountOfHour: 12,
          address: {
            cep: '68901-335',
            uf: 'AP',
            city: 'Macapá',
            street: 'Avenida Professora Cora de Carvalho',
            number: '874'
          },
          rating: 2,
          documents: ['documento 1', 'documento 2', 'documento 3', 'documento 4']
        },
        {
          photo: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png',
          name: 'Carlos Edson Pedro Henrique Barros',
          email: 'carlos_edson_barros@marquesalcantra.comabdv.com.br',
          phone: '(11) 00000-0000',
          specialty: 'Clínica médica',
          code: '000000-SP',
          amountOfHour: 12,
          address: {
            cep: '68901-335',
            uf: 'AP',
            city: 'Macapá',
            street: 'Avenida Professora Cora de Carvalho',
            number: '874'
          },
          rating: 5,
          documents: ['documento 1']
        },
        {
          photo: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png',
          name: 'Heloisa Lorena Ramos',
          email: 'Heloisa@Lorena.comabdv.com.br',
          phone: '(11) 00000-0000',
          specialty: 'Clínica médica',
          code: '000000-SP',
          amountOfHour: 12,
          address: {
            cep: '68901-335',
            uf: 'AP',
            city: 'Macapá',
            street: 'Avenida Professora Cora de Carvalho',
            number: '874'
          },
          rating: 4,
          documents: []
        },
        {
          photo: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png',
          name: 'Marli Sara Vieira',
          email: 'marlisaravieira@iega.com.br',
          phone: '(11) 00000-0000',
          specialty: 'Clínica médica',
          code: '000000-SP',
          amountOfHour: 12,
          address: {
            cep: '68901-335',
            uf: 'AP',
            city: 'Macapá',
            street: 'Avenida Professora Cora de Carvalho',
            number: '874'
          },
          rating: 2,
          documents: ['documento 1', 'documento 2', 'documento 3', 'documento 4']
        },
        {
          photo: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png',
          name: 'Carlos Edson Pedro Henrique Barros',
          email: 'carlos_edson_barros@marquesalcantra.comabdv.com.br',
          phone: '(11) 00000-0000',
          specialty: 'Clínica médica',
          code: '000000-SP',
          amountOfHour: 12,
          address: {
            cep: '68901-335',
            uf: 'AP',
            city: 'Macapá',
            street: 'Avenida Professora Cora de Carvalho',
            number: '874'
          },
          rating: 5,
          documents: ['documento 1']
        },
        {
          photo: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png',
          name: 'Heloisa Lorena Ramos',
          email: 'Heloisa@Lorena.comabdv.com.br',
          phone: '(11) 00000-0000',
          specialty: 'Clínica médica',
          code: '000000-SP',
          amountOfHour: 12,
          address: {
            cep: '68901-335',
            uf: 'AP',
            city: 'Macapá',
            street: 'Avenida Professora Cora de Carvalho',
            number: '874'
          },
          rating: 4,
          documents: []
        },
        {
          photo: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png',
          name: 'Marli Sara Vieira',
          email: 'marlisaravieira@iega.com.br',
          phone: '(11) 00000-0000',
          specialty: 'Clínica médica',
          code: '000000-SP',
          amountOfHour: 12,
          address: {
            cep: '68901-335',
            uf: 'AP',
            city: 'Macapá',
            street: 'Avenida Professora Cora de Carvalho',
            number: '874'
          },
          rating: 2,
          documents: ['documento 1', 'documento 2', 'documento 3', 'documento 4']
        },
        {
          photo: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png',
          name: 'Carlos Edson Pedro Henrique Barros',
          email: 'carlos_edson_barros@marquesalcantra.comabdv.com.br',
          phone: '(11) 00000-0000',
          specialty: 'Clínica médica',
          code: '000000-SP',
          amountOfHour: 12,
          address: {
            cep: '68901-335',
            uf: 'AP',
            city: 'Macapá',
            street: 'Avenida Professora Cora de Carvalho',
            number: '874'
          },
          rating: 5,
          documents: ['documento 1']
        },
        {
          photo: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png',
          name: 'Heloisa Lorena Ramos',
          email: 'Heloisa@Lorena.comabdv.com.br',
          phone: '(11) 00000-0000',
          specialty: 'Clínica médica',
          code: '000000-SP',
          amountOfHour: 12,
          address: {
            cep: '68901-335',
            uf: 'AP',
            city: 'Macapá',
            street: 'Avenida Professora Cora de Carvalho',
            number: '874'
          },
          rating: 4,
          documents: []
        }
      ],
      mockCrm: [
        {
          name: '03/03/2022 21:20 | CRM 204324-SP',
          url: 'https://stage.buscaplantao.com.br/convite/JDJiJDEwJHlQa2JvTXphYlV1Taaaaaaaaaaaaaa'
        },
        {
          name: '03/03/2022 21:20 | CRM 404324-SP',
          url: 'https://stage.buscaplantao.com.br/convite/JDJiJDEwJHlQa2JvTXphYlV1Taaaaaaaaaaaaaa'
        },
        {
          name: '03/03/2022 21:20 | CRM 604324-SP',
          url: 'https://stage.buscaplantao.com.br/convite/JDJiJDEwJHlQa2JvTXphYlV1Taaaaaaaaaaaaaa'
        }
      ],
      filteredMock: [],
      filteredMockCrm: []
    }
  },
  mutations: {
    filterMock(state: IStates, query: string) {
      state.filteredMock =
        query.length > 0
          ? state.cards.filter((_card: card) =>
              _card.name.toLowerCase().includes(query.toLowerCase())
            )
          : state.cards
    },
    filterMockCrm(state: IStates, query: string) {
      state.filteredMockCrm =
        query.trim().length > 0
          ? state.mockCrm.filter((_crm: crm) => {
              const code = /CRM (.*)/g.exec(_crm.name)?.[1].toLowerCase()

              return code?.includes(query.toLowerCase())
            })
          : state.mockCrm
    }
  },
  actions: {
    filterCards(context: any, param: string) {
      context.commit('filterMock', param)
    },
    filterCrm(context: any, param: string) {
      context.commit('filterMockCrm', param)
    }
  },
  getters: {
    getFilteredCards(state: IStates) {
      return state.filteredMock
    },
    getFilteredCrm(state: IStates) {
      return state.filteredMockCrm
    }
  }
}
