<template>
  <div class="flex flex-col">
    <div class="flex flex-row gap-8 mb-5">
      <Input
        :isError="isDutyFieldInvalid.speciality"
        @input="onChangeNewDuty"
        errorMessage="Especialidade inválida"
        label="Especialidade"
        width="100%"
        :value="newDuty.speciality"
        name="speciality"
        required
      />
      <Input
        :isError="isDutyFieldInvalid.sector"
        @input="onChangeNewDuty"
        errorMessage="Setor inválido"
        label="Setor"
        width="100%"
        :value="newDuty.sector"
        name="sector"
        required
      />
    </div>
    <div class="flex flex-row gap-8 mb-5">
      <Input
        :isError="isDutyFieldInvalid.initialHour"
        @input="onChangeNewDuty"
        errorMessage="Horário inicial inválido"
        label="Horário inicial"
        width="100%"
        mask="##:##"
        pattern="^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
        :value="newDuty.initialHour"
        name="initialHour"
        required
      />
      <Input
        :isError="isDutyFieldInvalid.endHour"
        @input="onChangeNewDuty"
        errorMessage="Horário final inválido"
        label="Horário final"
        width="100%"
        mask="##:##"
        pattern="^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
        :value="newDuty.endHour"
        name="endHour"
        required
      />
    </div>
    <div class="flex flex-row gap-8 mb-5">
      <Input
        :isError="isDutyFieldInvalid.value"
        @input="onChangeNewDuty"
        errorMessage="Valor por plantão inválido"
        label="Valor por plantão"
        width="100%"
        mask="#*,##"
        pattern="^(?=.)(([0-9]*)(,([0-9]+))?)$"
        :value="newDuty.value"
        name="value"
        required
      />
      <Input
        :isError="isDutyFieldInvalid.payer"
        @input="onChangeNewDuty"
        errorMessage="Pagador inválido"
        label="Pagador"
        width="100%"
        :value="newDuty.payer"
        name="payer"
        required
      />
    </div>
    <div class="flex flex-row gap-8 mb-5">
      <Input
        :isError="isDutyFieldInvalid.closeDay"
        @input="onChangeNewDuty"
        errorMessage="Dia de fechamento inválido"
        label="Dia de fechamento"
        width="100%"
        :value="newDuty.closeDay"
        name="closeDay"
        required
      />
      <Input
        :isError="isDutyFieldInvalid.paymentDate"
        @input="onChangeNewDuty"
        errorMessage="Data de pagamento inválida"
        label="Data de pagamento"
        width="100%"
        :value="newDuty.paymentDate"
        name="paymentDate"
        required
      />
    </div>
    <div class="font-bold text-left mb-2">Deduções fiscais</div>
    <div class="flex flex-row gap-8 mb-8">
      <Input
        :isError="isDutyFieldInvalid.fiscalDeducationName"
        @input="onChangeNewDuty"
        errorMessage="Nome inválido"
        label="Nome"
        width="100%"
        :value="newDuty.fiscalDeducationName"
        name="fiscalDeducationName"
        required
      />
      <Input
        :isError="isDutyFieldInvalid.fiscalDeducationPercentage"
        @input="onChangeNewDuty"
        errorMessage="Valor inválido"
        label="Valor (%)"
        width="100%"
        mask="#*,##"
        pattern="[0-9]+([\.,][0-9]+)?"
        :value="newDuty.fiscalDeducationPercentage"
        name="fiscalDeducationPercentage"
        required
      />
    </div>
    <div class="flex justify-end gap-8 mb-8">
      <OutlineButton text="Cancelar" width="151px" @click="cancelCreation" />
      <FilledButton
        :text="`${isEditingDuty ? 'Editar plantão' : 'Adicionar plantão'}`"
        width="151px"
        @click="saveDuty"
      />
    </div>
  </div>
</template>

<script>
import Input from '@/components/designSystem/Inputs/Input.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import { useStore } from 'vuex'
import { computed, reactive } from 'vue'
import { isEmpty, validateNumberField, validateHour } from '@/utils/validation'

export default {
  name: 'CreateDuty',
  props: ['contractIndex'],
  emits: ['setShowSaveDuty', 'setShowContractContent'],
  components: {
    Input,
    FilledButton,
    OutlineButton
  },
  setup(props, { emit }) {
    const store = useStore()
    const newDuty = computed(() => store.state.contract.newDuty)
    const isEditingDuty = computed(() => store.state.contract.editedDutyIndex !== -1)

    let isDutyFieldInvalid = reactive({
      speciality: false,
      sector: false,
      initialHour: false,
      endHour: false,
      value: false,
      payer: false,
      closeDay: false,
      paymentDate: false,
      fiscalDeducationName: false,
      fiscalDeducationPercentage: false
    })
    const isValid = (property) => !property

    const validateDuty = () => {
      isDutyFieldInvalid.speciality = isEmpty(newDuty.value.speciality)
      isDutyFieldInvalid.sector = isEmpty(newDuty.value.sector)
      isDutyFieldInvalid.initialHour = !validateHour(newDuty.value.initialHour)
      isDutyFieldInvalid.endHour = !validateHour(newDuty.value.endHour)
      isDutyFieldInvalid.value = !validateNumberField(newDuty.value.value)
      isDutyFieldInvalid.payer = isEmpty(newDuty.value.payer)
      isDutyFieldInvalid.closeDay = isEmpty(newDuty.value.closeDay)
      isDutyFieldInvalid.paymentDate = isEmpty(newDuty.value.paymentDate)
      isDutyFieldInvalid.fiscalDeducationName = isEmpty(newDuty.value.fiscalDeducationName)
      isDutyFieldInvalid.fiscalDeducationPercentage = !validateNumberField(
        newDuty.value.fiscalDeducationPercentage
      )

      const dontHaveInvalidField = !Object.values(isDutyFieldInvalid).find(
        (value) => value === true
      )
      return dontHaveInvalidField
    }

    const cancelCreation = () => {
      emit('setShowSaveDuty', false)
      store.dispatch('clearNewDuty')
    }

    const clearValidation = () => {
      isDutyFieldInvalid = {
        speciality: false,
        sector: false,
        initialHour: false,
        endHour: false,
        value: false,
        payer: false,
        closeDay: false,
        paymentDate: false,
        fiscalDeducationName: false,
        fiscalDeducationPercentage: false
      }
    }

    const saveDuty = () => {
      if (!validateDuty()) return

      if (isEditingDuty.value) {
        store.dispatch('editDuty', props.contractIndex)
      } else {
        store.dispatch('createDuty', props.contractIndex)
      }
      emit('setShowSaveDuty', false)
      emit('setShowContractContent', true)
      clearValidation()
    }

    const onChangeNewDuty = (e) => {
      store.dispatch('setNewDuty', { property: e.target.name, value: e.target.value })
    }

    return {
      isDutyFieldInvalid,
      isValid,
      newDuty,
      saveDuty,
      isEditingDuty,
      cancelCreation,
      onChangeNewDuty
    }
  }
}
</script>
