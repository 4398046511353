<template>
  <div class="w-full h-full grid-layout my-0">
    <div class="w-full lg:flex justify-between items-center">
      <div class="hidden lg:inline w-full">
        <Input v-model="textInput" width="80%" label="Buscar escala" />
      </div>
      <div class="lg:hidden inline w-full" style="grid-area: search">
        <Input v-model="textInput" width="100%" label="Buscar escala" />
      </div>
    </div>

    <ResultsTable />

    <div class="grid grid-cols-1 lg:hidden gap-4 my-0" style="grid-area: buttons"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import ResultsTable from './components/ResultsTable/index.vue'
import Input from '../../../components/designSystem/Inputs/Input.vue'

const store = useStore()
const textInput = ref('')

watch(textInput, () => {
  store.dispatch('filterUsers', textInput.value)
})
</script>

<style scoped>
.grid-layout {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 64px 1fr;
  gap: 0px 0px;
  grid-template-areas:
    'search'
    'table';
}

@media only screen and (max-width: 1024px) {
  .grid-layout {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: 58px 1fr 132px;
    gap: 0px 0px;
    grid-template-areas:
      'search'
      'table'
      'buttons';
  }
}
</style>
