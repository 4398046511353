import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0700e93e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex mt-8" }
const _hoisted_3 = { class: "mt-10" }
const _hoisted_4 = ["onClick", "to"]
const _hoisted_5 = {
  key: 0,
  class: "mx-4"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["onClick", "to"]
const _hoisted_11 = { class: "mx-4" }
const _hoisted_12 = {
  key: 0,
  class: "mx-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_left_arrow = _resolveComponent("left-arrow")!
  const _component_rigth_arrow = _resolveComponent("rigth-arrow")!
  const _component_up_icon = _resolveComponent("up-icon")!
  const _component_down_icon = _resolveComponent("down-icon")!
  const _component_LogoutIcon = _resolveComponent("LogoutIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([[
        $setup.isMobile && $setup.isOpen ? 'fixed' : '',
        $setup.isOpen ? 'w-64' : '',
        $setup.isMobile && !$setup.isOpen ? 'hidden' : ''
      ], "inset-y-0 left-0 z-30 p-3 overflow-y-auto transition duration-300 transform bg-white shadow min-h-[100vh]"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["w-full flex", $setup.isOpen ? 'justify-between' : 'justify-center'])
        }, [
          ($setup.isOpen)
            ? (_openBlock(), _createBlock(_component_Logo, {
                key: 0,
                width: "120px",
                logo: "variant"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.showSideBar && $setup.showSideBar(...args))),
            class: "text-gray-500 focus:outline-none lg:block"
          }, [
            ($setup.isOpen)
              ? (_openBlock(), _createBlock(_component_left_arrow, { key: 0 }))
              : (_openBlock(), _createBlock(_component_rigth_arrow, { key: 1 }))
          ])
        ], 2)
      ]),
      _createElementVNode("nav", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.menus.items, (menu, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            onClick: ($event: any) => ($setup.clickItem($event, menu)),
            class: "flex flex-col",
            to: menu.path
          }, [
            (menu.active)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass([[_ctx.$route.name === menu.path ? $data.activeClass : $data.inactiveClass], "flex items-center px-6 py-3 mt-4 duration-200 rounded cursor-pointer hover-scale"])
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent($setup.getIconComponent(menu.icon)), {
                    color: _ctx.$route.name === menu.path ? '#FFFFFF' : '#1E1E29'
                  }, null, 8, ["color"])),
                  ($setup.isOpen)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(menu.name), 1))
                    : _createCommentVNode("", true),
                  (menu.childrens.length > 0 && $setup.isOpen)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        onClick: ($event: any) => ($setup.clickExpand($event, menu.path))
                      }, [
                        ($setup.expandChildrens[menu.path])
                          ? (_openBlock(), _createElementBlock("a", _hoisted_7, [
                              _createVNode(_component_up_icon, {
                                color: _ctx.$route.name === menu.path ? '#FFFFFF' : '#1E1E29'
                              }, null, 8, ["color"])
                            ]))
                          : (_openBlock(), _createElementBlock("a", _hoisted_8, [
                              _createVNode(_component_down_icon, {
                                color: _ctx.$route.name === menu.path ? '#FFFFFF' : '#1E1E29'
                              }, null, 8, ["color"])
                            ]))
                      ], 8, _hoisted_6))
                    : _createCommentVNode("", true)
                ], 2))
              : _createCommentVNode("", true),
            (menu.childrens.length > 0 && $setup.expandChildrens[menu.path] && $setup.isOpen)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.childrens, (children, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass([[_ctx.$route.name === children.path ? $data.activeClass : $data.inactiveClass], "flex items-center px-6 py-3 duration-200 rounded cursor-pointer hover-scale"]),
                      key: index,
                      onClick: ($event: any) => ($setup.clickChildrenItem($event, children)),
                      to: children.path
                    }, [
                      _createElementVNode("span", _hoisted_11, _toDisplayString(children.name), 1)
                    ], 10, _hoisted_10))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ], 8, _hoisted_4))
        }), 128))
      ]),
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($data.logout && $data.logout(...args))),
        class: _normalizeClass(["flex items-center px-6 py-2 mt-10 duration-200", [_ctx.$route.name === 'Blank' ? $data.activeClass : $data.inactiveClass]])
      }, [
        _createVNode(_component_LogoutIcon),
        ($setup.isOpen)
          ? (_openBlock(), _createElementBlock("span", _hoisted_12, "Deslogar"))
          : _createCommentVNode("", true)
      ], 2)
    ], 2)
  ]))
}