<template>
  <component :is="layout">
    <router-view />
    <alert-modal />
  </component>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import AlertModal from '@/components/designSystem/Alerts/index.vue'
const defaultLayout = 'default'

const { currentRoute } = useRouter()

const layout = computed(() => `${currentRoute.value.meta.layout || defaultLayout}-layout`)
</script>

<style>
#app {
  font-family: Inter, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .v-input--is-focused .v-input__slot {
    border: 2px solid #005fcc !important;
    border-bottom-color: rgba(255, 13, 13, 0.38) !important;
  }
}

.fade-in-top {
  -webkit-animation: fade-in-top 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-out-top {
  -webkit-animation: fade-out-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-out-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes fade-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
}

@keyframes fade-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
}

.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: #ffffff;
  border: 1px solid red;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  &:before {
    display: none;
  }
}
</style>
