<template>
  <v-row>
    <template v-if="isLoading">
      <v-col class="text-primary font-bold text-center" cols="12">
        Buscando plantões, aguarde!
      </v-col>
      <v-col cols="12">
        <v-progress-linear color="primary" indeterminate rounded />
      </v-col>
    </template>
    <v-col
      v-else
      v-for="(row, i) in stationsToApprove"
      :key="i + row.doctorCRM"
      cols="12"
      sm="6"
      md="3"
      xl="2"
    >
      <card-doctor
        :doctorName="row.doctorName"
        :doctorCrm="row.doctorCRM"
        :hospitalName="row.healthUnitName"
        :dateDuty="row.date"
        :hoursOfDuty="row.hoursStation"
        :hoursOfDutPerformed="row.hoursCheck"
        :dutyFee="row.value"
        :stationId="row.stationId"
        @clickDetails="clickDetails"
        @clickApprove="clickApprove"
      />
    </v-col>
  </v-row>
  <details-modal
    :currentDoctor="currentDoctor"
    :showDetailsModal="showDetailsModal"
    @closeDetailsModal="closeDetailsModal"
  />
  <approve-modal
    v-if="showApproveModal"
    :currentDoctor="currentDoctor"
    :showApproveModal="showApproveModal"
    @closeApproveModal="closeApproveModal"
    @approvePayment="approvePayment"
  />
  <approve-success-modal
    :showApproveSuccessModal="showApproveSuccessModal"
    @closeApproveSuccessModal="closeApproveSuccessModal"
  />
</template>

<script lang="ts" setup>
import { ref, onBeforeMount } from 'vue'
import CardDoctor from './components/CardDoctor.vue'
import DetailsModal from './components/DetailsModal.vue'
import ApproveModal from './components/ApproveModal.vue'
import ApproveSuccessModal from './components/ApproveSuccessModal.vue'
import { StationsToApproveResponse } from '@/Network/Types/Responses/StationResponses'
import { useStationsApproveStore } from '@/pinia-store/useStationStore'
import { storeToRefs } from 'pinia'
import { useFilterStationsStore } from '@/components/Filters/useFilterStation'
import Network from '@/Network'
import { useToast } from 'vue-toastification'
import Axios from 'axios'
const useFilterStations = useFilterStationsStore()
const { stationsToApprove, isLoading } = storeToRefs(useStationsApproveStore())

onBeforeMount(async () => {
  await useFilterStations.initialRequest()
})
const currentDoctor = ref<StationsToApproveResponse>()
const showDetailsModal = ref(false)
const showApproveModal = ref(false)
const showApproveSuccessModal = ref(false)

const clickDetails = (stationId: number) => {
  currentDoctor.value = stationsToApprove.value.find((d) => d.stationId === stationId)
  showDetailsModal.value = true
}

const clickApprove = (stationId: number) => {
  currentDoctor.value = stationsToApprove.value.find((d) => d.stationId === stationId)
  showApproveModal.value = true
}

const closeDetailsModal = () => {
  showDetailsModal.value = false
  currentDoctor.value = undefined
}
const closeApproveModal = () => {
  showApproveModal.value = false
  currentDoctor.value = undefined
}

const closeApproveSuccessModal = () => {
  showApproveSuccessModal.value = false
}
const toast = useToast()

const approvePayment = async ({ stationId, value }: { stationId: number; value: number }) => {
  try {
    await Network.station.approveStation({ stationId, value })
    showApproveModal.value = false
    showApproveSuccessModal.value = true
    toast.success('Plantão aprovado com sucesso')
  } catch (error) {
    showApproveModal.value = false
    let message = `Aconteceu um erro ao aprovar o plantão`
    if (Axios.isAxiosError(error)) {
      message = error.response?.data?.message || message
    }
    toast.error(message)
  }
}
</script>
