import * as yup from 'yup'
import { setLocale } from 'yup'

setLocale({
  mixed: {
    default: 'Campo inválido',
    required: 'Campo obrigatório'
  },
  string: {},
  number: {
    integer: 'O número deve ser um inteiro'
  }
  // Adicione mais traduções de acordo com os tipos de validação do Yup que você está utilizando
})

export const requiredNumberValidator = yup.number().typeError('Formato inválido').required()

export const requiredStringValidator = yup.string().typeError('Formato inválido').required()

export const birthDateValidator = yup
  .string()
  .typeError('Formato de data inválido')
  .required('Data é obrigatória')
  .matches(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/, 'Data inválida')

export const nameValidator = yup
  .string()
  .min(5, 'Nome deve ter no mínimo 5 caracteres')
  .typeError('Nome inválido')
  .required('Nome é obrigatório')

export const addressValidator = yup
  .string()
  .typeError('Endereço inválido')
  .required('Endereço é obrigatório')

export const bioValidator = yup
  .string()
  .typeError('Sobre inválido')
  .required('Sobre é obrigatório')
  .min(20, 'Sobre deve ter no mínimo 20 caracteres')

export const emailValidator = yup
  .string()
  .typeError('Email inválido')
  .email('Email inválido')
  .required('Email é obrigatório')

export const emailConfirmationValidator = (prop: string): any =>
  yup
    .string()
    .typeError('Email de confirmação inválido')
    .oneOf([yup.ref(prop)], 'Os emails não são iguais')
    .required('Email de confirmação é obrigatório')

export const passwordValidator = yup
  .string()
  .typeError('Senha inválida')
  .required('Senha é obrigatória')
  .min(8, 'Senha deve ter no mínimo 8 caracteres')

export const documentNumberValidator = yup
  .string()
  .typeError('CPF/CNPJ inválido')
  // .required('CPF/CNPJ é obrigatório')
  .matches(
    /^(\d{3}\.\d{3}\.\d{3}-\d{2})|(\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/,
    'CPF/CNPJ deve seguir a seguinte regra "XXX.XXX.XXX-XX"/"XX.XXX.XXX/XXXX-XX"'
  )

export const personalDocumentNumberValidator = yup
  .string()
  .typeError('CPF inválido')
  // .required('CPF é obrigatório')
  .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF deve seguir a seguinte regra "XXX.XXX.XXX-XX"')

export const companyDocumentNumberValidator = yup
  .string()
  .typeError('CNPJ inválido')
  .required('CNPJ é um campo obrigatório')
  .matches(
    /^(\d{3}\.\d{3}\.\d{3}-\d{2})|(\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/,
    'CNPJ deve seguir a seguinte regra "XX.XXX.XXX/XXXX-XX"'
  )

export const companyNameValidator = yup
  .string()
  .min(5, 'Nome da Empresa deve ter no mínimo 5 caracteres')
  .typeError('Nome não é válido.')
  .required('Nome da Empresa é um campo obrigatório.')

export const phoneNumberValidator = yup
  .string()
  .typeError('Telefone inválido')
  .required('Telefone é obrigatório')
  .matches(/^\(\d{2}\) \d{4,5}-\d{4}$/, 'Número inválido. Tente novamente, não se esqueça do DDD.')

export const postalCodeValidator = yup
  .string()
  .typeError('CEP inválido')
  .required('CEP é obrigatório')
  .matches(/^\d{5}-\d{3}$/, 'CEP deve seguir a seguinte regra "XXXXXX-XXX"')
