<template>
  <v-row dense>
    <v-col
      cols="12"
      class="flex items-center gap-2 cursor-pointer"
      @click="showForm = !showForm"
      :class="showForm ? 'bg-gray-100 rounded-t-lg px-4 py-2' : ''"
    >
      <h2 class="text-left font-bold text-[18px]">Criar Escala</h2>
      <arrow-icon :class="showForm ? 'rotate-180' : ''" color="#000" />
    </v-col>

    <v-row
      v-if="showForm"
      dense
      class="items-center bg-gray-100 rounded-lg p-2"
      :class="showForm ? 'rounded-t-none' : ''"
    >
      <v-col class="md:mt-0" cols="12" md="3">
        <v-text-field
          v-model="scheduleName"
          label="Nome da Escala*"
          class="w-full"
          color="primary20"
          variant="outlined"
          hide-details
        />
      </v-col>
      <v-col class="md:mt-0 mt-4" cols="12" md="3">
        <v-autocomplete
          v-model="healthUnitSelected"
          class="w-full"
          color="primary20"
          label="Buscar Unidade *"
          theme="light"
          :items="listHealthUnits"
          variant="outlined"
          hide-details
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="item?.raw?.title"
              :subtitle="item?.raw?.subtitle"
            ></v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col class="md:mt-0 mt-4" cols="12" md="3">
        <v-autocomplete
          v-model="scheduleTimezone"
          class="w-full"
          color="primary20"
          label="Fuso Horário"
          theme="light"
          :items="timezones"
          variant="outlined"
          hide-details
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="item?.raw?.title"
              :subtitle="item?.raw?.subtitle"
            ></v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="3" class="flex justify-end">
        <span class="flex items-center gap-2 text-[16px]">
          <add-circle-icon
            @click="$emit('openCreateHospitalModal')"
            class="cursor-pointer"
            bg="#f3f4f600"
          />
          <span @click="$emit('openCreateHospitalModal')" class="cursor-pointer">
            Cadastrar Hospital
          </span>
        </span>
      </v-col>

      <v-col class="md:mt-0 mt-2" cols="12" md="4">
        <v-checkbox
          v-model="requiredToAll"
          density="compact"
          label="Obrigatório para todos"
          color="primary"
          hide-details
        />
      </v-col>
      <v-col class="md:mt-0 mt-2" cols="12" md="4">
        <v-checkbox
          v-model="requiredToOurTeam"
          density="compact"
          label="Obrigatório para nosso time"
          color="primary"
          hide-details
        />
      </v-col>

      <v-col cols="12" md="4">
        <span class="w-full flex justify-end gap-8">
          <!-- <outline-button @click="router.push('/dashboard')" width="119px" text="Voltar" /> -->
          <filled-button
            @click="createSchedule"
            :disabled="!scheduleName || !healthUnitSelected || isLoading"
            width="143px"
            text="Criar Escala"
            :loading="isLoading"
          />
        </span>
      </v-col>
    </v-row>
  </v-row>
</template>

<script setup lang="ts">
import AddCircleIcon from '@/assets/icons/add-circle.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import { useHealthUnitStore } from '@/pinia-store/useHealthUnitStore'
import { useCompanyStore } from '@/pinia-store/useCompanyStore'
import { useScheduleStore } from '@/pinia-store/useScheduleStore'
import { onMounted } from 'vue'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { timezones } from '@/hooks/useCommon'
import ArrowIcon from '@/assets/icons/down.vue'
import { useToast } from 'vue-toastification'

const defaultValueTimezone = timezones[1].value
const healthUnitStore = useHealthUnitStore()
const companyStore = useCompanyStore()
const scheduleStore = useScheduleStore()
const healthUnitSelected = ref<string | undefined>(undefined)
const isLoading = ref(false)
const showForm = ref(true)
const { listHealthUnits } = storeToRefs(useHealthUnitStore())

const healthUnits = ref<{ name: string; id: string }[]>([])
const scheduleName = ref<string>('')
const scheduleTimezone = ref<string>(defaultValueTimezone)
const requiredToOurTeam = ref<boolean>(false)
const requiredToAll = ref<boolean>(false)

onMounted(async () => {
  const { data: healthUnitsData } = await healthUnitStore.getAllHealthUnits()
  healthUnits.value = healthUnitsData.map((h) => ({ name: h.name, id: String(h.id) }))
})

const clearInputs = () => {
  isLoading.value = false
  scheduleName.value = ''
  healthUnitSelected.value = ''
  scheduleTimezone.value = defaultValueTimezone
  requiredToAll.value = false
  requiredToOurTeam.value = false
}

const toast = useToast()

const createSchedule = async () => {
  try {
    isLoading.value = true
    const data = {
      healthUnitId: Number(healthUnitSelected.value),
      payload: {
        name: scheduleName.value,
        timezone: scheduleTimezone.value,
        requiredCheckNotOurTeam: requiredToAll.value,
        requiredCheckOurTeam: requiredToOurTeam.value
      }
    }
    await scheduleStore.create(data)
    await healthUnitStore.getAllHealthUnits(true)
    await companyStore.getMyCompanies(true)

    toast.success(
      `Escala: ${
        scheduleName.value
      } criada com sucesso na unidade: ${healthUnitStore.getHealthUnitNameById(
        Number(healthUnitSelected.value)
      )}`
    )
    clearInputs()
  } catch (error) {
    console.error(error)
    toast.error('Erro ao criar escala, por favor tente novamente!')
  } finally {
    isLoading.value = false
  }
}
</script>
