import {
  UserData,
  ProfessionalData,
  Address,
  PersonalData
} from '@/modules/public/SignUp/doctor/helpers'
import { defineStore } from 'pinia'

export type DoctorRegistryState = {
  userDataForm: UserData
  personalDataForm: PersonalData
  professionalDataForm: ProfessionalData
  addressForm: Address
  userDataFormRef: { isValid: boolean; validate(): void }
  professionalDataFormRef: { isValid: boolean; validate(): void }
  addressFormRef: { isValid: boolean; validate(): void }
  personalDataFormRef: { isValid: boolean; validate(): void }
}

export const useDoctorRegistryStore = defineStore('doctorRegistry', {
  state: (): DoctorRegistryState => ({
    userDataForm: {
      email: '',
      password: '',
      confirmPassword: '',
      phone: ''
    },
    personalDataForm: {
      rg: '',
      issueDate: '',
      issuingAuthority: '',
      birthDate: '',
      civilStates: '',
      cpf: '',
      fatherName: '',
      motherName: '',
      nationality: ''
    },
    professionalDataForm: {
      crm: '',
      crmUf: '',
      name: ''
    },
    addressForm: {
      cep: '',
      street: '',
      number: '',
      district: '',
      complement: '',
      state: '',
      city: ''
    },
    userDataFormRef: { isValid: false, validate: () => {} },
    professionalDataFormRef: { isValid: false, validate: () => {} },
    addressFormRef: { isValid: false, validate: () => {} },
    personalDataFormRef: { isValid: false, validate: () => {} }
  }),
  actions: {
    setFormData(data: DoctorRegistryState) {
      this.userDataForm = { ...this.userDataForm, ...data }
    }
  }
})
