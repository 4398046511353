import { defineStore } from 'pinia'
import Network from '@/Network'
import { ListDoctorsCompanyResponse } from '@/Network/Types/Responses/CompanyResponses'

export type DoctorState = {
  doctorsCompany: ListDoctorsCompanyResponse | undefined
  listDoctorsCompany: { title: string; subtitle: string; value: string }[]
}

export const useDoctorStore = defineStore('doctor', {
  state: (): DoctorState => ({
    doctorsCompany: undefined,
    listDoctorsCompany: []
  }),
  actions: {
    async getAllDoctorsByCompany(companyId: number, forceReload: boolean = false): Promise<void> {
      if (forceReload || !this.doctorsCompany) {
        const data = await Network.company.listDoctorsByCompany(companyId)
        this.doctorsCompany = data
      }
    },
    async setDoctorsByCompany(companyId: number | null) {
      if (!companyId) {
        this.listDoctorsCompany = []
        return
      }
      await this.getAllDoctorsByCompany(companyId, true)
      this.listDoctorsCompany =
        this.doctorsCompany?.data.map((d) => {
          const crm = d.doctor.mainCrm
          return {
            title: d.doctor.mainCrm.name,
            subtitle: `${crm.uf}-${crm.number}`,
            value: String(d.doctor.id)
          }
        }) || []
    }
  }
})
