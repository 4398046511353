<template>
  <div class="container-signup">
    <div class="flex w-full justify-between">
      <div class="logo flex justify-start">
        <Logo width="200px" logo="horizontal" />
      </div>

      <div class="buttons">
        <router-link to="/signup">
          <close-icon />
        </router-link>
      </div>
    </div>
    <div class="steps flex justify-center">
      <steps @changeStep="changeStep" :stepSelected="stepSelected" title="Cadastro Empresa">
        <template v-slot:step-0>
          <personal-data />
        </template>
        <template v-slot:step-1>
          <administrative-data />
        </template>
        <template v-slot:step-2>
          <Address />
        </template>
        <template v-slot:footer> </template>
      </steps>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import Logo from '@/components/designSystem/Logo/index.vue'
import Steps from '@/components/designSystem/Steps/Index.vue'
import CloseIcon from '@/assets/icons/CloseIcon.vue'
import PersonalData from '@/modules/public/SignUp/company/steps/PersonalData.vue'
import AdministrativeData from '@/modules/public/SignUp/company/steps/AdministrativeData.vue'
import Address from '@/modules/public/SignUp/company/steps/Address.vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import Network from '@/Network'
import PATHS from '@/router/paths'

export default defineComponent({
  name: 'Contractor',
  components: {
    Logo,
    CloseIcon,
    Steps,
    PersonalData,
    AdministrativeData,
    Address
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const toast = useToast()

    const stepSelected = ref(0)

    async function changeStep(step: any) {
      if (step === 0) {
        const formPesonalData = document.getElementById(
          'formCompanySignUpPesonalData'
        ) as HTMLFormElement
        formPesonalData.reportValidity()
        if (formPesonalData.checkValidity()) {
          // Chamar api, SE API RETORNAR 200 VAI PRA PROXIMA STEP
          stepSelected.value = step + 1
        }
      }
      if (step === 1) {
        const formAdministrativeData = document.getElementById(
          'formCompanyAdministrativeData'
        ) as HTMLFormElement
        formAdministrativeData.reportValidity()
        if (formAdministrativeData.checkValidity()) {
          // Chamar api, SE API RETORNAR 200 VAI PRA PROXIMA STEP
          stepSelected.value = step + 1
        }
      }
      if (step === 2) {
        const formAddress = document.getElementById('formCompanyAddress') as HTMLFormElement
        formAddress.reportValidity()
        if (formAddress.checkValidity()) {
          try {
            //TOOD Refatorar estado do cadastro.
            Network.company
              .createCompanyByPublic(store.state.signUp.company)
              .then(() => {
                toast.success('Cadastro concluido com sucesso!')
                router.push(PATHS.SIGNIN)
              })
              .catch((e) => {
                console.error(e)
                toast.error(
                  'Ops, ocorreu algum erro, por favor tente novamente ou entre em contato conosco!'
                )
              })
          } catch (e) {
            console.error(e)
          }
        }
      }
    }

    return {
      changeStep,
      stepSelected
    }
  }
})
</script>

<style scoped>
.container-signup {
  background-color: #fafafa;
  height: 100%;
  min-height: 100vh;
  padding: 40px;
}

.steps {
  width: 392px;
  margin-left: auto;
  margin-right: auto;
}

.footer-step {
  width: 392px;
}

@media (max-width: 556px) {
  .steps {
    width: 250px;
  }

  .footer-step {
    width: 250px;
  }
}
</style>
