<template>
  <div class="flex items-center gap-2 my-5">
    <span
      @click="exportXLSX"
      class="flex items-center gap-1 hover:shadow text-primary p-2 border border-primary rounded"
      :class="isLoadingBtn ? 'cursor-not-allowed' : 'cursor-pointer'"
    >
      <v-progress-circular v-if="isLoadingBtn" size="20" indeterminate />
      <v-icon color="primary" :icon="mdiFileExcel" />
      Exportar XLSX
    </span>
  </div>
  <v-data-table
    class="text-left"
    :loading="isLoading"
    :hover="true"
    :headers="headers"
    :items="doctorReports.rows"
    item-value="id"
    :items-per-page="itemsPerPage"
    @update:sort-by="changeSort"
  >
    <template v-slot:item.checkinData="{ item }">
      <div v-if="item.raw.checkinData" class="flex gap-2 items-center">
        <a
          class="h-full flex justify-center items-center"
          :href="item.raw.checkinData?.url"
          target="_blank"
        >
          <v-icon class="hover:cursor-pointer" :icon="mdiMapMarkerRadius" color="primary" />
        </a>
        {{ item.raw.checkinData?.distance?.toFixed(2) }} metros
      </div>
    </template>
    <template v-slot:item.checkoutData="{ item }">
      <div v-if="item.raw.checkoutData" class="flex gap-2 items-center">
        <a
          class="h-full flex justify-center items-center"
          :href="item.raw.checkoutData?.url"
          target="_blank"
        >
          <v-icon class="hover:cursor-pointer" :icon="mdiMapMarkerRadius" color="primary" />
        </a>
        {{ item.raw.checkoutData?.distance?.toFixed(2) }} metros
      </div>
    </template>
    <template v-slot:bottom>
      <v-pagination
        v-model="page"
        :total-visible="5"
        :length="pageCount"
        density="comfortable"
        color="primary"
        @update:model-value="changePage"
      />
    </template>
  </v-data-table>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { mdiFileExcel, mdiMapMarkerRadius } from '@mdi/js'
import type { VDataTable } from 'vuetify/labs/components'
import { ConsultingRequest } from '@/Network/Types/Requests/StationRequests'
import { useDoctorReportsStore } from '@/pinia-store/useStationStore'
import { storeToRefs } from 'pinia'
import { useToast } from 'vue-toastification'
import { formatCurrency } from '@/utils/formatters'

import Network from '@/Network'
import { useFilterStationsStore } from '@/components/Filters/useFilterStation'

type Headers = InstanceType<typeof VDataTable>['headers']

const useFilterStations = useFilterStationsStore()
const { filters } = storeToRefs(useFilterStations)
const useDoctorReports = useDoctorReportsStore()
const { isLoading, doctorReports } = storeToRefs(useDoctorReports)

const headers: Headers = [
  {
    key: 'date',
    align: 'start',
    title: 'Data'
  },
  {
    key: 'hours',
    align: 'start',
    title: 'Horário',
    sortable: false
  },
  {
    key: 'name',
    align: 'start',
    title: 'Médico(a)'
  },
  {
    key: 'crm',
    align: 'start',
    title: 'CRM'
  },
  {
    key: 'schedule',
    align: 'start',
    title: 'Escala'
  },
  {
    key: 'status',
    align: 'start',
    title: 'Situação'
  },
  {
    key: 'check',
    align: 'start',
    title: 'Check-in/out',
    sortable: false
  },
  {
    key: 'checkinData',
    align: 'start',
    title: 'Checkin',
    sortable: false
  },
  {
    key: 'checkoutData',
    align: 'start',
    title: 'Checkout',
    sortable: false
  },
  {
    key: 'hoursWorked',
    align: 'start',
    title: 'Horas esperadas/trabalhadas',
    sortable: false
  },
  {
    key: 'modifiedValue',
    align: 'start',
    title: 'Valor da vaga',
    value: ({ modifiedValue }) => `R$ ${formatCurrency(modifiedValue)}`
  },
  {
    key: 'paymentValue',
    align: 'start',
    title: 'Valor aprovado',
    value: ({ paymentValue }) => `R$ ${formatCurrency(paymentValue)}`
  }
]
const itemsPerPage = 10
const page = ref(1)
const isLoadingBtn = ref(false)

const pageCount = computed(() => {
  return Math.ceil(doctorReports.value.totalRecords / itemsPerPage)
})

const serverParams = ref<ConsultingRequest>({
  columnFilters: filters.value,
  sort: [],
  page: 1,
  perPage: itemsPerPage
})

const changePage = async (p: number) => {
  serverParams.value = Object.assign({}, serverParams.value, { page: p })
  await loadItems()
}

const changeSort = async (sort: { key: string; order: string }[]) => {
  serverParams.value = Object.assign({}, serverParams.value, {
    sort: sort.map((s) => ({ field: s.key, type: s.order }))
  })
  await loadItems()
}

async function loadItems() {
  await useDoctorReports.getDoctorReports({ ...serverParams.value, columnFilters: filters.value })
}
const toast = useToast()
const exportXLSX = async () => {
  if (isLoadingBtn.value) return
  isLoadingBtn.value = true
  const link = await Network.station.exportRelatoryMedicXlsx(serverParams.value).finally(() => {
    isLoadingBtn.value = false
  })
  document.body.appendChild(link)
  link.click()
  toast.success('Relatório médico exportado com sucesso')
}
</script>
