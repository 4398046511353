<template>
  <div class="flex flex-col gap-4 mt-2">
    <div>
      <p class="font-bold text-lg">{{ props.title }}</p>
      <p class="md:text-base text-sm mb-2">{{ props.description }}</p>
      <div
        class="grid md:grid-cols-12 grid-cols-1 gap-0 py-0 my-0 md:min-h-[250px] min-h-[400px] h-full"
      >
        <div
          class="col-span-5 border-2 border-[#BCBCCD] rounded-lg p-3 max-h-[240px] min-h-[240px] overflow-auto"
        >
          <div class="flex flex-col gap-5">
            <div
              class="flex items-center"
              v-for="option in options"
              :key="option + Math.random() + 'A'"
            >
              <input
                type="checkbox"
                :id="option + unique"
                v-model="PermArraySelected"
                :value="option"
              />
              <label class="leading-5" :for="option + unique">{{ option }}</label>
            </div>
          </div>
        </div>

        <!-- Mobile Button -->
        <div class="p-3 col-span-2 flex md:hidden items-center justify-center md:flex-col gap-4">
          <button
            class="rotate-[270deg] md:rotate-0"
            @click="() => insertArray(PermArraySelec, PermArrayModel, options)"
          >
            <svg
              width="42"
              height="42"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16.091" cy="15.9091" r="15.6364" fill="#CB2957" />
              <path
                d="M13.1589 22.75L19.9998 15.9091L13.1589 9.06818"
                stroke="white"
                stroke-width="1.95455"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            @click="() => insertArray(options, PermArraySelected, PermArraySelec)"
            class="rotate-[270deg] md:rotate-0"
          >
            <svg
              width="42"
              height="42"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="16.0909"
                cy="15.6364"
                r="15.6364"
                transform="rotate(-180 16.0909 15.6364)"
                fill="#CB2957"
              />
              <path
                d="M19.0227 8.79546L12.1818 15.6364L19.0227 22.4773"
                stroke="white"
                stroke-width="1.95455"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            @click="() => insertAllIntoArray(PermArraySelec, options)"
            class="rotate-[270deg] md:rotate-0"
          >
            <svg
              width="42"
              height="42"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16.0907" cy="16.3636" r="15.6364" fill="#CB2957" />
              <path
                d="M17.0681 23.2045L23.909 16.3636L17.0681 9.52272"
                stroke="white"
                stroke-width="1.95455"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.76123 22.0644L14.462 16.3636L8.76123 10.6629"
                stroke="white"
                stroke-width="1.95455"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            @click="() => insertAllIntoArray(options, PermArraySelec)"
            class="rotate-[270deg] md:rotate-0"
          >
            <svg
              width="42"
              height="42"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="16.0909"
                cy="16.0909"
                r="15.6364"
                transform="rotate(-180 16.0909 16.0909)"
                fill="#CB2957"
              />
              <path
                d="M15.1135 9.24999L8.27262 16.0909L15.1135 22.9318"
                stroke="white"
                stroke-width="1.95455"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M23.4204 10.3901L17.7197 16.0909L23.4204 21.7917"
                stroke="white"
                stroke-width="1.95455"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <!-- Desktop Button -->
        <div class="p-3 col-span-2 md:flex hidden items-center justify-center md:flex-col gap-4">
          <button
            class="rotate-[270deg] md:rotate-0"
            @click="() => insertArray(options, PermArraySelected, PermArraySelec)"
          >
            <svg
              width="42"
              height="42"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16.091" cy="15.9091" r="15.6364" fill="#CB2957" />
              <path
                d="M13.1589 22.75L19.9998 15.9091L13.1589 9.06818"
                stroke="white"
                stroke-width="1.95455"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            @click="() => insertArray(PermArraySelec, PermArrayModel, options)"
            class="rotate-[270deg] md:rotate-0"
          >
            <svg
              width="42"
              height="42"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="16.0909"
                cy="15.6364"
                r="15.6364"
                transform="rotate(-180 16.0909 15.6364)"
                fill="#CB2957"
              />
              <path
                d="M19.0227 8.79546L12.1818 15.6364L19.0227 22.4773"
                stroke="white"
                stroke-width="1.95455"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            @click="() => insertAllIntoArray(options, PermArraySelec)"
            class="rotate-[270deg] md:rotate-0"
          >
            <svg
              width="42"
              height="42"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16.0907" cy="16.3636" r="15.6364" fill="#CB2957" />
              <path
                d="M17.0681 23.2045L23.909 16.3636L17.0681 9.52272"
                stroke="white"
                stroke-width="1.95455"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.76123 22.0644L14.462 16.3636L8.76123 10.6629"
                stroke="white"
                stroke-width="1.95455"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            @click="() => insertAllIntoArray(PermArraySelec, options)"
            class="rotate-[270deg] md:rotate-0"
          >
            <svg
              width="42"
              height="42"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="16.0909"
                cy="16.0909"
                r="15.6364"
                transform="rotate(-180 16.0909 16.0909)"
                fill="#CB2957"
              />
              <path
                d="M15.1135 9.24999L8.27262 16.0909L15.1135 22.9318"
                stroke="white"
                stroke-width="1.95455"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M23.4204 10.3901L17.7197 16.0909L23.4204 21.7917"
                stroke="white"
                stroke-width="1.95455"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <div
          class="col-span-5 border-2 border-[#BCBCCD] rounded-lg p-3 max-h-[240px] min-h-[240px] overflow-auto"
        >
          <div class="flex flex-col gap-5">
            <div
              class="flex items-center"
              v-for="option in PermArraySelec"
              :key="option + Math.random() + 'A'"
            >
              <input type="checkbox" :id="option" v-model="PermArrayModel" :value="option" />
              <label class="leading-5" :for="option">{{ option }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, toRefs, watch } from 'vue'

const props = defineProps(['title', 'description', 'options', 'clean'])
const emits = defineEmits(['clean'])

const { options, clean } = toRefs(props)

const PermArraySelected = ref([])
const PermArraySelec = ref([])
const PermArrayModel = ref([])
const unique = Math.random()

const clear = () => {
  emits('clean', { title: props.title, clean })

  PermArraySelected.value = []
  PermArraySelec.value = []
  PermArrayModel.value = []
}

watch(clean, () => {
  clear()
})

const insertArray = (
  optionsArray: string[],
  selectedOptionsArray: string[],
  arrayToSendOptions: string[]
) => {
  selectedOptionsArray.forEach((value) => {
    const index = optionsArray.indexOf(value)

    if (index !== -1) {
      optionsArray.splice(index, 1)
    }
  })

  selectedOptionsArray.forEach((value) => arrayToSendOptions.push(value))
  selectedOptionsArray.splice(0, selectedOptionsArray.length)
}

const insertAllIntoArray = (fromArray: string[], toArray: string[]) => {
  fromArray.forEach((value) => toArray.push(value))

  fromArray.splice(0, fromArray.length)
}
</script>

<style scoped>
input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  background-color: white;
  border-radius: 4px;
  border: 2px solid #2f7f91;
  display: flex;
  margin-right: 4px;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
}

input[type='checkbox']::after {
  appearance: none;
  content: ' ';
  height: 100%;
  width: 100%;
  background-color: #2f7f91;
  background-image: url('data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IndoaXRlIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PCEtLSEgRm9udCBBd2Vzb21lIFBybyA2LjIuMCBieSBAZm9udGF3ZXNvbWUgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbSBMaWNlbnNlIC0gaHR0cHM6Ly9mb250YXdlc29tZS5jb20vbGljZW5zZSAoQ29tbWVyY2lhbCBMaWNlbnNlKSBDb3B5cmlnaHQgMjAyMiBGb250aWNvbnMsIEluYy4gLS0+PHBhdGggZD0iTTQ3MC42IDEwNS40YzEyLjUgMTIuNSAxMi41IDMyLjggMCA0NS4zbC0yNTYgMjU2Yy0xMi41IDEyLjUtMzIuOCAxMi41LTQ1LjMgMGwtMTI4LTEyOGMtMTIuNS0xMi41LTEyLjUtMzIuOCAwLTQ1LjNzMzIuOC0xMi41IDQ1LjMgMEwxOTIgMzM4LjcgNDI1LjQgMTA1LjRjMTIuNS0xMi41IDMyLjgtMTIuNSA0NS4zIDB6Ii8+PC9zdmc+');
  background-size: contain;
  display: none;
}

input[type='checkbox']:checked:after {
  display: block;
}

label {
  cursor: pointer;
  word-break: normal;
  user-select: none;
}
</style>
