<template>
  <div class="container-personal-data">
    <div class="flex justify-center">
      <form id="formSignUpPesonalData" class="flex items-center flex-col rounded overflow-hidden">
        <h1 class="titleBold text-left">Dados Pessoais</h1>
        <p>
          O e-mail informado em "Dados Pessoais" será utilizado para realizar o acesso a nossa
          Plataforma e o e-mail em "Dados Administrativos" será utilizado para contato.
        </p>
        <div class="input w-full">
          <Input
            :isError="isErrorUserName"
            @input="onChangeUserName"
            errorMessage="Nome de usuário inválido"
            width="100%"
            label="Nome"
            pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
            :value="userName"
            required
          />
        </div>

        <div class="input w-full">
          <Input
            :v-model="email"
            @input="onChangeEmail"
            :isError="isErrorEmail"
            errorMessage="E-mail inválido!"
            pattern="[a-z0-9]+@[a-z]+\.[a-z]{2,3}"
            type="email"
            width="100%"
            label="Email"
            required
          />
        </div>

        <div class="input w-full">
          <Input
            :v-model="phone1"
            @input="onChangePhone1"
            :isError="isErrorPhone1"
            errorMessage="Telefone inválido"
            width="100%"
            type="tel"
            pattern="(\(\d{2}\)\s)(\d{4,5}-\d{4})"
            label="Telefone 01"
            mask="(##) #####-####"
            required
          />
        </div>

        <div class="input w-full">
          <Input
            :v-model="phone2"
            @input="onChangePhone2"
            :isError="isErrorPhone2"
            pattern="(\(\d{2}\)\s)(\d{4,5}-\d{4})"
            errorMessage="Telefone inválido"
            width="100%"
            mask="(##) #####-####"
            label="Telefone 02"
          />
        </div>

        <div class="input w-full">
          <Input
            :v-model="password"
            @input="onChangePassword"
            :isError="isErrorPassword"
            errorMessage="A senha precisa ter pelo menos 8 caracters, 1 letra maíuscula, 1 caracter especial e 1 número"
            type="password"
            pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$"
            width="100%"
            label="Senha"
            required
          />
        </div>

        <div class="input w-full">
          <Input
            id="confirm-password-2323"
            :v-model="confirmPassword"
            @input="onChangeConfirmPassword"
            :isError="isErrorConfirmPassword"
            type="password"
            errorMessage="As senhas não coincidem!"
            pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$"
            width="100%"
            label="Confirmação da senha"
            required
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import Input from '@/components/designSystem/Inputs/Input.vue'

export default defineComponent({
  name: 'ChooseCompany',
  components: {
    Input
  },
  setup() {
    const store = useStore()

    const userName = computed(() => store.state.signUp.contractor.userName)

    const email = computed(() => store.state.signUp.contractor.email)

    const phone1 = computed(() => store.state.signUp.contractor.phone1)

    const phone2 = computed(() => store.state.signUp.contractor.phone2)

    const password = computed(() => store.state.signUp.contractor.password)

    const confirmPassword = computed(() => store.state.signUp.contractor.confirmPassword)

    const isErrorUserName = ref(false)
    const isErrorEmail = ref(false)
    const isErrorPhone1 = ref(false)
    const isErrorPhone2 = ref(false)
    const isErrorPassword = ref(false)
    const isErrorConfirmPassword = ref(false)
    const isValid = ref(false)

    // const formPesonalData = ref({
    //   // eslint-disable-next-line @typescript-eslint/no-empty-function
    //   reportValidity: () => {},
    // });

    function validateUserName() {
      const regex = new RegExp(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/)

      if (userName.value === '' || !regex.test(userName.value)) {
        isValid.value = false
        isErrorUserName.value = true
      } else {
        isErrorUserName.value = false
        isValid.value = true
      }
    }

    function validateEmail() {
      const regex = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/)

      if (email.value === '' || !regex.test(email.value)) {
        isValid.value = false
        isErrorEmail.value = true
      } else {
        isValid.value = true
        isErrorEmail.value = false
      }
    }

    function validatePhone1() {
      const regex = new RegExp(/(\(\d{2}\)\s)(\d{4,5}-\d{4})/)

      if (phone1.value === '' || !regex.test(phone1.value)) {
        isValid.value = false
        isErrorPhone1.value = true
      } else {
        isValid.value = true
        isErrorPhone1.value = false
      }
    }

    function validatePhone2() {
      const regex = new RegExp(/(\(\d{2}\)\s)(\d{4,5}-\d{4})/)

      if (!regex.test(phone2.value) && phone2.value !== '') {
        isValid.value = false
        isErrorPhone2.value = true
      } else {
        isValid.value = true
        isErrorPhone2.value = false
      }
    }

    function validatePassword() {
      const regex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$/)
      if (password.value === '' || !regex.test(password.value)) {
        isValid.value = false
        isErrorPassword.value = true
      } else {
        isValid.value = true
        isErrorPassword.value = false
      }
    }

    function validateConfirmPassword() {
      const isEqualPasswords = confirmPassword.value === password.value
      const input = document.getElementById('confirm-password-2323') as HTMLFormElement

      if (confirmPassword.value === '') {
        isValid.value = false
        isErrorConfirmPassword.value = true
      } else if (!isEqualPasswords) {
        isValid.value = false
        isErrorConfirmPassword.value = true
        input.setCustomValidity('Senhas não coincidem!')
      } else {
        input.setCustomValidity('')
        isValid.value = true
        isErrorConfirmPassword.value = false
      }
    }

    function onChangeUserName(e: any) {
      store.dispatch('setUserName', e.target.value)
      validateUserName()
    }

    function onChangeEmail(e: any) {
      store.dispatch('setEmail', e.target.value)
      validateEmail()
    }

    function onChangePhone1(e: any) {
      store.dispatch('setPhone1', e.target.value)
      validatePhone1()
    }

    function onChangePhone2(e: any) {
      store.dispatch('setPhone2', e.target.value)
      validatePhone2()
    }

    function onChangePassword(e: any) {
      store.dispatch('setPassword', e.target.value)
      validatePassword()
    }

    function onChangeConfirmPassword(e: any) {
      store.dispatch('setConfirmPassword', e.target.value)
      validateConfirmPassword()
    }

    return {
      isErrorUserName,
      isErrorEmail,
      isErrorPhone1,
      isErrorPhone2,
      isErrorPassword,
      isErrorConfirmPassword,
      userName,
      email,
      phone1,
      phone2,
      password,
      confirmPassword,
      onChangeUserName,
      onChangeEmail,
      onChangePhone1,
      onChangePhone2,
      onChangePassword,
      onChangeConfirmPassword,
      validateEmail,
      validateUserName
    }
  }
})
</script>

<style scoped>
.input {
  margin-bottom: 25px;
}

.container-signup {
  background-color: #fafafa;
  height: 100vh;
}

.card {
  background-color: #fff;
  border-radius: 0.2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  min-height: 280px;
}

.card > h2 {
  font-weight: 700;
  font-size: 18px;
}
.card > h5 {
  margin: 1rem 0;
  font-size: 16px;
}

p {
  text-align: justify;
  margin-bottom: 15px;
}

@media (max-width: 556px) {
  .buttons {
    display: none;
  }
  .container-cards {
    flex-direction: column;
    margin: 2rem;
  }

  .card {
    max-width: 100%;
    margin-bottom: 2rem;
    min-height: auto;
  }
}

.titleBold {
  font-family: 'Inter';
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 25px;
  margin-top: 35px;
}

.titleBold {
  font-family: 'Inter';
  font-size: 1.5rem;
  font-weight: 700;
}

h1 {
  text-align: left;
  width: 100%;
}
</style>
