// export type State = {
//     // step 0
//     userName: string,
//     email: string,
//     phone1: string,
//     phone2: string,
//     password: string,
//     confirmPassword: string,
//     // step 1
//     companyName: string,
//     fantasyName: string,
//     cnpj: string,
//     corporateEmail: string,
//     corporatePhone1: string,
//     corporatePhone2: string,
//     // step 2
//     cep: string,
//     street: string,
//     number: string,
//     district: string,
//     complement: string,
//     state: string,
//     city: string,
// }

// TODO criar pasta para os modulos e separar os types do store.
export type Company = {
  // step 0
  userName: string
  email: string
  phone1: string
  password: string
  confirmPassword: string
  // step 1
  companyName: string
  fantasyName: string
  cnpj: string
  corporateEmail: string
  corporatePhone1: string
  // step 2
  cep: string
  street: string
  number: string
  district: string
  complement: string
  state: string
  city: string
}

export type Doctor = {
  // step 0
  email: string
  phone: string
  password: string
  confirmPassword: string
  // step 1
  specialty: string
  crm: string
  crmUf: string
  name: string
  // step 2
  cep: string
  // step 3
  street: string
  number: string
  district: string
  complement: string
  state: string
  city: string
  // step 4
  rg: string
  issueDate: string
  issuingAuthority: string
  cpf: string
  birthDate: string
  motherName: string
  fatherName: string
  nationality: string
  maritalStatus: string
}

export type State = {
  company: Company
  doctor: Doctor
}

export default {
  state() {
    return {
      /*contractor: {
        // step 0
        userName: '',
        email: '',
        phone1: '',
        phone2: '',
        password: '',
        confirmPassword: '',
        // step 1
        companyName: '',
        fantasyName: '',
        cnpj: '',
        corporateEmail: '',
        corporatePhone1: '',
        corporatePhone2: '',
        // step 2
        cep: '',
        street: '',
        district: '',
        number: '',
        complement: '',
        state: '',
        city: '',
      },*/
      company: {
        // step 0
        userName: '',
        email: '',
        phone1: '',
        password: '',
        confirmPassword: '',
        // step 1
        companyName: '',
        fantasyName: '',
        cnpj: '',
        corporateEmail: '',
        corporatePhone1: '',
        // step 2
        cep: '',
        street: '',
        district: '',
        number: '',
        complement: '',
        state: '',
        city: ''
      },
      doctor: {
        // step 0
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        // step 1
        specialty: '',
        crm: '',
        crmUf: '',
        name: '',
        // step 2
        cep: '',
        // step 3
        street: '',
        number: '',
        district: '',
        complement: '',
        state: '',
        city: '',
        // step 4
        rg: '',
        issueDate: '',
        issuingAuthority: '',
        cpf: '',
        birthDate: '',
        motherName: '',
        fatherName: '',
        nationality: '',
        maritalStatus: ''
      }
    }
  },
  mutations: {
    // State
    setEmail(state: State, email: string) {
      state.doctor.email = email
    },
    setPhone(state: State, phone: string) {
      state.doctor.phone = phone
    },
    setPassword(state: State, password: string) {
      state.doctor.password = password
    },
    setConfirmPassword(state: State, confirmPassword: string) {
      state.doctor.confirmPassword = confirmPassword
    },
    setCEP(state: State, cep: string) {
      state.doctor.cep = cep
    },
    setStreet(state: State, street: string) {
      state.doctor.street = street
    },
    setNumber(state: State, number: string) {
      state.doctor.number = number
    },
    setDistrict(state: State, district: string) {
      state.doctor.district = district
    },
    setComplement(state: State, complement: string) {
      state.doctor.complement = complement
    },
    setState(state: State, stateOfCountry: string) {
      state.doctor.state = stateOfCountry
    },
    setCity(state: State, city: string) {
      state.doctor.city = city
    },
    /*setUserName(state: State, name: string) {
      state.contractor.userName = name;
    },
    
    
    setPhone2(state: State, phone2: string) {
      state.contractor.phone2 = phone2;
    },
    
   
    setCompanyName(state: State, companyName: string) {
      state.contractor.companyName = companyName;
    },
    setFantasyName(state: State, fantasyName: string) {
      state.contractor.fantasyName = fantasyName;
    },
    setCorporateEmail(state: State, corporateEmail: string) {
      state.contractor.corporateEmail = corporateEmail;
    },
    setCorporatePhone1(state: State, corporatePhone1: string) {
      state.contractor.corporatePhone1 = corporatePhone1;
    },
    setCorporatePhone2(state: State, corporatePhone2: string) {
      state.contractor.corporatePhone2 = corporatePhone2;
    },
    
    
    setCnpj(state: State, cnpj: string) {
      state.contractor.cnpj = cnpj;
    },*/
    // Company
    setCompanyUserName(state: State, name: string) {
      if (state.company) state.company.userName = name
    },
    setCompanyEmail(state: State, email: string) {
      if (state.company) state.company.email = email
    },
    setCompanyPhone1(state: State, phone1: string) {
      if (state.company) state.company.phone1 = phone1
    },
    setCompanyPassword(state: State, password: string) {
      if (state.company) state.company.password = password
    },
    setCompanyConfirmPassword(state: State, confirmPassword: string) {
      if (state.company) state.company.confirmPassword = confirmPassword
    },
    setCompanyCompanyName(state: State, companyName: string) {
      if (state.company) state.company.companyName = companyName
    },
    setCompanyFantasyName(state: State, fantasyName: string) {
      if (state.company) state.company.fantasyName = fantasyName
    },
    setCompanyCorporateEmail(state: State, corporateEmail: string) {
      if (state.company) state.company.corporateEmail = corporateEmail
    },
    setCompanyCorporatePhone1(state: State, corporatePhone1: string) {
      if (state.company) state.company.corporatePhone1 = corporatePhone1
    },
    setCompanyCEP(state: State, cep: string) {
      if (state.company) state.company.cep = cep
    },
    setCompanyStreet(state: State, street: string) {
      if (state.company) state.company.street = street
    },
    setCompanyNumber(state: State, number: string) {
      if (state.company) state.company.number = number
    },
    setCompanyDistrict(state: State, district: string) {
      if (state.company) state.company.district = district
    },
    setCompanyComplement(state: State, complement: string) {
      if (state.company) state.company.complement = complement
    },
    setCompanyState(state: State, stateOfCountry: string) {
      if (state.company) state.company.state = stateOfCountry
    },
    setCompanyCity(state: State, city: string) {
      if (state.company) state.company.city = city
    },
    setCompanyCnpj(state: State, cnpj: string) {
      if (state.company) state.company.cnpj = cnpj
    },
    // State
    setDoctorEmail(state: State, email: string) {
      if (state.doctor) state.doctor.email = email
    },
    setDoctorPhone(state: State, phone: string) {
      if (state.doctor) state.doctor.phone = phone
    },
    setDoctorPassword(state: State, password: string) {
      if (state.doctor) state.doctor.password = password
    },
    setDoctorConfirmPassword(state: State, confirmPassword: string) {
      if (state.doctor) state.doctor.confirmPassword = confirmPassword
    },
    setDoctorSpecialty(state: State, specialty: string) {
      if (state.doctor) state.doctor.specialty = specialty
    },
    setDoctorCrm(state: State, crm: string) {
      if (state.doctor) state.doctor.crm = crm
    },
    setDoctorCrmUf(state: State, crmUf: string) {
      if (state.doctor) state.doctor.crmUf = crmUf
    },
    setDoctorName(state: State, name: string) {
      if (state.doctor) state.doctor.name = name
    },
    setDoctorCep(state: State, cep: string) {
      if (state.doctor) state.doctor.cep = cep
    },
    setDoctorState(state: State, _state: string) {
      if (state.doctor) state.doctor.state = _state
    },
    setDoctorCity(state: State, city: string) {
      if (state.doctor) state.doctor.city = city
    },
    setDoctorDistrict(state: State, district: string) {
      if (state.doctor) state.doctor.district = district
    },
    setDoctorStreet(state: State, street: string) {
      if (state.doctor) state.doctor.street = street
    },
    setDoctorNumber(state: State, number: string) {
      if (state.doctor) state.doctor.number = number
    },
    setDoctorComplement(state: State, complement: string) {
      if (state.doctor) state.doctor.complement = complement
    },
    setRg(state: State, rg: string) {
      if (state.doctor) state.doctor.rg = rg
    },
    setIssueDate(state: State, issueDate: string) {
      if (state.doctor) state.doctor.issueDate = issueDate
    },
    setIssuingAuthority(state: State, issuingAuthority: string) {
      if (state.doctor) state.doctor.issuingAuthority = issuingAuthority
    },
    setCpf(state: State, cpf: string) {
      if (state.doctor) state.doctor.cpf = cpf
    },
    setBirthDate(state: State, birthDate: string) {
      if (state.doctor) state.doctor.birthDate = birthDate
    },
    setMotherName(state: State, motherName: string) {
      if (state.doctor) state.doctor.motherName = motherName
    },
    setFatherName(state: State, fatherName: string) {
      if (state.doctor) state.doctor.fatherName = fatherName
    },
    setNationality(state: State, nationality: string) {
      if (state.doctor) state.doctor.nationality = nationality
    },
    setMaritalStatus(state: State, maritalStatus: string) {
      if (state.doctor) state.doctor.maritalStatus = maritalStatus
    }
  },
  actions: {
    // State
    setUserName(context: any, param: any) {
      context.commit('setUserName', param)
    },
    setEmail(context: any, param: any) {
      context.commit('setEmail', param)
    },
    setPhone(context: any, param: any) {
      context.commit('setPhone', param)
    },
    setPhone2(context: any, param: any) {
      context.commit('setPhone2', param)
    },
    setPassword(context: any, param: any) {
      context.commit('setPassword', param)
    },
    setConfirmPassword(context: any, param: any) {
      context.commit('setConfirmPassword', param)
    },
    setCompanyName(context: any, param: any) {
      context.commit('setCompanyName', param)
    },
    setFantasyName(context: any, param: any) {
      context.commit('setFantasyName', param)
    },
    setCorporateEmail(context: any, param: any) {
      context.commit('setCorporateEmail', param)
    },
    setCorporatePhone1(context: any, param: any) {
      context.commit('setCorporatePhone1', param)
    },
    setCorporatePhone2(context: any, param: any) {
      context.commit('setCorporatePhone2', param)
    },
    setCEP(context: any, param: any) {
      context.commit('setCEP', param)
    },
    setStreet(context: any, param: any) {
      context.commit('setStreet', param)
    },
    setNumber(context: any, param: any) {
      context.commit('setNumber', param)
    },
    setComplement(context: any, param: any) {
      context.commit('setComplement', param)
    },
    setState(context: any, param: any) {
      context.commit('setState', param)
    },
    setCity(context: any, param: any) {
      context.commit('setCity', param)
    },
    setDistrict(context: any, param: any) {
      context.commit('setDistrict', param)
    },
    setCnpj(context: any, param: any) {
      context.commit('setCnpj', param)
    },
    setRg(context: any, param: any) {
      context.commit('setRg', param)
    },
    setIssueDate(context: any, param: any) {
      context.commit('setIssueDate', param)
    },
    setIssuingAuthority(context: any, param: any) {
      context.commit('setIssuingAuthority', param)
    },
    setCpf(context: any, param: any) {
      context.commit('setCpf', param)
    },
    setBirthDate(context: any, param: any) {
      context.commit('setBirthDate', param)
    },
    setMotherName(context: any, param: any) {
      context.commit('setMotherName', param)
    },
    setFatherName(context: any, param: any) {
      context.commit('setFatherName', param)
    },
    setNationality(context: any, param: any) {
      context.commit('setNationality', param)
    },
    setMaritalStatus(context: any, param: any) {
      context.commit('setMaritalStatus', param)
    },
    // State
    setCompanyUserName(context: any, param: any) {
      context.commit('setCompanyUserName', param)
    },
    setCompanyEmail(context: any, param: any) {
      context.commit('setCompanyEmail', param)
    },
    setCompanyPhone1(context: any, param: any) {
      context.commit('setCompanyPhone1', param)
    },
    setCompanyPassword(context: any, param: any) {
      context.commit('setCompanyPassword', param)
    },
    setCompanyConfirmPassword(context: any, param: any) {
      context.commit('setCompanyConfirmPassword', param)
    },
    setCompanyCompanyName(context: any, param: any) {
      context.commit('setCompanyCompanyName', param)
    },
    setCompanyFantasyName(context: any, param: any) {
      context.commit('setCompanyFantasyName', param)
    },
    setCompanyCorporateEmail(context: any, param: any) {
      context.commit('setCompanyCorporateEmail', param)
    },
    setCompanyCorporatePhone1(context: any, param: any) {
      context.commit('setCompanyCorporatePhone1', param)
    },
    setCompanyCorporatePhone2(context: any, param: any) {
      context.commit('setCompanyCorporatePhone2', param)
    },
    setCompanyCEP(context: any, param: any) {
      context.commit('setCompanyCEP', param)
    },
    setCompanyStreet(context: any, param: any) {
      context.commit('setCompanyStreet', param)
    },
    setCompanyNumber(context: any, param: any) {
      context.commit('setCompanyNumber', param)
    },
    setCompanyComplement(context: any, param: any) {
      context.commit('setCompanyComplement', param)
    },
    setCompanyState(context: any, param: any) {
      context.commit('setCompanyState', param)
    },
    setCompanyCity(context: any, param: any) {
      context.commit('setCompanyCity', param)
    },
    setCompanyDistrict(context: any, param: any) {
      context.commit('setCompanyDistrict', param)
    },
    setCompanyCnpj(context: any, param: any) {
      context.commit('setCompanyCnpj', param)
    },
    // State
    setDoctorEmail(context: any, param: any) {
      context.commit('setDoctorEmail', param)
    },
    setDoctorPhone(context: any, param: any) {
      context.commit('setDoctorPhone', param)
    },
    setDoctorPassword(context: any, param: any) {
      context.commit('setDoctorPassword', param)
    },
    setDoctorConfirmPassword(context: any, param: any) {
      context.commit('setDoctorConfirmPassword', param)
    },
    setDoctorSpecialty(context: any, param: any) {
      context.commit('setDoctorSpecialty', param)
    },
    setDoctorCrm(context: any, param: any) {
      context.commit('setDoctorCrm', param)
    },
    setDoctorCrmUf(context: any, param: any) {
      context.commit('setDoctorCrmUf', param)
    },
    setDoctorName(context: any, param: any) {
      context.commit('setDoctorName', param)
    },
    setDoctorCep(context: any, param: any) {
      context.commit('setDoctorCep', param)
    },
    setDoctorState(context: any, param: any) {
      context.commit('setDoctorState', param)
    },
    setDoctorCity(context: any, param: any) {
      context.commit('setDoctorCity', param)
    },
    setDoctorDistrict(context: any, param: any) {
      context.commit('setDoctorDistrict', param)
    },
    setDoctorStreet(context: any, param: any) {
      context.commit('setDoctorStreet', param)
    },
    setDoctorNumber(context: any, param: any) {
      context.commit('setDoctorNumber', param)
    },
    setDoctorComplement(context: any, param: any) {
      context.commit('setDoctorComplement', param)
    }
  }
}
