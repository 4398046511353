<template>
  <v-autocomplete
    @update:model-value="$emit('update:modelValue', $event)"
    :items="listHealthUnits"
    :model-value="modelValue ? modelValue : undefined"
    color="primary"
    label="Unidade"
    variant="outlined"
    density="comfortable"
  >
    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props" :title="item?.raw?.title" :subtitle="item?.raw?.subtitle" />
    </template>
  </v-autocomplete>
</template>

<script lang="ts" setup>
import { onMounted, defineProps, defineEmits } from 'vue'
import { storeToRefs } from 'pinia'
import { useHealthUnitStore } from '@/pinia-store/useHealthUnitStore'

defineProps(['modelValue'])
defineEmits(['update:modelValue'])

const healthUnitStore = useHealthUnitStore()
const { listHealthUnits } = storeToRefs(useHealthUnitStore())

onMounted(async () => {
  await healthUnitStore.getAllHealthUnits()
})
</script>
