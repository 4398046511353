<template>
  <div class="flex justify-between items-center my-5">
    <div class="max-w-[300px] w-full">
      <HealthUnitSelect v-model="healthUnits" density="comfortable" clearable chips multiple />
    </div>
  </div>
  <Bar :chart-options="chartOptions" :chart-data="chartData" />
  <div class="flex justify-center my-5">
    Total de gastos com profissionais no ano:
    <span class="font-bold">R$ {{ formatCurrency(expensesTotal) }}</span>
  </div>
</template>

<script lang="ts" setup>
import { Bar } from 'vue-chartjs'
import { ref } from 'vue'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import HealthUnitSelect from '@/components/Autocompletes/HealthUnitSelect.vue'
import { formatCurrency } from '@/utils/formatters'
import { onMounted } from 'vue'
import Network from '@/Network'
import { TChartData } from 'vue-chartjs/dist/types'
import { watch } from 'vue'

const chartData = ref<TChartData<'bar', number[], unknown>>({
  labels: [],
  datasets: []
})

const chartOptions = ref()
const expensesTotal = ref(0)
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
const healthUnits = ref<string[]>([])
const labels = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

const getYearlyExpenses = async () => {
  const response = await Network.station.relatoryYearlyExpenses(healthUnits.value)
  expensesTotal.value = response.reduce((acc, value) => {
    acc += value.totalExpense ?? 0
    return acc
  }, 0)
  const data = response.map((item) => item.totalExpense ?? 0)
  chartData.value = {
    labels,
    datasets: [
      {
        label: 'Gastos',
        backgroundColor: '#3CA2B9',
        barPercentage: 0.7,
        data
      }
    ]
  }

  chartOptions.value = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          callback(label, index) {
            const total = `R$ ${formatCurrency(data[index])}`
            return [labels[index], total]
          }
        }
      },
      y: {
        ticks: {
          callback(label) {
            return `R$ ${formatCurrency(label)}`
          }
        }
      }
    }
  }
}

onMounted(async () => {
  await getYearlyExpenses()
})

watch(healthUnits, async () => {
  await getYearlyExpenses()
})
</script>
