import { useCompanyStore } from '@/pinia-store/useCompanyStore'

export default class Service {
  prefix: string | undefined

  getCompletePath(path = '') {
    if (!this.prefix) {
      throw new Error(`Prefixo inválido`)
    }
    return `${this.prefix}${path}`
  }

  async getCompanyIdsHeader(): Promise<{ Company_ids: string }> {
    const companyStore = useCompanyStore()
    await companyStore.getMyCompanies()
    const ids = companyStore.myCompanies.map((c) => c.id).join(',')
    return {
      Company_ids: ids
    }
  }
}
