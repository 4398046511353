<template>
  <modal-vue @close="handleToggleModal" :modalActive="modals[modalName]" :hasFooterBorder="true">
    <template #header>
      <div class="text-left px-[16px] flex">
        <strong>Removendo plantões</strong>
      </div>
    </template>
    <div>
      <p class="text-base font-normal">
        Tem certeza que deseja excluir {{ ids?.length }} plantões?
        <br />
        Essa ação não poderá ser desfeita!
      </p>
    </div>

    <template #footer>
      <div class="mt-5 flex justify-center gap-4">
        <outlined-button width="119px" @click="handleToggleModal" text="Cancelar" />
        <filled-button :loading="isLoading" text="Excluir" width="102px" @click="handleRemove" />
      </div>
    </template>
  </modal-vue>
</template>

<script lang="ts">
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useToast } from 'vue-toastification'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import Network from '@/Network'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import OutlinedButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    infos: {
      type: Array<number>
    },
    ids: {
      type: Array<number>
    }
  },
  components: {
    ModalVue,
    FilledButton,
    OutlinedButton
  },
  setup(props, { emit }) {
    /* ******** Modal Props and Actions Default *********** */
    const modalName = EModals.REMOVE_BULK_STATION
    const { modals } = storeToRefs(useModalStore())
    const modalStore = useModalStore()
    const handleToggleModal = () => {
      modalStore.toggleModal(modalName)
    }
    /* **************************************************** */

    const isLoading = ref(false)

    /* ******** Form Submited  *********** */
    const toast = useToast()

    const handleRemove = async () => {
      if (!props.ids) return
      try {
        isLoading.value = true
        await Network.station.bulkDelete(props.ids)
        toast.success('Plantões removido com sucesso')
        emit('refreshData')
      } catch (error) {
        toast.error(`Erro ao remover plantões: ${error}`)
      } finally {
        isLoading.value = false
        handleToggleModal()
      }
    }
    /* **************************************************** */

    return {
      modalName,
      modals,
      isLoading,
      handleToggleModal,
      handleRemove
    }
  }
}
</script>
