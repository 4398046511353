import { nextTick } from 'vue'
import * as yup from 'yup'

export type FormState = {
  values: Record<string, string | number | null | undefined | boolean | Date>
  errors: Record<string, string>
}

export const schemaValidate = async (field: string, states: FormState, schema: yup.AnySchema) => {
  try {
    await schema.validateAt(field, states.values)
    states.errors[field] = ''
    return true
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      states.errors[field] = err.message
      return false
    }
  }
}

export const schemaValidateAllFields = async (
  states: FormState,
  schema: yup.AnySchema,
  ref?: any
) => {
  const _schema = schema as any
  if (_schema && _schema.fields) {
    const fields = Object.keys(_schema.fields as object)
    for (const field of fields) {
      await schemaValidate(field, states, schema)
    }
    nextTick(async () => {
      if (ref.value) {
        await ref.value.validate()
      }
    })
    return schema.isValid(states.values)
  } else {
    throw new Error('Erro ao obter os fields do schema.')
  }
}
