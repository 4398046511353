type ID = number | null

// interface Duty {

// }
interface Scale {
  id: ID
  name: string
  hospitalId: ID
  duties: any[]
  selectedDuties: any[]
}

const initialScale: Scale = {
  id: null,
  name: '',
  hospitalId: null,
  duties: [],
  selectedDuties: []
}

const initialDuty = {
  id: null,
  scaleName: null,
  name: '',
  startHour: '',
  endHour: '',
  daysToEmergency: '',
  mondayVacancies: '',
  mondayValue: '',
  tuesdayVacancies: '',
  tuesdayValue: '',
  wednesdayVacancies: '',
  wednesdayValue: '',
  thursdayVacancies: '',
  thursdayValue: '',
  fridayVacancies: '',
  fridayValue: '',
  saturdayVacancies: '',
  saturdayValue: '',
  sundayVacancies: '',
  sundayValue: '',
  notes: ''
}

export default {
  state() {
    return {
      hospitals: [
        { id: 1, name: 'Hospital do Coração' },
        { id: 2, name: 'Hospital do Cancer' }
      ],
      newScale: { ...initialScale },
      scales: [
        {
          id: 1,
          name: 'Pediatria',
          duties: [],
          selectedDuties: []
        }
      ],
      scaleToDeleteId: null,
      scaleToEditId: null,
      newDuty: { ...initialDuty },
      dutyToDelete: { scaleId: null, dutyId: null },
      dutyToEdit: null
    }
  },
  mutations: {
    createScale(state: any) {
      state.newScale = { ...state.newScale, id: Math.round(Math.random() * 9999) + 1 }
      state.scales.push(state.newScale)
      state.newScale = { ...initialScale }
    },
    setNewScale(state: any, newScale: Scale) {
      state.newScale = newScale
    },
    setScaleIdToDelete(state: any, scaleId: ID) {
      state.scaleToDeleteId = scaleId
    },
    setScaleIdToEdit(state: any, scaleId: ID) {
      state.scaleToEditId = scaleId
    },
    deleteScale(state: any) {
      state.scales = state.scales.filter((scale) => scale.id !== state.scaleToDeleteId)
      state.scaleToDeleteId = null
    },
    updateScale(
      state: any,
      payload: { newScaleName: string; newDuties: any[]; newSelectedDuties: any[] }
    ) {
      const scaleIndex = state.scales.findIndex((s) => s.id === state.scaleToEditId)

      const newScale = {
        ...state.scales[scaleIndex],
        name: payload.newScaleName,
        duties: payload.newDuties,
        selectedDuties: payload.newSelectedDuties
      }

      state.scales[scaleIndex] = newScale
      state.scaleToEditId = null
    },
    setNewDutyInScale(state: any, newDuty: Scale) {
      state.newDuty = newDuty
    },
    createDutyInScale(state: any) {
      const scaleIndex = state.scales.findIndex((s) => s.name === state.newDuty.scaleName)
      const id = Math.round(Math.random() * 9999) + 1
      const newDuty = { ...state.newDuty, id }
      newDuty.name = `${newDuty.scaleName} Emergência ${id}` // TODO: fix name, name devia ser a especialidade no plantão em contrato(pedi para o Carlos me explicar como isso é vinculado com contrato)
      newDuty.startHour = '10:00' // TODO: fix startHour, Cristina disse que isso seria definido no contrato e que iria mudar no figma a definição de horário
      newDuty.endHour = '12:00' // TODO: fix endHour, Cristina disse que isso seria definido no contrato e que iria mudar no figma a definição de horário
      delete newDuty.scaleName
      state.scales[scaleIndex].duties.push(newDuty)
      state.newDuty = { ...initialDuty }
    },
    setDutyToDelete(state: any, payload: { scaleId: ID; dutyId: ID }) {
      const { scaleId, dutyId } = payload
      state.dutyToDelete = { scaleId, dutyId }
    },
    deleteDutyInScale(state: any) {
      const scaleIndex = state.scales.findIndex((scale) => scale.id === state.dutyToDelete.scaleId)
      if (state.scales[scaleIndex].duties.find((duty) => duty.id === state.dutyToDelete.dutyId)) {
        state.scales[scaleIndex].duties = state.scales[scaleIndex].duties.filter(
          (duty) => duty.id !== state.dutyToDelete.dutyId
        )
      } else {
        state.scales[scaleIndex].selectedDuties = state.scales[scaleIndex].selectedDuties.filter(
          (duty) => duty.id !== state.dutyToDelete.dutyId
        )
      }
    },
    setDutyToEdit(state: any, payload: { scaleId: ID; dutyId: ID } | null) {
      if (payload === null) {
        state.dutyToEdit = null
        state.newDuty = { ...initialDuty }
      } else {
        const { scaleId, dutyId } = payload
        state.dutyToEdit = { scaleId, dutyId }
        const scaleIndex = state.scales.findIndex((scale) => scale.id === scaleId)
        let duty = state.scales[scaleIndex].duties.find((d) => d.id === dutyId)
        if (duty) {
          state.newDuty = duty
        } else {
          duty = state.scales[scaleIndex].selectedDuties.find((d) => d.id === dutyId)
          state.newDuty = duty
        }
      }
    },
    editDutyInScale(state: any) {
      const scaleIndex = state.scales.findIndex((scale) => scale.id === state.dutyToEdit.scaleId)
      const scaleIndexByName = state.scales.findIndex(
        (scale) => scale.name === state.newDuty.scaleName
      )
      let dutyIndex = state.scales[scaleIndex].duties.findIndex(
        (duty) => duty.id === state.dutyToEdit.dutyId
      )
      const { newDuty } = state
      delete newDuty.scaleName

      if (dutyIndex !== -1) {
        if (scaleIndex !== scaleIndexByName) {
          // check if duty of scale changed
          state.scales[scaleIndex].duties = state.scales[scaleIndex].duties.filter(
            (d, i) => i !== dutyIndex
          )
          state.scales[scaleIndexByName].duties.push(newDuty)
        } else {
          state.scales[scaleIndex].duties[dutyIndex] = newDuty
        }
      } else {
        dutyIndex = state.scales[scaleIndex].selectedDuties.findIndex(
          (duty) => duty.id === state.dutyToEdit.dutyId
        )

        if (scaleIndex !== scaleIndexByName) {
          // check if duty of scale changed
          state.scales[scaleIndex].selectedDuties = state.scales[scaleIndex].selectedDuties.filter(
            (d, i) => i !== dutyIndex
          )
          state.scales[scaleIndexByName].duties.push(newDuty)
        } else {
          state.scales[scaleIndex].selectedDuties[dutyIndex] = newDuty
        }
      }
      state.newDuty = { ...initialDuty }
    }
  },
  actions: {
    setNewScale(context: any, payload: { property: string; value: string }) {
      const newScale = { ...context.state.newScale, [payload.property]: payload.value }
      context.commit('setNewScale', newScale)
    },
    createScale(context: any) {
      context.commit('createScale')
    },
    setScaleIdToDelete(context: any, scaleId: ID) {
      context.commit('setScaleIdToDelete', scaleId)
    },
    deleteScale(context: any) {
      context.commit('deleteScale')
    },
    setScaleIdToEdit(context: any, scaleId: ID) {
      context.commit('setScaleIdToEdit', scaleId)
    },
    updateScale(
      context: any,
      payload: { newScaleName: string; newDuties: any[]; newSelectedDuties: any[] }
    ) {
      context.commit('updateScale', payload)
    },
    setNewDutyInScale(context: any, payload: { property: string; value: string }) {
      const newDuty = { ...context.state.newDuty, [payload.property]: payload.value }
      context.commit('setNewDutyInScale', newDuty)
    },
    createDutyInScale(context: any) {
      context.commit('createDutyInScale')
    },
    setDutyToDelete(context: any, payload: { scaleId: ID; dutyId: ID }) {
      context.commit('setDutyToDelete', payload)
    },
    deleteDutyInScale(context: any, payload: { scaleId: ID; dutyId: ID }) {
      context.commit('deleteDutyInScale', payload)
    },
    setDutyToEdit(context: any, payload: { scaleId: ID; dutyId: ID } | null) {
      context.commit('setDutyToEdit', payload)
    },
    editDutyInScale(context: any) {
      context.commit('editDutyInScale')
    }
  }
}
