import request from '@/Network/Requests/request'
import Service from '@/Network/Services/Service'
import {
  ApproveStationRequest,
  ConsultingRequest,
  CreateStationRequest,
  ListFiltersRequest,
  PatchStationsRequest,
  RelatoryMonthlyExpensesFilter,
  UpdateStationRequest
} from '../Types/Requests/StationRequests'
import {
  ConsultingResponse,
  ListStationsResponse,
  StationsToApproveResponse
} from '../Types/Responses/StationResponses'

import axios from 'axios'
import {
  RelatoryMonthlyExpesesResponse,
  RelatoryYearlyExpesesResponse
} from '../Types/Responses/HealthUnitResponses'
import { FilterStates } from '@/components/Filters/useFilterStation'

export default class StationService extends Service {
  prefix: string

  constructor() {
    super()
    this.prefix = '/stations'
  }

  async create(payload: CreateStationRequest): Promise<any> {
    try {
      const { data } = await request.post<any>(`${this.getCompletePath()}`, payload)
      return data
    } catch (e) {
      throw new Error('Ocorreu um erro')
    }
  }

  async list(filters?: ListFiltersRequest): Promise<ListStationsResponse> {
    try {
      const { data } = await request.get<ListStationsResponse>(this.getCompletePath(), filters)
      return data
    } catch (e) {
      throw new Error('Ocorreu um erro')
    }
  }

  async getConsultingList(filters: ConsultingRequest): Promise<ConsultingResponse> {
    try {
      const columnsFilters = { ...filters.columnFilters }
      const query = {
        ...columnsFilters,
        sort: filters.sort,
        page: filters.page,
        perPage: filters.perPage
      }
      const { data } = await request.get<ConsultingResponse>(
        this.getCompletePath() + '/consulting',
        query
      )
      return data
    } catch (e) {
      throw new Error('Ocorreu um erro')
    }
  }

  async exportRelatoryMedicXlsx(filters: ConsultingRequest): Promise<HTMLAnchorElement> {
    const columnsFilters = { ...filters.columnFilters }
    const query = {
      ...columnsFilters,
      sort: filters.sort,
      page: filters.page,
      perPage: filters.perPage,
      exportXLSX: true
    }
    const { data } = await request.get<BlobPart>(
      `${this.getCompletePath()}/relatory-medic`,
      query,
      undefined,
      'blob'
    )
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'relatório-médico.xlsx')
    return link
  }

  async exportRelatoryResumeMedicXlsx(filters: ConsultingRequest): Promise<HTMLAnchorElement> {
    const columnsFilters = { ...filters.columnFilters }
    const query = {
      ...columnsFilters,
      sort: filters.sort,
      page: filters.page,
      perPage: filters.perPage,
      exportXLSX: true
    }
    const { data } = await request.get<BlobPart>(
      `${this.getCompletePath()}/relatory-resume-medic`,
      query,
      undefined,
      'blob'
    )
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'relatório-médico-resumido.xlsx')
    return link
  }

  async update(stationId: number, payload: UpdateStationRequest): Promise<ListStationsResponse> {
    try {
      const { data } = await request.put(`${this.getCompletePath()}/${stationId}`, payload)
      return data
    } catch (e) {
      throw new Error('Ocorreu um erro')
    }
  }

  async patchStations(stationIds: number[], payload: PatchStationsRequest): Promise<void> {
    try {
      await request.patch(`${this.getCompletePath()}/bulk?ids=${stationIds}`, payload)
    } catch (e) {
      throw new Error('Ocorreu um erro')
    }
  }

  async bulkDelete(stationIds: number[]): Promise<void> {
    try {
      await request.delete(`${this.getCompletePath()}/bulk?ids=${stationIds}`)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(e.response?.data)
        throw e.response?.data.message
      }
      throw new Error('Ocorreu um erro')
    }
  }

  async delete(stationId: number): Promise<void> {
    try {
      const { data } = await request.delete(`${this.getCompletePath()}/${stationId}`)
      return data
    } catch (e) {
      throw new Error('Ocorreu um erro')
    }
  }

  async exportRelatoryXlsx(filters: {
    startDate: Date
    endDate: Date
    filename: string
  }): Promise<HTMLAnchorElement> {
    const { data } = await request.get<BlobPart>(
      `${this.getCompletePath()}/relatory/${filters.startDate}&${filters.endDate}`,
      undefined,
      undefined,
      'blob'
    )
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filters.filename)
    return link
  }

  async relatoryMonthlyExpenses(
    filters?: RelatoryMonthlyExpensesFilter
  ): Promise<RelatoryMonthlyExpesesResponse> {
    try {
      const { data } = await request.get<RelatoryMonthlyExpesesResponse>(
        `${this.getCompletePath()}/monthly-expenses`,
        filters
      )
      return data
    } catch (e) {
      return []
    }
  }

  async relatoryYearlyExpenses(healthUnits?: string[]): Promise<RelatoryYearlyExpesesResponse[]> {
    try {
      const { data } = await request.get<RelatoryYearlyExpesesResponse[]>(
        `${this.getCompletePath()}/yearly-expenses`,
        {
          healthUnits
        }
      )
      return data
    } catch (e) {
      return []
    }
  }

  async getStationsToApprove(filters?: FilterStates): Promise<StationsToApproveResponse[]> {
    try {
      const { data } = await request.get<StationsToApproveResponse[]>(
        `${this.getCompletePath()}/approve`,
        filters
      )
      return data
    } catch (e) {
      return []
    }
  }

  async approveStation(payload: ApproveStationRequest): Promise<any> {
    const { data } = await request.post<void>(`${this.getCompletePath()}/approve`, payload)
    return data
  }
}
