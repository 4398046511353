import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-start gap-1 flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.companyDoctors, (companyDoctor, index) => {
      return (_openBlock(), _createBlock(_component_Card, {
        key: index,
        companyDoctor: companyDoctor
      }, null, 8, ["companyDoctor"]))
    }), 128))
  ]))
}