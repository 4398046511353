import { createStore } from 'vuex'
import { inject, provide } from 'vue'
import user from './modules/public/user'
import signUp from './modules/public/signUp'
import filters from './modules/public/filters'
import contract from './modules/public/contract'
import protocol from './modules/public/protocol'
import userCrud from './modules/public/userCrud'
import cardsCrud from './modules/public/cardsCrud'
import alerts from './modules/public/alerts'
import hospitalsCrud from './modules/public/hospitalsCrud'
import scales from './modules/public/scales'

const StoreSymbol = Symbol('vuex-store')

export function _createStore() {
  return createStore({
    modules: {
      user,
      signUp,
      filters,
      contract,
      protocol,
      userCrud,
      cardsCrud,
      alerts,
      hospitalsCrud,
      scales
    }
  })
}

export function provideStore(store: any) {
  provide(StoreSymbol, store)
}

export default function useStore(): any {
  const store = inject(StoreSymbol)
  if (!store) {
    throw Error('no store provided')
  }
  return store
}
