import { mergeProps as _mergeProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, _mergeProps({ ..._ctx.$props, ..._ctx.$attrs }, {
    variant: "flat",
    class: "button-generic flex items-center px-2 py-4 font-semibold duration-200 transform rounded-md text-sm"
  }), _createSlots({ _: 2 }, [
    _renderList(_ctx.$slots, (_, slot) => {
      return {
        name: slot,
        fn: _withCtx((scope) => [
          _renderSlot(_ctx.$slots, slot, _mergeProps({ key: slot }, scope))
        ])
      }
    })
  ]), 1040))
}