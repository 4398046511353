import { reactive } from 'vue'
export type FilterDashboardStates = {
  doctors?: number[]
  crmStates?: string[]
  crms?: string[]
  valueMin?: number
  valueMax?: number
  paymentValueMin?: number
  paymentValueMax?: number
  withCheckin?: boolean
  withCheckout?: boolean
  stationType?: string[]
  inCash?: boolean
  advanceRequested?: boolean
  advancePaid?: boolean
  ourTeam?: boolean
  status?: string
  openedStation?: boolean
}

const DEFAULT_VALUES = {
  doctors: undefined,
  crmStates: undefined,
  crms: undefined,
  valueMin: undefined,
  valueMax: undefined,
  paymentValueMin: undefined,
  paymentValueMax: undefined,
  withCheckin: undefined,
  withCheckout: undefined,
  inCash: undefined,
  advanceRequested: undefined,
  advancePaid: undefined,
  status: undefined,
  stationType: undefined,
  ourTeam: undefined,
  openedStation: undefined
}

export const filterDashboardStates: FilterDashboardStates = reactive({ ...DEFAULT_VALUES })

export const removeFilterDashboardOpt = (filter: string) => {
  if (!filterDashboardStates[filter]) return
  filterDashboardStates[filter] = undefined
}

export const clearFilterDashboardOpt = () => {
  Object.keys(filterDashboardStates).forEach((key) => {
    filterDashboardStates[key] = undefined
  })
}
