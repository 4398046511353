<template>
  <modal-vue @close="close" :modalActive="modalStateProp">
    <template #header>
      <p class="font-bold text-start">Criar convite</p>
    </template>

    <div class="flex flex-col gap-4">
      <GenericInput v-model="form.crm" label="CRM*" width="100%" />

      <v-autocomplete
        v-model="form.crmUf"
        label="Selecione o CRMUF"
        :items="StatesFromBR"
        variant="outlined"
      />

      <v-autocomplete
        v-model="form.companyId"
        color="primary20"
        label="Empresa"
        :items="listCompanies"
        variant="outlined"
      />
    </div>

    <template #footer>
      <div class="flex flex-col md:flex-row md:justify-end gap-2">
        <div class="w-full md:max-w-[140px] order-2 md:order-1">
          <OutlineButton @click="close" text="Cancelar" width="100%" />
        </div>
        <div class="w-full md:max-w-[140px] order-1 md:order-2">
          <filled-button
            :loading="loading"
            @click="submitCreateInvite"
            text="Criar convite"
            width="100%"
          />
        </div>
      </div>
    </template>
  </modal-vue>
  <modal-vue
    @close="
      () => {
        showModalCreatedSuccess = false
        close()
      }
    "
    :modalActive="showModalCreatedSuccess"
  >
    <template #header>
      <p class="font-bold text-start">Convite criado</p>
    </template>

    <div class="flex flex-col gap-4">
      <Input label="CRM*" width="100%" />
      <Input v-model="result.code" label="Código*" width="100%" />
    </div>

    <template #footer>
      <div class="flex justify-end">
        <button
          @click="copy"
          class="flex justify-center items-center lg:self-end gap-3 py-3 lg:col-span-2 active:bg-[#D8EEF3] rounded-md"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.1562 5.4125C3.1562 5.16052 3.36047 4.95625 3.61245 4.95625H12.3C14.8094 4.95625 16.8437 6.99056 16.8437 9.5V21.8375C16.8437 22.0895 16.6394 22.2938 16.3875 22.2938H3.61245C3.36047 22.2938 3.1562 22.0895 3.1562 21.8375V5.4125Z"
              fill="white"
              stroke="#2F7F91"
              stroke-width="0.9125"
            />
            <path
              d="M12.3 5V8.5C12.3 9.05228 12.7478 9.5 13.3 9.5H16.8"
              stroke="#2F7F91"
              stroke-linecap="round"
            />
            <path
              d="M7.1562 2.4125C7.1562 2.16052 7.36047 1.95625 7.61245 1.95625H16.3C18.8094 1.95625 20.8437 3.99056 20.8437 6.5V18.8375C20.8437 19.0895 20.6394 19.2938 20.3875 19.2938H7.61245C7.36047 19.2938 7.1562 19.0895 7.1562 18.8375V2.4125Z"
              fill="white"
              stroke="#2F7F91"
              stroke-width="0.9125"
            />
            <path
              d="M16.3 2V5.5C16.3 6.05228 16.7478 6.5 17.3 6.5H20.8"
              stroke="#2F7F91"
              stroke-linecap="round"
            />
          </svg>
          <p class="font-bold text-[#2F7F91]">{{ copyText }}</p>
        </button>
      </div>
    </template>
  </modal-vue>
</template>

<script setup lang="ts">
import { defineProps, toRefs, defineEmits, reactive, ref } from 'vue'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import Input from '@/components/designSystem/Inputs/Input.vue'
import StatesFromBR from '@/config/StatesFromBR'
import GenericInput from '@/components/designSystem/Inputs/GenericInput.vue'
import { storeToRefs } from 'pinia'
import { useCompanyStore } from '@/pinia-store/useCompanyStore'
import Network from '@/Network'

import { useToast } from 'vue-toastification'

const props = defineProps<{ modalStateProp: boolean; updateListFunction: Function }>()

const emit = defineEmits(['close'])

const { modalStateProp } = toRefs(props)

const error = reactive({
  select: false
})

const form = reactive({
  companyId: '',
  crm: '',
  crmUf: '',
  autoApprove: false
})

const result = reactive({
  crm: '',
  code: ''
})

const loading = ref(false)
const { listCompanies } = storeToRefs(useCompanyStore())
const toast = useToast()
const showModalCreatedSuccess = ref(false)

// TODO quando tirar o autoaproove descomentar essa linha.
// const openModalSucess = () => {
//   if (!Object.values(form).every((value) => value)) return

//   console.log(Object.values(result).every((value) => value))

//   showModalCreatedSuccess.value = true
// }

const submitCreateInvite = async () => {
  try {
    loading.value = true
    await Network.company.inviteDoctorForCompany({
      ...form,
      companyId: Number(form.companyId)
    })
    toast.success('Médico vinculado com sucesso')
    close()
    try {
      if (props.updateListFunction) {
        await props.updateListFunction()
      }
    } catch (error) {
      console.error(error)
    }
  } catch (error) {
    toast.error('Erro ao vicular médico, verifique os dados e tente novamente!')
    console.error(error)
  } finally {
    loading.value = false
  }
}

const close = () => {
  form.crm = ''
  form.crmUf = ''
  form.companyId = ''
  error.select = false
  showModalCreatedSuccess.value = false

  emit('close')
}
const initialText = 'Copiar código'
const copyText = ref(initialText)

const copy = () => {
  copyText.value = 'Código copiado'
  const code = /CRM (.*)/g.exec(result.code)?.[1]

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  navigator.clipboard.writeText(code!)

  setTimeout(() => {
    copyText.value = initialText
  }, 1000)
}
</script>
