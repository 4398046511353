<template>
  <div v-if="duties.length > 0">
    <table class="table">
      <tr>
        <th>Ativo</th>
        <th>Especialista</th>
        <th>Setor</th>
        <th>Mudança</th>
        <th>Valor</th>
        <th>Deduções fiscais</th>
        <th>Fechamento</th>
        <th>Pagamento</th>
        <th>Pagador</th>
        <th>Organização Social</th>
        <th></th>
        <th></th>
      </tr>
      <tr class="border-b-2" v-for="(duty, index) in duties" :key="index">
        <td v-if="duty.isActive" class="flex justify-center items-center">
          <img width="20" src="@/assets/icons/right-check.svg" />
        </td>
        <td v-else class="flex justify-center items-center">
          <img width="20" src="@/assets/icons/deactivate.png" />
        </td>
        <td class="table-data">{{ duty.speciality }}</td>
        <td class="table-data">{{ duty.sector }}</td>
        <td class="table-data">{{ `${duty.initialHour} - ${duty.endHour}` }}</td>
        <td class="table-data">R${{ duty.value }}</td>
        <td class="table-data">R${{ duty.fiscalDeducationPercentage }}</td>
        <td class="table-data">{{ duty.closeDay }}</td>
        <td class="table-data">{{ duty.paymentDate }}</td>
        <td class="table-data">{{ duty.payer }}</td>
        <td class="table-data">{{ duty.fiscalDeducationName }}</td>
        <td class="table-data">
          <EditPencilIcon @click="initEditedDuty(index)" class="cursor-pointer" />
        </td>
        <td v-if="duty.isActive" class="flex justify-center items-center table-data">
          <img
            class="cursor-pointer"
            @click="toggleActiveDuty(index)"
            style="width: 24px; max-width: none"
            src="@/assets/icons/turn-on.svg"
          />
        </td>
        <td v-else class="flex justify-center items-center table-data">
          <img
            class="cursor-pointer"
            @click="toggleActiveDuty(index)"
            style="width: 24px; max-width: none"
            src="@/assets/icons/turn-off.svg"
          />
        </td>
      </tr>
    </table>
  </div>
  <div v-else>Este contrato não possui nenhum plantão.</div>
</template>

<script>
import EditPencilIcon from '@/assets/icons/edit-pencil.vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'

export default {
  name: 'DutyTable',
  components: { EditPencilIcon },
  props: ['duties', 'contractIndex'],
  emits: ['setShowSaveDuty'],
  setup(props, { emit }) {
    const store = useStore()
    const toast = useToast()

    const toggleActiveDuty = (dutyIndex) => {
      store.dispatch('toggleActiveDuty', { contractIndex: props.contractIndex, dutyIndex })
      if (props.duties[dutyIndex].isActive) {
        toast.success('Plantão ativado com sucesso!')
      } else {
        toast.error('Plantão desativado com sucesso!')
      }
    }

    const initEditedDuty = (dutyIndex) => {
      store.dispatch('initEditedDuty', { contractIndex: props.contractIndex, dutyIndex })
      emit('setShowSaveDuty', true)
    }

    return {
      toggleActiveDuty,
      initEditedDuty
    }
  }
}
</script>

<style scoped>
.table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
.table-data {
  max-width: 150px;
}
.table td:nth-child(1),
.table th:nth-child(1) {
  padding: 10px 0 10px 0;
}

.table td,
.table th {
  padding: 10px 20px 10px 20px;
}
</style>
