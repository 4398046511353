<template>
  <div class="w-full h-full grid-layout my-0">
    <div class="w-full lg:flex justify-between items-start gap-4">
      <div class="hidden lg:inline w-4/5">
        <SearchInput
          v-model="textInput"
          :search-function="getListUsers"
          width="80%"
          label="Buscar usuário"
        />
      </div>
      <div class="lg:hidden inline w-4/5" style="grid-area: search">
        <SearchInput
          v-model="textInput"
          :search-function="getListUsers"
          width="80%"
          label="Buscar usuário"
        />
      </div>

      <div class="hidden lg:flex w-1/5">
        <FilledButton
          @click="toggleModalCreateUser"
          text="Criar usuário"
          width="100%"
          height="56px"
        />

        <!-- <OutlineButton
          @click="toggleModalUsersGroup"
          text="Grupo de usuários"
          width="220px"
          class="ml-4"
        /> -->
      </div>
    </div>

    <UsersTable :companyUsers="companyUsers" :count="count" />

    <div class="grid grid-cols-1 lg:hidden gap-4 my-0" style="grid-area: buttons">
      <FilledButton @click="toggleModalCreateUser" text="Criar usuário" width="100%" />

      <OutlineButton @click="toggleModalUsersGroup" text="Grupo de usuários" width="100%" />
    </div>

    <CreateUserModal @close="toggleModalCreateUser" :modalActive="showModalCreateUser" />
    <UsersGroupModal @close="toggleModalUsersGroup" :modalActive="showModalUsersGroup" />
    <DeleteUserModal @close="toggleModalUsersGroup" :modalActive="showModalUsersGroup" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import CreateUserModal from './components/CreateUserModal/Index.vue'
import UsersGroupModal from './components/UsersGroupModal/Index.vue'
import DeleteUserModal from './components/DeleteUserModal/Index.vue'
import UsersTable from './components/UsersTable/index.vue'
import Network from '@/Network'
import {
  CompanyUserResponse,
  ListCompanyUserResponse
} from '@/Network/Types/Responses/CompanyResponses'
import SearchInput from '@/components/designSystem/Inputs/SearchInput.vue'

const showModalCreateUser = ref(false)
const showModalUsersGroup = ref(false)
const textInput = ref('')
const loading = ref(false)

const companyUsers = ref<CompanyUserResponse[]>()
const count = ref<number>()

const getListUsers = async () => {
  try {
    loading.value = true
    const usersPayload: ListCompanyUserResponse = await Network.company.listCompanyUsers(
      textInput.value
    )
    companyUsers.value = usersPayload.data
    count.value = usersPayload.count
  } catch (e) {
    console.error(e)
  } finally {
    loading.value = false
  }
}

const toggleModalCreateUser = () => {
  showModalCreateUser.value = !showModalCreateUser.value
}

const toggleModalUsersGroup = () => {
  showModalUsersGroup.value = !showModalUsersGroup.value
}
</script>

<style scoped>
.grid-layout {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 64px 1fr;
  gap: 0px 0px;
  grid-template-areas:
    'search'
    'table';
}

@media only screen and (max-width: 1024px) {
  .grid-layout {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: 58px 1fr 132px;
    gap: 0px 0px;
    grid-template-areas:
      'search'
      'table'
      'buttons';
  }
}
</style>
