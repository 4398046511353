import request from '@/Network/Requests/request'
import Service from '@/Network/Services/Service'
import { Company } from '@/store/modules/public/signUp'
import { CompanyResponse, ListCompanyDoctorsResponse, ListCompanyUserResponse, ListDoctorsCompanyResponse } from '../Types/Responses/CompanyResponses'
import { CompanyDoctorInvite, CompanyDoctorInviteSearchParams, CompanyUser, CompanyUserChangePassword } from '../Types/Requests/CompanyRequests'

export default class CompanyService extends Service {
  prefix: string

  constructor() {
    super()
    this.prefix = '/companies'
  }

  async inviteDoctorForCompany(data: CompanyDoctorInvite) {
    try {
      return await request.post(this.getCompletePath('/invite-doctor'), data)
    } catch (e) {
      console.error(e)
      throw e;
    }
  }

  async getCompanyDoctors(params: CompanyDoctorInviteSearchParams) {
    try {
      const response = await request.get<ListCompanyDoctorsResponse>(this.getCompletePath('/doctor'), { ...params })
      return {
        data: response.data.data,
        count: response.data.count
      }
    } catch (e) {
      console.error(e)
      throw e;
    }
  }

  async changeUserCompanyPassword(id: number, newPassword: CompanyUserChangePassword) {
    try {
      return request.put(this.getCompletePath(`/user/${id}/change-password`), newPassword)
    } catch (error) {
      throw new Error('Ocorreu um erro ao cadastrar a empresa, por favor tente novamente.')
    }
  }

  async createCompanyByPublic(data: Company) {
    try {
      const formatedBody = {
        "name": data.userName,
        "email": data.email,
        "phone": data.phone1,
        "password": data.password,
        "confirmPassword": data.confirmPassword,
        "companyName": data.companyName,
        "fantasyName": data.fantasyName,
        "cnpj": data.cnpj,
        "companyEmail": data.corporateEmail,
        "companyPhone": data.corporatePhone1,
        "companyCep": data.cep,
        "companyStreet": data.street,
        "companyDistrict": data.district,
        "companyNumber": data.number,
        "companyComplement": data.complement,
        "companyUf": data.state,
        "companyCity": data.city
      };
      return request.post(this.getCompletePath(), formatedBody)
    } catch (e) {
      throw new Error('Ocorreu um erro ao cadastrar a empresa, por favor tente novamente.')
    }
  }

  async list(): Promise<CompanyResponse[]> {
    try {
      const { data } = await request.get<CompanyResponse[]>(this.getCompletePath() + '/my')
      return data
    } catch (e) {
      return []
    }
  }

  async listDoctorsByCompany(companyId: number): Promise<ListDoctorsCompanyResponse> {
    try {
      const { data } = await request.get<ListDoctorsCompanyResponse>(
        `${this.getCompletePath()}/${companyId}/doctors`
      )
      return data
    } catch (e) {
      return {
        count: 0,
        data: []
      }
    }
  }

  async createCompanyUser(data: CompanyUser) {
    return request.post(this.getCompletePath('/user'), data)
  }

  async listCompanyUsers(search: string): Promise<ListCompanyUserResponse> {
    try {
      const response = await request.get<ListCompanyUserResponse>(this.getCompletePath('/user'), { search: search ? search : undefined });
      return {
        data: response.data.data,
        count: response.data.count
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async persistLocalCompanyList(companiesId: number[]) {
    if (companiesId) {
      try {
        localStorage.setItem('@ME:companies_id', companiesId.join())
      } catch (e) {
        console.error(e)
      }
    }
  }

  getLocalTokenCompanyList(): string | undefined {
    try {
      const companiesId = localStorage.getItem('@ME:companies_id');
      return companiesId ? companiesId : undefined;
    } catch (e) {
      console.error(e)
    }
  }


}
