import { defineStore } from 'pinia'
import Network from '@/Network'
import { CreateHealthUnit, FiltersHealthUnits } from '@/Network/Types/Requests/HealthUnitRequests'
import { ListHealthUnitsResponse } from '@/Network/Types/Responses/HealthUnitResponses'

export type HealthUnitState = {
  healthUnits: ListHealthUnitsResponse | undefined
  listHealthUnits: { title: string; value: string; subtitle: string }[]
  healthUnitsMap: Map<number, string>
  isLoading: boolean
}

export const useHealthUnitStore = defineStore('healthUnit', {
  state: (): HealthUnitState => ({
    healthUnits: undefined,
    listHealthUnits: [],
    healthUnitsMap: new Map(),
    isLoading: false
  }),
  actions: {
    async getAllHealthUnits(
      forceResync: boolean = false,
      filters?: FiltersHealthUnits
    ): Promise<ListHealthUnitsResponse> {
      try {
        if ((forceResync || !this.healthUnits) && !this.isLoading) {
          this.isLoading = true
          this.healthUnits = await Network.healthUnit.list(filters)
        }
        this.listHealthUnits = (this.healthUnits?.data ?? []).map((h) => {
          const unit = {
            title: h.name,
            value: String(h.id),
            subtitle: h.company.name
          }
          this.healthUnitsMap.set(+unit.value, unit.title)
          return unit
        })
        return this.healthUnits ?? { count: 0, data: [] }
      } finally {
        this.isLoading = false
      }
    },
    async create({ companyId, payload }: { companyId: string; payload: CreateHealthUnit }) {
      await Network.healthUnit.create(companyId, payload)
    },
    async update({ healthUnitId, payload }: { healthUnitId: number; payload: CreateHealthUnit }) {
      await Network.healthUnit.update(healthUnitId, payload)
    },
    async setHealthUnitsByCompany(companyId: number | null) {
      if (!companyId) {
        this.listHealthUnits = []
        return
      }
      await this.getAllHealthUnits()
      const healthUnits = this.healthUnits?.data.filter((h) => h.company.id === companyId) || []
      this.listHealthUnits = healthUnits.map((h) => ({
        title: h.name,
        value: String(h.id),
        subtitle: h.company.name
      }))
    }
  },
  getters: {
    getHealthUnitNameById: (state) => (id: number) => {
      return state.healthUnitsMap.get(id) ?? ''
    }
  }
})
