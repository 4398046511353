import request from '@/Network/Requests/request'
import Service from '@/Network/Services/Service'
import { AuthUserCompany, AuthDoctor } from '@/Network/Types/Requests/AuthRequests'
import { AuthResponse } from '@/Network/Types/Responses/AuthResponses'
import { setUserContextInLocalStorage } from '@/router'

export const STORAGE_TOKEN_KEY = ``

export default class AuthService extends Service {
  prefix: string

  constructor() {
    super()
    this.prefix = '/auth'
  }

  async loginUserCompany(payload: AuthUserCompany): Promise<AuthResponse> {
    try {
      const { data } = await request.post<AuthResponse>(this.getCompletePath('/company'), payload)
      return data
    } catch (e) {
      console.error('Erro ao realizar login!', e)
      throw new Error('Erro ao realizar login.')
    }
  }

  async loginDoctor(payload: AuthDoctor): Promise<AuthResponse> {
    try {
      const { data } = await request.post<AuthResponse>(this.getCompletePath('/doctor'), payload)
      return data
    } catch (e) {
      console.error('Erro ao realizar login!', e)
      throw new Error('Erro ao realizar login.')
    }
  }

  persistAuthResponse(payload: AuthResponse) {
    if (payload) {
      localStorage.clear()
      setUserContextInLocalStorage('company')
      localStorage.setItem('@ME:access_token', `${payload.access_token}`)
      localStorage.setItem('@ME:needChangePassword', `${payload.needChangePassword}`)
      localStorage.setItem('@ME:permissions', JSON.stringify(payload.permissions))
      localStorage.setItem('@ME:name', JSON.stringify(payload.name || 'Minha Escala'))
    }
  }

  persistDoctorAuthResponse(payload: AuthResponse) {
    if (payload) {
      localStorage.clear()
      setUserContextInLocalStorage('doctor')
      localStorage.setItem('@ME:doctor_access_token', `${payload.access_token}`)
      localStorage.setItem('@ME:needChangePassword', `${payload.needChangePassword}`)
      localStorage.setItem('@ME:permissions', JSON.stringify(payload.permissions))
      localStorage.setItem('@ME:name', JSON.stringify(payload.name || 'Minha Escala'))
    }
  }

  getLocalToken(): string | null {
    try {
      const userContext = localStorage.getItem('@ME:user_context')
      const isDoctorUserContext = userContext == 'doctor'
      if (isDoctorUserContext) {
        const doctorUserToken = localStorage.getItem('@ME:doctor_access_token')
        return doctorUserToken
      }

      const companyUserToken = localStorage.getItem('@ME:access_token')
      return companyUserToken
    } catch (e) {
      console.error('Token de autenticação não existe.', e)
      return null
    }
  }

  getLocalDoctorToken(): string | null {
    try {
      const token = localStorage.getItem('@ME:doctor_access_token')
      return token ? token : null
    } catch (e) {
      console.error('Token de autenticação não existe.', e)
      return null
    }
  }

  logout() {
    localStorage.clear()
    window.location.href = '/'
  }
}
