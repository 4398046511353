<template>
  <div class="container-login w-full h-screen">
    <div class="logo flex justify-center">
      <Logo width="160px" logo="full" />
    </div>
    <div class="flex justify-center">
      <div class="card flex items-center flex-col rounded overflow-hidden shadow-lg">
        <h1 class="title">Esqueceu sua senha?</h1>
        <h5 class="subtitle">
          Insira seu e-mail de cadastro e enviaremos um link de recuperação para você!
        </h5>
        <div class="input">
          <Input :isError="isErrorUserName" width="329px" label="Email" />
        </div>
        <div class="login">
          <router-link to="/">
            <FilledButton width="329px" text="Enviar link" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import Logo from '@/components/designSystem/Logo/index.vue'
import Input from '@/components/designSystem/Inputs/Input.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'

export default defineComponent({
  name: 'AppSignin',
  components: {
    Logo,
    Input,
    FilledButton
  },
  setup() {
    const store = useStore()
    const userName = computed(() => store.state.user.name)
    const isErrorUserName = ref(false)

    return {
      userName,
      isErrorUserName
    }
  }
})
</script>

<style scoped>
.container-login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #2f7f91;
}
.card {
  width: 508px;
  padding: 3rem;
  background-color: #fff;
}

@media (max-width: 556px) {
  .card {
    width: 90%;
    background-color: #fff;
  }
}

.title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 15px;
}
.subtitle {
  font-family: 'Inter';
  font-style: normal;
  max-width: 80%;
}
.input {
  margin: 3rem 0;
}
.logo {
  padding-bottom: 3rem;
}
.login {
  margin-bottom: 15px;
}
</style>
