<template>
  <ModalVue @close="$emit('closeApproveSuccessModal')" :modalActive="showApproveSuccessModal">
    <template #header>
      <p class="font-bold px-[8px] flex justify-center">
        <SuccessIcon width="50px" height="50px" />
      </p>
    </template>
    <p class="text-base font-normal">
      O pagamento foi<br />
      aprovado com sucesso!
    </p>
  </ModalVue>
</template>

<script>
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import SuccessIcon from '@/assets/icons/success.vue'

export default {
  emits: ['closeApproveSuccessModal'],
  props: {
    showApproveSuccessModal: Boolean
  },
  components: { ModalVue, SuccessIcon }
}
</script>
