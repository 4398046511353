<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.74878 17.5607L14.0588 7.25066L16.7481 9.94L6.43812 20.25H3.74878V17.5607ZM17.8384 3.82033L20.1784 6.16033C20.2756 6.25744 20.2756 6.41256 20.1784 6.50967L18.8788 7.80934L16.1894 5.12L17.4891 3.82033C17.5361 3.77339 17.5943 3.75 17.6588 3.75C17.7373 3.75 17.791 3.77284 17.8384 3.82033Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#52526F'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
