<template>
  <div class="container-steps">
    <div class="flex flex-col items-center">
      <div class="title text-primary30 w-full text-center">
        {{ title }}
      </div>
      <div class="flex">
        <div v-for="(step, i) in new Array(stepsLength)" :key="'multi-step-' + i">
          <div class="flex items-center">
            <circle-icon :color="i <= stepSelected ? '#2F7F91' : '#BCBCCD'" />
            <bar-icon
              class="bar-icon"
              :color="i < stepSelected ? '#2F7F91' : '#BCBCCD'"
              v-if="i != new Array(stepsLength).length - 1"
            />
          </div>
        </div>
      </div>
      <div v-for="(step, i) in new Array(stepsLength)" :key="'multi-step-body-' + i" class="w-full">
        <div v-if="i === stepSelected">
          <slot :name="'step-' + i"></slot>
        </div>
      </div>
      <div v-if="!hiddenBtn" class="w-full flex gap-5 items-start justify-center">
        <!-- <outline-button v-if="stepSelected !== 0" @click="prevStep" width="150px" text="Voltar" /> -->
        <filled-button
          @click="nextStep"
          width="150px"
          :text="btnText"
          :disabled="nextStepDisabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CircleIcon from '@/assets/icons/CircleIcon.vue'
import BarIcon from '@/assets/icons/BarIcon.vue'
import { ref, onMounted, watch } from 'vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'

export default {
  components: {
    CircleIcon,
    BarIcon,
    FilledButton
  },
  props: {
    btnText: {
      type: String,
      default: 'Continuar'
    },
    stepsLength: {
      default: 3,
      type: Number
    },
    hiddenBtn: {
      default: false,
      type: Boolean
    },
    nextStepDisabled: {
      default: false
    },
    stepSelected: {
      default: true
    },
    title: {
      default: 'Steps'
    }
  },
  setup(props, context) {
    const stepSelectedState = ref(0)

    function nextStep() {
      context.emit('changeStep', stepSelectedState.value)
    }

    onMounted(() => {
      stepSelectedState.value = props.stepSelected
    })

    watch(
      () => props.stepSelected,
      (value) => {
        if (value) {
          stepSelectedState.value = value
        }
      }
    )

    return {
      stepSelectedState,
      nextStep
    }
  }
}
</script>

<style scoped>
.container-steps {
  width: 100%;
}

.bar-icon {
  margin-left: 10px;
  margin-right: 10px;
}

.title {
  /* Body/medium */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  margin-bottom: 10px;
}
</style>
