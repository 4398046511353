<template>
  <div :class="classMainContainer">
    <div class="flex w-full justify-between">
      <div class="logo flex justify-start">
        <Logo width="200px" logo="horizontal" />
      </div>

      <div class="buttons cursor-pointer">
        <a v-if="!disableDefaultCloseCallback" @click="onCloseSignupForm">
          <close-icon />
        </a>
        <router-link v-if="disableDefaultCloseCallback" to="/signup">
          <close-icon />
        </router-link>
      </div>
    </div>
    <div class="w-full steps flex justify-center mt-5">
      <steps
        @changeStep="changeStep"
        :stepSelected="stepSelected"
        :stepsLength="5"
        title="Cadastro Médico"
      >
        <template v-slot:step-0>
          <user-data />
        </template>
        <template v-slot:step-1>
          <professional-data />
        </template>
        <template v-slot:step-2>
          <Address />
        </template>
        <template v-slot:step-3>
          <personal-data />
        </template>
        <template v-slot:step-4>
          <div
            class="w-full flex gap-2 items-center justify-center flex-col rounded overflow-hidden my-10"
          >
            <h1 class="text-center font-bold text-2xl mb-7">Cadastro realizado com sucesso!</h1>
            <h5 class="subtitle">Clique para abrir o aplicativo!</h5>
            <div class="flex gap-2">
              <a :href="androidUrl" target="_blank" rel="noopener noreferrer">
                <div
                  class="w-[175px] h-[60px] bg-white border rounded border-gray-500 p-3 cursor-pointer"
                >
                  <img width="150" height="33px" src="/google play.png" />
                </div>
              </a>
              <a :href="iosUrl" target="_blank" rel="noopener noreferrer">
                <div class="w-[175px] h-[60px] bg-white border rounded border-gray-500 p-3">
                  <img width="150" height="33" src="/app_store.png" />
                </div>
              </a>
            </div>
          </div>
        </template>
        <template v-slot:footer> </template>
      </steps>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import Logo from '@/components/designSystem/Logo/index.vue'
import Steps from '@/components/designSystem/Steps/Index.vue'
import CloseIcon from '@/assets/icons/CloseIcon.vue'
import UserData from '@/modules/public/SignUp/doctor/steps/UserData.vue'
import PersonalData from '@/modules/public/SignUp/doctor/steps/PersonalData.vue'
import ProfessionalData from '@/modules/public/SignUp/doctor/steps/ProfessionalData.vue'
import Address from '@/modules/public/SignUp/doctor/steps/Address.vue'
import Network from '@/Network'
import { CreateDoctorRequest } from '@/Network/Types/Requests/DoctorRequests'
import { useDoctorRegistryStore } from '@/pinia-store/useDoctorRegistryStore'
import { storeToRefs } from 'pinia'
import { useToast } from 'vue-toastification'
import {
  UserDataSchema,
  PersonalDataSchema,
  AddressSchema,
  ProfessionalDataSchema
} from './helpers'

export default defineComponent({
  name: 'Doctor',
  components: {
    Logo,
    CloseIcon,
    Steps,
    UserData,
    ProfessionalData,
    PersonalData,
    Address
  },
  props: {
    activeCustomSubmitEvent: {
      type: Boolean,
      default: false
    },
    disableDefaultCloseCallback: {
      type: Boolean,
      default: false
    },
    classMainContainer: {
      type: String,
      default: 'container-signup'
    }
  },
  emits: ['onSuccessSubmit', 'onCloseSignupForm'],
  setup(props, { emit }) {
    const stepSelected = ref(0)
    const isLoading = ref(false)
    const isSuccessSubmit = ref(false)
    const androidUrl = ref('#')
    const iosUrl = ref('#')
    const doctorRegistryStore = useDoctorRegistryStore()
    const {
      userDataForm,
      userDataFormRef,
      professionalDataForm,
      professionalDataFormRef,
      addressForm,
      addressFormRef,
      personalDataForm,
      personalDataFormRef
    } = storeToRefs(doctorRegistryStore)

    async function changeStep(step: number) {
      if (step === 0) {
        userDataFormRef.value.validate()
        if (userDataFormRef.value.isValid) {
          stepSelected.value = step + 1
        }
      }
      if (step === 1) {
        professionalDataFormRef.value.validate()
        if (professionalDataFormRef.value.isValid) {
          stepSelected.value = step + 1
        }
      }
      if (step === 2) {
        addressFormRef.value.validate()
        if (addressFormRef.value.isValid) {
          stepSelected.value = step + 1
        }
      }
      if (step === 3) {
        personalDataFormRef.value.validate()
        if (personalDataFormRef.value.isValid) {
          await handleSubmit()
          stepSelected.value = step + 1
        }
      }
    }

    const onCloseSignupForm = () => {
      console.log(`sadsa`)
      emit('onCloseSignupForm')
    }

    const toast = useToast()
    const handleSubmit = async () => {
      try {
        if (isLoading.value) return
        isLoading.value = true
        const userDataPayload = await UserDataSchema.validate(userDataForm.value)
        const personalDataPayload = await PersonalDataSchema.validate(personalDataForm.value)
        const addressPayload = await AddressSchema.validate(addressForm.value)
        const professionalDataPayload = await ProfessionalDataSchema.validate(
          professionalDataForm.value
        )

        const payloadToCreate: CreateDoctorRequest = {
          email: userDataPayload.email,
          password: userDataPayload.password,
          confirmPassword: userDataPayload.confirmPassword,
          phone: userDataPayload.phone,
          crms: [
            {
              number: professionalDataPayload.crm,
              uf: professionalDataPayload.crmUf
            }
          ],
          name: professionalDataPayload.name,

          street: addressPayload.street,
          number: addressPayload.number,
          complement: addressPayload.complement,
          district: addressPayload.district,
          city: addressPayload.city,
          uf: addressPayload.state,
          cep: addressPayload.cep,
          specialties: [],
          birthDate: personalDataPayload.birthDate,
          cpf: personalDataPayload.cpf,
          fatherName: personalDataPayload.fatherName,
          motherName: personalDataPayload.motherName,
          maritalStatus: personalDataPayload.civilStates,
          nationality: personalDataPayload.nationality,
          rg: personalDataPayload.rg,
          issueDate: personalDataPayload.issueDate,
          issuingAuthority: personalDataPayload.issuingAuthority
        }
        const mobileUrls = await Network.doctor.create(payloadToCreate)

        if (mobileUrls.androidUrl && mobileUrls.iosUrl) {
          androidUrl.value = mobileUrls.androidUrl
          iosUrl.value = mobileUrls.iosUrl
        }

        toast.success('Cadastro realizado com sucesso')
        if (props.activeCustomSubmitEvent) {
          emit('onSuccessSubmit', mobileUrls)
        } else {
          isSuccessSubmit.value = true
        }
      } catch (error: any) {
        toast.error('Erro ao realizar seu cadastro, se o erro persistir entre em contato conosco.')
        console.error(error.errors)
        throw error
      } finally {
        isLoading.value = false
      }
    }

    return {
      changeStep,
      onCloseSignupForm,
      stepSelected,
      isSuccessSubmit,
      androidUrl,
      iosUrl
    }
  }
})
</script>

<style scoped>
.container-signup {
  background-color: #fafafa;
  height: 100%;
  min-height: 100vh;
  padding: 40px;
}
.steps {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.footer-step {
  width: 392px;
}

@media (max-width: 556px) {
  .container-signup {
    padding: 20px;
  }
  .steps {
    width: 250px;
  }
  .footer-step {
    width: 250px;
  }
}
</style>
