<template>
  <div class="w-full flex flex-col text-left">
    <h1 class="font-bold text-xl mb-8">Dados básicos</h1>
    <div class="md:w-3/4 w-full mb-8">
      <h1 class="font-bold text-lg mb-5">Administrativo</h1>
      <div class="flex flex-row gap-8">
        <Input
          @input="onChangeBasicData"
          errorMessage="Setor inválido"
          label="Setor"
          width="100%"
          :value="basicData.sector"
          name="sector"
        />
        <Input
          @input="onChangeBasicData"
          errorMessage="Cargo inválido"
          label="Cargo"
          width="100%"
          :value="basicData.occupation"
          name="occupation"
        />
      </div>
    </div>

    <div class="md:w-3/4 w-full mb-8">
      <h1 class="font-bold text-lg mb-5">Contatos</h1>
      <div class="flex flex-row gap-8">
        <Input
          @input="onChangeBasicData"
          errorMessage="Telefone inválido"
          label="Telefone"
          width="100%"
          pattern="(\(\d{2}\)\s)(\d{4,5}-\d{4})"
          mask="(##) #####-####"
          :value="basicData.phone"
          name="phone"
        />
        <Input
          @input="onChangeBasicData"
          errorMessage="Email inválido"
          label="Email"
          width="100%"
          pattern="[a-z0-9]+@[a-z]+\.[a-z]{2,3}"
          :value="basicData.email"
          name="email"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/designSystem/Inputs/Input.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  name: 'BasicData',
  components: {
    Input
  },
  setup() {
    const store = useStore()
    const basicData = computed(() => store.state.contract.basicData)

    const onChangeBasicData = (e) => {
      const payload = { property: e.target.name, value: e.target.value }
      store.dispatch('setBasicData', payload)
    }

    return {
      basicData,
      onChangeBasicData
    }
  }
}
</script>
