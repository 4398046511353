<template>
  <div>
    <div
      v-show="modalActive"
      :class="`modal z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>

      <div
        class="container-modal z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container"
      >
        <div
          @click="close"
          class="absolute md:inline flex top-0 right-0 z-50 flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <div class="px-6 py-4 text-left modal-content" :key="keyRender">
          <div class="flex items-center justify-between pb-3">
            <p class="titleBold">Criar usuário</p>
            <div class="z-50 cursor-pointer modal-close" @click="close">
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <p class="titleBold my-2">Acesso total ao sistema</p>

          <Checkbox
            v-model:checked="form.fullAccess"
            fieldId="access"
            label="Conceder acesso total"
          />

          <div class="grid md:grid-cols-2 grid-cols-1 gap-4 my-4">
            <Input
              :isError="error.name"
              @change="() => validateUserName()"
              errorMessage="Nome de inválido"
              width="100%"
              label="Nome"
              pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
              v-model="form.name"
              required
            />

            <Input
              @change="() => validateEmail()"
              :isError="error.email"
              v-model="form.email"
              errorMessage="E-mail inválido!"
              pattern="[a-z0-9]+@[a-z]+\.[a-z]{2,3}"
              type="email"
              width="100%"
              label="Email"
              required
            />

            <Input
              @change="() => validatePhone1()"
              :isError="error.phone1"
              v-model="form.phone1"
              errorMessage="Telefone inválido"
              width="100%"
              type="tel"
              pattern="(\(\d{2}\)\s)(\d{4,5}-\d{4})"
              label="Telefone"
              mask="(##) #####-####"
              required
            />

            <v-autocomplete
              v-model="companySelected"
              class="w-full"
              color="primary20"
              label="Empresa"
              :items="listCompanies"
              :rules="[validateCompany]"
              variant="outlined"
            />

            <Input
              @change="() => validatePassword()"
              :isError="error.password"
              v-model="form.password"
              errorMessage="A senha precisa ter pelo menos 8 caracters, 1 letra maíuscula, 1 caracter especial e 1 número"
              type="password"
              pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$"
              width="100%"
              label="Senha"
              required
            />

            <Input
              @change="() => validateConfirmPassword()"
              :isError="error.confirmPassword"
              v-model="form.confirmPassword"
              errorMessage="As senhas precisam serem idênticas"
              type="password"
              pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$"
              width="100%"
              label="Confirmar senha"
              required
            />
          </div>
          <!-- <div class="flex flex-wrap gap-2 mb-5">
            <FilterBtn key="Empresa 1" text="Empresa 1" />
            <FilterBtn key="Empresa 2" text="Empresa 2" />
          </div> -->
          <div class="flex justify-end">
            <OutlineButton @click="close" class="mr-4" text="Cancelar" width="120px" />
            <FilledButton :loading="loading" text="Criar" width="100px" @click="createUser" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, reactive, ref, watch } from 'vue'
import Input from '@/components/designSystem/Inputs/Input.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import Checkbox from '@/components/designSystem/Checkbox/index.vue'
import { storeToRefs } from 'pinia'
import { useCompanyStore } from '@/pinia-store/useCompanyStore'
import Network from '@/Network'
import { useToast } from 'vue-toastification'

const emits = defineEmits(['close'])
const companySelected = ref()
const loading = ref(false)
const { listCompanies } = storeToRefs(useCompanyStore())
const toast = useToast()
// const groups = computed(() => store.getters.getGroups);

const keyRender = ref(Math.random())
const error = reactive({
  name: false,
  email: false,
  select: false,
  password: false,
  confirmPassword: false,
  phone1: false,
  companySelected: false
})

const form = reactive({
  name: '',
  userGroup: '',
  email: '',
  password: '',
  confirmPassword: '',
  phone1: '',
  fullAccess: { isChecked: false }
})

const isValid = ref(Object.values(error).every((e) => e != true))

watch(form, () => {
  isValid.value = Object.values(error).every((e) => e != true)
})

function validateUserName() {
  const regex = new RegExp(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/)
  error.name = form.name === '' || !regex.test(form.name)
}

function validateEmail() {
  const regex = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/)
  error.email = form.email === '' || !regex.test(form.email)
}

function validatePhone1() {
  const regex = new RegExp(/(\(\d{2}\)\s)(\d{4,5}-\d{4})/)
  error.phone1 = form.phone1 === '' || !regex.test(form.phone1)
}

function validatePassword() {
  const regex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$/)
  error.password = form.password === '' || !regex.test(form.password)
  error.confirmPassword = form.password !== form.confirmPassword
}

function validateConfirmPassword() {
  error.confirmPassword = form.password !== form.confirmPassword || form.confirmPassword == ''
}

function validateCompany() {
  const isError = companySelected.value === ''
  error.companySelected = companySelected.value === ''
  return isError ? 'Empresa inválida' : true
}

function validateAll() {
  validateUserName()
  validateEmail()
  validatePhone1()
  validatePassword()
  validateConfirmPassword()
  validateCompany()

  isValid.value = Object.values(error).every((e) => e != true)
}

defineProps(['modalActive'])

const createUser = async () => {
  validateAll()
  if (isValid.value === false) return

  try {
    loading.value = true
    console.log(form.fullAccess ? form.fullAccess.isChecked : false)
    await Network.company.createCompanyUser({
      companyId: Number(companySelected.value),
      name: form.name,
      email: form.email,
      phone: form.phone1,
      password: form.password,
      confirmPassword: form.confirmPassword,
      fullAccess: form.fullAccess ? form.fullAccess.isChecked : false
    })
    toast.success('Usuário cadastrado com sucesso!')

    emits('close')
    keyRender.value = Math.random()
    Object.keys(error).forEach((key) => {
      error[key] = false
    })
    Object.keys(form).forEach((key) => {
      form[key] = ''
    })
  } catch (e) {
    toast.error('Verifique os dados inseridos e tente novamente!')
  } finally {
    loading.value = false
  }
}

const close = async () => {
  Object.keys(form).forEach((key) => {
    form[key] = ''
  })

  Object.keys(error).forEach((key) => {
    error[key] = false
  })

  keyRender.value = Math.random()

  emits('close')
}
</script>

<style scoped>
.modal {
  transition: opacity 0.25s ease;
}

.container-modal {
  width: 700px;
}

.titleBold {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 700;
}
</style>
