import request from '@/Network/Requests/request'
import Service from '@/Network/Services/Service'
import { CompanyDoctorInvite } from '@/Network/Types/Responses/CompanyResponses'

export const STORAGE_TOKEN_KEY = ``

export default class AuthService extends Service {
  prefix: string

  constructor() {
    super()
    this.prefix = '/doctors'
  }

  async validateTokenCompanyDoctorInvite(token: string): Promise<any> {
    return request.post(this.getCompletePath('/invite-validate-token'), { token })
  }
}
