<template>
  <v-row dense class="items-center">
    <v-col cols="6" sm="4" md="2" lg="1">
      <legend-table :legendColor="ELegendColor.alert" text="Aberta" />
    </v-col>
    <v-col cols="6" sm="4" md="2" lg="1">
      <legend-table :legendColor="ELegendColor.default" text="Semanal" />
    </v-col>
    <v-col cols="6" sm="4" md="2" lg="1">
      <legend-table :legendColor="ELegendColor.warning" text="Cobertura" />
    </v-col>
    <v-col cols="6" sm="4" md="2" lg="1">
      <legend-table :legendColor="ELegendColor.success" text="Extra" />
    </v-col>
    <v-col cols="12" md="3" lg="2">
      <ghost-button
        text="Mostrar todas legendas"
        width="100%"
        density="compact"
        :prependIcon="mdiMapLegend"
        @click="() => modalStore.toggleModal(EModals.LEGENDS)"
      />
    </v-col>
  </v-row>
  <teleport to="#app">
    <legends-modal />
  </teleport>
</template>
<script lang="ts" setup>
import LegendTable, { ELegendColor } from '@/components/others/Legend.vue'
import LegendsModal from './legends.modal.vue'

import GhostButton from '@/components/designSystem/Buttons/GhostButton/index.vue'
import { mdiMapLegend } from '@mdi/js'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
const modalStore = useModalStore()
</script>
