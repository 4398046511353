import * as yup from 'yup'

export const HealthUnitSchema = yup.object({
  name: yup.string().required('Nome é obrigatório'),
  email: yup.string().email('Digite um email válido').optional(),
  avatarUrl: yup.string().optional(),
  cnpj: yup.string().length(14, 'CNPJ Deve conter 14 caracteres').optional(),
  profile: yup.string().optional(),
  quantityColaborators: yup
    .number()
    .integer()
    .optional()
    .typeError('Quantidade deve ser um número'),
  generalManager: yup.string().optional(),
  technicalManager: yup.string().optional(),
  mainPhone: yup
    .string()
    .optional()
    // .min(10, 'Telefone deve ter no mínimo 10 caracteres')
    .max(11, 'Telefone deve ter no máximo 11 caracteres'),

  postalCode: yup.string().length(8, 'Cep deve conter 8 caracteres').required(),
  state: yup.string().required(),
  city: yup.string().required('Campo obrigatório'),
  street: yup.string().required('Campo obrigatório'),
  number: yup.string().required(),
  neighborhood: yup.string().required(),
  complement: yup.string().optional(),
  latitude: yup.string().optional(),
  longitude: yup.string().optional(),
  linkWaze: yup.string().optional()
})

export type HealthUnit = yup.InferType<typeof HealthUnitSchema>

export type ErrorUnit = { [K in keyof HealthUnit]: string }

const values: HealthUnit = {
  name: '',
  email: undefined,
  avatarUrl: undefined,
  cnpj: undefined,
  generalManager: undefined,
  mainPhone: undefined,
  profile: undefined,
  quantityColaborators: 0,
  technicalManager: undefined,
  city: '',
  complement: undefined,
  latitude: undefined,
  longitude: undefined,
  neighborhood: '',
  number: '',
  postalCode: '',
  state: '',
  street: '',
  linkWaze: ''
}

const errors: ErrorUnit = {
  email: '',
  name: '',
  avatarUrl: '',
  cnpj: '',
  generalManager: '',
  mainPhone: '',
  profile: '',
  quantityColaborators: '',
  technicalManager: '',
  city: '',
  complement: '',
  latitude: '',
  longitude: '',
  neighborhood: '',
  number: '',
  postalCode: '',
  state: '',
  street: '',
  linkWaze: ''
}

export const states = {
  values,
  errors
}

//  Genérico
type FormState = {
  values: Record<string, string | number | null | undefined>
  errors: Record<string, string>
}

export const schemaValidate = async (field: string, states: FormState, schema: yup.AnySchema) => {
  try {
    await schema.validateAt(field, states.values)
    states.errors[field] = ''
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      states.errors[field] = err.message
    }
  }
}
