<template>
  <div class="w-full h-full grid-layout my-0">
    <div class="w-full flex justify-between items-start gap-4">
      <div class="hidden lg:inline w-4/5">
        <SearchInput
          v-model="textInput"
          width="100%"
          label="Buscar unidade"
          :searchFunction="() => healthUnitStore.getAllHealthUnits(true, { name: textInput })"
        />
      </div>
      <div class="lg:hidden inline w-full" style="grid-area: search">
        <SearchInput v-model="textInput" width="100%" label="Buscar unidade" />
      </div>

      <div class="pl-4 hidden lg:flex">
        <FilledButton @click="handleOpenModal('create')" text="Cadastrar unidade" width="180px" />
      </div>
    </div>

    <div class="w-full mt-4">
      <div class="grid xl:flex grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:flex-wrap my-0 gap-4">
        <template v-for="(unit, index) in healthUnits?.data || []" :key="index">
          <CardHospital @mousedown="setHealthUnitToUpdate(unit)" :unit="unit" />
        </template>
      </div>
    </div>

    <div
      class="grid grid-cols-1 lg:hidden gap-4 my-0 mt-4"
      style="grid-area: buttons; height: 52px"
    >
      <FilledButton @click="handleOpenModal('create')" text="Cadastrar unidade" width="100%" />
    </div>
  </div>

  <HealthUnitModal
    v-if="toggleHealthUnitModal"
    :healthUnit="healthUnitToUpdate"
    :modalStateProp="toggleHealthUnitModal"
    @close="toggleHealthUnitModal = false"
    :type="operationModal"
  />
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import CardHospital from './components/CardHospital.vue'
import HealthUnitModal from './modals/health.unit.modal.vue'
import { HealthUnitResponse } from '@/Network/Types/Responses/HealthUnitResponses'
import { useHealthUnitStore } from '@/pinia-store/useHealthUnitStore'
import SearchInput from '@/components/designSystem/Inputs/SearchInput.vue'
const units = ref<HealthUnitResponse[]>()
const unitsOrigin = ref<HealthUnitResponse[]>()
const count = ref<number>(0)
const healthUnitStore = useHealthUnitStore()
const { healthUnits } = storeToRefs(useHealthUnitStore())

onMounted(async () => {
  await healthUnitStore.getAllHealthUnits()
  // unitsOrigin.value = healthUnits.value?.data || []
  // units.value = healthUnits.value?.data || []
  count.value = healthUnits.value?.count || 0
})

const textInput = ref('')
const operationModal = ref<'create' | 'update'>('create')
const toggleHealthUnitModal = ref(false)
const healthUnitToUpdate = ref<HealthUnitResponse>()

const setHealthUnitToUpdate = (unit: HealthUnitResponse) => {
  healthUnitToUpdate.value = unit
  handleOpenModal('update')
}

watch(textInput, () => {
  units.value = unitsOrigin.value?.filter((u) => u.name.startsWith(textInput.value))
})

const handleOpenModal = (operation: 'update' | 'create') => {
  operationModal.value = operation
  toggleHealthUnitModal.value = true
}
</script>

<style scoped>
.grid-layout {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 64px 1fr;
  gap: 0px 0px;
  grid-template-areas:
    'search'
    'table';
}

@media only screen and (max-width: 1024px) {
  .grid-layout {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: 58px 1fr 132px;
    gap: 0px 0px;
    grid-template-areas:
      'search'
      'table'
      'buttons';
  }
}
</style>
