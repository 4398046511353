<template>
  <div class="container-administrative-data">
    <div class="flex justify-center">
      <form id="formCompanyAddress" class="flex items-center flex-col rounded overflow-hidden">
        <h1 class="titleBold text-left">Endereço</h1>
        <p>
          O e-mail informado em "Dados Pessoais" será utilizado para realizar o acesso a nossa
          Plataforma e o e-mail em "Dados Administrativos" será utilizado para contato.
        </p>
        <div class="input w-full">
          <Input
            :v-model="cep"
            @input="onChangeCep"
            :isError="isErrorCep"
            errorMessage="CEP inválido!"
            width="100%"
            label="CEP"
            pattern="^[0-9]{5}-[0-9]{3}$"
            mask="#####-###"
            required
          />
        </div>

        <div class="input w-full">
          <Input
            :isError="isErrorStreet"
            @input="onChangeStreet"
            errorMessage="Rua inválida"
            width="100%"
            label="Rua / Avenida / Travessa"
            :value="street"
            required
          />
        </div>

        <div class="two-inputs input w-full">
          <div class="input-1">
            <Input
              :isError="isErrorNumber"
              @input="onChangeNumber"
              errorMessage="Numero Inválido."
              width="100%"
              label="Número"
              :value="number"
              required
            />
          </div>
          <div class="input-2">
            <Input
              :isError="isErrorDistrict"
              @input="onChangeDistrict"
              errorMessage="Bairro inválido!"
              width="100%"
              label="Bairro"
              :value="district"
              required
            />
          </div>
        </div>
        <div class="input w-full">
          <Input
            :v-model="complement"
            @input="onChangeComplement"
            :isError="isErrorComplement"
            errorMessage="Complemento inválido!"
            width="100%"
            label="Complemento"
            required
          />
        </div>
        <div class="two-inputs input w-full">
          <div class="input-1">
            <Input
              :isError="isErrorState"
              @input="onChangeState"
              errorMessage="Estado Inválido, use as siglas. Ex: SP"
              width="100%"
              label="UF"
              pattern="^(\s*(AC|AL|AP|AM|BA|CE|DF|ES|GO|MA|MT|MS|MG|PA|PB|PR|PE|PI|RJ|RN|RS|RO|RR|SC|SP|SE|TO)?)$"
              :value="state"
              mask="AA"
              required
            />
          </div>
          <div class="input-2">
            <Input
              :isError="isErrorCity"
              @input="onChangeCity"
              errorMessage="Cidade inválida!"
              width="100%"
              label="Cidade"
              :value="city"
              required
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import Input from '@/components/designSystem/Inputs/Input.vue'

export default defineComponent({
  name: 'ChooseCompany',
  components: {
    Input
  },
  setup() {
    const store = useStore()

    const street = computed(() => store.state.signUp.company.street)
    const cep = computed(() => store.state.signUp.company.cep)
    const number = computed(() => store.state.signUp.company.number)
    const complement = computed(() => store.state.signUp.company.complement)
    const district = computed(() => store.state.signUp.company.district)
    const state = computed(() => store.state.signUp.company.state)
    const city = computed(() => store.state.signUp.company.city)

    const isErrorStreet = ref(false)
    const isErrorCep = ref(false)
    const isErrorNumber = ref(false)
    const isErrorComplement = ref(false)
    const isErrorDistrict = ref(false)
    const isErrorState = ref(false)
    const isErrorCity = ref(false)
    const isValid = ref(false)

    // const formPesonalData = ref({
    //   // eslint-disable-next-line @typescript-eslint/no-empty-function
    //   reportValidity: () => {},
    // });

    function validateCEP() {
      const regex = new RegExp(/^[0-9]{5}-[0-9]{3}$/)

      if (cep.value === '' || !regex.test(cep.value)) {
        isValid.value = false
        isErrorCep.value = true
      } else {
        isValid.value = true
        isErrorCep.value = false
      }
    }

    function validateStreet() {
      if (street.value === '') {
        isValid.value = false
        isErrorStreet.value = true
      } else {
        isErrorStreet.value = false
        isValid.value = true
      }
    }

    function validateNumber() {
      if (number.value === '') {
        isValid.value = false
        isErrorNumber.value = true
      } else {
        isErrorNumber.value = false
        isValid.value = true
      }
    }

    function validateComplement() {
      if (complement.value === '') {
        isValid.value = false
        isErrorComplement.value = true
      } else {
        isErrorComplement.value = false
        isValid.value = true
      }
    }

    function validateDistrict() {
      if (district.value === '') {
        isValid.value = false
        isErrorDistrict.value = true
      } else {
        isErrorDistrict.value = false
        isValid.value = true
      }
    }

    function validateState() {
      const regex = new RegExp(
        /^(\s*(AC|AL|AP|AM|BA|CE|DF|ES|GO|MA|MT|MS|MG|PA|PB|PR|PE|PI|RJ|RN|RS|RO|RR|SC|SP|SE|TO)?)$/
      )
      if (state.value === '' || !regex.test(state.value)) {
        isValid.value = false
        isErrorState.value = true
      } else {
        isErrorState.value = false
        isValid.value = true
      }
    }

    function validateCity() {
      if (city.value === '') {
        isValid.value = false
        isErrorCity.value = true
      } else {
        isErrorCity.value = false
        isValid.value = true
      }
    }

    function onChangeCep(e: any) {
      store.dispatch('setCompanyCEP', e.target.value)
      validateCEP()
    }

    function onChangeStreet(e: any) {
      store.dispatch('setCompanyStreet', e.target.value)
      validateStreet()
    }

    function onChangeNumber(e: any) {
      store.dispatch('setCompanyNumber', e.target.value)
      validateNumber()
    }

    function onChangeDistrict(e: any) {
      store.dispatch('setCompanyDistrict', e.target.value)
      validateDistrict()
    }

    function onChangeComplement(e: any) {
      store.dispatch('setCompanyComplement', e.target.value)
      validateComplement()
    }

    function onChangeState(e: any) {
      store.dispatch('setCompanyState', e.target.value.toUpperCase())
      validateState()
    }

    function onChangeCity(e: any) {
      store.dispatch('setCompanyCity', e.target.value)
      validateCity()
    }

    return {
      isErrorStreet,
      isErrorCep,
      street,
      cep,
      onChangeStreet,
      onChangeCep,
      validateCEP,
      validateStreet,
      onChangeNumber,
      isErrorNumber,
      isErrorComplement,
      onChangeComplement,
      number,
      onChangeDistrict,
      isErrorDistrict,
      district,
      complement,
      onChangeCity,
      onChangeState,
      isErrorCity,
      isErrorState,
      city,
      state
    }
  }
})
</script>

<style scoped>
.input {
  margin-bottom: 25px;
}

.container-signup {
  background-color: #fafafa;
  height: 100vh;
}

.card {
  background-color: #fff;
  border-radius: 0.2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  min-height: 280px;
}

.card > h2 {
  font-weight: 700;
  font-size: 18px;
}
.card > h5 {
  margin: 1rem 0;
  font-size: 16px;
}

p {
  text-align: justify;
  margin-bottom: 15px;
}

.two-inputs {
  display: flex;
  justify-content: space-between;
}

.input-1 {
  width: 30%;
}

.input-2 {
  width: 65%;
}

@media (max-width: 556px) {
  .two-inputs {
    flex-direction: column;
  }

  .input-1 {
    margin-bottom: 25px;
    width: 100%;
  }

  .input-2 {
    width: 100%;
  }

  .buttons {
    display: none;
  }
  .container-cards {
    flex-direction: column;
    margin: 2rem;
  }

  .card {
    max-width: 100%;
    margin-bottom: 2rem;
    min-height: auto;
  }
}

.titleBold {
  font-family: 'Inter';
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 25px;
  margin-top: 35px;
}

.titleBold {
  font-family: 'Inter';
  font-size: 1.5rem;
  font-weight: 700;
}

h1 {
  text-align: left;
  width: 100%;
}
</style>
