<template>
  <v-form
    ref="addressFormRef"
    id="formDoctorSignUpPesonalData"
    class="w-full flex gap-7 items-center flex-col rounded overflow-hidden mt-5 mb-4"
  >
    <div class="w-full flex flex-col">
      <h1 class="text-left font-bold text-2xl">Endereço</h1>
      <p class="text-left text-sm text-gray-500">
        Preenche seu CEP para buscar o endereço automáticamente
      </p>
    </div>

    <div class="flex flex-col w-full gap-2">
      <v-text-field
        v-model="addressForm.cep"
        v-maska="'#####-###'"
        label="* Cep"
        class="w-full"
        color="primary20"
        variant="outlined"
        :error="!!cepResponse"
        :rules="[rules.required]"
        @update:modelValue="searchCep"
        validate-on="lazy input"
      >
        <template v-slot:loader>
          <v-progress-linear
            :active="isLoadingCep"
            color="primary"
            height="7"
            indeterminate
          ></v-progress-linear>
        </template>
      </v-text-field>

      <v-text-field
        v-model="addressForm.street"
        label="* Rua"
        class="w-full"
        color="primary20"
        variant="outlined"
      />
      <v-text-field
        v-model="addressForm.number"
        label="* Número"
        class="w-full"
        variant="outlined"
        color="primary20"
      />
      <v-text-field
        v-model="addressForm.complement"
        label="Complemento"
        class="w-full"
        variant="outlined"
        color="primary20"
      />
      <v-text-field
        v-model="addressForm.district"
        label="Bairro"
        class="w-full"
        variant="outlined"
        color="primary20"
      />

      <v-text-field
        v-model="addressForm.city"
        label="Cidade"
        class="w-full"
        color="primary20"
        variant="outlined"
      />
      <v-autocomplete
        v-model="addressForm.state"
        label="* Estado"
        color="primary"
        variant="outlined"
        :items="ufs"
        :rules="[rules.required, rules.uf, rules.ufLen]"
      >
        <template v-slot:item="{ props, item }">
          <v-list-item
            v-bind="props"
            :title="item?.raw?.value"
            :subtitle="item?.raw?.title"
          ></v-list-item>
        </template>
      </v-autocomplete>
    </div>
  </v-form>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useDoctorRegistryStore } from '@/pinia-store/useDoctorRegistryStore'
import ufs from '@/config/StatesFromBR'
import { rules } from '@/utils/rules'
import { SearchCep } from '@/Network/Services/CepService'

const doctorRegistryStore = useDoctorRegistryStore()
const { addressForm, addressFormRef } = storeToRefs(doctorRegistryStore)

const isLoadingCep = ref(false)
const cepResponse = ref<string | null>(null)
const searchCep = async () => {
  cepResponse.value = ''
  const cep = addressForm.value.cep.replace('-', '')
  if (cep.length !== 8) return
  try {
    isLoadingCep.value = true
    const data = await SearchCep(cep)
    addressForm.value.street = data.logradouro
    addressForm.value.district = data.bairro
    addressForm.value.city = data.localidade
    addressForm.value.state = data.uf
  } catch (error) {
    cepResponse.value = 'CEP não encontrado'
  } finally {
    isLoadingCep.value = false
  }
}
</script>
