<template>
  <div class="flex flex-col mt-6 justify-between">
    <v-autocomplete
      @update:model-value="setCompany"
      :model-value="company ? company : undefined"
      class="w-full"
      color="primary20"
      label="Empresa"
      theme="light"
      :items="listCompanies"
      variant="outlined"
      clearable
      density="compact"
    />
    <v-autocomplete
      @update:model-value="setHealthUnits"
      :model-value="healthUnits ? healthUnits : undefined"
      class="w-full"
      color="primary20"
      label="Unidades"
      :items="listHealthUnits"
      variant="outlined"
      density="compact"
      chips
      multiple
      clearable
      :disabled="!company"
    />
    <v-autocomplete
      @update:model-value="setSchedules"
      :model-value="schedules ? schedules : undefined"
      class="w-full"
      color="primary20"
      label="Escalas"
      theme="light"
      :items="listSchedules"
      variant="outlined"
      density="compact"
      chips
      multiple
      clearable
      :disabled="!healthUnits?.length"
    >
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :title="item?.raw?.title" :subtitle="item?.raw?.subtitle" />
      </template>
    </v-autocomplete>
    <v-autocomplete
      @update:model-value="setDoctors"
      :model-value="doctors ? doctors : undefined"
      class="w-full"
      color="primary20"
      label="Médicos"
      :items="listDoctorsCompany"
      variant="outlined"
      density="compact"
      chips
      multiple
      clearable
      :disabled="!company"
    >
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :title="item?.raw?.title" :subtitle="item?.raw?.subtitle" />
      </template>
    </v-autocomplete>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue'
import { storeToRefs } from 'pinia'
import { useHealthUnitStore } from '@/pinia-store/useHealthUnitStore'
import { useCompanyStore } from '@/pinia-store/useCompanyStore'
import { useScheduleStore } from '@/pinia-store/useScheduleStore'
import { useDoctorStore } from '@/pinia-store/useDoctorStore'

defineProps(['company', 'healthUnits', 'schedules', 'doctors'])
const emit = defineEmits([
  'update:company',
  'update:healthUnits',
  'update:schedules',
  'update:doctors'
])

const healthUnitStore = useHealthUnitStore()
const scheduleStore = useScheduleStore()
const doctorStore = useDoctorStore()

const { listCompanies } = storeToRefs(useCompanyStore())
const { listHealthUnits } = storeToRefs(useHealthUnitStore())
const { listSchedules } = storeToRefs(useScheduleStore())
const { listDoctorsCompany } = storeToRefs(useDoctorStore())

const setCompany = (v: string) => {
  emit('update:company', v)
  emit('update:healthUnits', undefined)
  emit('update:schedules', undefined)
  healthUnitStore.setHealthUnitsByCompany(+v)
  doctorStore.setDoctorsByCompany(+v)
}

const setHealthUnits = (healthUnits: string[]) => {
  emit('update:healthUnits', healthUnits)
  emit('update:schedules', undefined)
  scheduleStore.setSchedulesByHealthUnits(healthUnits.map((h) => Number(h)))
}

const setSchedules = (schedules: string[]) => {
  emit('update:schedules', schedules)
}

const setDoctors = (doctors: string[]) => {
  emit('update:doctors', doctors)
}
</script>
