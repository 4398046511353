<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect :width="width" :height="height" rx="6" :fill="bgColor" />
    <path
      d="M7.5 17L12.5 12L7.5 7"
      :stroke="arrowColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13 17L18 12L13 7"
      :stroke="arrowColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    arrowColor: {
      type: String,
      default: '#FFFFFF'
    },
    bgColor: {
      type: String,
      default: '#CB2957'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
