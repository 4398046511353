import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '@/../tailwind.config.js'

type tailwindColorVariable = {
  default: string
  variants: object
}

export const getColorVariable = (variable: string): tailwindColorVariable | undefined => {
  const _tailwindConfig = tailwindConfig as any
  const resolvedTailwindConfig = resolveConfig(_tailwindConfig)
  const colors = resolvedTailwindConfig.theme.colors

  const existVariable = Object.hasOwn(colors, variable);
  if (existVariable) {
    const tailwindVar = colors[variable];

    if (typeof tailwindVar === 'string') {
      const resultedVariable: tailwindColorVariable = {
        default: tailwindVar,
        variants: []
      }
      return resultedVariable;
    } else if (typeof tailwindVar === 'object') {
      const resultedVariable: tailwindColorVariable = {
        default: Object.values(tailwindVar)[0] as string,
        variants: tailwindVar
      }
      return resultedVariable;
    }

  } else {
    throw new Error("Variável não encontrada. Verifique se existe esta variável no arquivo tailwind.config.js");
  }

}
