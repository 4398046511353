import { FilterStates } from '@/components/Filters/useFilterStation'

export type Observation = { date?: string; description: string; userName?: string }

export type StationConfig = {
  repetionDays: Record<string, string>
  finishAtType: string
  occurrence?: number
  date: Date
  startHour: Date
  endHour: Date
  repeatIn: number[] | undefined
  finishAt: Date | undefined
  timezone: string
}
export type CreateStationRequest = {
  scheduleId: number
  doctorId: number | undefined
  sponsorDoctorId: number | undefined
  stationType: string
  ourTeam: boolean
  value: number | undefined
  config: StationConfig
  inCash: boolean
  advanceRequested: boolean
  advancePaid: boolean
  observations: Observation[]
}

export enum DashboardType {
  MONTHLY = 'MONTHLY',
  WEEK = 'WEEK'
}
export type ListFiltersRequest = {
  type: DashboardType
  startDate: string
  endDate: string
  startHour?: string[]
  endHour?: string[]
  schedules?: string[]
  healthUnits?: string[]
  doctorPending?: string
  limit?: string
  offset?: string

  doctorIds?: string[] | string
  openedStation?: string
  stationType?: string[]
  valueMin?: string
  valueMax?: string
  paymentValueMin?: string
  paymentValueMax?: string
  withCheckin?: string
  withCheckout?: string
  inCash?: string
  advanceRequested?: string
  advancePaid?: string
  ourTeam?: string
  status?: string
}

export type ConsultingRequest = {
  columnFilters?: FilterStates
  perPage: number
  page: number
  sort: {
    field: string
    type: 'asc' | 'desc'
  }[]
}

export type StationTypes = 'SEMANAL' | 'COBERTURA'

export type UpdateStationRequest = {
  doctorId?: number
  sponsorDoctorId?: number
  stationType: StationTypes
  ourTeam: boolean
  inCash: boolean
  value?: number
  justification?: string
  config: {
    date: Date
    startHour: Date
    endHour: Date
    timezone: string
  }
  histories: HistoryDateRequest[]
  advanceRequested: boolean
  advancePaid: boolean
  observations: Observation[]
  modifiedValue: number
}

export type PatchStationsRequest = {
  schedule?: number
  healthUnit?: number
  company?: number
  doctorId?: number
  sponsorDoctorId?: number
  stationType?: StationTypes
  ourTeam?: boolean
  value?: number
  justification?: string
  observations?: string
  inCash?: boolean
  config?: {
    date: Date
    startHour: Date
    endHour: Date
  }
  histories?: HistoryDateRequest[]
}

export enum CheckOrigin {
  DOCTOR = 'DOCTOR',
  WEB = 'WEB'
}

export interface HistoryDate {
  id?: number
  checkin: string
  labelCheckin?: string
  checkinLatitude?: string | number
  checkinLongitude?: string | number
  checkout?: string
  checkoutLatitude?: string | number
  checkoutLongitude?: string | number
  labelCheckout?: string
  origin: CheckOrigin
  isMain: boolean
}

export interface HistoryDateRequest {
  id?: number
  checkin: Date
  checkout?: Date
  origin: CheckOrigin
  isMain: boolean
}

export type RelatoryMonthlyExpensesFilter = {
  healthUnits?: number[]
  month?: number
}

export type ApproveStationRequest = {
  stationId: number
  value: number
}
