<template>
  <modal-vue @close="close" :modalActive="modalStateProp">
    <template #header>
      <p class="font-bold text-start">{{ titleText() }}</p>
    </template>
    <v-form ref="formRef" class="modal-content overflow-x-hidden overflow-y-scroll">
      <p class="text-left font-bold">Dados do hospital</p>

      <div class="grid grid-cols-1 md:grid-cols-2 my-4 gap-4">
        <GenericInput
          :rules="[rules.form('name')]"
          width="100%"
          label="Nome"
          v-model="form.values.name"
          @validate="validate('name')"
          required
        />
        <!-- <Input width="100%" v-model="form.values.social" :isError="!!form.social.error" :errorMessage="form.social.error"
          label="Razão social" @keypress="() => validate('social')" @blur="() => validate('social')" required /> -->
        <v-autocomplete
          v-model="companySelected"
          class="w-full"
          color="primary20"
          label="Empresa"
          :items="listCompanies"
          variant="outlined"
        />
        <GenericInput
          @maska="form.values.cnpj = $event.target.dataset.maskRawValue"
          v-maska="'##.###.### /#### -##'"
          :rules="[rules.form('cnpj')]"
          width="100%"
          label="CNPJ"
          v-model="form.values.cnpj"
          @validate="validate('cnpj')"
        />
        <GenericInput
          label="Email"
          :rules="[rules.form('email')]"
          width="100%"
          v-model="form.values.email"
          @validate="validate('email')"
        />

        <GenericInput
          @maska="form.values.mainPhone = $event.target.dataset.maskRawValue"
          v-maska="['(##) #####-####', '(##) ####-####']"
          label="Telefone "
          :rules="[rules.form('mainPhone')]"
          width="100%"
          v-model="form.values.mainPhone"
          @validate="validate('mainPhone')"
        />

        <GenericInput
          label="Coordenação técnica"
          :rules="[rules.form('technicalManager')]"
          width="100%"
          v-model="form.values.technicalManager"
          @validate="validate('technicalManager')"
        />

        <GenericInput
          label="Gerência Geral"
          :rules="[rules.form('generalManager')]"
          width="100%"
          v-model="form.values.generalManager"
          @validate="validate('generalManager')"
        />

        <GenericInput
          type="number"
          label="Total de funcionários"
          :rules="[rules.form('quantityColaborators')]"
          width="100%"
          v-model="form.values.quantityColaborators"
          @validate="validate('quantityColaborators')"
        />

        <GenericInput
          label="Perfil"
          :rules="[rules.form('profile')]"
          width="100%"
          v-model="form.values.profile"
          @validate="validate('profile')"
        />
        <GenericInput
          label="Link do Waze"
          :rules="[rules.form('linkWaze')]"
          width="100%"
          v-model="form.values.linkWaze"
          @validate="validate('linkWaze')"
        />
      </div>

      <!-- <div class="flex gap-2 items-center cursor-pointer my-2 w-max select-none">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="16" cy="16" r="15" fill="#2F7F91" stroke="#2F7F91" stroke-width="2" />
          <rect width="19.2" height="19.2" transform="translate(6.39996 6.3999)" fill="#2F7F91" />
          <path d="M9.94696 16.1597H21.7784" stroke="white" stroke-width="2" stroke-linecap="round" />
          <path d="M15.8627 21.4399L15.8627 10.3999" stroke="white" stroke-width="2" stroke-linecap="round" />
        </svg>
        <p class="font-bold">Adicionar Avatar</p>
      </div> -->

      <p class="text-left font-bold">* Endereço</p>

      <div>
        <div class="grid grid-cols-1 md:grid-cols-3 my-4 gap-4">
          <div class="col-span-1">
            <GenericInput
              required
              label="CEP"
              :rules="[rules.form('postalCode')]"
              width="100%"
              v-model="form.values.postalCode"
              @validate="validate('postalCode')"
              @maska="form.values.postalCode = $event.target.dataset.maskRawValue"
              v-maska="'#####-###'"
              @input="changeInputAddress"
            />
          </div>

          <v-autocomplete
            v-model="form.values.state"
            class="w-full"
            :rules="[rules.required]"
            required
            color="primary20"
            label="* Estado"
            theme="light"
            :items="StatesFromBR"
            variant="outlined"
          >
          </v-autocomplete>

          <GenericInput
            required
            label="Cidade"
            :rules="[rules.form('city')]"
            width="100%"
            v-model="form.values.city"
            @validate="validate('city')"
            @input="changeInputAddress"
          />

          <GenericInput
            required
            label="Rua"
            :rules="[rules.form('street')]"
            width="100%"
            v-model="form.values.street"
            @validate="validate('street')"
            @input="changeInputAddress"
          />

          <GenericInput
            required
            label="Número"
            :rules="[rules.form('number')]"
            width="100%"
            v-model="form.values.number"
            @validate="validate('number')"
            @input="changeInputAddress"
          />

          <GenericInput
            required
            label="Bairro"
            :rules="[rules.form('neighborhood')]"
            width="100%"
            v-model="form.values.neighborhood"
            @validate="validate('neighborhood')"
            @input="changeInputAddress"
          />

          <GenericInput
            label="Complemento"
            :rules="[rules.form('complement')]"
            width="100%"
            v-model="form.values.complement"
            @validate="validate('complement')"
          />

          <GenericInput
            :rules="[rules.form('latitude')]"
            @validate="validate('latitude')"
            label="Latitude"
            width="100%"
            disabled
            v-model="latitude"
          />

          <GenericInput
            :rules="[rules.form('longitude')]"
            @validate="validate('longitude')"
            label="Longitude"
            width="100%"
            disabled
            v-model="longitude"
          />
        </div>

        <filled-button
          v-if="type === 'update'"
          @click="submit"
          text="Desativar"
          width="180px"
          class="bg-negative"
        />
      </div>
    </v-form>
    <template #footer>
      <div class="flex gap-5 justify-end mt-4 px-3">
        <outlined-button @click="close" text="Cancelar" width="150px" />
        <filled-button
          @click="showGoogleMapsModal"
          width="230px"
          :loading="isLoading"
          :disabled="isLoading"
          :class="locationConfirmed ? 'bg-primary20' : 'bg-alert'"
        >
          {{ buttonLocationText }}
          <v-icon
            :icon="locationConfirmed ? mdiCheckDecagram : mdiAlert"
            :size="25"
            class="check-decagram"
            :end="true"
          />
        </filled-button>
        <v-tooltip
          location="top"
          :disabled="locationConfirmed"
          text="Por favor, verifique a localização do endereço!"
        >
          <template v-slot:activator="{ props }">
            <div v-bind="props">
              <filled-button
                @click="submit"
                :text="titleText()"
                :loading="isLoading"
                :disabled="!locationConfirmed || isLoading"
                width="180px"
                height="57px"
              />
            </div>
          </template>
        </v-tooltip>
      </div>
    </template>
  </modal-vue>

  <modal-vue :modalActive="isModalVisible" @close="closeModal">
    <div class="mt-4">
      <GoogleMaps
        :latitude="Number(latitude)"
        :longitude="Number(longitude)"
        :address="formattedAddress"
        @marker-dragged="updateCoordinates"
      />
      <div class="flex gap-5 justify-start mt-4 px-3">
        <outlined-button @click="closeModal" text="Sair" width="150px" />
        <filled-button @click="confirmedLocation" text="Confirmar Localização" width="250px" />
      </div>
    </div>
  </modal-vue>
</template>

<script setup lang="ts">
import { defineProps, toRefs, defineEmits, reactive, onMounted, ref, computed, nextTick } from 'vue'
import { storeToRefs } from 'pinia'
import { states, schemaValidate, HealthUnitSchema, HealthUnit } from '../health.unit.schemas'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import OutlinedButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import { CreateHealthUnit, PhoneType } from '@/Network/Types/Requests/HealthUnitRequests'
import { useHealthUnitStore } from '@/pinia-store/useHealthUnitStore'
import { useCompanyStore } from '@/pinia-store/useCompanyStore'
import GenericInput from '@/components/designSystem/Inputs/GenericInput.vue'
import { HealthUnitResponse } from '@/Network/Types/Responses/HealthUnitResponses'
import { useToast } from 'vue-toastification'
import StatesFromBR from '@/config/StatesFromBR'
import { schemaValidateAllFields } from '@/utils/yup'
import { VForm } from 'vuetify/lib/components/index.mjs'
import axios from 'axios'
import GoogleMaps from '@/components/GoogleMaps.vue'
import { mdiCheckDecagram, mdiAlert } from '@mdi/js'

const props = defineProps<{
  modalStateProp: boolean
  type: 'update' | 'create'
  healthUnit?: HealthUnitResponse
}>()

const healthUnitStore = useHealthUnitStore()
const { listCompanies } = storeToRefs(useCompanyStore())
const companyStore = useCompanyStore()
const { modalStateProp, type } = toRefs(props)
const form = reactive(states)
const companySelected = ref<string>()
const toast = useToast()

const latitude = ref('0')
const formattedAddress = ref('')
const longitude = ref('0')
const foundLocation = ref(false)
const locationConfirmed = ref(false)
const isModalVisible = ref(false)
const isLoading = ref(false)

const formRef = ref<typeof VForm | null>(null)

onMounted(async () => {
  await companyStore.getMyCompanies()
  if (props.modalStateProp && props.type === 'update' && props.healthUnit) {
    companySelected.value = String(props.healthUnit.company.id)
    form.values.name = props.healthUnit.name ?? form.values.name
    form.values.cnpj = props.healthUnit.cnpj ?? form.values.cnpj
    form.values.email = props.healthUnit.email ?? form.values.email
    form.values.mainPhone = props.healthUnit.mainPhone?.phoneNumber ?? form.values.mainPhone
    form.values.technicalManager = props.healthUnit.technicalManager ?? form.values.technicalManager
    form.values.generalManager = props.healthUnit.generalManager ?? form.values.generalManager
    form.values.quantityColaborators =
      props.healthUnit.quantityColaborators ?? form.values.quantityColaborators
    form.values.profile = props.healthUnit.profile ?? form.values.profile
    form.values.avatarUrl = props.healthUnit.avatarUrl ?? form.values.avatarUrl

    form.values.postalCode = props.healthUnit.mainAddress?.postalCode ?? form.values.postalCode
    form.values.city = props.healthUnit.mainAddress?.city ?? form.values.city
    form.values.complement = props.healthUnit.mainAddress?.complement ?? form.values.complement
    form.values.neighborhood =
      props.healthUnit.mainAddress?.neighborhood ?? form.values.neighborhood
    console.log(props.healthUnit.mainAddress?.number ?? form.values.number)
    console.log(props.healthUnit.mainAddress?.number)
    console.log(form.values.number)
    form.values.number = props.healthUnit.mainAddress?.number ?? form.values.number
    form.values.state = props.healthUnit.mainAddress?.state ?? form.values.state
    form.values.street = props.healthUnit.mainAddress?.street ?? form.values.street
    form.values.linkWaze = props.healthUnit.linkWaze ?? form.values.linkWaze
    latitude.value = props.healthUnit.mainAddress?.latitude ?? latitude.value
    longitude.value = props.healthUnit.mainAddress?.longitude ?? longitude.value
  }
})
const emit = defineEmits(['close'])

const validate = async (field: keyof HealthUnit) => {
  await schemaValidate(field, form, HealthUnitSchema)
}

const rules = {
  form: (f: string) => !form.errors[f] || form.errors[f],
  required: (v: any) => !!v || 'Campo obrigatório'
}

async function showGoogleMapsModal() {
  try {
    isLoading.value = true
    const isValid = await schemaValidateAllFields(form, HealthUnitSchema, formRef)
    if (!isValid || !companySelected.value) {
      toast.error('Existe campos inválidos')
      throw new Error('Existe campos inválidos')
    }

    await getGeolocationByAddress()

    if (foundLocation.value) {
      isModalVisible.value = true
    }
  } catch (e) {
    console.error(e)
  } finally {
    isLoading.value = false
  }
}

function closeModal() {
  isModalVisible.value = false
}

function confirmedLocation() {
  isModalVisible.value = false
  locationConfirmed.value = true
}

function changeInputAddress() {
  locationConfirmed.value = false
}

// const getGeolocationFromNeighborhood = () => {
//   changeInputAddress()
// }

async function getGeolocationByAddress() {
  const address = `Rua ${form.values.street}, ${form.values.number}, ${form.values.neighborhood}, ${form.values.city}, ${form.values.state}, ${form.values.postalCode}`
  await getGeolocation(address)
}

async function getGeolocation(address) {
  // const apiKey = 'AIzaSyBqWbLuMudFtQkk4uyiAwB4RouMdB0S7us'
  const apiKey = process.env.VUE_APP_GMAPSAPIKEY
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    address
  )}&key=${apiKey}`

  try {
    //
    const response = await axios.get(url)
    if (response.data.status === 'OK') {
      const location: { lat: string; lng: string } = response.data.results[0].geometry.location
      const formatted_address = response.data.results[0].formatted_address
      formattedAddress.value = formatted_address
      latitude.value = location.lat
      longitude.value = location.lng

      foundLocation.value = true
    } else {
      toast.error('Não foi possível obter a localização')
    }
  } catch (error) {
    toast.error('Erro ao obter a localização:')
  }
}

const updateCoordinates = (newCoordinates) => {
  latitude.value = newCoordinates.lat
  longitude.value = newCoordinates.lng
}

const submit = async () => {
  try {
    isLoading.value = true
    const isValid = await schemaValidateAllFields(form, HealthUnitSchema, formRef)
    if (!isValid || !companySelected.value) {
      toast.error('Existe campos inválidos')
      return
    }

    if (!locationConfirmed.value) {
      toast.error('Por favor, verifique a localização antes de prosseguir.')
      return
    }

    const { city, street, neighborhood, number, postalCode, complement, state } = form.values

    // const address = `Rua ${form.values.street}, ${form.values.number}, ${form.values.neighborhood}, ${form.values.city}, ${form.values.state}, ${form.values.postalCode}`
    // await getGeolocation(address)

    const payload: CreateHealthUnit = {
      // avatarUrl: '',
      cnpj: form.values.cnpj || undefined,
      email: form.values.email || undefined,
      generalManager: form.values.generalManager || undefined,
      mainAddress: {
        city,
        street,
        complement,
        neighborhood,
        number,
        postalCode,
        state,
        latitude: Number(latitude.value),
        longitude: Number(longitude.value)
      },
      mainPhone: form.values.mainPhone
        ? { phoneNumber: form.values.mainPhone, phoneType: PhoneType.COMMERCIAL }
        : undefined,
      name: form.values.name,
      profile: form.values.profile,
      quantityColaborators: Number(form.values.quantityColaborators),
      technicalManager: form.values.technicalManager,
      linkWaze: form.values.linkWaze
    }

    if (props.type === 'create') await handleSubmitCreate(companySelected.value, payload)
    else await handleSubmitUpdate(payload)
    await healthUnitStore.getAllHealthUnits(true)
    emit('close')
  } catch (error) {
    toast.error('Erro ao processar solicitação')
  } finally {
    isLoading.value = false
  }
}

const handleSubmitUpdate = async (payload: CreateHealthUnit) => {
  if (!props.healthUnit) return
  const healthUnitId = props.healthUnit.id
  await healthUnitStore.update({ healthUnitId, payload })
  toast.success('Hospital alterado com sucesso')
}

const handleSubmitCreate = async (companyId: string, payload: CreateHealthUnit) => {
  await healthUnitStore.create({ companyId, payload })
  toast.success('Hospital adicionado com sucesso')
}

const clearObject = (obj: Record<string, any>, to: '' | undefined): void => {
  Object.keys(obj).forEach((value) => {
    obj[value] = to
  })
}

const close = () => {
  clearObject(form.values, undefined)
  clearObject(form.errors, '')
  companySelected.value = undefined
  emit('close')
}

const titleText = () => {
  return props.type === 'create' ? 'Cadastrar Unidade' : 'Editar Unidade'
}

const buttonLocationText = computed(() => {
  return locationConfirmed.value ? 'Localização Verificada' : 'Verificar Localização'
})
</script>

<style scoped>
.modal-content {
  max-height: calc(100vh - 200px);
}
</style>
