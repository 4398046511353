<template>
  <Transition>
    <div
      v-if="modalActive"
      class="modal z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center"
    >
      <div class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>

      <div
        class="container-modal z-50 w-full max-h-[692px] md:max-h-max mx-auto overflow-y-auto bg-white rounded-lg shadow-lg modal-container relative"
        :class="`${isFilterModal ? 'max-w-[500px]' : 'max-w-[850px]'}`"
      >
        <close-esc v-if="showCloseButton" @click="close" />
        <close-btn v-if="showCloseButton" @click="close" />
        <div class="flex items-center justify-center flex-col px-6 pt-6 pb-4">
          <header class="w-full">
            <slot name="header"></slot>
          </header>
          <main class="w-full flex flex-col mt-4 px-[8px]">
            <slot></slot>
          </main>
        </div>
        <footer
          :class="hasFooterBorder ? 'border-t-2 border-gray-100' : ''"
          class="w-full px-8 pb-6"
        >
          <slot name="footer"></slot>
        </footer>
      </div>
    </div>
  </Transition>
</template>

<script>
import CloseEsc from './commons/closeEsc.vue'
import CloseBtn from './commons/closeBtn.vue'

export default {
  name: 'ConfirmDeleteScaleModal',
  components: {
    CloseEsc,
    CloseBtn
  },
  props: {
    modalActive: {
      type: Boolean,
      default: false
    },
    hasFooterBorder: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    isFilterModal: {
      type: Boolean,
      default: false
    }
  },
  setup(_, { emit }) {
    const close = () => {
      emit('close')
    }

    return {
      close
    }
  }
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
