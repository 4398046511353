<template>
  <div class="container-login w-full h-screen">
    <div class="logo flex justify-center">
      <Logo width="160px" logo="full" />
    </div>
    <div class="flex justify-center">
      <v-form
        @submit.prevent="submitLogin"
        :fast-fail="false"
        class="card flex items-center flex-col rounded overflow-hidden shadow-lg"
        ref="elLoginForm"
      >
        <h1 class="title">Login</h1>
        <div class="input w-80">
          <GenericInput
            type="email"
            label="Email"
            v-model="loginForm.values.email"
            @validate="validate('email')"
            :rules="[rules.email]"
          />
        </div>
        <div class="input w-80">
          <PasswordInput
            v-model="loginForm.values.password"
            @validate="validate('password')"
            :rules="[rules.password]"
          />
        </div>
        <div class="recover-password">
          <router-link to="/forgot-password">
            <GhostButton text="Esqueci minha senha" />
          </router-link>
        </div>
        <div class="login">
          <FilledButton text="Entrar" type="submit" />
        </div>
        <div class="register">
          <router-link to="/signup">
            <OutlineButton text="Registrar" />
          </router-link>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, nextTick } from 'vue'
import Logo from '@/components/designSystem/Logo/index.vue'
import PasswordInput from '@/components/designSystem/Inputs/PasswordInput.vue'
import GenericInput from '@/components/designSystem/Inputs/GenericInput.vue'
import GhostButton from '@/components/designSystem/Buttons/GhostButton/index.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import { states, LoginSchema, LoginType } from '@/modules/public/Signin/schemas'
import { schemaValidateAllFields, schemaValidate, FormState } from '@/utils/yup'
import { VForm } from 'vuetify/components'
import { useAuthStore } from '@/pinia-store/useAuthStore'
import { useRouter } from 'vue-router'
import PATHS from '@/router/paths'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'AppSignin',
  components: {
    Logo,
    PasswordInput,
    GhostButton,
    FilledButton,
    OutlineButton,
    GenericInput
  },
  setup() {
    const authStore = useAuthStore()
    const loginForm = reactive(states)
    const elLoginForm = ref<typeof VForm | null>(null)
    const router = useRouter()
    const toast = useToast()

    const rules = reactive({
      email: () => {
        return !loginForm.errors.email || loginForm.errors.email
      },
      password: () => {
        return !loginForm.errors.password || loginForm.errors.password
      }
    })

    const validate = async (field: string) => {
      await schemaValidate(field, loginForm, LoginSchema)
    }

    const submitLogin = async () => {
      const isValidForm = await schemaValidateAllFields(loginForm, LoginSchema)
      if (isValidForm) {
        const userLogin = {
          email: loginForm.values.email,
          password: loginForm.values.password
        }
        try {
          await authStore.login({
            email: userLogin?.email || '',
            password: userLogin?.password || ''
          })
          router.push(PATHS.DASHBOARD)
        } catch (e) {
          toast.error('Por favor verifique seus dados e tente novamente!')
          console.error(e)
        }
      } else {
        nextTick(async () => {
          if (elLoginForm.value) {
            await elLoginForm.value.validate()
          }
        })
      }
    }

    return {
      submitLogin,
      elLoginForm,
      loginForm,
      validate,
      rules
    }
  }
})
</script>

<style scoped>
.container-login {
  background-color: #2f7f91;
}

.card {
  width: 508px;
  background-color: #fff;
}

@media (max-width: 556px) {
  .card {
    width: 90%;
    background-color: #fff;
  }
}

.title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 25px;
  margin-top: 35px;
}

.logo {
  padding-top: 40px;
  padding-bottom: 40px;
}

.recover-password {
  margin-bottom: 25px;
}

.login {
  margin-bottom: 15px;
}

.register {
  margin-bottom: 40px;
}
</style>
