import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SignupForm = _resolveComponent("SignupForm")!
  const _component_modal_vue = _resolveComponent("modal-vue")!

  return (_openBlock(), _createBlock(_component_modal_vue, {
    onClose: $setup.handleToggleModal,
    modalActive: $setup.modals[$setup.modalName],
    showCloseButton: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_SignupForm, {
        "class-main-container": "signupContainerForm",
        activeCustomSubmitEvent: true,
        onOnSuccessSubmit: $setup.onSuccessSubmit,
        disableDefaultCloseCallback: false,
        onOnCloseSignupForm: $setup.onCloseSignupForm
      }, null, 8, ["onOnSuccessSubmit", "onOnCloseSignupForm"])
    ]),
    _: 1
  }, 8, ["onClose", "modalActive"]))
}