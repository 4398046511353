import api from './api'

export default class PatchRequest {
  url: string

  body: any
  headers: Record<string, string> | undefined

  constructor(url: string, body: any, headers?: Record<string, string>) {
    this.url = url
    this.body = body
    this.headers = headers
  }

  async run<R>() {
    return api.patch<R>(this.url, this.body, { headers: this.headers })
  }
}
