<template>
  <modal-vue @close="handleToggleModal" :modalActive="modals[modalName]" :hasFooterBorder="true">
    <template #header>
      <div class="text-left px-[16px] flex">
        <strong>Exportar relatório de apontamentos do médico</strong>
      </div>
    </template>
    <VDatePicker v-model.range="range" mode="date" expanded />
    <div class="rounded border border-primary30 mt-2 text-primary">
      de {{ formatDateToString(range.start) }} até {{ formatDateToString(range.end) }}
    </div>
    <template #footer>
      <div class="flex gap-5 justify-end mt-4 px-3">
        <filled-button @click="handleExport" text="Exportar" height="52px" width="180px" />
      </div>
    </template>
  </modal-vue>
</template>

<script lang="ts">
import { startOfMonth, endOfMonth } from 'date-fns'
import { storeToRefs } from 'pinia'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import { ref } from 'vue'
import { formatDateToString } from '@/utils/date'
import Network from '@/Network'
import { useToast } from 'vue-toastification'
import axios from 'axios'
export default {
  components: {
    ModalVue,
    FilledButton
  },
  setup() {
    /* ******** Modal Props and Actions Default *********** */
    const modalName = EModals.EXPORT_DASHBOARD_EXCEL
    const { modals } = storeToRefs(useModalStore())
    const modalStore = useModalStore()
    const handleToggleModal = () => {
      modalStore.toggleModal(modalName)
    }
    /* **************************************************** */
    const today = new Date()
    const firstDateOfMonth = startOfMonth(today)
    const lastDateOfMonth = endOfMonth(today)

    const toast = useToast()

    const range = ref({
      start: firstDateOfMonth,
      end: lastDateOfMonth
    })

    const handleExport = async () => {
      try {
        const filename = `relatorio-plantoes-${formatDateToString(
          range.value.start
        )}-${formatDateToString(range.value.end)}.xlsx`
        const link = await Network.station.exportRelatoryXlsx({
          startDate: range.value.start,
          endDate: range.value.end,
          filename
        })
        document.body.appendChild(link)
        link.click()
        toast.success('Relatório exportado com sucesso')
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          toast.warning('Nenhum apontamento encontrado dentro do período especificado')
          return
        }
        toast.error('Erro ao exportar relatório, contate um administrador!')
      } finally {
        handleToggleModal()
      }
    }
    return {
      modalName,
      modals,
      handleToggleModal,
      handleExport,
      range,
      formatDateToString
    }
  }
}
</script>
