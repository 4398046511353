<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="5" width="18" height="16" rx="2" stroke="#68688D" stroke-width="2" />
    <path d="M7 2.5V6.5" stroke="#68688D" stroke-width="2" stroke-linecap="round" />
    <path d="M17 2.5V6.5" stroke="#68688D" stroke-width="2" stroke-linecap="round" />
    <path d="M3 10L21 10" stroke="#68688D" stroke-width="2" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#68688D'
    },
    width: {
      type: Number,
      default: 26
    },
    height: {
      type: Number,
      default: 26
    }
  }
}
</script>
