<template>
  <div class="border-b-2">
    <div class="tab__header">
      <a
        href="#"
        class="tab__link py-4 flex flex-col md:flex-row justify-between"
        @click.prevent="showContractContent = !showContractContent"
      >
        <div class="flex flex-row items-center md:pb-0 pb-8">
          <img width="18" src="@/assets/icons/chevron-down.svg" v-show="!showContractContent" />
          <img width="18" src="@/assets/icons/chevron-up.svg" v-show="showContractContent" />
          <strong class="pl-4">{{ contract.name }}</strong>
        </div>
        <div class="flex md:flex-row flex-col md:gap-8 gap-2">
          <button
            @click.prevent.stop="setShowSaveDuty(true)"
            :class="`flex items-center rounded px-2 py-1 add-contract-btn ${
              showSaveDuty && !isEditingDuty ? 'add-contract-btn-border' : ''
            }`"
          >
            <img src="@/assets/icons/mini-add-circle.png" width="20" />
            <span class="ml-2">Novo plantão</span>
          </button>
          <button
            @click.prevent.stop="setShowEditContractModal(true)"
            class="flex items-center rounded px-2 py-1 z-10 edit-contract-btn"
          >
            <EditPencilIcon />
            <span class="ml-2">Editar contrato</span>
          </button>
          <button
            v-if="isContractActive"
            @click.prevent.stop="toggleActiveContract"
            class="flex items-center rounded px-2 py-1 z-10 deactive-contract-btn"
          >
            <img src="@/assets/icons/deactivate.png" width="20" />
            <span class="ml-2">Desativar contrato</span>
          </button>
          <button
            v-else
            @click.prevent.stop="toggleActiveContract"
            class="flex items-center rounded px-2 py-1 z-10 active-contract-btn"
          >
            <img src="@/assets/icons/right-check.svg" width="20" />
            <span class="ml-2">Ativar contrato</span>
          </button>
        </div>
      </a>
    </div>
    <div class="tab__content" v-show="showContractContent">
      <SaveDuty
        v-if="showSaveDuty"
        @setShowContractContent="setShowContractContent"
        @setShowSaveDuty="setShowSaveDuty"
        :contractIndex="contractIndex"
      />
      <DutyTable
        v-else
        :duties="contract.duties"
        @setShowSaveDuty="setShowSaveDuty"
        :contractIndex="contractIndex"
      />
    </div>

    <EditContractModal
      :contractIndex="contractIndex"
      :showEditContractModal="showEditContractModal"
      :setShowEditContractModal="setShowEditContractModal"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import EditPencilIcon from '@/assets/icons/edit-pencil.vue'
import SaveDuty from '../SaveDuty/SaveDuty.vue'
import DutyTable from '../DutyTable/DutyTable.vue'
import EditContractModal from '../EditContractModal/EditContractModal.vue'

export default {
  name: 'Contract',
  props: ['contract', 'contractIndex'],
  components: {
    EditPencilIcon,
    SaveDuty,
    DutyTable,
    EditContractModal
  },
  setup(props) {
    const store = useStore()
    const showContractContent = ref(false)
    const showSaveDuty = ref(false)
    const showEditContractModal = ref(false)

    const isContractActive = computed(() =>
      store.state.contract.contracts[props.contractIndex].duties.some((duty) => duty.isActive)
    )
    const isEditingDuty = computed(() => store.state.contract.editedDutyIndex !== -1)

    const setShowContractContent = (value) => {
      showContractContent.value = value
    }

    const setShowEditContractModal = (value) => {
      store.dispatch('setEditContractName', props.contract.name)
      showEditContractModal.value = value
    }

    const setShowSaveDuty = (value) => {
      showSaveDuty.value = value
      if (!showContractContent.value) {
        showContractContent.value = true
      }
    }

    const toggleActiveContract = () => {
      store.dispatch('toggleActiveContract', props.contractIndex)
    }

    return {
      setShowContractContent,
      showContractContent,
      showSaveDuty,
      setShowSaveDuty,
      showEditContractModal,
      setShowEditContractModal,
      toggleActiveContract,
      isContractActive,
      isEditingDuty
    }
  }
}
</script>

<style scoped>
.add-contract-btn-border {
  border: 1px solid #2f7f91;
}
.add-contract-btn {
  background-color: #d8eef3;
}
.edit-contract-btn {
  background-color: #ebf1ff;
}
.active-contract-btn {
  background-color: #eefcf2;
}
.deactive-contract-btn {
  background-color: #fcedee;
}
</style>
