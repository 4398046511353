<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.56967 4.5L11.5 11.5" :stroke="color" stroke-linecap="round" />
    <path d="M11.4304 4.5L4.50001 11.5" :stroke="color" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#235E6C'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
