<template>
  <div class="flex">
    <div
      :class="[
        isMobile && isOpen ? 'fixed' : '',
        isOpen ? 'w-64' : '',
        isMobile && !isOpen ? 'hidden' : ''
      ]"
      class="inset-y-0 left-0 z-30 p-3 overflow-y-auto transition duration-300 transform bg-white shadow min-h-[100vh]"
    >
      <div class="flex mt-8">
        <div class="w-full flex" :class="isOpen ? 'justify-between' : 'justify-center'">
          <Logo v-if="isOpen" width="120px" logo="variant" />

          <button @click="showSideBar" class="text-gray-500 focus:outline-none lg:block">
            <left-arrow v-if="isOpen" />
            <rigth-arrow v-else />
          </button>
        </div>
      </div>

      <nav class="mt-10">
        <div
          v-bind:key="index"
          v-for="(menu, index) in menus.items"
          @click="clickItem($event, menu)"
          class="flex flex-col"
          :to="menu.path"
        >
          <div
            v-if="menu.active"
            :class="[$route.name === menu.path ? activeClass : inactiveClass]"
            class="flex items-center px-6 py-3 mt-4 duration-200 rounded cursor-pointer hover-scale"
          >
            <component
              :color="$route.name === menu.path ? '#FFFFFF' : '#1E1E29'"
              :is="getIconComponent(menu.icon)"
            />
            <span v-if="isOpen" class="mx-4">{{ menu.name }}</span>
            <div v-if="menu.childrens.length > 0 && isOpen" @click="clickExpand($event, menu.path)">
              <a v-if="expandChildrens[menu.path]"
                ><up-icon :color="$route.name === menu.path ? '#FFFFFF' : '#1E1E29'"
              /></a>
              <a v-else><down-icon :color="$route.name === menu.path ? '#FFFFFF' : '#1E1E29'" /></a>
            </div>
          </div>
          <div v-if="menu.childrens.length > 0 && expandChildrens[menu.path] && isOpen">
            <div
              :class="[$route.name === children.path ? activeClass : inactiveClass]"
              class="flex items-center px-6 py-3 duration-200 rounded cursor-pointer hover-scale"
              v-bind:key="index"
              v-for="(children, index) in menu.childrens"
              @click="clickChildrenItem($event, children)"
              :to="children.path"
            >
              <span class="mx-4">{{ children.name }}</span>
            </div>
          </div>
        </div>
      </nav>

      <a
        href="#"
        @click="logout"
        class="flex items-center px-6 py-2 mt-10 duration-200"
        :class="[$route.name === 'Blank' ? activeClass : inactiveClass]"
      >
        <LogoutIcon />

        <span v-if="isOpen" class="mx-4">Deslogar</span>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineAsyncComponent, Ref, ref, onMounted } from 'vue'
import Logo from '@/components/designSystem/Logo/index.vue'

import ComputerIcon from '@/assets/icons/computer.vue'
import LogoutIcon from '@/assets/icons/logout.vue'
import UpIcon from '@/assets/icons/up.vue'
import DownIcon from '@/assets/icons/down.vue'
import LeftArrow from '@/assets/icons/rightArrow.vue'
import RigthArrow from '@/assets/icons/leftArrow.vue'
import { useRouter } from 'vue-router'
import { useSidebar } from '@/hooks/useSidebar'
import sidebarItems from '../config/sidebar_items.json'
import Network from '@/Network'

const activeClass = ref('bg-primary30 text-white font-semibold')
const inactiveClass = ref('')

export default {
  name: 'Sidebar',
  components: {
    Logo,
    ComputerIcon,
    LogoutIcon,
    UpIcon,
    DownIcon,
    LeftArrow,
    RigthArrow
  },
  setup() {
    const { isOpen, setIsOpen } = useSidebar()
    const router = useRouter()

    const expandChildrens: Ref = ref({})

    const isMobile = ref(window.innerWidth < 800)

    onMounted(() => {
      const sideBarItensWithChildrens = Object.values(sidebarItems.items).filter(
        (item: any) => item.childrens.length > 0
      )
      sideBarItensWithChildrens.forEach((item) => {
        expandChildrens.value[item.path] = false
      })

      window.addEventListener('resize', () => {
        isMobile.value = window.innerWidth < 800
      })
    })

    function getIconComponent(icon: any) {
      const component = defineAsyncComponent(() => import(`@/assets/icons/${icon}.vue`))
      return component
    }

    function clickExpand(e: any, item: any) {
      e.stopPropagation()
      expandChildrens.value[item] = !expandChildrens.value[item]
    }

    function clickItem(e: any, menu: any) {
      router.push(menu.path)
    }

    function clickChildrenItem(e: any, children: any) {
      e.stopPropagation()
      router.push(children.path)
    }

    function showSideBar() {
      setIsOpen(!isOpen.value)
    }

    return {
      isOpen,
      expandChildrens,
      isMobile,
      getIconComponent,
      clickExpand,
      clickItem,
      clickChildrenItem,
      showSideBar
    }
  },
  data() {
    return {
      menus: sidebarItems,
      activeClass,
      inactiveClass,
      logout: Network.auth.logout
    }
  }
}
</script>
<style scoped>
.hover-scale:hover {
  transform: scale(1.1);
}

.hidden {
  display: none;
}
</style>
