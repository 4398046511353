import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26a3eae4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer-alert-confirm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_FilledButton = _resolveComponent("FilledButton")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.showAlertVModel,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showAlertVModel) = $event)),
    "max-width": _ctx.options.width,
    style: _normalizeStyle({ zIndex: _ctx.options.zIndex }),
    onKeydown: _withKeys(_ctx.cancel, ["esc"])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, {
            color: _ctx.options.color,
            dense: "",
            flat: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar_title, { class: "font-weight-bold grey--text" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.title), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["color"]),
          _withDirectives(_createVNode(_component_v_card_text, {
            style: {"margin-top":"1rem","margin-bottom":"1rem"},
            innerHTML: _ctx.message
          }, null, 8, ["innerHTML"]), [
            [_vShow, !!_ctx.message]
          ]),
          _createVNode(_component_v_card_actions, { class: "mt-4" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", null, [
                  _createVNode(_component_FilledButton, {
                    onClick: _ctx.cancel,
                    width: "120px",
                    height: "50px",
                    color: "error",
                    text: "Não"
                  }, null, 8, ["onClick"])
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_FilledButton, {
                    onClick: _ctx.confirm,
                    width: "120px",
                    height: "50px",
                    text: "Sim"
                  }, null, 8, ["onClick"])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "max-width", "style", "onKeydown"]))
}