<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.2892 12.582C9.28948 12.5817 9.28976 12.5815 9.29004 12.5813C9.50375 12.4027 9.6156 12.1464 9.6156 11.8879V8.85309C9.6156 8.55705 9.70806 8.26839 9.88007 8.02744L12.6644 4.12734M9.2892 12.582L3.13789 4.12724C3.11173 4.09646 3.08675 4.0614 3.06465 4.0218L3.55557 3.74774L3.06465 4.0218C2.89648 3.72057 2.8543 3.32623 3.05574 2.97072C3.22988 2.64215 3.59152 2.40208 4.00025 2.4221L11.8064 2.42232V3.00132M9.2892 12.582L8.91847 12.1372M9.2892 12.582L8.91847 12.1372M12.6644 4.12734C12.6905 4.09664 12.7154 4.06166 12.7375 4.02218L12.2321 3.73967M12.6644 4.12734L12.6803 4.10502C12.6645 4.12708 12.647 4.14776 12.6279 4.16687L12.2802 3.81922L12.2802 3.81919L12.2186 3.75761L12.2802 3.81917L12.2802 3.8192L12.6279 4.16689C12.6402 4.15459 12.6524 4.14141 12.6644 4.12734ZM12.2321 3.73967L12.7375 4.02217C12.908 3.71717 12.9488 3.31727 12.7397 2.95888C12.5451 2.6254 12.1983 2.42232 11.8064 2.42232L11.8064 3.00132M12.2321 3.73967C12.3189 3.58435 12.3296 3.40496 12.2396 3.25067C12.1476 3.09312 11.9902 3.00132 11.8064 3.00132M12.2321 3.73967C12.2284 3.74622 12.2238 3.75217 12.2185 3.75747C12.2151 3.76088 12.212 3.76456 12.2092 3.76848L9.40884 7.69103C9.16674 8.03015 9.0366 8.43642 9.0366 8.85309V11.8879C9.0366 11.9797 8.99719 12.0716 8.91847 12.1372M11.8064 3.00132L7.3039 13.4892L8.91847 12.1372M3.61549 3.79973L3.61592 3.80033L3.61848 3.82688C3.61835 3.8269 3.61823 3.82691 3.6181 3.82692L3.61549 3.79973ZM6.94941 13.5417L6.62885 14.0239C6.64163 14.0324 6.65454 14.0403 6.66752 14.0477C6.67509 14.0518 6.68273 14.0557 6.69044 14.0596L6.94941 13.5417ZM3.12187 4.10479L3.52213 3.81904L3.52214 3.81904L3.52214 3.81904L3.12187 4.10479L3.12187 4.10479ZM3.50745 2.67422L3.50706 2.67426L3.50745 2.67422Z"
      :stroke="stroke ?? '#68688D'"
      stroke-width="1.158"
    />
  </svg>
</template>

<script>
export default {
  props: ['width', 'height', 'bg', 'stroke']
}
</script>
