<template>
  <div class="grid grid-cols-3 gap-4 my-8">
    <div class="col-span-3 md:col-span-1">
      <Input
        :isError="isErrorUserName"
        @input="onChangeUserName"
        errorMessage="CEP inválido"
        width="100%"
        label="CEP"
        pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        :value="userName"
        required
      />
    </div>

    <div class="col-span-1 md:col-span-1">
      <Input
        :isError="isErrorUserName"
        @input="onChangeUserName"
        errorMessage="UF inválido"
        width="100%"
        label="UF"
        pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        :value="userName"
        required
      />
    </div>

    <div class="col-span-2 md:col-span-1">
      <Input
        :isError="isErrorUserName"
        @input="onChangeUserName"
        errorMessage="Cidade inválido"
        width="100%"
        label="Cidade"
        pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        :value="userName"
        required
      />
    </div>

    <div class="col-span-3 md:col-span-1">
      <Input
        :isError="isErrorUserName"
        @input="onChangeUserName"
        errorMessage="Rua inválido"
        width="100%"
        label="Rua"
        pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        :value="userName"
        required
      />
    </div>

    <div class="col-span-3 md:col-span-1">
      <Input
        :v-model="email"
        @input="onChangeEmail"
        :isError="isErrorEmail"
        errorMessage="E-mail inválido!"
        pattern="[a-z0-9]+@[a-z]+\.[a-z]{2,3}"
        type="email"
        width="100%"
        label="Número"
        required
      />
    </div>

    <div class="col-span-3 md:col-span-1">
      <Input
        :v-model="phone1"
        @input="onChangePhone1"
        :isError="isErrorPhone1"
        errorMessage="Telefone inválido"
        width="100%"
        type="tel"
        pattern="(\(\d{2}\)\s)(\d{4,5}-\d{4})"
        label="Bairro"
        mask="(##) #####-####"
        required
      />
    </div>

    <div class="col-span-3 md:col-span-1">
      <Input
        :v-model="phone1"
        @input="onChangePhone1"
        :isError="isErrorPhone1"
        errorMessage="Telefone inválido"
        width="100%"
        type="tel"
        pattern="(\(\d{2}\)\s)(\d{4,5}-\d{4})"
        label="Complemento"
        mask="(##) #####-####"
        required
      />
    </div>
  </div>

  <div class="flex justify-end mt-10">
    <FilledButton
      text="Salvar alterações"
      @click="() => toast.success('Endereço alterado com sucesso!')"
      width="180px"
    />
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import Input from '@/components/designSystem/Inputs/Input.vue'

export default {
  name: 'AddressPanel',
  components: {
    Input,
    FilledButton
  },
  setup() {
    const toast = useToast()

    return { toast }
  }
}
</script>
