import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col gap-10" }
const _hoisted_2 = { class: "flex justify-between items-end md:items-center" }
const _hoisted_3 = { class: "flex gap-2 flex-wrap" }
const _hoisted_4 = { class: "w-full flex justify-between flex-wrap md:flex-nowrap items-center gap-8" }
const _hoisted_5 = { class: "w-full" }
const _hoisted_6 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_simple_flag = _resolveComponent("simple-flag")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_Reports = _resolveComponent("Reports")!
  const _component_TableApprovalsShifts = _resolveComponent("TableApprovalsShifts")!
  const _component_FilterStationModal = _resolveComponent("FilterStationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_simple_flag, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.changeTab('relatorios'))),
            selected: $setup.tabSelected === 'relatorios',
            text: "Relatórios"
          }, null, 8, ["selected"]),
          _createVNode(_component_simple_flag, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.changeTab('aprovacao'))),
            selected: $setup.tabSelected === 'aprovacao',
            text: "Aprovação pós plantão"
          }, null, 8, ["selected"])
        ]),
        _createVNode(_component_v_btn, {
          onClick: $setup.toggleFilterModal,
          class: "font-bold capitalize",
          slim: "",
          color: "primary",
          "prepend-icon": $setup.mdiFilter,
          variant: "text"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Filtrar ")
          ]),
          _: 1
        }, 8, ["onClick", "prepend-icon"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Reports)
        ], 512), [
          [_vShow, $setup.tabSelected === 'relatorios']
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_6, [
          _createVNode(_component_TableApprovalsShifts)
        ], 512), [
          [_vShow, $setup.tabSelected === 'aprovacao']
        ])
      ])
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "#app" }, [
      _createVNode(_component_FilterStationModal)
    ]))
  ], 64))
}