<template>
  <GenericInput
    v-bind="{ ...$props, ...$attrs }"
    :value="modelValue"
    :type="visible ? 'text' : 'password'"
    @blur="$emit('validate', modelValue)"
    @keyup="$emit('validate', modelValue)"
    @update:modelValue="$emit('update:modelValue', $event)"
    label="Senha"
  >
    <template v-slot:append-inner>
      <span @click="visible = !visible" class="cursor-pointer">
        <ShowIcon v-if="!visible" />
        <HideIcon v-else />
      </span>
    </template>
  </GenericInput>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import ShowIcon from '@/assets/icons/ShowIcon.vue'
import HideIcon from '@/assets/icons/HideIcon.vue'
import GenericInput from '@/components/designSystem/Inputs/GenericInput.vue'

export default defineComponent({
  extends: GenericInput,
  inheritAttrs: false,
  emits: ['update:modelValue', 'validate'],
  components: {
    ShowIcon,
    HideIcon,
    GenericInput
  },
  setup() {
    const visible = ref(false)

    return {
      visible
    }
  }
})
</script>
