interface Protocol {
  id: number | null
  name: string
  speciality: string
  notes: string
  file: string
}

interface State {
  newProtocol: Protocol
  protocols: Protocol[]
  deleteProtocolId: number | null
}

const initialProtocol = {
  id: null,
  name: '',
  speciality: '',
  notes: '',
  file: ''
}

export default {
  state() {
    return {
      newProtocol: { ...initialProtocol },
      protocols: []
    }
  },
  mutations: {
    setNewProtocol(state: State, newProtocol: Protocol) {
      state.newProtocol = newProtocol
    },
    createProtocol(state: State, newProtocols: Protocol[]) {
      state.protocols = newProtocols
      state.newProtocol = { ...initialProtocol }
    },
    deleteProtocol(state: State) {
      state.protocols = state.protocols.filter((protocol) => protocol.id !== state.deleteProtocolId)
      state.deleteProtocolId = null
    },
    setDeleteProtocolId(state: State, protocolId: number) {
      state.deleteProtocolId = protocolId
    },
    clearNewProtocol(state: State) {
      state.newProtocol = { ...initialProtocol }
    }
  },
  actions: {
    setNewProtocol(context: any, payload: { property: string; value: string }) {
      const newProtocol = { ...context.state.newProtocol, [payload.property]: payload.value }
      context.commit('setNewProtocol', newProtocol)
    },
    createProtocol(context: any) {
      const newProtocols = [...context.state.protocols]
      const newProtocol = { ...context.state.newProtocol, id: Math.round(1 + Math.random() * 9999) }

      newProtocols.push(newProtocol)

      context.commit('createProtocol', newProtocols)
    },
    setDeleteProtocolId(context: any, protocolId: number) {
      context.commit('setDeleteProtocolId', protocolId)
    },
    deleteProtocol(context: any) {
      context.commit('deleteProtocol')
    },
    clearNewProtocol(context: any) {
      context.commit('clearNewProtocol')
    }
  }
}
