<template>
  <div
    :class="`${selected ? 'tab-active font-bold' : 'tab'} text-${
      selected ? 'white' : 'black'
    } hover:scale-105 cursor-pointer text-xs inline-flex items-center leading-sm px-3 py-2 rounded-full`"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      default: 'Lorem Inpsum',
      type: String
    },
    selected: {
      default: true,
      type: Boolean
    }
  }
}
</script>

<style scoped>
.tab {
  background-color: #f0f0f4;
}
.tab-active {
  background-color: #2f7f91;
}
</style>
