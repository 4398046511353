<template>
  <div class="mb-8">
    <div class="flex items-center font-bold gap-2 mb-8">
      <AddCircleIcon class="cursor-pointer" @click="showCreateContract = true" :solid="true" />
      Criar contrato
    </div>
    <div class="md:w-3/4 w-full">
      <div class="relative" v-if="showCreateContract">
        <Input
          :isError="showContractNameInvalidMessage"
          @input="onChangeNewContractName"
          errorMessage="Nome do contrato inválido"
          label="Nome do contrato"
          width="100%"
          :value="newContractName"
          required
        />
        <button
          @click="createContract"
          class="absolute top-3 right-5 z-10 add-contract-btn font-bold"
        >
          adicionar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AddCircleIcon from '@/assets/icons/add-circle.vue'
import Input from '@/components/designSystem/Inputs/Input.vue'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { isEmpty } from '@/utils/validation'

export default {
  name: 'CreateContract',
  components: {
    AddCircleIcon,
    Input
  },
  setup() {
    const store = useStore()

    const showCreateContract = ref(false)
    const showContractNameInvalidMessage = ref(false)

    const newContractName = computed(() => store.state.contract.newContractName)

    const onChangeNewContractName = (e) => {
      store.dispatch('setNewContractName', e.target.value)
    }

    const validateContractName = () => {
      if (isEmpty(newContractName.value)) {
        showContractNameInvalidMessage.value = true
        return false
      }
      return true
    }

    const createContract = () => {
      if (validateContractName()) {
        store.dispatch('createContract')
        showContractNameInvalidMessage.value = false
      }
    }

    return {
      newContractName,
      showCreateContract,
      onChangeNewContractName,
      createContract,
      isEmpty,
      showContractNameInvalidMessage
    }
  }
}
</script>

<style scoped>
.add-contract-btn {
  color: #2f7f91;
}
</style>
