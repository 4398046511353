<template>
  <div class="station-form-category-container">
    <span class="station-form-category-title">Informações do plantão</span>
    <div class="station-form-category-content">
      <div class="station-form-category-columns text-left justify-start">
        <div class="flex flex-col">
          <span class="text-sg">Periodicidade</span>
          <v-radio-group @update:model-value="setStationType" :model-value="stationType" column>
            <v-radio label="Fixo" color="primary" value="SEMANAL" />
            <v-radio label="Cobertura" color="primary" value="COBERTURA" />
            <v-radio label="Extra" color="primary" value="EXTRA" />
          </v-radio-group>
        </div>
        <div class="flex flex-col">
          <span class="text-sg">Nossa equipe</span>
          <v-radio-group @update:model-value="setOurTeam" :model-value="ourTeam" column>
            <v-radio label="Sim" color="primary" :value="true" />
            <v-radio label="Não" color="primary" :value="false" />
          </v-radio-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue'

defineProps(['stationType', 'ourTeam'])
const emit = defineEmits(['update:stationType', 'update:ourTeam'])
const setStationType = (v: string) => emit('update:stationType', v)
const setOurTeam = (v: string) => emit('update:ourTeam', v)
</script>
