<template>
  <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.73 12.7705C16.73 12.8405 16.73 12.9005 16.73 12.9705C16.73 15.3105 14.78 18.4105 10.73 22.1105C6.67998 18.4105 4.72998 15.3205 4.72998 12.9705C4.72998 9.34047 7.37998 6.77047 10.73 6.77047C11.07 6.77047 11.41 6.80047 11.73 6.85047V4.83047C11.4 4.79047 11.07 4.77047 10.73 4.77047C6.52998 4.77047 2.72998 7.99047 2.72998 12.9705C2.72998 16.2905 5.39998 20.2205 10.73 24.7705C16.06 20.2205 18.73 16.2905 18.73 12.9705C18.73 12.9005 18.73 12.8405 18.73 12.7705H16.73Z"
      fill="#F18F1B"
    />
    <path
      d="M10.73 14.7705C11.8345 14.7705 12.73 13.875 12.73 12.7705C12.73 11.6659 11.8345 10.7705 10.73 10.7705C9.62541 10.7705 8.72998 11.6659 8.72998 12.7705C8.72998 13.875 9.62541 14.7705 10.73 14.7705Z"
      fill="#F18F1B"
    />
    <path
      d="M21.27 4.65047L19.85 3.23047L17.73 5.36047L15.61 3.23047L14.19 4.65047L16.32 6.77047L14.19 8.89047L15.61 10.3105L17.73 8.18047L19.85 10.3105L21.27 8.89047L19.14 6.77047L21.27 4.65047Z"
      fill="#F18F1B"
    />
    <rect x="14" y="3" width="8" height="8" fill="white" />
    <path
      d="M17.5 1.25C14.0625 1.25 11.25 4.0625 11.25 7.5C11.25 10.9375 14.0625 13.75 17.5 13.75C20.9375 13.75 23.75 10.9375 23.75 7.5C23.75 4.0625 20.9375 1.25 17.5 1.25ZM17.5 12.5C14.7438 12.5 12.5 10.2563 12.5 7.5C12.5 4.74375 14.7438 2.5 17.5 2.5C20.2563 2.5 22.5 4.74375 22.5 7.5C22.5 10.2563 20.2563 12.5 17.5 12.5ZM17.8125 4.375H16.875V8.125L20.125 10.125L20.625 9.3125L17.8125 7.625V4.375Z"
      fill="#F18F1B"
    />
  </svg>
</template>

<script>
export default {}
</script>
