<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1818 0.909088H1.81818C0.818182 0.909088 0 1.72727 0 2.72727V13.6364C0 14.6364 0.818182 15.4545 1.81818 15.4545H8.18182V17.2727H6.36364V19.0909H13.6364V17.2727H11.8182V15.4545H18.1818C19.1818 15.4545 20 14.6364 20 13.6364V2.72727C20 1.72727 19.1818 0.909088 18.1818 0.909088ZM18.1818 13.6364H1.81818V2.72727H18.1818V13.6364Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#1E1E29'
    },
    width: {
      type: String,
      default: '20'
    },
    height: {
      type: String,
      default: '20'
    }
  }
}
</script>
