<template>
  <div :title="unit.name"
    class="w-full xl:max-w-[300px] hover:shadow-lg px-4 py-4 rounded-[8px] my-0 grid grid-cols-12 border-2 gap-2 border-[#F0F0F4] hover:border-[#2F7F91] transition ease-in-out cursor-pointer select-none">
    <v-avatar class="col-span-2 sm:col-span-3 shrink-0 w-[40px]" color="primary">
      <span class="text-md font-bold">{{ getInitalsName(unit.name || 'ME') }}</span>
    </v-avatar>
    <!-- <img class="col-span-2 sm:col-span-3 shrink-0 w-[40px]" draggable="false" src="https://i.imgur.com/rj93B4I.png"
      :alt="unit.name" /> -->
    <div class="col-span-9 sm:col-span-8 text-left">
      <p class="font-bold truncate">{{ unit.name }}</p>
      <p>{{ unit.mainPhone?.phoneNumber }}</p>
    </div>
    <button class="flex items-center justify-center bg-primary rounded w-5 h-5 mt-auto col-span-1">
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.79939 7.74926L0.587258 7.53713L0.79939 7.74926L6.02939 2.51926L7.48013 3.97L2.25013 9.2H0.79939V7.74926ZM7.97226 0.857132L9.14226 2.02713C9.2201 2.10497 9.2201 2.23003 9.14226 2.30787L8.43939 3.01074L6.98865 1.56L7.69152 0.857132L7.47939 0.645L7.69152 0.857132C7.7293 0.819354 7.77759 0.8 7.82939 0.8C7.88679 0.8 7.93326 0.818135 7.97226 0.857132Z"
          stroke="white" stroke-width="0.6" stroke-linejoin="round" />
      </svg>
    </button>
  </div>
</template>

<script setup lang="ts">
import { HealthUnitResponse } from '@/Network/Types/Responses/HealthUnitResponses';
import { defineProps } from 'vue'

defineProps<{
  unit: HealthUnitResponse
}>()

const getInitalsName = (name: string) => {
  return name.substring(0, 2).toUpperCase()
}
</script>
