<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_288_8350" fill="white">
      <path
        d="M1.33333 8.00016C1.33333 4.32016 4.32 1.3335 8 1.3335C11.68 1.3335 14.6667 4.32016 14.6667 8.00016C14.6667 11.6802 11.68 14.6668 8 14.6668C4.32 14.6668 1.33333 11.6802 1.33333 8.00016Z"
      />
    </mask>
    <path
      d="M8 0.333496C3.76771 0.333496 0.333332 3.76788 0.333332 8.00016H2.33333C2.33333 4.87245 4.87228 2.3335 8 2.3335V0.333496ZM0.333332 8.00016C0.333332 12.2324 3.76771 15.6668 8 15.6668V13.6668C4.87228 13.6668 2.33333 11.1279 2.33333 8.00016H0.333332ZM8 15.6668C12.2323 15.6668 15.6667 12.2324 15.6667 8.00016H13.6667C13.6667 11.1279 11.1277 13.6668 8 13.6668V15.6668ZM15.6667 8.00016C15.6667 3.76788 12.2323 0.333496 8 0.333496V2.3335C11.1277 2.3335 13.6667 4.87245 13.6667 8.00016H15.6667Z"
      :fill="color"
      mask="url(#path-1-inside-1_288_8350)"
    />
    <path d="M8 5V9" :stroke="color" stroke-linecap="round" />
    <path d="M8 9L11 9" :stroke="color" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#68688D'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
