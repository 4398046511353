<template>
  <div
    class="w-full relative overflow-hidden flex-col bg-white rounded-lg border lg:flex lg:max-w-lg shadow-lg"
  >
    <img src="/bg-card.png" class="w-full h-[53px]" />

    <!-- <img
      src="@/assets/icons/avatar_doctor.png"
      class="rounded-lg mr-2 border-2"
      style="margin-left: 10px; margin-top: -28px; width: 50px; height: 50px; border-radius: 50px"
    /> -->

    <div class="w-full flex p-3 pt-2">
      <div class="w-full flex flex-col gap-2 items-start">
        <div class="flex flex-col justify-start items-start">
          <h2 class="text-[16px] font-bold text-gray-900">{{ doctorName }}</h2>
          <p class="text-[14px] text-gray-600">{{ doctorCrm }}</p>
          <p class="text-[14px] text-gray-600 mb-2">{{ hospitalName }}</p>
        </div>

        <div class="w-full flex items-center justify-between flex-wrap">
          <p class="text-[14px] text-gray-600">Data</p>
          <p class="text-[14px] font-bold">{{ dateDuty }}</p>
        </div>

        <div class="w-full flex items-center justify-between flex-wrap">
          <p class="text-[14px] text-gray-600">Hora do plantão</p>
          <p class="text-[14px] font-bold">{{ hoursOfDuty }}</p>
        </div>

        <div class="w-full flex items-center justify-between flex-wrap">
          <p class="text-[14px] text-gray-600">Hora executada</p>
          <p class="text-[14px] font-bold">{{ hoursOfDutPerformed }}</p>
        </div>

        <div class="w-full flex items-center justify-between">
          <p class="text-[14px] text-gray-600">Valor</p>
          <p class="text-[14px] font-bold">R$ {{ formatCurrency(dutyFee) }}</p>
        </div>
      </div>
    </div>

    <div class="p-2 flex w-full justify-between">
      <button
        @click="$emit('clickDetails', stationId)"
        class="w-24 text-primary30 border border-primary30 font-bold rounded p-1"
      >
        Detalhes
      </button>
      <button
        @click="$emit('clickApprove', stationId)"
        class="text-white bg-primary30 p-1 rounded font-bold w-24"
      >
        Aprovar
      </button>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/utils/formatters'
export default {
  name: 'CardDoctor',
  emits: ['clickDetails', 'clickApprove'],
  props: {
    stationId: {
      type: Number
    },
    doctorName: {
      type: String,
      default: 'Nome do medico'
    },
    doctorCrm: {
      type: String,
      default: '12345-CRM'
    },
    hospitalName: {
      type: String,
      default: 'Nome do hospital'
    },
    dateDuty: {
      type: [String, Date],
      default: '23/08/2022'
    },
    hoursOfDuty: {
      type: String,
      default: '19:00 - 07:00'
    },
    hoursOfDutPerformed: {
      type: String,
      default: '20:40 - 12:45'
    },
    dutyFee: {
      type: [String, Number],
      default: '1500,00'
    },
    // TODO usar props no card, estao usando imagens mockadas.
    doctorAvatar: {
      type: String,
      default: '@/assets/icons/avatar_doctor.png'
    },
    hospitalBanner: {
      type: String,
      default: '@/assets/icons/bannercard.svg'
    }
  },
  setup: () => {
    return { formatCurrency }
  }
}
</script>
