<template>
  <modal-vue @close="handleToggleModal" :modalActive="modals[modalName]" :showCloseButton="false">
    <!-- <template #header>
      <div class="text-left px-[16px] flex">
        <strong>Exportar relatório de apontamentos do médico</strong>
      </div>
    </template> -->
    <SignupForm
      class-main-container="signupContainerForm"
      :activeCustomSubmitEvent="true"
      @onSuccessSubmit="onSuccessSubmit"
      :disableDefaultCloseCallback="false"
      @onCloseSignupForm="onCloseSignupForm"
    />

    <!-- <template #footer>
      <div class="flex gap-5 justify-end mt-4 px-3">
        <filled-button @click="handleExport" text="Exportar" height="52px" width="180px" />
      </div>
    </template> -->
  </modal-vue>
</template>

<script lang="ts">
// import { startOfMonth, endOfMonth } from 'date-fns'
import { storeToRefs } from 'pinia'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
// import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import SignupForm from '@/modules/public/SignUp/doctor/Index.vue'
// import { ref } from 'vue'
// import { formatDateToString } from '@/utils/date'
// import Network from '@/Network'
// import { useToast } from 'vue-toastification'
// import axios from 'axios'
export default {
  components: {
    ModalVue,
    SignupForm
    // FilledButton
  },
  setup(props, { emit }) {
    /* ******** Modal Props and Actions Default *********** */
    const modalName = EModals.DOCTOR_SIGNUP
    const { modals } = storeToRefs(useModalStore())
    const modalStore = useModalStore()
    const handleToggleModal = () => {
      modalStore.toggleModal(modalName)
    }
    const onSuccessSubmit = () => {
      handleToggleModal()
      emit('onSuccessSubmit')
    }
    const onCloseSignupForm = () => {
      handleToggleModal()
    }
    /* **************************************************** */
    return {
      modalName,
      modals,
      handleToggleModal,
      onSuccessSubmit,
      onCloseSignupForm
    }
  }
}
</script>
<style>
.signupContainerForm {
  background-color: #fafafa;
  overflow: scroll;
  height: 85vh;
  /* min-height: 100vh; */
  padding: 10px;
}

@media (max-width: 556px) {
  .signupContainerForm {
    padding: 20px;
  }
}
</style>
