<template>
  <div>
    <div v-show="props.modalStateProp"
      :class="`modal z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`">
      <div class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>

      <div
        class="container-modal z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container max-h-[80vh]">
        <div @click="() => {
            close()
            clear()
          }
          "
          class="absolute md:flex hidden top-0 right-0 z-50 flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close">
          <svg class="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
            viewBox="0 0 18 18">
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <div class="px-6 py-4 text-left modal-content">
          <div class="flex items-center justify-between pb-3">
            <p class="titleBold">Criar grupo</p>
            <div class="z-50 cursor-pointer modal-close" @click="close">
              <svg class="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                viewBox="0 0 18 18">
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
              </svg>
            </div>
          </div>

          <div class="mb-2">
            <Input v-model="groupNameInput" width="100%" label="Nome da escala*" />
          </div>

          <Transfer v-for="transfer in transfers" :key="transfer.id" :title="transfer.title"
            :description="transfer.description" :options="transfer.perms" ref="optionsRefs" @clean="reset"
            :clean="transfer.clean" />

          <div class="flex justify-end pt-8">
            <OutlineButton @click="() => {
                close()
                clear()
              }
              " class="mr-4" text="Cancelar" width="120px" />
            <FilledButton text="Criar" width="100px" @click="() => createGroup(groupNameInput)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, reactive } from 'vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import Input from '@/components/designSystem/Inputs/Input.vue'
import { useStore } from 'vuex'
import Transfer from './components/Transfer.vue'

const store = useStore()
const props = defineProps(['modalStateProp'])
const emits = defineEmits(['close'])
const groupNameInput = ref('')
const optionsRefs = ref([])

const { users } = store.state.userCrud
const { hospitals } = store.state.userCrud
const defaultPerms = ref([
  'Excluir Configuração de Atraso da Vaga',
  'Editar Configuração de Atraso da Vaga',
  'Criar Configuração de Atraso da Vaga',
  'Visualizar Configuração de Atraso da Vaga',
  'Receber Email de Candidatura',
  'Receber Notificação de Candidatura',
  'Realizar Aprovação de Valores Para Médicos',
  'Visualizar Valores Para Aprovação dos Médicos',
  'Visualizar Resumo de Plantões na Dashboard',
  'Receber Email de Novo Documento Inserido Pelo Médico',
  'Receber Notificação de Novo documento Inserido Pelo Médico',
  'Receber Email de Novo Médico Vinculado',
  'Receber Notificação De novo Médico Vinculado',
  'Editar Perfil da Empresa',
  'Visualizar Perfil da Empresa',
  'Visualizar Relatório',
  'Visualizar Controle de Gastos',
  'Excluir Usuário',
  'Editar Usuário',
  'Adicionar Usuários',
  'Visualizar Usuários',
  'Remover Médicos',
  'Adicionar Médico',
  'Visualizar Meus Médicos',
  'Remover Meus Hospitais',
  'Editar Meus Hospitais',
  'Adicionar Meus Hospitais',
  'Visualizar Meus Hospitais',
  'Visualizar Dashboard'
])
const defaultPermsHospital = ref([
  'Receber Email de Candidatura',
  'Receber Notificação de Candidatura',
  'Receber Email de CheckOut Realizado Antes do Fim',
  'Receber Notificação de CheckOut Realizado Antes do Fim',
  'Receber Email de Confirmação de Plantão',
  'Receber Notificação de Confirmação de Plantão',
  'Editar Escala',
  'Receber Notificação de Vaga Aberta 1 Dia Antes',
  'Receber Email de Vaga Aberta 1 Dia Antes',
  'Receber Notificação de Falta Médica',
  'Receber Email de Falta Médica',
  'Receber Noificação de Falta de Checkout',
  'Receber Email de Falta de Checkout',
  'Receber Notificação de falta de Checkin',
  'Receber Email de falta de Checkin',
  'Apagar Protocolos',
  'Editar Protocolos',
  'Criar Protocolos',
  'Visualizar Protocolos',
  'Editar Justificativa',
  'Criar Justificativa',
  'Deletar Vaga',
  'Solitar edição do Valor do Plantão',
  'Editar Valor do Plantão',
  'Editar Vaga',
  'Criar Escala',
  'Visualizar Escala',
  'Desativar Contrato',
  'Editar Contrato',
  'Criar Contrato',
  'Visualizar Contratos',
  'Visualizar Dashboard'
])

const transfers: any = reactive([
  {
    id: 1,
    title: 'Usuários',
    description: 'Usuários do sistema que fazem parte desse grupo',
    perms: users.map((user) => user.name),
    clean: false
  },
  {
    id: 2,
    title: 'Hospital',
    description: 'Hospitais que os usuários do grupo poderão acessar',
    perms: hospitals.map((hospital) => hospital.name),
    clean: false
  },
  {
    id: 3,
    title: 'Permissões',
    description: 'Ações que os usuários do grupo poderão realizar na empresa',
    perms: defaultPerms,
    clean: false
  },
  {
    id: 4,
    title: 'Permissões no Hospital',
    description: 'Ações que os usuários do grupo poderão realizar no hospital',
    perms: defaultPermsHospital,
    clean: false
  }
])

const clear = () => {
  transfers.forEach((value, index) => {
    transfers[index].clean = true
  })
}

const reset = () => {
  groupNameInput.value = ''

  transfers.forEach((value, index) => {
    transfers[index].clean = false
  })

  transfers[0].perms = users.map((user) => user.name)
  transfers[1].perms = hospitals.map((hospital) => hospital.name)
  transfers[2].perms = defaultPerms
  transfers[3].perms = defaultPermsHospital
}

const close = () => {
  emits('close')
}

const createGroup = (group) => {
  store.commit('createGroup', { name: group })

  close()
  clear()
  groupNameInput.value = ''

  emits('close')
}
</script>

<style scoped>
.modal {
  transition: opacity 0.25s ease;
}

.container-modal {
  width: 700px;
}

.titleBold {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 700;
}
</style>
