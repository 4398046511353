export interface Hospital {
  name: string
  number: string
}

interface IStates {
  hospitals: Hospital[]
  filteredHospitals: Hospital[]
  activeHospital: Hospital | null
}

export default {
  state(): IStates {
    return {
      hospitals: [
        {
          name: 'Hospital xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
          number: '(11) 0000-0000'
        },
        {
          name: 'Teste 123',
          number: '(11) 0000-0000'
        },
        {
          name: 'xlo',
          number: '(11) 0000-0000'
        },
        {
          name: 'sss',
          number: '(11) 0000-0000'
        }
      ],
      filteredHospitals: [],
      activeHospital: null
    }
  },
  mutations: {
    filterMock(state: IStates, query: string) {
      state.filteredHospitals =
        query.length > 0
          ? state.hospitals.filter((_hospital: Hospital) =>
              _hospital.name.toLowerCase().includes(query.toLowerCase())
            )
          : state.hospitals
    },
    setActiveHospital(state: IStates, activeHospital: Hospital) {
      state.activeHospital = activeHospital
    }
  },
  actions: {
    filterHospital(context: any, param: string) {
      context.commit('filterMock', param)
    },
    setHospital(context: any, param: Hospital) {
      context.commit('setActiveHospital', param)
    }
  },
  getters: {
    getFilteredHospital(state: IStates) {
      return state.filteredHospitals
    },
    getActiveHospital(state: IStates) {
      return state.activeHospital
    }
  }
}
