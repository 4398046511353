import api from './api'

export default class GetRequest {
  url: string

  params: any | undefined
  headers: any | undefined
  responseType: any | undefined

  constructor(
    url: string,
    params: any | undefined,
    headers: any | undefined,
    responseType: string | undefined
  ) {
    this.url = url
    this.params = params
    this.headers = headers
    this.responseType = responseType
  }

  async run<R>() {
    return api.get<R>(this.url, {
      params: this.params,
      headers: this.headers,
      responseType: this.responseType
    })
  }
}
