<template>
  <GenericInput
    v-bind="{ ...$props, ...$attrs }"
    :value="modelValue"
    type="search"
    @blur="$emit('validate', modelValue)"
    @keyup="handleKeyUp"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <template v-if="loading" v-slot:loader>
      <v-progress-linear
        class="mt[6px]"
        :active="true"
        :color="colors.primary20"
        height="5"
        indeterminate
      ></v-progress-linear>
    </template>
    <template #append-inner>
      <SearchIcon />
    </template>
  </GenericInput>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import SearchIcon from '@/assets/icons/search.vue'
import GenericInput from '@/components/designSystem/Inputs/GenericInput.vue'
import { getColorVariable } from '@/utils/tailwindcss'
import { boolean } from 'yup'
import { onMounted } from 'vue'

export default defineComponent({
  extends: GenericInput,
  inheritAttrs: false,
  emits: ['update:modelValue', 'validate', 'onSearch'],
  components: {
    GenericInput,
    SearchIcon
  },
  props: {
    searchFunction: {
      type: Function,
      default: () => {
        return async function () {}
      }
    },
    debounceActived: {
      default: true
    }
  },
  setup(props, ctx) {
    const visible = ref(false)
    const colors = {
      primary20: getColorVariable('primary20')?.default ?? '#3CA2B9'
    }
    const typingTimer = ref()
    const loading = ref(false)

    const onKeyUpSearch = () => {
      clearTimeout(typingTimer.value)

      typingTimer.value = setTimeout(async () => {
        try {
          await props.searchFunction()
        } catch (e) {
          console.error(e)
        } finally {
          loading.value = false
        }
      }, 500)
    }

    const executeDebounceSearch = () => {
      if (props.debounceActived) {
        loading.value = true
        onKeyUpSearch()
      }
    }

    const handleKeyUp = () => {
      ctx.emit('validate', props.modelValue)
      executeDebounceSearch()
    }

    onMounted(() => {
      executeDebounceSearch()
    })

    return {
      visible,
      colors,
      handleKeyUp,
      loading
    }
  }
})
</script>
