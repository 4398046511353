<template>
  <v-form
    ref="userDataFormRef"
    id="formDoctorSignUpPesonalData"
    class="w-full flex gap-7 items-center flex-col rounded overflow-hidden mt-5 mb-4"
  >
    <div class="w-full flex flex-col">
      <h1 class="text-left font-bold text-2xl">Dados de Acesso</h1>
      <p class="text-left text-sm text-gray-500">
        Seu e-mail e senha será utilizado para entrar no aplicativo Minha Escala.
      </p>
    </div>

    <div class="flex flex-col w-full gap-2">
      <v-text-field
        v-model="userDataForm.email"
        label="* Email"
        class="w-full"
        color="primary20"
        variant="outlined"
        @input="userDataForm.email = $event.target.value.toLowerCase()"
        :rules="[rules.required, rules.email]"
        :error="!!emailIsValid"
        :error-messages="emailIsValid"
        @update:modelValue="verifyEmail"
      />
      <v-text-field
        v-model="userDataForm.phone"
        v-maska="'(##) #####-####'"
        label="* Telefone"
        class="w-full"
        color="primary20"
        variant="outlined"
        :rules="[rules.required, rules.phone]"
      />

      <v-text-field
        v-model="userDataForm.password"
        label="* Senha"
        class="w-full"
        color="primary20"
        variant="outlined"
        :type="showPassword ? 'text' : 'password'"
        @click:append-inner="showPassword = !showPassword"
        :append-inner-icon="showPassword ? mdiEye : mdiEyeOff"
        :rules="[rules.required, rules.password]"
      />
      <v-text-field
        v-model="userDataForm.confirmPassword"
        label="* Confirmação da senha"
        class="w-full"
        color="primary20"
        variant="outlined"
        :type="showRepeatPassword ? 'text' : 'password'"
        @click:append-inner="showRepeatPassword = !showRepeatPassword"
        :append-inner-icon="showRepeatPassword ? mdiEye : mdiEyeOff"
        :rules="[rules.required, rulesSpecific.confirmPassword]"
      />
    </div>
  </v-form>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { mdiEye, mdiEyeOff } from '@mdi/js'
import { useDoctorRegistryStore } from '@/pinia-store/useDoctorRegistryStore'
import { rules } from '@/utils/rules'
import Network from '@/Network'
import { validateEmail } from '@/utils/validation'

const doctorRegistryStore = useDoctorRegistryStore()
const { userDataForm, userDataFormRef } = storeToRefs(doctorRegistryStore)

const showPassword = ref(false)
const showRepeatPassword = ref(false)
const emailIsValid = ref<string>('')

const verifyEmail = async () => {
  if (!validateEmail(userDataForm.value.email)) return
  try {
    emailIsValid.value = ''
    const data = await Network.user.verifyEmail(userDataForm.value.email)
    if (data.emailIsAlreadyInUse) {
      emailIsValid.value = 'Este email já está em uso!'
    }
  } catch (error) {
    emailIsValid.value = 'Erro ao validar email!'
  }
}

const rulesSpecific = {
  confirmPassword: (value: string) => {
    return value === userDataForm.value.password || 'As senhas não coincidem!'
  }
}
</script>
