<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.73 12.7705C16.73 12.8405 16.73 12.9005 16.73 12.9705C16.73 15.3105 14.78 18.4105 10.73 22.1105C6.67998 18.4105 4.72998 15.3205 4.72998 12.9705C4.72998 9.34047 7.37998 6.77047 10.73 6.77047C11.07 6.77047 11.41 6.80047 11.73 6.85047V4.83047C11.4 4.79047 11.07 4.77047 10.73 4.77047C6.52998 4.77047 2.72998 7.99047 2.72998 12.9705C2.72998 16.2905 5.39998 20.2205 10.73 24.7705C16.06 20.2205 18.73 16.2905 18.73 12.9705C18.73 12.9005 18.73 12.8405 18.73 12.7705H16.73Z"
      fill="#F18F1B"
    />
    <path
      d="M10.73 14.7705C11.8345 14.7705 12.73 13.875 12.73 12.7705C12.73 11.6659 11.8345 10.7705 10.73 10.7705C9.62541 10.7705 8.72998 11.6659 8.72998 12.7705C8.72998 13.875 9.62541 14.7705 10.73 14.7705Z"
      fill="#F18F1B"
    />
    <path
      d="M21.27 4.65047L19.85 3.23047L17.73 5.36047L15.61 3.23047L14.19 4.65047L16.32 6.77047L14.19 8.89047L15.61 10.3105L17.73 8.18047L19.85 10.3105L21.27 8.89047L19.14 6.77047L21.27 4.65047Z"
      fill="#F18F1B"
    />
    <rect x="14" y="3" width="8" height="8" fill="white" />
    <path
      d="M19.2201 4.66699L17.5834 6.17783L15.9466 4.66699L15.0556 5.48949L16.6923 7.00033L15.0556 8.51116L15.9466 9.33366L17.5834 7.82283L19.2201 9.33366L20.1111 8.51116L18.4744 7.00033L20.1111 5.48949L19.2201 4.66699ZM17.5834 1.16699C14.0887 1.16699 11.2639 3.77449 11.2639 7.00033C11.2639 10.2262 14.0887 12.8337 17.5834 12.8337C21.078 12.8337 23.9028 10.2262 23.9028 7.00033C23.9028 3.77449 21.078 1.16699 17.5834 1.16699ZM17.5834 11.667C14.7965 11.667 12.5278 9.57283 12.5278 7.00033C12.5278 4.42783 14.7965 2.33366 17.5834 2.33366C20.3702 2.33366 22.6389 4.42783 22.6389 7.00033C22.6389 9.57283 20.3702 11.667 17.5834 11.667Z"
      fill="#F18F1B"
    />
  </svg>
</template>

<script>
export default {}
</script>
