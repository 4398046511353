/* eslint-disable */
import request from './Requests/request'
import AuthService from './Services/AuthService'
import CompanyService from './Services/CompanyService'
import HealthUnitService from './Services/HealthUnitService'
import DoctorsService from './Services/DoctorsService'
import StationService from './Services/StationService'
import ScheduleService from './Services/ScheduleUnitService'
import { NetworkInterface } from './Types/NetworkInterface'
import {
  createDoctorProtocol,
  createCompanyProtocol,
  createInviteDoctorLinkProtocol,
  createHospitalProtocol,
  createContractProtocol,
  createContractTaxDeductionProtocol
} from './Types/Requets'
import UserService from './Services/UserService'
import DoctorService from './Services/DoctorService'
import CfmService from './Services/CfmService'

class Network implements NetworkInterface {
  auth: AuthService
  company: CompanyService
  doctors: DoctorsService
  healthUnit: HealthUnitService
  schedule: ScheduleService
  station: StationService
  user: UserService
  doctor: DoctorService
  cfm: CfmService

  constructor() {
    this.auth = new AuthService()
    this.company = new CompanyService()
    this.healthUnit = new HealthUnitService()
    this.schedule = new ScheduleService()
    this.station = new StationService()
    this.user = new UserService()
    this.doctors = new DoctorsService()
    this.doctor = new DoctorService()
    this.cfm = new CfmService()
  }

  async getCompanyVacancyById(hospitalId: number, scheduleId: number, vacancyId: number) {
    const url = `/companies/myhospitals/${hospitalId}/schedules/${scheduleId}/vacancies/${vacancyId}/0`
    return request.get(url)
  }

  async getCompanyObservationsVacancyById(
    hospitalId: number,
    scheduleId: number,
    vacancyId: number,
    order = 'DESC'
  ) {
    const url = `/companies/myhospitals/${hospitalId}/schedules/${scheduleId}/vacancies/${vacancyId}/observations`
    const params = { order }
    return await request.get(url, params)
  }

  async getCompanyMyDoctors(
    isActive = true,
    name?: string,
    crm?: string,
    crmUf?: string,
    limit?: number,
    offset?: number
  ) {
    const url = '/companies/mydoctors'
    const params = {
      isActive,
      name,
      crm,
      crmUf,
      limit,
      offset
    }
    return await request.get(url, params)
  }

  async getCompanyMyDoctorById(doctorId: number) {
    const url = `/companies/mydoctors/${doctorId}`
    return await request.get(url)
  }

  async getCompanyUsersCompanyInCharge(
    hospitalId: number,
    groupId: number,
    isActive = true,
    name?: string,
    offset?: number,
    limit?: number
  ) {
    const url = `/companies/myhospitals/${hospitalId}/schedules/${groupId}/vacancies/userscompany`
    const params = {
      isActive,
      name,
      offset,
      limit
    }
    return await request.get(url, params)
  }

  async getCompanyConfigSchedule() {
    const url = '/companies/config/schedule'
    return await request.get(url)
  }

  async getCompanyConfigScheduleById(configId: number) {
    const url = `/companies/config/schedule/${configId}`
    return await request.get(url)
  }

  async getCompanyHospitalContracts(hospitalId: number) {
    const url = `/companies/myhospitals/${hospitalId}/contracts/`
    return await request.get(url)
  }

  async getCompanyHospitalContractsPartialInfo(hospitalId: number) {
    const url = `/companies/myhospitals/${hospitalId}/contracts/partialinfo`
    return await request.get(url)
  }

  async getCompanyUsersCompany(isActive = true, name?: string, limit?: number, offset?: number) {
    const url = '/companies/users'
    const params = {
      isActive,
      name,
      limit,
      offset
    }
    return await request.get(url, params)
  }

  async getCompanyUserCompanyById(userId: number) {
    const url = `/companies/users/${userId}`
    return await request.get(url)
  }

  async getPublicSpecialties(specialtyName?: string, limit?: number) {
    const url = '/allspecialties'
    const params = { name: specialtyName, limit }
    return await request.get(url, params)
  }

  async getCompanyHospitalCities(cityName?: string) {
    const url = '/companies/myhospitals/cities'
    const params = { name: cityName }
    return await request.get(url, params)
  }

  async getCompanyHospitals(isActive = true, sort = 'name', hospitalName?: string) {
    const url = '/companies/myhospitals'
    const params = { isActive, sort, name: hospitalName }
    return await request.get(url, params)
  }

  async getCompanyCompanyResume(startDate: string, endDate: string) {
    const url = '/companies/myhospitals/companyresume'
    const params = { startDate, endDate }
    return await request.get(url, params)
  }

  async getCompanyCompanyResumeDetails(
    startDate: string,
    endDate: string,
    type: string,
    limit?: number,
    offset?: number
  ) {
    const url = '/companies/myhospitals/companyresume/details'
    const params = {
      startDate,
      endDate,
      type,
      limit,
      offset
    }
    return await request.get(url, params)
  }

  async getCompanyHospitalResume(
    hospitalId: number,
    startDate: string,
    endDate: string,
    contractId?: number
  ) {
    const url = `/companies/myhospitals/${hospitalId}/resume`
    const params = { startDate, endDate, contract: contractId }
    return await request.get(url, params)
  }

  async getCompanyHospitalResumeDetails(
    hospitalId: number,
    startDate: string,
    endDate: string,
    type: string,
    contractId?: number,
    limit?: number,
    offset?: number
  ) {
    const url = `/companies/myhospitals/${hospitalId}/resume/details`
    const params = {
      startDate,
      endDate,
      contract: contractId,
      type,
      limit,
      offset
    }
    return await request.get(url, params)
  }

  async getCompanyScheduleGroupByHospitalId(hospitalId: number) {
    const url = `/companies/myhospitals/${hospitalId}/schedulesgroup`
    return await request.get(url)
  }

  async getCompanyNotifications(limit?: number, offset?: number) {
    const url = '/companies/notifications'
    const params = { limit, offset }
    return await request.get(url, params)
  }

  async getCompanyCompanyProfile() {
    const url = '/companies/companyprofile'
    return await request.get(url)
  }

  async getCompanyHospitalById(hospitalId: number) {
    const url = `/companies/myhospitals/${hospitalId}`
    return await request.get(url)
  }

  async getCompanyDoctorsInvitesLink(
    order = 'DESC',
    crm?: string,
    crmUf?: string,
    limit?: number,
    offset?: number
  ) {
    const url = '/companies/mydoctors/invitelink'
    const params = {
      order,
      crm,
      crmUf,
      limit,
      offset
    }
    return await request.get(url, params)
  }

  async getCompanyGroup() {
    const url = '/companies/group'
    return await request.get(url)
  }

  async getCompanyHomePages() {
    const url = '/companies/homepages'
    return await request.get(url)
  }

  async getCompanyCompanyPermissions() {
    const url = '/companies/companypermissions'
    return await request.get(url)
  }

  async getCompanyHospitalPermissions() {
    const url = '/companies/hospitalpermissions'
    return await request.get(url)
  }

  async getCompanyReportsCostsSchedules(
    startDate: string,
    endDate: string,
    hospital: number,
    limit?: number,
    offset?: number
  ) {
    const url = '/companies/reports/costs/schedules'
    const params = {
      startDate,
      endDate,
      hospital,
      limit,
      offset
    }
    return await request.get(url, params)
  }

  async getCompanyReportsMonthlyResumeCost(month: number, year: number) {
    const url = '/companies/reports/monthlyresumecost'
    const params = { month, year }
    return await request.get(url, params)
  }

  async getCompanyReportsYearlyResumeCost(year: number) {
    const url = '/companies/reports/yearlyresumecost'
    const params = { year }
    return await request.get(url, params)
  }

  async getCompanyProtocolsByHospitalId(hospitalId: number, startName?: string, limit?: number) {
    const url = `/companies/myhospitals/${hospitalId}/protocols`
    const params = { startName, limit }
    return await request.get(url, params)
  }

  async getCompanySchedulesBySpecialtyId(hospitalId: number, specialtyId: number) {
    const url = `/companies/myhospitals/${hospitalId}/specialties/${specialtyId}/schedules`
    return await request.get(url)
  }

  async getCompanyContractGroups(
    hospitalId: number,
    sort = 'name',
    order = 'ASC',
    limit?: number,
    offset?: number
  ) {
    const url = `/companies/myhospitals/${hospitalId}/contracts-groups`
    const params = {
      sort,
      order,
      limit,
      offset
    }
    return await request.get(url, params)
  }

  async getPublicCheckCrm(crm: string, crmUf: string) {
    const url = `/checkcrm?crm=${crm}&uf=${crmUf}`
    return await request.get(url)
  }

  async getPublicInfoRecoveryPassword(userId: number, userKey: string) {
    const url = `/recovery-password/${userId}/${userKey}`
    return await request.get(url)
  }

  async postCompanyCreateObservation(
    hospitalId: number,
    scheduleId: number,
    vacancyId: number,
    observation: string
  ) {
    const url = `/companies/myhospitals/${hospitalId}/schedules/${scheduleId}/vacancies/${vacancyId}/observations`
    const data = { observation }
    return await request.post(url, data)
  }

  async postAvatar(avatarFile: string) {
    const url = '/avatar/'
    const data = new FormData()

    data.append('file', avatarFile)

    return await request.post(url, data)
  }

  async postLogin(email: string, password: string) {
    const url = 'login'
    const data = { email, password }

    return await request.post(url, data)
  }

  async postConfirmEmail(email: string) {
    const url = '/confirm-email'
    const data = { email }

    return await request.post(url, data)
  }

  async postCreateCompany(data: createCompanyProtocol) {
    const url = 'companies'

    return await request.post(url, data)
  }

  async postCreateDoctor(data: createDoctorProtocol) {
    const url = 'doctors'

    return await request.post(url, data)
  }

  async postCreateInviteLinkToDoctors(data: createInviteDoctorLinkProtocol) {
    const url = '/companies/mydoctors/invitelink'

    return await request.post(url, data)
  }

  async postCreateHospital(data: createHospitalProtocol) {
    const url = '/companies/myhospitals'

    return await request.post(url, data)
  }

  async postCreateContract(data: createContractProtocol) {
    const url = `/companies/myhospitals/${data.hospitalId}/contracts`

    return await request.post(url, data)
  }

  async postCreateContractTaxDeductions(data: createContractTaxDeductionProtocol) {
    const url = `/companies/myhospitals/${data.hospitalId}/contracts/${data.contractId}/taxdeductions`

    return await request.post(url, data)
  }
}

export default new Network()
