<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.75 12C2.75 6.89324 6.88519 2.75 11.99 2.75C17.1063 2.75 21.25 6.8947 21.25 12C21.25 17.1053 17.1063 21.25 11.99 21.25C6.88519 21.25 2.75 17.1068 2.75 12Z"
      stroke="#DA1E28"
      stroke-width="1.5"
    />
    <path d="M9 9L15 15" stroke="#DA1E28" stroke-width="1.5" stroke-linecap="round" />
    <path d="M15 9L9 15" stroke="#DA1E28" stroke-width="1.5" stroke-linecap="round" />
  </svg>
</template>
