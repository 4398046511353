<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.6 5.49939C5.6 5.00233 6.00294 4.59939 6.5 4.59939H17.5C17.9971 4.59939 18.4 5.00233 18.4 5.49939C18.4 5.99645 17.9971 6.39939 17.5 6.39939H6.5C6.00294 6.39939 5.6 5.99645 5.6 5.49939Z"
      :stroke="color"
      stroke-width="1.2"
    />
    <path
      d="M9.6 3.99951C9.6 3.22631 10.2268 2.59951 11 2.59951H13C13.7732 2.59951 14.4 3.22631 14.4 3.99951V4.39951H9.6V3.99951Z"
      :stroke="color"
      stroke-width="1.2"
    />
    <mask id="path-3-inside-1_727_62260" fill="white">
      <path
        d="M18 7.99951H6V19.9995C6 21.0995 6.9 21.9995 8 21.9995H16C17.1 21.9995 18 21.0995 18 19.9995V7.99951Z"
      />
    </mask>
    <path
      d="M18 7.99951H19.2V6.79951H18V7.99951ZM6 7.99951V6.79951H4.8V7.99951H6ZM18 6.79951H6V9.19951H18V6.79951ZM4.8 7.99951V19.9995H7.2V7.99951H4.8ZM4.8 19.9995C4.8 21.7623 6.23726 23.1995 8 23.1995V20.7995C7.56274 20.7995 7.2 20.4368 7.2 19.9995H4.8ZM8 23.1995H16V20.7995H8V23.1995ZM16 23.1995C17.7627 23.1995 19.2 21.7623 19.2 19.9995H16.8C16.8 20.4368 16.4373 20.7995 16 20.7995V23.1995ZM19.2 19.9995V7.99951H16.8V19.9995H19.2Z"
      :fill="color"
      mask="url(#path-3-inside-1_727_62260)"
    />
    <path
      d="M9.6 11.9996C9.6 11.6683 9.33137 11.3996 9 11.3996C8.66863 11.3996 8.4 11.6683 8.4 11.9996H9.6ZM8.4 17.4996C8.4 17.831 8.66863 18.0996 9 18.0996C9.33137 18.0996 9.6 17.831 9.6 17.4996H8.4ZM8.4 11.9996V17.4996H9.6V11.9996H8.4Z"
      :fill="color"
    />
    <path
      d="M12.6 11.9996C12.6 11.6683 12.3314 11.3996 12 11.3996C11.6686 11.3996 11.4 11.6683 11.4 11.9996H12.6ZM11.4 17.4996C11.4 17.831 11.6686 18.0996 12 18.0996C12.3314 18.0996 12.6 17.831 12.6 17.4996H11.4ZM11.4 11.9996V17.4996H12.6V11.9996H11.4Z"
      :fill="color"
    />
    <path
      d="M15.6 11.9996C15.6 11.6683 15.3314 11.3996 15 11.3996C14.6686 11.3996 14.4 11.6683 14.4 11.9996H15.6ZM14.4 17.4996C14.4 17.831 14.6686 18.0996 15 18.0996C15.3314 18.0996 15.6 17.831 15.6 17.4996H14.4ZM14.4 11.9996V17.4996H15.6V11.9996H14.4Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#52526F'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
