<template>
  <div>
    <div
      v-show="modalActive"
      :class="`modal z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>

      <div
        class="container-modal z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container max-h-[80vh]"
      >
        <div
          @click="close"
          class="absolute top-0 md:flex hidden right-0 z-50 flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <div class="px-6 py-4 text-left modal-content">
          <div class="flex items-center justify-between pb-3">
            <p class="titleBold">Grupos de usuários</p>
            <div class="z-50 cursor-pointer modal-close" @click="close">
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <div class="flex flex-col">
            <GroupList
              v-for="(g, index) in groups.map((hospital) => hospital.name)"
              :key="index"
              :group="g"
            />
          </div>

          <div class="flex justify-end mt-10">
            <OutlineButton @click="close" class="mr-4" text="Cancelar" width="120px" />
            <FilledButton text="Criar grupo" width="150px" @click="() => (modalState = true)" />
          </div>
        </div>
      </div>
    </div>
    <CreateGroupModal :modalStateProp="modalState" @close="() => (modalState = false)" />
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, toRefs, ref, computed } from 'vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import { useStore } from 'vuex'
import GroupList from '../GroupList.vue'
import CreateGroupModal from '../CreateGroupModal/index.vue'

const store = useStore()
const groups = computed(() => store.getters.getGroups)
const emit = defineEmits(['close'])
const props = defineProps(['modalActive'])
const modalState = ref(false)
const { modalActive } = toRefs(props)

const close = () => {
  emit('close')
}
</script>

<style scoped>
.modal {
  transition: opacity 0.25s ease;
}

.container-modal {
  width: 700px;
}

.titleBold {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 700;
}
</style>
