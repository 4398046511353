import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_SearchIcon = _resolveComponent("SearchIcon")!
  const _component_GenericInput = _resolveComponent("GenericInput")!

  return (_openBlock(), _createBlock(_component_GenericInput, _mergeProps({ ..._ctx.$props, ..._ctx.$attrs }, {
    value: _ctx.modelValue,
    type: "search",
    onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('validate', _ctx.modelValue))),
    onKeyup: _ctx.handleKeyUp,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
  }), _createSlots({
    "append-inner": _withCtx(() => [
      _createVNode(_component_SearchIcon)
    ]),
    _: 2
  }, [
    (_ctx.loading)
      ? {
          name: "loader",
          fn: _withCtx(() => [
            _createVNode(_component_v_progress_linear, {
              class: "mt[6px]",
              active: true,
              color: _ctx.colors.primary20,
              height: "5",
              indeterminate: ""
            }, null, 8, ["color"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1040, ["value", "onKeyup"]))
}