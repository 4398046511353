<template>
  <div class="w-9/12 m-auto py-16 flex items-center justify-center">
    <div class="bg-white overflow-hidden">
      <div class="flex flex-col items-center justify-center">
        <h1 class="text-md font-bold py-8">Nenhum protocolo encontrado</h1>
        <p v-show="description" class="text-1xl pb-8 px-12 font-medium">
          {{ description }}
        </p>

        <svg
          width="218"
          height="216"
          viewBox="0 0 218 216"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_843_66884)">
            <path
              d="M143.635 57.2152H108.018C107.238 57.2175 106.468 57.042 105.766 56.7019C105.065 56.3619 104.45 55.8662 103.968 55.2528C103.478 54.6335 103.136 53.9106 102.968 53.1391C102.799 52.3675 102.809 51.5678 102.996 50.8006C106.279 37.0512 106.279 22.7221 102.996 8.97271C102.809 8.20557 102.799 7.40584 102.968 6.63438C103.136 5.86292 103.478 5.14005 103.968 4.52078C104.45 3.90726 105.064 3.41161 105.766 3.07155C106.468 2.73149 107.238 2.55597 108.018 2.55837H143.635C144.864 2.56191 146.053 3.00183 146.989 3.79979C147.924 4.59774 148.546 5.70192 148.744 6.91566C151.088 22.14 151.088 37.6335 148.744 52.8579C148.546 54.0716 147.924 55.1758 146.989 55.9737C146.053 56.7717 144.864 57.2116 143.635 57.2152Z"
              fill="#E6E6E6"
            />
            <path
              d="M108.017 5.2383C107.643 5.23704 107.273 5.3212 106.936 5.48439C106.599 5.64758 106.304 5.88549 106.072 6.18002C105.834 6.4818 105.668 6.8339 105.587 7.20956C105.505 7.58521 105.51 7.97452 105.602 8.34787C108.983 22.5081 108.983 37.2654 105.602 51.4256C105.51 51.7989 105.505 52.1881 105.587 52.5637C105.668 52.9393 105.834 53.2913 106.072 53.593L106.073 53.5933C106.304 53.8879 106.599 54.1258 106.936 54.2891C107.273 54.4523 107.643 54.5365 108.017 54.5352H143.634C144.225 54.5354 144.796 54.326 145.247 53.9443C145.697 53.5625 145.998 53.0332 146.095 52.4507C148.397 37.4962 148.397 22.2773 146.095 7.32282C145.998 6.74026 145.697 6.21099 145.247 5.82925C144.796 5.44751 144.225 5.23809 143.634 5.23829L108.017 5.2383Z"
              fill="white"
            />
            <path
              d="M135.862 18.1247H117.172C116.462 18.1239 115.78 17.8413 115.278 17.3389C114.776 16.8365 114.493 16.1553 114.492 15.4448V14.3976C114.493 13.6871 114.776 13.0059 115.278 12.5035C115.78 12.0011 116.462 11.7185 117.172 11.7178H135.862C136.573 11.7186 137.254 12.0011 137.756 12.5035C138.259 13.0059 138.541 13.6871 138.542 14.3976V15.4449C138.541 16.1554 138.259 16.8365 137.756 17.3389C137.254 17.8413 136.573 18.1239 135.862 18.1247Z"
              fill="#E6E6E6"
            />
            <path
              d="M135.862 31.3573H117.172C116.462 31.3565 115.78 31.074 115.278 30.5716C114.776 30.0692 114.493 29.388 114.492 28.6775V27.6303C114.493 26.9198 114.776 26.2386 115.278 25.7362C115.78 25.2338 116.462 24.9512 117.172 24.9504H135.862C136.573 24.9512 137.254 25.2338 137.756 25.7362C138.259 26.2386 138.541 26.9198 138.542 27.6303V28.6775C138.541 29.388 138.259 30.0692 137.756 30.5716C137.254 31.074 136.573 31.3565 135.862 31.3573Z"
              fill="#E6E6E6"
            />
            <path
              d="M135.862 44.5895H117.172C116.462 44.5887 115.78 44.3061 115.278 43.8037C114.776 43.3013 114.493 42.6202 114.492 41.9097V40.8624C114.493 40.1519 114.776 39.4708 115.278 38.9684C115.78 38.466 116.462 38.1834 117.172 38.1826H135.862C136.573 38.1834 137.254 38.466 137.756 38.9684C138.259 39.4708 138.541 40.1519 138.542 40.8624V41.9097C138.541 42.6202 138.259 43.3013 137.756 43.8037C137.254 44.3061 136.573 44.5887 135.862 44.5895Z"
              fill="#E6E6E6"
            />
            <path
              d="M210.633 91.161H175.016C174.236 91.1633 173.466 90.9878 172.765 90.6477C172.063 90.3077 171.448 89.812 170.967 89.1986C170.477 88.5793 170.134 87.8564 169.966 87.0849C169.797 86.3134 169.807 85.5136 169.995 84.7464C173.277 70.997 173.277 56.6679 169.994 42.9185C169.807 42.1514 169.798 41.3516 169.966 40.5802C170.134 39.8087 170.477 39.0859 170.967 38.4666C171.448 37.8531 172.063 37.3574 172.764 37.0173C173.466 36.6773 174.236 36.5018 175.016 36.5042H210.633C211.863 36.5077 213.051 36.9476 213.987 37.7456C214.922 38.5435 215.545 39.6477 215.742 40.8615C218.086 56.0858 218.086 71.5793 215.742 86.8037C215.545 88.0174 214.922 89.1216 213.987 89.9195C213.051 90.7175 211.863 91.1574 210.633 91.161Z"
              fill="#E6E6E6"
            />
            <path
              d="M175.016 39.1841C174.641 39.1828 174.271 39.267 173.934 39.4302C173.597 39.5934 173.302 39.8313 173.071 40.1258C172.833 40.4276 172.666 40.7797 172.585 41.1554C172.503 41.531 172.509 41.9203 172.6 42.2937C175.981 56.4539 175.981 71.2112 172.6 85.3714C172.509 85.7447 172.503 86.1339 172.585 86.5095C172.666 86.8851 172.833 87.2371 173.071 87.5388L173.071 87.5391C173.302 87.8337 173.597 88.0716 173.934 88.2349C174.271 88.3981 174.641 88.4823 175.016 88.481H210.633C211.223 88.4812 211.795 88.2718 212.245 87.8901C212.696 87.5083 212.996 86.979 213.093 86.3965C215.396 71.442 215.396 56.2231 213.093 41.2686C212.996 40.6861 212.696 40.1568 212.245 39.775C211.795 39.3933 211.223 39.1839 210.633 39.1841L175.016 39.1841Z"
              fill="white"
            />
            <path
              d="M139.614 129.797H103.997C103.184 129.799 102.381 129.616 101.649 129.261C100.916 128.906 100.275 128.389 99.7729 127.749C99.2619 127.103 98.9049 126.35 98.7292 125.545C98.5535 124.741 98.5637 123.907 98.7589 123.107C102.033 109.391 102.033 95.0981 98.7587 81.3829C98.5636 80.583 98.5535 79.749 98.7292 78.9446C98.9049 78.1401 99.2618 77.3863 99.7728 76.7406C100.275 76.1005 100.916 75.5834 101.648 75.2286C102.381 74.8738 103.184 74.6907 103.997 74.6931H139.614C140.897 74.697 142.137 75.1561 143.113 75.9887C144.089 76.8213 144.738 77.9734 144.944 79.2397C147.292 94.4866 147.292 110.003 144.944 125.25C144.738 126.516 144.089 127.668 143.113 128.501C142.137 129.334 140.897 129.793 139.614 129.797Z"
              fill="#F2F2F2"
            />
            <path
              d="M202.86 58.7702H184.17C183.459 58.7694 182.778 58.4868 182.276 57.9844C181.773 57.482 181.491 56.8008 181.49 56.0903V55.0431C181.491 54.3326 181.773 53.6514 182.276 53.149C182.778 52.6466 183.459 52.3641 184.17 52.3633H202.86C203.571 52.3641 204.252 52.6466 204.754 53.149C205.257 53.6514 205.539 54.3326 205.54 55.0431V56.0904C205.539 56.8009 205.257 57.482 204.754 57.9844C204.252 58.4868 203.571 58.7694 202.86 58.7702Z"
              fill="#E6E6E6"
            />
            <path
              d="M202.86 72.0028H184.17C183.459 72.0021 182.778 71.7195 182.276 71.2171C181.773 70.7147 181.491 70.0335 181.49 69.323V68.2758C181.491 67.5653 181.773 66.8841 182.276 66.3817C182.778 65.8793 183.459 65.5967 184.17 65.5959H202.86C203.571 65.5967 204.252 65.8793 204.754 66.3817C205.257 66.8841 205.539 67.5653 205.54 68.2758V69.323C205.539 70.0335 205.257 70.7147 204.754 71.2171C204.252 71.7195 203.571 72.0021 202.86 72.0028Z"
              fill="#E6E6E6"
            />
            <path
              d="M121.906 112.334C127.579 112.334 132.179 107.734 132.179 102.061C132.179 96.387 127.579 91.7876 121.906 91.7876C116.232 91.7876 111.633 96.387 111.633 102.061C111.633 107.734 116.232 112.334 121.906 112.334Z"
              fill="white"
            />
            <path
              d="M126.819 100.721H123.246V97.1473C123.246 96.792 123.105 96.4511 122.853 96.1998C122.602 95.9486 122.261 95.8074 121.906 95.8074C121.55 95.8074 121.21 95.9486 120.958 96.1998C120.707 96.4511 120.566 96.792 120.566 97.1473V100.721H116.993C116.637 100.721 116.296 100.862 116.045 101.113C115.794 101.364 115.653 101.705 115.653 102.061C115.653 102.416 115.794 102.757 116.045 103.008C116.296 103.259 116.637 103.401 116.993 103.401H120.566V106.974C120.566 107.329 120.707 107.67 120.958 107.921C121.21 108.173 121.55 108.314 121.906 108.314C122.261 108.314 122.602 108.173 122.853 107.921C123.105 107.67 123.246 107.329 123.246 106.974V103.401H126.819C127.174 103.401 127.515 103.259 127.766 103.008C128.018 102.757 128.159 102.416 128.159 102.061C128.159 101.705 128.018 101.364 127.766 101.113C127.515 100.862 127.174 100.721 126.819 100.721Z"
              fill="#E6E6E6"
            />
            <path
              d="M134.859 7.59313H116.1C115.567 7.59253 115.056 7.38058 114.679 7.00377C114.302 6.62696 114.09 6.11607 114.09 5.58319V2.00995C114.09 1.47706 114.302 0.966171 114.679 0.589363C115.056 0.212555 115.567 0.000600554 116.1 0H134.859C135.392 0.000600554 135.903 0.212555 136.28 0.589363C136.656 0.966171 136.868 1.47706 136.869 2.00995V5.58319C136.868 6.11607 136.656 6.62696 136.28 7.00377C135.903 7.38058 135.392 7.59253 134.859 7.59313Z"
              fill="#CCCCCC"
            />
            <path
              d="M202.75 41.9855H183.991C183.458 41.9849 182.947 41.7729 182.57 41.3961C182.193 41.0193 181.981 40.5084 181.981 39.9755V36.4023C181.981 35.8694 182.193 35.3585 182.57 34.9817C182.947 34.6049 183.458 34.3929 183.991 34.3923H202.75C203.283 34.3929 203.794 34.6049 204.171 34.9817C204.548 35.3585 204.76 35.8694 204.76 36.4023V39.9755C204.76 40.5084 204.548 41.0193 204.171 41.3961C203.794 41.7729 203.283 41.9849 202.75 41.9855Z"
              fill="#CCCCCC"
            />
            <path
              d="M40.0719 210.402H45.5479L47.26 189.28H40.071L40.0719 210.402Z"
              fill="#FFB8B8"
            />
            <path
              d="M38.675 208.614L49.459 208.614H49.4594C50.3619 208.614 51.2556 208.791 52.0894 209.137C52.9232 209.482 53.6808 209.988 54.319 210.626C54.9572 211.264 55.4634 212.022 55.8088 212.856C56.1541 213.69 56.3319 214.583 56.3319 215.486V215.709L38.6754 215.71L38.675 208.614Z"
              fill="#2F2E41"
            />
            <path
              d="M10.5927 210.402H16.0687L18.674 189.28H10.5918L10.5927 210.402Z"
              fill="#FFB8B8"
            />
            <path
              d="M9.19629 208.614L19.9802 208.614H19.9807C20.8832 208.614 21.7768 208.791 22.6107 209.137C23.4445 209.482 24.2021 209.988 24.8403 210.626C25.4784 211.264 25.9846 212.022 26.33 212.856C26.6754 213.69 26.8531 214.583 26.8531 215.486V215.709L9.19663 215.71L9.19629 208.614Z"
              fill="#2F2E41"
            />
            <path
              d="M11.5641 203.729C11.0055 203.729 10.4674 203.519 10.0566 203.14C9.83614 202.939 9.65856 202.696 9.53455 202.424C9.41054 202.153 9.34265 201.859 9.33496 201.561L7.13892 122.854L38.3204 130.649L47.9412 159.511C49.1818 163.224 49.7188 167.136 49.5247 171.045L48.3301 199.022C48.3029 199.596 48.0559 200.136 47.6405 200.532C47.2251 200.928 46.6731 201.148 46.0994 201.148H40.8695C40.3297 201.146 39.8087 200.949 39.4022 200.594C38.9958 200.239 38.7309 199.749 38.6564 199.214L34.9184 168.332C34.5917 165.922 33.749 163.612 32.4476 161.559L25.0914 149.912C25.0422 149.834 24.9702 149.773 24.8852 149.738C24.8002 149.702 24.7064 149.694 24.6166 149.714C24.5267 149.734 24.4453 149.782 24.3834 149.85C24.3214 149.918 24.2821 150.003 24.2706 150.095L18.4978 201.5C18.4358 202.021 18.1919 202.504 17.8089 202.863C17.426 203.222 16.9286 203.435 16.4043 203.463L11.6912 203.725C11.6488 203.728 11.6065 203.729 11.5641 203.729Z"
              fill="#2F2E41"
            />
            <path
              d="M33.1317 71.8967C39.1904 71.8967 44.102 66.9851 44.102 60.9264C44.102 54.8676 39.1904 49.9561 33.1317 49.9561C27.073 49.9561 22.1614 54.8676 22.1614 60.9264C22.1614 66.9851 27.073 71.8967 33.1317 71.8967Z"
              fill="#FFB8B8"
            />
            <path
              d="M38.1711 133.85C37.9823 133.85 37.7943 133.826 37.6115 133.779H37.6111L6.67389 125.847C6.12076 125.705 5.64292 125.357 5.33862 124.874C5.03432 124.39 4.92668 123.809 5.03785 123.249L12.1428 84.9748C12.5855 82.7624 13.5585 80.6904 14.9783 78.9369C16.3981 77.1834 18.2223 75.8006 20.2942 74.9074C22.313 74.0139 24.5207 73.631 26.7225 73.7927C28.9243 73.9543 31.0525 74.6555 32.9192 75.8343C33.3523 76.1045 33.7886 76.3991 34.2156 76.7101C35.9347 77.9783 37.3367 79.6275 38.3115 81.5284C39.2863 83.4293 39.8074 85.5302 39.834 87.6663L40.399 131.585C40.4028 131.881 40.348 132.174 40.2379 132.448C40.1277 132.722 39.9643 132.972 39.7572 133.182C39.55 133.393 39.3032 133.56 39.031 133.675C38.7587 133.789 38.4665 133.849 38.1711 133.85Z"
              fill="#2F7F91"
            />
            <path
              d="M9.85711 134.3C9.48806 133.757 9.24257 133.14 9.13795 132.491C9.03332 131.843 9.07211 131.18 9.25156 130.548C9.43101 129.917 9.74676 129.332 10.1766 128.836C10.6064 128.339 11.1399 127.943 11.7393 127.675L10.3633 111.773L17.6554 115.726L17.8441 130.22C18.2275 131.257 18.2172 132.399 17.8151 133.429C17.4131 134.458 16.6473 135.305 15.6627 135.808C14.6782 136.311 13.5435 136.435 12.4735 136.157C11.4035 135.879 10.4725 135.218 9.85712 134.3H9.85711Z"
              fill="#FFB8B8"
            />
            <path
              d="M12.4621 125.572C12.2564 125.572 12.0515 125.546 11.8521 125.495C11.4234 125.386 11.0322 125.162 10.7199 124.849C10.4076 124.536 10.1858 124.143 10.078 123.714L6.49445 109.473C5.24207 104.5 5.83909 99.2401 8.17442 94.6735L15.6822 79.9867C16.1568 78.2283 17.3035 76.7268 18.875 75.8061C20.4465 74.8855 22.317 74.6194 24.0829 75.0652C24.9631 75.2875 25.7904 75.6822 26.5169 76.2267C27.2433 76.7711 27.8545 77.4543 28.3149 78.2367C28.7848 79.0303 29.0921 79.9095 29.2189 80.823C29.3458 81.7365 29.2896 82.6661 29.0537 83.5577L18.3664 107.456L18.4933 121.765C18.4966 122.27 18.3448 122.764 18.0585 123.179C17.7721 123.595 17.3651 123.913 16.8923 124.09L13.3157 125.418C13.0426 125.52 12.7536 125.572 12.4621 125.572Z"
              fill="#2F7F91"
            />
            <path
              d="M63.6202 128.451C62.9678 128.376 62.3397 128.16 61.7802 127.816C61.2206 127.472 60.7432 127.01 60.3815 126.462C60.0198 125.914 59.7827 125.294 59.6869 124.644C59.591 123.994 59.6388 123.332 59.8268 122.703L46.8767 113.372L54.6291 110.424L65.7382 119.736C66.7741 120.122 67.6326 120.874 68.1509 121.851C68.6691 122.827 68.8112 123.96 68.5501 125.034C68.289 126.108 67.643 127.049 66.7344 127.679C65.8258 128.309 64.7177 128.583 63.6202 128.451H63.6202Z"
              fill="#FFB8B8"
            />
            <path
              d="M56.8411 121.638C56.4593 121.637 56.0828 121.549 55.7412 121.378L42.6099 114.804C38.0234 112.509 34.4266 108.626 32.4884 103.877L26.2543 88.6056C25.2313 87.0986 24.8414 85.25 25.1687 83.4583C25.4961 81.6665 26.5144 80.0752 28.0042 79.0273C28.7486 78.504 29.5896 78.134 30.4784 77.9389C31.3672 77.7439 32.286 77.7277 33.1811 77.8913C34.0867 78.0541 34.951 78.3951 35.7239 78.8944C36.4968 79.3937 37.163 80.0414 37.6837 80.8L48.8256 104.49L59.7533 113.728C60.1381 114.055 60.4133 114.492 60.5417 114.98C60.67 115.469 60.6453 115.984 60.471 116.458L59.1445 120.035C58.9713 120.504 58.6585 120.909 58.2481 121.195C57.8356 121.483 57.3444 121.638 56.8411 121.638Z"
              fill="#2F7F91"
            />
            <path
              d="M30.8276 72.0648C31.4105 72.3199 32.6041 67.6754 32.0694 66.7465C31.2743 65.365 31.3213 65.3749 30.7934 64.461C30.2656 63.547 30.1475 62.296 30.831 61.4918C31.5146 60.6876 33.0965 60.7942 33.4084 61.8025C33.2077 59.8874 35.1021 58.3479 36.9785 57.9153C38.855 57.4826 40.8343 57.7512 42.721 57.3662C44.9107 56.9194 47.189 55.084 46.3154 52.6761C46.1654 52.2752 45.941 51.9063 45.6538 51.5889C44.6444 50.4539 43.2324 49.9693 41.8519 49.5008C38.9799 48.5259 36.0293 47.5389 32.9983 47.6465C30.5008 47.7771 28.0956 48.6329 26.077 50.1091C24.0584 51.5854 22.5139 53.618 21.6325 55.9584C21.4236 56.5439 21.2618 57.1451 21.1486 57.7564C20.1082 63.3504 23.3567 68.8682 28.6328 70.9984L30.8276 72.0648Z"
              fill="#2F2E41"
            />
            <path
              opacity="0.2"
              d="M17.8748 122.09L18.1235 107.382L25.9145 91.7173L19.6614 107.797L17.8748 122.09Z"
              fill="black"
            />
            <path
              d="M88.5254 159.001L55.6345 145.335C54.9135 145.038 54.2698 144.581 53.7523 143.998C53.2348 143.414 52.8571 142.721 52.6481 141.97C52.4332 141.21 52.3945 140.411 52.5349 139.634C52.6754 138.857 52.9912 138.122 53.4586 137.485C61.7654 126.048 67.2631 112.815 69.507 98.8585C69.6286 98.0783 69.9264 97.336 70.378 96.6883C70.8296 96.0405 71.423 95.5043 72.113 95.1204C72.7929 94.7385 73.5509 94.5167 74.3293 94.4718C75.1078 94.427 75.8862 94.5604 76.6054 94.8618L109.496 108.527C110.631 109.003 111.559 109.865 112.117 110.961C112.675 112.057 112.826 113.315 112.543 114.512C108.866 129.47 102.921 143.778 94.9155 156.938C94.2673 157.983 93.2692 158.764 92.0989 159.141C90.9287 159.519 89.6623 159.47 88.5254 159.001Z"
              fill="#E6E6E6"
            />
            <path
              d="M75.5774 97.3367C75.2321 97.1918 74.8583 97.1277 74.4844 97.149C74.1106 97.1704 73.7465 97.2768 73.42 97.4601C73.0843 97.6474 72.7957 97.9088 72.5763 98.2244C72.3569 98.54 72.2123 98.9015 72.1536 99.2814C69.8426 113.655 64.1805 127.283 55.6255 139.062C55.3977 139.372 55.2435 139.729 55.1747 140.107C55.1058 140.485 55.1241 140.874 55.2282 141.244L55.2284 141.245C55.3288 141.605 55.5102 141.938 55.7588 142.218C56.0074 142.498 56.3167 142.718 56.663 142.861L89.5539 156.526C90.0992 156.753 90.7073 156.779 91.2698 156.599C91.8324 156.42 92.313 156.046 92.6259 155.545C100.49 142.619 106.329 128.565 109.941 113.871C110.075 113.296 110 112.692 109.731 112.167C109.461 111.641 109.014 111.229 108.468 111.002L75.5774 97.3367Z"
              fill="white"
            />
            <path
              d="M93.09 127.757L75.8301 120.586C75.1743 120.313 74.6537 119.791 74.3826 119.134C74.1114 118.477 74.1118 117.74 74.3836 117.083L74.7855 116.116C75.0588 115.46 75.5811 114.94 76.2378 114.669C76.8945 114.397 77.632 114.398 78.2884 114.67L95.5482 121.841C96.204 122.114 96.7246 122.637 96.9958 123.293C97.267 123.95 97.2666 124.687 96.9947 125.344L96.5929 126.311C96.3196 126.967 95.7973 127.487 95.1406 127.759C94.4838 128.03 93.7464 128.029 93.09 127.757Z"
              fill="#E6E6E6"
            />
            <path
              d="M88.0131 139.977L70.7532 132.806C70.0974 132.532 69.5768 132.01 69.3056 131.353C69.0345 130.697 69.0349 129.959 69.3067 129.303L69.7086 128.336C69.9819 127.68 70.5042 127.159 71.1609 126.888C71.8176 126.617 72.5551 126.617 73.2115 126.889L90.4713 134.06C91.1271 134.334 91.6477 134.856 91.9189 135.513C92.1901 136.169 92.1897 136.907 91.9178 137.563L91.516 138.53C91.2427 139.186 90.7204 139.707 90.0637 139.978C89.407 140.249 88.6695 140.249 88.0131 139.977Z"
              fill="#E6E6E6"
            />
            <path
              d="M100.007 110.584C99.7482 110.583 99.4923 110.533 99.253 110.436L81.8611 103.404C81.3673 103.204 80.9731 102.816 80.765 102.326C80.5569 101.835 80.5519 101.282 80.751 100.788L82.0904 97.4749C82.2903 96.9807 82.6783 96.5862 83.169 96.3781C83.6597 96.1699 84.213 96.1652 84.7072 96.365L102.099 103.397C102.593 103.597 102.987 103.985 103.195 104.475C103.403 104.966 103.408 105.519 103.209 106.013L101.87 109.326C101.719 109.697 101.462 110.015 101.13 110.239C100.798 110.463 100.407 110.583 100.007 110.584Z"
              fill="#CCCCCC"
            />
            <path
              d="M63.4783 215.958H0.946655C0.828195 215.958 0.714593 215.91 0.630829 215.827C0.547065 215.743 0.5 215.629 0.5 215.511C0.5 215.392 0.547065 215.279 0.630829 215.195C0.714593 215.111 0.828195 215.064 0.946655 215.064H63.4783C63.5968 215.064 63.7104 215.111 63.7942 215.195C63.8779 215.279 63.925 215.392 63.925 215.511C63.925 215.629 63.8779 215.743 63.7942 215.827C63.7104 215.91 63.5968 215.958 63.4783 215.958Z"
              fill="#CCCCCC"
            />
          </g>
          <defs>
            <clipPath id="clip0_843_66884">
              <rect width="217" height="215.958" fill="white" transform="translate(0.5)" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyList'
}
</script>

<style>
.text-9xl {
  font-size: 14rem;
}
@media (max-width: 645px) {
  .text-9xl {
    font-size: 5.75rem;
  }
  .text-6xl {
    font-size: 1.75rem;
  }
  .text-2xl {
    font-size: 1rem;
    line-height: 1.2rem;
  }
  .py-8 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .px-6 {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
  .mr-6 {
    margin-right: 0.5rem;
  }
  .px-12 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
