import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b5a10bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full flex flex-col gap-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_legends_group = _resolveComponent("legends-group")!
  const _component_filled_button = _resolveComponent("filled-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_ghost_button = _resolveComponent("ghost-button")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_filters = _resolveComponent("filters")!
  const _component_DashboardView = _resolveComponent("DashboardView")!
  const _component_update_station_modal = _resolveComponent("update-station-modal")!
  const _component_create_station_modal = _resolveComponent("create-station-modal")!
  const _component_export_excel_modal = _resolveComponent("export-excel-modal")!
  const _component_export_pdf_modal = _resolveComponent("export-pdf-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_legends_group),
      _createVNode(_component_v_row, { dense: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "6",
            md: "3",
            lg: "2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, { to: "/gerenciar-escala" }, {
                default: _withCtx(() => [
                  _createVNode(_component_filled_button, {
                    density: "compact",
                    width: "100%",
                    text: "Gerenciar escalas"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "6",
            md: "3",
            lg: "2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ghost_button, {
                density: "compact",
                width: "100%",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showCreateStationModal = true)),
                text: "Criar Plantão"
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "6",
            md: "3",
            lg: "2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ghost_button, {
                onClick: $setup.toggleExportModal,
                density: "compact",
                width: "100%"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    icon: $setup.mdiMicrosoftExcel,
                    size: 20,
                    class: "cursor-pointer"
                  }, null, 8, ["icon"]),
                  _createTextVNode(" Baixar Excel ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "6",
            md: "3",
            lg: "2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ghost_button, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.modalStore.toggleModal($setup.EModals.EXPORT_DASHBOARD_PDF))),
                density: "compact",
                width: "100%"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    icon: $setup.mdiFilePdfBox,
                    size: 20,
                    class: "cursor-pointer"
                  }, null, 8, ["icon"]),
                  _createTextVNode(" Baixar PDF ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_filters),
      _createVNode(_component_DashboardView, {
        id: "dashboard",
        weekData: $setup.listStations,
        "week-table-head": $setup.listStations?.tableHead || [],
        selectedYearDay: $setup.selectedYearDay,
        onOpenDutyModal: $setup.handleUpdateStation
      }, null, 8, ["weekData", "week-table-head", "selectedYearDay", "onOpenDutyModal"])
    ]),
    ($setup.stationSelected)
      ? (_openBlock(), _createBlock(_component_update_station_modal, {
          key: 0,
          infos: $setup.stationSelected,
          showModal: $setup.showDutyModal,
          onCloseModal: _cache[2] || (_cache[2] = ($event: any) => ($setup.showDutyModal = false))
        }, null, 8, ["infos", "showModal"]))
      : _createCommentVNode("", true),
    _createVNode(_component_create_station_modal, {
      showModal: $setup.showCreateStationModal,
      onCloseModal: _cache[3] || (_cache[3] = ($event: any) => ($setup.showCreateStationModal = false))
    }, null, 8, ["showModal"]),
    _createVNode(_component_export_excel_modal),
    _createVNode(_component_export_pdf_modal)
  ], 64))
}