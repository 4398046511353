<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <v-dialog
    v-model="showAlertVModel"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar :color="options.color" dense flat>
        <v-toolbar-title class="font-weight-bold grey--text">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        style="margin-top: 1rem; margin-bottom: 1rem"
        v-show="!!message"
        v-html="message"
      ></v-card-text>
      <v-card-actions class="mt-4">
        <div class="footer-alert-confirm">
          <div>
            <FilledButton @click="cancel" width="120px" height="50px" color="error" text="Não" />
          </div>
          <div>
            <FilledButton @click="confirm" width="120px" height="50px" text="Sim" />
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'

export default defineComponent({
  name: 'AppSignin',
  components: {
    FilledButton
  },
  props: {
    title: {
      type: String,
      default: 'Confirmar'
    },
    message: {
      type: String,
      default: 'Tem certeza que deseja realizar está ação?'
    },
    showAlert: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => {
        return {
          color: 'grey lighten-3',
          width: 400,
          zIndex: 200,
          noconfirm: false
        }
      }
    }
  },
  setup(props, { emit }) {
    const showAlertVModel = ref(props.showAlert)

    const cancel = () => {
      emit('onCancel')
      showAlertVModel.value = false
    }

    const confirm = () => {
      emit('onConfirm')
    }

    return {
      confirm,
      cancel,
      showAlertVModel
    }
  }
})
</script>
<style scoped>
.footer-alert-confirm {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
