<template>
  <div class="flex flex-col gap-10">
    <legends-group />
    <breadcrumb-vue title="Editar unidade" subtitle="Unidades" />
    <div class="border-2 border-[#E2E2E9] rounded-[8px] p-4 -mt-2">
      <div class="flex gap-2 items-center">
        <img class="col-span-2 sm:col-span-3 shrink-0 w-[40px] h-[40px]" draggable="false"
          src="https://i.imgur.com/rj93B4I.png" />
        <div class="text-left overflow-hidden">
          <p class="truncate" :title="activeHospital?.name">{{ activeHospital?.name }}</p>
          <p>{{ activeHospital?.number }}</p>
        </div>
      </div>

      <!-- Hospital Form -->
      <div class="flex flex-col gap-2 mt-4">
        <div class="flex justify-between items-center cursor-pointer select-none"
          @click="() => (activeFormData = !activeFormData)">
          <p>Dados do hospital</p>
          <img draggable="false" :class="`${activeFormData ? 'rotate-180' : ''}`" src="@/assets/icons/down.svg"
            alt="Down icon" />
        </div>
        <div v-if="activeFormData">
          <div class="grid grid-cols-1 md:grid-cols-3 my-4 gap-4">
            <Input width="100%" v-model="form.name.value" :isError="!!form.name.error" :errorMessage="form.name.error"
              label="Nome" @keypress="() => validate(form.name.validation, 'name')"
              @blur="() => validate(form.name.validation, 'name')" required />

            <Input width="100%" v-model="form.social.value" :isError="!!form.social.error"
              :errorMessage="form.social.error" label="Razão social"
              @keypress="() => validate(form.social.value, 'social')"
              @blur="() => validate(form.social.validation, 'social')" required />

            <Input width="100%" v-model="form.cnpj.value" :isError="!!form.cnpj.error" :errorMessage="form.cnpj.error"
              label="CNPJ" mask="##.###.###/####-##" @keypress="() => validate(form.cnpj.validation, 'cnpj')"
              @blur="() => validate(form.cnpj.validation, 'cnpj')" required />

            <Input width="100%" v-model="form.email.value" :isError="!!form.email.error" :errorMessage="form.email.error"
              label="Email" pattern="[a-z0-9]+@[a-z]+\.[a-z]{2,3}"
              @keypress="() => validate(form.email.validation, 'email')"
              @blur="() => validate(form.email.validation, 'email')" required />

            <Input width="100%" v-model="form.phone1.value" :isError="!!form.phone1.error"
              :errorMessage="form.phone1.error" label="Telefone 01" mask="(##) #####-####"
              pattern="(\(\d{2}\)\s)(\d{4,5}-\d{4})" @keypress="() => validate(form.phone1.validation, 'phone1')"
              @blur="() => validate(form.phone1.validation, 'phone1')" required />

            <Input width="100%" v-model="form.phone2.value" :isError="!!form.phone2.error"
              :errorMessage="form.phone2.error" label="Telefone 02" mask="(##) #####-####"
              pattern="(\(\d{2}\)\s)(\d{4,5}-\d{4})" @keypress="() => validate(form.phone2.validation, 'phone2')"
              @blur="() => validate(form.phone2.validation, 'phone2')" required />

            <Input width="100%" v-model="form.geral.value" :isError="!!form.geral.error"
              :errorMessage="'Gerência geral é um campo obrigatório.'" label="Gerência geral"
              @keypress="() => validate(form.geral.validation, 'geral')"
              @blur="() => validate(form.geral.validation, 'geral')" required />

            <Input width="100%" v-model="form.technique.value" :isError="!!form.technique.error"
              :errorMessage="'Coordenação técnica é um campo obrigatório.'" label="Coordenação técnica"
              @keypress="() => validate(form.technique.validation, 'technique')"
              @blur="() => validate(form.technique.validation, 'technique')" required />

            <Input width="100%" v-model="form.total.value" :isError="!!form.total.error"
              :errorMessage="'Total de funcionários é um campo obrigatório.'" label="Total de funcionários"
              @keypress="() => validate(form.total.validation, 'total')"
              @blur="() => validate(form.total.validation, 'total')" required />

            <Input width="100%" v-model="form.perfil.value" :isError="!!form.perfil.error"
              :errorMessage="'Perfil é um campo obrigatório.'" label="Perfil"
              @keypress="() => validate(form.perfil.validation, 'perfil')"
              @blur="() => validate(form.perfil.validation, 'perfil')" required />
          </div>
        </div>
        <div class="flex justify-between items-center cursor-pointer select-none"
          @click="() => (activeAddress = !activeAddress)">
          <p>Endereço</p>
          <img draggable="false" :class="`${activeAddress ? 'rotate-180' : ''}`" src="@/assets/icons/down.svg"
            alt="Down icon" />
        </div>
        <div v-if="activeAddress">
          <div class="grid grid-cols-1 md:grid-cols-3 my-4 gap-4">
            <div class="col-span-1">
              <Input width="100%" v-model="form.cep.value" :isError="!!form.cep.error" :errorMessage="form.cep.error"
                label="CEP" mask="#####-###" @keypress="() => validate(form.cep.validation, 'cep')"
                @blur="() => validate(form.cep.validation, 'cep')" required />
            </div>

            <Input width="100%" v-model="form.uf.value" :isError="!!form.uf.error"
              :errorMessage="'UF é um campo obrigatório.'" label="UF" @keypress="() => validate(form.uf.validation, 'uf')"
              @blur="() => validate(form.uf.validation, 'uf')" required />

            <Input width="100%" v-model="form.city.value" :isError="!!form.city.error"
              :errorMessage="'Cidade é um campo obrigatório.'" label="Cidade"
              @keypress="() => validate(form.city.validation, 'city')"
              @blur="() => validate(form.city.validation, 'city')" required />

            <Input width="100%" v-model="form.street.value" :isError="!!form.street.error"
              :errorMessage="'Rua é um campo obrigatório.'" label="Rua"
              @keypress="() => validate(form.street.validation, 'street')"
              @blur="() => validate(form.street.validation, 'street')" required />

            <Input width="100%" v-model="form.number.value" :isError="!!form.number.error"
              :errorMessage="'Numero é um campo obrigatório.'" label="Numero"
              @keypress="() => validate(form.number.validation, 'number')"
              @blur="() => validate(form.number.validation, 'number')" required />

            <Input width="100%" v-model="form.neighborhood.value" :isError="!!form.neighborhood.error"
              :errorMessage="'Bairro é um campo obrigatório.'" label="Bairro"
              @keypress="() => validate(form.neighborhood.validation, 'neighborhood')"
              @blur="() => validate(form.neighborhood.validation, 'neighborhood')" required />

            <Input width="100%" v-model="form.complement.value" :isError="!!form.complement.error"
              :errorMessage="'Complemento é um campo obrigatório.'" label="Complemento"
              @keypress="() => validate(form.complement.validation, 'complement')"
              @blur="() => validate(form.complement.validation, 'complement')" required />
          </div>
        </div>

        <div>
          <div class="flex justify-end gap-2 items-center">
            <div class="w-full sm:max-w-[140px]">
              <OutlineButton border="border-none" hover="bg-transparent" text="Desativar" width="100%" />
            </div>
            <div class="w-full sm:max-w-[140px]">
              <OutlineButton @click="() => router.push('unidades')" text="Cancelar" width="100%" />
            </div>
            <div class="w-full sm:max-w-[76px]">
              <FilledButton @click="submit" text="Salvar" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Input from '@/components/designSystem/Inputs/Input.vue'
import LegendsGroup from '@/components/LegendsGroup/index.vue'
import BreadcrumbVue from '@/components/ManageScale/Breadcrumb.vue'
import { useStore } from 'vuex'
import { computed, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Hospital } from '@/store/modules/public/hospitalsCrud'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import {
  companyDocumentNumberValidator,
  emailValidator,
  phoneNumberValidator,
  companyNameValidator,
  nameValidator,
  postalCodeValidator,
  requiredStringValidator,
  requiredNumberValidator
} from '@/hooks/useYup'

const store = useStore()
const activeHospital = ref<Hospital>()
const activeFormData = ref(false)
const activeAddress = ref(false)
const router = useRouter()

onMounted(() => {
  const hospitalFromStore = computed(() => store.getters.getActiveHospital)

  if (!hospitalFromStore.value) {
    router.push('unidades')
  }

  activeHospital.value = hospitalFromStore.value
})

const form = reactive({
  name: {
    value: '',
    error: null,
    validation: nameValidator
  },
  social: {
    value: '',
    error: null,
    validation: companyNameValidator
  },
  cnpj: {
    value: '',
    error: null,
    validation: companyDocumentNumberValidator
  },
  email: {
    value: '',
    error: null,
    validation: emailValidator
  },
  phone1: {
    value: '',
    error: null,
    validation: phoneNumberValidator
  },
  phone2: {
    value: '',
    error: null,
    validation: phoneNumberValidator
  },
  geral: {
    value: '',
    error: null,
    validation: requiredStringValidator
  },
  technique: {
    value: '',
    error: null,
    validation: requiredStringValidator
  },
  total: {
    value: '',
    error: null,
    validation: requiredNumberValidator
  },
  perfil: {
    value: '',
    error: null,
    validation: requiredStringValidator
  },
  cep: {
    value: '',
    error: null,
    validation: postalCodeValidator
  },
  uf: {
    value: '',
    error: null,
    validation: requiredStringValidator
  },
  city: {
    value: '',
    error: null,
    validation: requiredStringValidator
  },
  street: {
    value: '',
    error: null,
    validation: requiredStringValidator
  },
  number: {
    value: '',
    error: null,
    validation: requiredNumberValidator
  },
  neighborhood: {
    value: '',
    error: null,
    validation: requiredStringValidator
  },
  complement: {
    value: '',
    error: null,
    validation: requiredStringValidator
  }
})

const validate = (_validate, field) => {
  _validate
    .validate(form[field].value)
    .then(() => {
      form[field].error = null
    })
    .catch((err) => {
      form[field].error = err.message
    })
}

const submit = () => {
  Object.keys(form).forEach((value) => {
    validate(form[value].validation, value)
  })
}

const daysOfWeek = [
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
  'Domingo',
  '09/05',
  '10/05',
  '11/05',
  '12/05',
  '13/05',
  '14/05',
  '15/05'
]

const apiResponse = {
  type: 'semanal',
  periods: [
    {
      title: 'Plantonista 09:00 - 14:00',
      dates: [
        {
          id: 1,
          opened: true,
          cell: 'opened'
        },
        {
          id: 2,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            waiting: true,
            pendences: true
          }
        },
        {
          id: 3,
          opened: true
        },
        {
          id: 4,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 5,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 6,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            waiting: true
          }
        },
        {
          id: 7,
          opened: true
        },
        {
          id: 8,
          opened: true
        },

        {
          id: 9,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 10,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            waiting: true
          }
        },
        {
          id: 11,
          opened: false,
          cell: 'warning',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 12,
          opened: true
        },

        {
          id: 13,
          opened: true,
          cell: 'opened'
        },
        {
          id: 14,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            waiting: true
          }
        },
        {
          id: 15,
          opened: false,
          cell: 'warning',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 16,
          opened: true,
          cell: 'opened'
        },

        {
          id: 17,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 18,
          opened: false,
          cell: 'neutral',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            waiting: true
          }
        },
        {
          id: 19,
          opened: true
        },
        {
          id: 20,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },

        {
          id: 21,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 22,
          opened: false,
          cell: 'neutral',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            waiting: true
          }
        },
        {
          id: 23,
          opened: false,
          cell: 'warning',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 24,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 25,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 26,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            waiting: true
          }
        },
        {
          id: 27,
          opened: true
        },
        {
          id: 28,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        }
      ]
    },
    {
      title: 'Plantonista 16:00 - 22:00',
      dates: [
        {
          id: 29,
          opened: true,
          cell: 'opened'
        },
        {
          id: 30,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 31,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 32,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            waiting: true
          }
        },
        {
          id: 33,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 34,
          opened: true
        },
        {
          id: 35,
          opened: false,
          cell: 'neutral',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 36,
          opened: false,
          cell: 'neutral',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            error: true,
            waiting: true
          }
        },
        {
          id: 37,
          opened: false,
          cell: 'neutral',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 38,
          opened: true
        },
        {
          id: 39,
          opened: true
        },
        {
          id: 40,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 41,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 42,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        }
      ]
    },
    {
      title: 'Balcão 16:00 - 22:00',
      dates: [
        {
          id: 43,
          opened: true,
          cell: 'opened'
        },
        {
          id: 44,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },

        {
          id: 45,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 46,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            waiting: true
          }
        },

        {
          id: 47,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 48,
          opened: true
        },

        {
          id: 49,
          opened: false,
          cell: 'neutral',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 50,
          opened: false,
          cell: 'neutral',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            error: true,
            waiting: true
          }
        },
        {
          id: 51,
          opened: false,
          cell: 'neutral',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 52,
          opened: true
        },
        {
          id: 53,
          opened: true
        },
        {
          id: 54,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 55,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        },
        {
          id: 56,
          opened: false,
          cell: 'default',
          name: 'Nome completo',
          crm: '000000-SP',
          type: {
            success: true,
            error: true,
            waiting: true,
            pendences: true
          }
        }
      ]
    }
  ]
}

// function changeFilterPeriod(event: {
//   label: string;
//   value: string | number;
// }) {
//   // to-do: Call API Escalas.
//   console.log(event);
// }
</script>

<style scoped>
.weekDay {
  background: #fafafa;
  padding: 10px;
}

.parent {
  padding: 1px;
  background: #f0f0f4;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  grid-column-gap: 2px;
  grid-row-gap: 2px;
}

.period {
  grid-column: 1 / -1;
  padding: 9px;
}

.card {
  min-width: 150px;
}

.border-input-time {
  border-bottom: 2px solid #2f7f91;
  border-radius: 4px 4px 0 0;
}
</style>
<style>
:root {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-box-shadow: 0px 0px 16px rgba(128, 128, 128, 0.3);
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #9e9eb7 rgb(255, 255, 255);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #9e9eb7;
  border-radius: 20px;
  border: 3px solid rgb(255, 255, 255);
}
</style>
