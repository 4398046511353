import request from '@/Network/Requests/request'
import Service from '@/Network/Services/Service'
import { ChangePasswordWithTokenRequest, TokenValidateRequest } from '../Types/Requests/UserRequest'
import {
  ChangePasswordWithTokenResponse,
  VerifyCpfResponse,
  VerifyEmailResponse
} from '../Types/Responses/UserResponse'

export const STORAGE_TOKEN_KEY = ``

export default class UserService extends Service {
  prefix: string

  constructor() {
    super()
    this.prefix = '/users'
  }

  async validateToken(payload: TokenValidateRequest): Promise<void> {
    await request.post<void>(this.getCompletePath('/validate-token'), payload)
  }

  async changePassword(
    payload: ChangePasswordWithTokenRequest
  ): Promise<ChangePasswordWithTokenResponse> {
    const { data } = await request.post<ChangePasswordWithTokenResponse>(
      this.getCompletePath('/change-password-with-token'),
      payload
    )
    return data
  }

  async verifyEmail(email: string): Promise<VerifyEmailResponse> {
    const { data } = await request.get<VerifyEmailResponse>(this.getCompletePath('/verify-email'), {
      email
    })
    return data
  }
  async verifyCpf(cpf: string): Promise<VerifyCpfResponse> {
    const { data } = await request.get<VerifyCpfResponse>(this.getCompletePath('/verify-cpf'), {
      cpf
    })
    return data
  }
}
