import { ref } from 'vue'

const isOpen = ref(false)

export function useSidebar() {
  function setIsOpen(param: boolean) {
    isOpen.value = param
  }

  return {
    isOpen,
    setIsOpen
  }
}
