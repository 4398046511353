<template>
  <v-form
    ref="personalDataFormRef"
    class="w-full flex gap-7 items-center flex-col rounded overflow-hidden mt-5 mb-4"
  >
    <div class="w-full flex flex-col">
      <h1 class="text-left font-bold text-2xl">Dados de Pessoais</h1>
      <p class="text-left text-sm text-gray-500">Preencha com seus dados pessoais.</p>
    </div>

    <div class="flex flex-col w-full gap-2">
      <v-text-field
        v-model="personalDataForm.rg"
        label="* Rg"
        class="w-full"
        color="primary20"
        variant="outlined"
        :rules="[rules.required]"
      />
      <v-text-field
        v-model="personalDataForm.issuingAuthority"
        label="* Orgão Expeditor"
        class="w-full"
        color="primary20"
        variant="outlined"
        :rules="[rules.required]"
      />
      <v-text-field
        v-maska="'##/##/####'"
        v-model="personalDataForm.issueDate"
        label="* Data de emissão"
        class="w-full"
        color="primary20"
        variant="outlined"
        :rules="[rules.required, rules.localeDate]"
      />
      <v-text-field
        v-maska="'###.###.###-##'"
        v-model="personalDataForm.cpf"
        label="* Cpf"
        class="w-full"
        color="primary20"
        variant="outlined"
        :rules="[rules.required, rules.cpf, validateCPF]"
        :error="!!cpfIsValid"
        :error-messages="cpfIsValid"
        @update:modelValue="verifyCpf"
      />

      <v-text-field
        v-maska="'##/##/####'"
        v-model="personalDataForm.birthDate"
        label="* Data de Nascimento"
        class="w-full"
        color="primary20"
        variant="outlined"
        :rules="[rules.required, rules.birthday]"
      />

      <v-text-field
        v-model="personalDataForm.motherName"
        label="* Nome da mãe"
        class="w-full"
        color="primary20"
        variant="outlined"
        :rules="[rules.required]"
      />

      <v-text-field
        v-model="personalDataForm.fatherName"
        label="* Nome do pai"
        class="w-full"
        color="primary20"
        variant="outlined"
        :rules="[rules.required]"
      />

      <v-autocomplete
        v-model="personalDataForm.civilStates"
        label="* Estado Civil"
        color="primary"
        variant="outlined"
        :items="civilStates"
        :rules="[rules.required]"
      />

      <v-text-field
        v-model="personalDataForm.nationality"
        label="* Nacionalidade"
        class="w-full"
        color="primary20"
        variant="outlined"
        :rules="[rules.required]"
      />
    </div>
  </v-form>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useDoctorRegistryStore } from '@/pinia-store/useDoctorRegistryStore'
import { rules } from '@/utils/rules'
import { ref } from 'vue'
import Network from '@/Network'
import { validateCPF } from '@/utils/string'

const doctorRegistryStore = useDoctorRegistryStore()
const { personalDataForm, personalDataFormRef } = storeToRefs(doctorRegistryStore)

const civilStates = [
  {
    title: 'Solteiro',
    value: 'solteiro'
  },
  {
    title: 'Casado',
    value: 'casado'
  }
]

const cpfIsValid = ref<string>('')

const verifyCpf = async () => {
  if (!validateCPF(personalDataForm.value.cpf)) return
  try {
    cpfIsValid.value = ''
    const cpf = personalDataForm.value.cpf.replace(/\D/g, '')
    const [data, data2] = await Promise.all([
      Network.user.verifyCpf(personalDataForm.value.cpf),
      Network.user.verifyCpf(cpf)
    ])
    if (data.cpfIsAlreadyInUse || data2.cpfIsAlreadyInUse) {
      cpfIsValid.value = 'Este cpf já está em uso!'
    }
  } catch (error) {
    cpfIsValid.value = 'Erro ao validar cpf!'
  }
}
</script>
