import api from './api'

export default class DeleteRequest {
  url: string

  headers: any = {}

  constructor(url: string, headers: any) {
    this.url = url
    this.headers = headers
  }

  async run() {
    const headers = { ...this.headers }
    return api.delete(this.url, { headers })
  }
}
