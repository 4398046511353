import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "w-full mt-4 overflow-y-auto overflow-x-hidden",
  style: {"grid-area":"table"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableUsers = _resolveComponent("TableUsers")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.companyUsers, (companyUser) => {
      return (_openBlock(), _createBlock(_component_TableUsers, {
        key: companyUser.user.id,
        user: companyUser.user,
        company: companyUser.company
      }, null, 8, ["user", "company"]))
    }), 128))
  ]))
}