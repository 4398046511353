<template>
  <ModalVue @close="closeApproveModal" :modalActive="showApproveModal" isFilterModal>
    <template #header>
      <p class="font-bold px-[8px] mb-5 text-left">Aprovar pagamento</p>
    </template>
    <div class="flex flex-row text-left">
      <div class="basis-3/4">
        <div
          v-for="paymentType in Object.keys(paymentTypes)"
          :key="paymentType"
          class="py-1 flex items-center"
        >
          <input
            :id="paymentTypes[paymentType].label"
            :name="paymentTypes[paymentType].label"
            class="payment-radio-btn"
            type="radio"
            v-model="selectedPaymentType"
            :value="paymentType"
          />
          <label class="ml-5" :for="paymentTypes[paymentType].label">
            {{ paymentTypes[paymentType].label }}
          </label>
        </div>
      </div>
      <div
        :class="`basis-3/4 flex items-center ${
          selectedPaymentType == 'custom' ? 'justify-end' : 'justify-center'
        }`"
      >
        <div v-if="selectedPaymentType == 'custom'">
          <v-text-field
            v-model="customValue"
            class="w-ful"
            prefix="R$"
            type="number"
            label="Digite o valor"
            color="primary20"
            variant="outlined"
            density="comfortable"
            hideDetails
          />
        </div>
        <div v-else class="payment font-bold text-2xl">
          R$ {{ formatCurrency(paymentTypes[selectedPaymentType].value) }}
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-5 mt-8">
      <OutlineButton text="Cancelar" width="119px" @click="closeApproveModal" />
      <FilledButton text="Confirmar" width="119px" @click="approvePayment" />
    </div>
  </ModalVue>
</template>

<script lang="ts">
import { StationsToApproveResponse } from '@/Network/Types/Responses/StationResponses'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import { ref } from 'vue'
import { computed } from 'vue'
import { formatCurrency } from '@/utils/formatters'

export default {
  emits: ['closeApproveModal', 'approvePayment'],
  props: {
    showApproveModal: Boolean,
    currentDoctor: Object as () => StationsToApproveResponse
  },
  components: {
    ModalVue,
    OutlineButton,
    FilledButton
  },
  setup(props, { emit }) {
    const customValue = ref('')

    const paymentTypes = computed(() => ({
      complete: {
        label: 'Valor completo',
        value: props.currentDoctor?.value
      },
      proportional: {
        label: 'Valor proporcional',
        value: props.currentDoctor?.valueProposed
      },
      custom: {
        label: 'Valor customizado',
        value: customValue.value
      }
    }))

    const selectedPaymentType = ref('complete')

    const resetModalData = () => {
      paymentTypes.value.custom.value = '0'
      // selectedPaymentType.value = 'complete'
    }

    const closeApproveModal = () => {
      emit('closeApproveModal')
      resetModalData()
    }

    const approvePayment = () => {
      emit('approvePayment', {
        stationId: props.currentDoctor?.stationId,
        value: Number(paymentTypes.value[selectedPaymentType.value].value)
      })
      resetModalData()
    }

    return {
      paymentTypes,
      selectedPaymentType,
      closeApproveModal,
      approvePayment,
      formatCurrency,
      customValue
    }
  }
}
</script>

<style scoped>
.border-bottom-gray {
  box-shadow: inset 0px -1px 0px #e6e6e6;
}
.payment {
  color: #9e9eb7;
}
.payment-radio-btn {
  width: 20px;
  height: 20px;
  accent-color: #2f7f91;
}
</style>
