import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PendingDoctor = _resolveComponent("PendingDoctor")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.companyDoctors, (companyDoctor, index) => {
    return (_openBlock(), _createBlock(_component_PendingDoctor, {
      companyDoctor: companyDoctor,
      key: index
    }, null, 8, ["companyDoctor"]))
  }), 128))
}