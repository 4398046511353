import { ActionContext } from 'vuex'

interface user {
  id: number
  name: string
  email: string
  phone1: string
  phone2: string
  status: string
}

interface group {
  name: string
}

interface hospital {
  name: string
}

interface IStates {
  users: user[]
  groups: group[]
  hospitals: hospital[]
  filteredUsers: user[]
}

export default {
  state(): IStates {
    return {
      users: [
        {
          id: 1,
          name: 'Mollie Dickens',
          email: 'email@email.com.br',
          phone1: '(00) 99999-9999 ',
          phone2: '(00) 99999-9999',
          status: 'Ativo'
        },
        {
          id: 2,
          name: 'Syed Wilkinson',
          email: 'email@email.com.br',
          phone1: '(00) 99999-9999 ',
          phone2: '(00) 99999-9999',
          status: 'Ativo'
        },
        {
          id: 3,
          name: 'Bronte Schneider',
          email: 'email@email.com.br',
          phone1: '(00) 99999-9999 ',
          phone2: '(00) 99999-9999',
          status: 'Ativo'
        },
        {
          id: 4,
          name: 'Melinda Maciel Rodrigues',
          email: 'email@email.com.br',
          phone1: '(00) 99999-9999 ',
          phone2: '(00) 99999-9999',
          status: 'Ativo'
        },
        {
          id: 5,
          name: 'Aliça Velasques Severo',
          email: 'email@email.com.br',
          phone1: '(00) 99999-9999 ',
          phone2: '(00) 99999-9999',
          status: 'Ativo'
        }
      ],
      groups: [
        {
          name: 'grupo 1'
        }
      ],
      hospitals: [
        {
          name: 'Hospital 1'
        },
        {
          name: 'Hospital 2'
        },
        {
          name: 'Hospital 3'
        }
      ],
      filteredUsers: []
    }
  },
  mutations: {
    createGroup(state: IStates, value: group) {
      state.groups.push(value)
    },
    deleteGroup(state: IStates, value: group) {
      const index = state.groups.findIndex((_hospital) => _hospital.name === value.name)

      if (index > -1) state.groups.splice(index, 1)
    },
    createUser(state: IStates, value: user) {
      state.users.push(value)
    },
    deleteUser(state: IStates, value: user) {
      const index = state.users.findIndex((_user) => _user === value)

      if (index > -1) state.users.splice(index, 1)
    },
    filterUsers(state: IStates, query: string) {
      state.filteredUsers =
        query.length > 0
          ? state.users.filter((_user: user) =>
              _user.name.toLowerCase().includes(query.toLowerCase())
            )
          : state.users
    }
  },
  actions: {
    filterUsers(context: ActionContext<IStates, IStates>, param: string) {
      context.commit('filterUsers', param)
    }
  },
  getters: {
    getFilteredUsers(state: IStates) {
      return state.filteredUsers
    },
    getGroups(state: IStates) {
      return state.groups
    }
  }
}
