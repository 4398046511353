<template>
  <div>
    <div
      :class="`grid lg:grid-cols-12 grid-cols-6 gap-1 w-full h-full items-center py-5 my-0 border-b border-gray-200`"
    >
      <div class="lg:col-span-2 md:col-span-3 col-span-3">
        <p class="text-left ml-2 md:ml-0 font-bold">Pediatria</p>
      </div>

      <div class="col-span-1 lg:block hidden">
        <p>|</p>
      </div>

      <div class="lg:col-span-2 md:col-span-3 col-span-3">
        <p class="text-left ml-2 md:ml-0">Hospital XPTO</p>
      </div>

      <div class="col-span-6 lg:block hidden"></div>

      <div class="flex justify-end gap-4">
        <svg
          width="9"
          height="16"
          viewBox="0 0 9 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 15L8 8L1 1"
            stroke="#52526F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
