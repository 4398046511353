<template>
  <div
    class="row-table"
    :class="`h-[${defineHeight()}]`"
    :style="`background: ${colorDefination.bg};`"
  >
    <div :class="`${colorDefination.stripe} w-3`"></div>
    <div class="flex flex-col p-1.5">
      <div class="flex justify-start gap-x-1.5">
        <v-avatar color="primary" size="21">
          <span class="text-[.6rem] font-bold">{{ getInitialsChar(name) }}</span>
        </v-avatar>
        <!-- <img class="w-[20px] h-[20px] rounded-full" :src="photo" width="20" height="20" alt="Foto miniatura" /> -->
        <span class="truncate text-left text-[12px] text-gray-800">
          {{ nameTruncated }}
        </span>
      </div>
      <div class="flex justify-start gap-x-1.5 -mt-1">
        <div class="w-[20px] h-[20px]"></div>
        <p class="div3 text-left text-gray-700 text-[10px]">
          {{ crm }}
        </p>
      </div>
      <div class="flex justify-start gap-x-1.5">
        <!-- <div class="w-[20px] h-[20px]"></div> -->
        <div class="group-buttons w-full flex items-center justify-center gap-2">
          <v-tooltip v-if="hasObservations" text="Existe observações">
            <template v-slot:activator="{ props }">
              <v-icon size="small" color="warning" v-bind="props" :icon="mdiTextBoxCheck" />
            </template>
          </v-tooltip>
          <v-tooltip v-if="doctorPending" text="Médico pendente">
            <template v-slot:activator="{ props }">
              <v-icon size="small" color="primary" v-bind="props" :icon="mdiAccountAlert" />
            </template>
          </v-tooltip>
          <v-tooltip v-if="!doctorConfirmed" text="Plantão pendente">
            <template v-slot:activator="{ props }">
              <v-icon size="small" color="neutral" v-bind="props" :icon="mdiClockAlertOutline" />
            </template>
          </v-tooltip>
          <v-tooltip v-if="doctorConfirmed" text="Plantão confirmado">
            <template v-slot:activator="{ props }">
              <v-icon size="small" color="success" v-bind="props" :icon="mdiClockCheckOutline" />
            </template>
          </v-tooltip>
          <v-tooltip
            v-if="doctorStationStatus !== DoctorStationStatus.SEM_STATUS"
            :text="doctorStationStatusTranslate[doctorStationStatus].text"
          >
            <template v-slot:activator="{ props }">
              <component
                v-bind="props"
                :is="doctorStationStatusTranslate[doctorStationStatus].icon"
              />
            </template>
          </v-tooltip>
          <!-- <feedback-manifest v-if="success" />
          <feedback-wrong v-if="error" />
          <feedback-waiting v-if="waiting" />
          <feedback-manifest v-if="pendences" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { getInitialsChar } from '@/utils/string'
import { computed, defineComponent, onMounted, ref } from 'vue'
// import FeedbackRight from '@/assets/icons/feedbackRight.vue'
// import FeedbackWrong from '@/assets/icons/feedbackWrong.vue'
// import FeedbackWaiting from '@/assets/icons/feedbackWaiting.vue'
// import FeedbackManifest from '@/assets/icons/feedbackManifest.vue'
import { PropType } from 'vue'
import {
  mdiTextBoxCheck,
  mdiAccountAlert,
  mdiClockAlertOutline,
  mdiClockCheckOutline
} from '@mdi/js'
import {
  DoctorStationStatus,
  doctorStationStatusTranslate
} from '@/translations/doctor.station.status'
// eslint-disable-next-line no-shadow

export type CellTypes = 'SEMANAL' | 'ABERTO' | 'COBERTURA' | 'EXTRA'
export default defineComponent({
  props: {
    tableType: {
      type: String as PropType<CellTypes>,
      default: 'EXTRA'
    },
    name: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    minimal: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    solid: {
      type: Boolean,
      default: false
    },
    crm: {
      type: String,
      default: ''
    },
    success: {
      type: Boolean,
      default: true
    },
    error: {
      type: Boolean,
      default: true
    },
    waiting: {
      type: Boolean,
      default: true
    },
    pendences: {
      type: Boolean,
      default: true
    },
    photo: {
      type: String,
      default: ''
    },
    hasObservations: {
      type: Boolean,
      default: false
    },
    doctorPending: {
      type: Boolean,
      default: false
    },
    doctorConfirmed: {
      type: Boolean,
      default: false
    },
    doctorStationStatus: {
      type: String as PropType<DoctorStationStatus>,
      default: DoctorStationStatus.SEM_STATUS
    }
  },
  // components: {
  //   FeedbackRight,
  //   FeedbackWrong,
  //   FeedbackWaiting,
  //   FeedbackManifest
  // },
  setup(props) {
    function defineHeight() {
      if (props.solid || (!props.text && !props.minimal)) {
        return '70px'
      }
      if (props.minimal) {
        return '25px'
      }
      if (props.flat) {
        return '30px'
      }
      return '38px'
    }
    function defineTextColor() {
      if (props.text && props.tableType === 'ABERTO') {
        if (props.solid) return 'text-white font-semibold'
        if (props.minimal) return 'text-feedBackAlert text-xs font-semibold'
        return props.flat
          ? 'text-feedBackAlert font-semibold text-xs'
          : 'text-feedBackAlert font-semibold'
      }
      if (props.minimal) {
        return 'text-gray-800 text-xs ml-1'
      }
      return 'text-gray-800'
    }
    function defineBgColor() {
      const colors: Record<CellTypes, { stripe: string; bg: string }> = {
        SEMANAL: { stripe: 'bg-primary10', bg: '#F7FCFD' },
        COBERTURA: { stripe: 'bg-tertiary', bg: '#FDF7F8' },
        EXTRA: { stripe: 'bg-feedBackSuccess', bg: '#F6FDF9' },
        ABERTO: {
          stripe: 'bg-feedBackAlert',
          bg: props.solid ? '#F18F1B' : '#FEF3E7'
        }
      }

      return colors[props.tableType]
    }
    function defineClass() {
      if (props.photo || props.flat) {
        return `${defineBgColor().stripe} w-3`
      }
      if (props.minimal) {
        return `${defineBgColor().stripe} w-2`
      }
      return `${defineBgColor().stripe} w-5`
    }

    function defineClassIcons() {
      if (props.photo) return 'px-2'
      if (props.minimal) return 'px-1 flex-col'
      return 'px-4 flex-col'
    }
    function truncateString(text: string, limit: number) {
      return `${text.substring(0, limit)}`
    }
    const nameTruncated = computed(() => truncateString(props.name, 13))

    const colorDefination = ref({ stripe: 'bg-primary10', bg: '#F7FCFD' })

    onMounted(() => {
      colorDefination.value = defineBgColor()
    })

    return {
      colorDefination,
      defineClass,
      defineBgColor,
      defineTextColor,
      defineHeight,
      defineClassIcons,
      nameTruncated,
      getInitialsChar,
      mdiTextBoxCheck,
      mdiAccountAlert,
      mdiClockAlertOutline,
      mdiClockCheckOutline,
      DoctorStationStatus,
      doctorStationStatusTranslate
    }
  }
})
</script>

<style scoped>
.row-table {
  display: flex;
}

.decoration-left {
  width: 18px !important;
}

.btn-green,
.btn-red,
.btn-yellow {
  width: 14px;
  height: 14px;
}

.btn-green {
  border: 1px #198038 solid;
}

.btn-red {
  border: 1px theme('colors.primary10') solid;
}

.btn-yellow {
  border: 1px #f1c21b solid;
}

.group-buttons {
  gap: 1px;
}
</style>
