<template>
  <PendingDoctor
    v-for="(companyDoctor, index) in companyDoctors"
    :companyDoctor="companyDoctor"
    :key="index"
  />
</template>

<script lang="ts">
import { DoctorCompanyResponse } from '@/Network/Types/Responses/CompanyResponses'
import { PendingDoctor } from '../atoms'

export default {
  components: {
    PendingDoctor
  },
  props: {
    companyDoctors: {
      type: Array<DoctorCompanyResponse>,
      default: () => {
        return []
      }
    }
  },
  setup() {}
}
</script>

<style scoped>
div:first-child {
  margin-top: -8px;
}
</style>
