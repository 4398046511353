<template>
  <div class="flex justify-start text-[12px] font-normal">
    <span>
      <router-link class="hover:text-primary" :to="redirectTo">
        {{ subtitle }}
      </router-link>
      / <span class="font-bold">{{ title }}</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    subtitle: String,
    title: String,
    redirectTo: {
      type: String,
      default: '/dashboard'
    }
  }
}
</script>

<style></style>
