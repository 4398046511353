import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_filters = _resolveComponent("dropdown-filters")!

  return (_openBlock(), _createBlock(_component_dropdown_filters, {
    onClicked: $setup.addFilter,
    title: "Escalas",
    items: $setup.listSchedules,
    icon: $setup.scaleIcon
  }, null, 8, ["onClicked", "items", "icon"]))
}