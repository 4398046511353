<template>
  <v-col cols="6">
    <div class="card" :class="cardClass">
      <span v-if="props.type === 'ABERTO'">Vaga aberta</span>
      <span v-else> Dr Minha Escala <br />123123-SP </span>
      <div class="flex flex-col items-center">
        <span v-if="confirmed" class="legend">Confirmado</span>
        <span v-if="notConfirmed" class="legend">Não confirmado</span>
        <span v-if="doctorPending" class="legend">Pendente</span>
      </div>
    </div>
  </v-col>
</template>
<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps(['type', 'confirmed', 'notConfirmed', 'doctorPending'])

const cardClass = computed(() => {
  switch (props.type) {
    case 'ABERTO':
      return 'card card-opened'
    case 'SEMANAL':
      return 'card card-weekly'
    case 'COBERTURA':
      return 'card card-coverage'
    case 'EXTRA':
      return 'card card-extra'
    default:
      return 'card'
  }
})
</script>

<style scoped>
.card {
  @apply flex flex-col justify-center items-center w-full  h-[110px] font-bold text-sm rounded text-white;
}
.card-opened {
  @apply bg-alert;
}
.card-weekly {
  @apply bg-primary10;
}
.card-coverage {
  @apply bg-tertiary;
}
.card-extra {
  @apply bg-feedBackSuccess;
}

.legend {
  @apply text-xs text-black font-normal;
}
</style>
