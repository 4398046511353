import { mergeProps as _mergeProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_text_field, _mergeProps({ ..._ctx.$props, ..._ctx.$attrs }, {
      class: "generic-input text-neutral50",
      color: _ctx.colors.primary20,
      value: _ctx.modelValue,
      onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('validate', _ctx.modelValue))),
      onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('validate', _ctx.modelValue))),
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
      label: _ctx.required ? `* ${_ctx.label}` : _ctx.label,
      rules: _ctx.required ? [..._ctx.rules, _ctx.inputRules.required] : [..._ctx.rules]
    }), _createSlots({ _: 2 }, [
      _renderList(_ctx.$slots, (_, slot) => {
        return {
          name: slot,
          fn: _withCtx((scope) => [
            _renderSlot(_ctx.$slots, slot, _mergeProps({ key: slot }, scope))
          ])
        }
      })
    ]), 1040, ["color", "value", "label", "rules"])
  ]))
}