<template>
  <div class="flex items-center gap-2 text-sm text-textPrimary">
    <component :is="dynamicComponent"></component>

    <v-icon v-if="legendIcon" :icon="legendIcon" :color="legendColor" />
    <div v-else-if="!dynamicComponent" class="w-4 h-4 rounded-full" :class="defineBgColor()"></div>
    {{ text }}
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
// eslint-disable-next-line no-shadow
export enum ELegendColor {
  default = 'default',
  warning = 'warning',
  neutral = 'neutral',
  success = 'success',
  alert = 'alert',
  coverage = 'coverage',
  primary = 'primary'
}
export default defineComponent({
  name: 'legend table',
  props: {
    legendColor: {
      type: String as PropType<ELegendColor>
    },
    dynamicComponent: {
      type: Object,
      required: false
    },
    text: {
      type: String,
      default: ''
    },
    legendIcon: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    function defineBgColor() {
      if (!props.legendColor) return
      const colors = {
        default: 'bg-primary10',
        warning: 'bg-tertiary',
        neutral: 'bg-neutralLight',
        success: 'bg-feedBackSuccess',
        alert: 'bg-feedBackAlert'
      }
      return colors[props.legendColor]
    }
    return {
      defineBgColor
    }
  }
})
</script>

<style scoped></style>
