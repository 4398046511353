<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.02148 30.0007C3.02148 15.1059 15.0825 3.02148 29.9715 3.02148C44.894 3.02148 56.9798 15.1102 56.9798 30.0007C56.9798 44.8911 44.894 56.9798 29.9715 56.9798C15.0825 56.9798 3.02148 44.8954 3.02148 30.0007Z"
      :stroke="color"
      stroke-width="4.375"
    />
    <path
      d="M16.875 31.75L26.4205 38.75L43.125 21.25"
      :stroke="color"
      stroke-width="4.375"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#2F7F91'
    },
    width: {
      type: String,
      default: '32'
    },
    height: {
      type: String,
      default: '32'
    }
  }
}
</script>
