import ufs from '@/config/StatesFromBR'
import { validateCPF } from './string'
export const rules = {
  required: (value: string) => !!value || 'Campo obrigatório',
  email: (value: string) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || 'Email inválido.'
  },
  phone: (value: string) => {
    const pattern = /(\(\d{2}\)\s)(\d{4,5}-\d{4})/
    return pattern.test(value) || 'Telefone inválido.'
  },
  password: (value: string) => {
    const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$/
    return (
      pattern.test(value) ||
      'A senha precisa ter pelo menos 8 caracters, 1 letra maíuscula, 1 caracter especial e 1 número.'
    )
  },
  crm: (value: string) => {
    const pattern = /^[0-9]{2,}$/
    return pattern.test(value) || 'CRM inválido.'
  },
  crmLen: (value: string) => {
    return value.length === 6 || 'CRM deve ter 6 dígitos.'
  },
  uf: (value: string) => {
    return ufs.some((uf) => uf.value === value) || 'Estado inválido.'
  },
  ufLen: (value: string) => {
    return value.length === 2 || 'Estado deve ter 2 dígitos.'
  },
  cpf: (value: string) => {
    const pattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/
    const validate = validateCPF(value)
    return (pattern.test(value) && validate) || 'CPF inválido.'
  },
  birthday: (value: string) => {
    const pattern = /^\d{2}\/\d{2}\/\d{4}$/
    const [day, month, year] = value.split('/')
    const date = new Date(`${year}-${month}-${day}`)
    const yearDate = date.getFullYear()
    const isValidYear = yearDate > 1900 && yearDate < new Date().getFullYear()
    return (pattern.test(value) && isValidYear) || 'Data de nascimento inválida.'
  },
  localeDate: (value: string) => {
    const pattern = /^\d{2}\/\d{2}\/\d{4}$/
    try {
      const [day, month, year] = value.split('/')
      new Date(`${year}-${month}-${day}`)
      return pattern.test(value) || 'Data inválida.'
    } catch (error) {
      return 'Data inválida'
    }
  }
}
