<template>
  <div class="w-full mt-4 overflow-y-auto overflow-x-hidden" style="grid-area: table">
    <TableUsers
      v-for="companyUser in companyUsers"
      :key="companyUser.user.id"
      :user="companyUser.user"
      :company="companyUser.company"
    />
  </div>
</template>

<script lang="ts">
import TableUsers from '@/components/designSystem/Table/TableUsers.vue'

export default {
  components: {
    TableUsers
  },
  props: ['companyUsers', 'count']
}
</script>
