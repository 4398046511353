<template>
  <div
    class="grid grid-cols-1 lg:grid-cols-12 items-center lg:text-start my-3 md:py-4 gap-3 lg:border-b-2 lg:border-[#E6E6E6]"
  >
    <p class="lg:col-span-3">
      <span class="font-bold">Nome </span>{{ `${props?.companyDoctor?.doctor?.mainCrm?.name}` }}
      <br />
      <span class="font-bold">CRM: </span>
      {{
        `${props?.companyDoctor?.doctor?.mainCrm?.number}-${props?.companyDoctor?.doctor?.mainCrm?.uf}`
      }}
      <br />
      <span class="font-bold">Empresa: </span>
      {{ `${props?.companyDoctor?.company?.name}` }}
      <br />
      <span class="font-bold">Status: </span>
      {{ `${props?.companyDoctor?.companyDoctorInvite?.doctorStatus}` }}
    </p>

    <v-tooltip content-class="bg-primary" :value="isCopiedInviteUrl" location="top">
      <template v-slot:activator="{ props }">
        <a @click="copy" v-bind="props" class="truncate mr-6 lg:col-span-7 hover:text-primary20">{{
          inviteUrl
        }}</a>
      </template>
      <span>{{ isCopiedInviteUrl ? 'Link Copiado!' : 'Clique para copiar o link' }}</span>
    </v-tooltip>
    <v-tooltip content-class="bg-primary" :value="isCopiedInviteUrl" location="top">
      <template v-slot:activator="{ props }">
        <GhostButton
          v-bind="props"
          @click="copy"
          class="font-bold text-[#2F7F91] m-auto"
          :prepend-icon="mdiContentCopy"
        >
          {{ isCopiedInviteUrl ? 'Copiado!' : 'Copiar link' }}
        </GhostButton>
      </template>
      <span>{{ isCopiedInviteUrl ? 'Link Copiado!' : 'Clique para copiar o link' }}</span>
    </v-tooltip>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, computed } from 'vue'
import { DoctorCompanyResponse } from '@/Network/Types/Responses/CompanyResponses'
import GhostButton from '@/components/designSystem/Buttons/GhostButton/index.vue'
import PATHS from '@/router/paths'
import { mdiContentCopy } from '@mdi/js'

const copyText = ref('')
const isCopiedInviteUrl = ref(false)
const props = defineProps<{
  companyDoctor: DoctorCompanyResponse
}>()

const inviteUrl = computed(() => {
  const hash = props?.companyDoctor?.companyDoctorInvite?.hash ?? ''
  const baseUrl = process.env.VUE_APP_URL ?? window.location.origin
  const routePath = PATHS.MANAGE_DOCTOR_INVITE ?? ''

  return `${baseUrl}${routePath}?token=${hash}`
})

const copy = () => {
  navigator.clipboard.writeText(inviteUrl.value).then(() => {
    isCopiedInviteUrl.value = true
    setTimeout(() => {
      isCopiedInviteUrl.value = false
    }, 1500)
  })
}
</script>
