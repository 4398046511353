export interface alert {
  title: string
  description: string
  date: string
  link: string
  deleted: boolean
}
interface IStates {
  alerts: alert[]
}

export default {
  state(): IStates {
    return {
      alerts: []
    }
  },
  mutations: {
    setAlert(state: IStates, value: alert) {
      state.alerts = [value, ...state.alerts]
    },
    changeAlert(state: IStates, { value, boolean }) {
      const updated: alert[] = state.alerts.map((_value, index) => {
        if (index === value) {
          return { ..._value, deleted: boolean }
        }

        return _value
      })

      state.alerts = updated
    }
  },
  actions: {
    addFilter(context: any, param: alert) {
      context.commit('setAlert', param)
    },
    softDeleteAlert(context: any, param: alert) {
      context.commit('changeAlert', param)
    }
  },
  getters: {
    getAlerts(state: IStates) {
      return state.alerts
    }
  }
}
