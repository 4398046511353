<template>
  <div class="flex items-center">
    <div class="relative">
      <button
        @click="notificationOpen = !notificationOpen"
        class="flex mx-4 text-gray-600 focus:outline-none mr-6"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
            fill="#2F7F91"
            stroke="#2F7F91"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13.73 21C13.5542 21.3031 13.3018 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6981 21.5547 10.4458 21.3031 10.27 21"
            stroke="#2F7F91"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>

      <div
        v-show="notificationOpen"
        @click="notificationOpen = false"
        class="fixed inset-0 h-full w-full z-30 bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        v-show="notificationOpen"
        class="absolute right-0 mt-2 mr-2 w-80 bg-white rounded-lg shadow-xl overflow-hidden z-30 p-3"
        style="width: 30rem"
      >
        <div class="flex justify-between p-3 items-center">
          <h3 class="text-sm font-bold">NOTIFICAÇÕES</h3>

          <button @click="notificationOpen = false">
            <svg
              width="12"
              height="14"
              viewBox="0 0 12 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.854492 1.75L11.25 12.25"
                stroke="#68688D"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M11.1455 1.75L0.749988 12.25"
                stroke="#68688D"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </button>
        </div>

        <div
          class="flex justify-between p-3 mt-2 border-b-2"
          v-for="(step, i) in alerts"
          :key="'multi-step-' + i"
        >
          <div class="flex w-full flex-col items-start">
            <p class="text-[10px] font-bold mb-2">ALERTAS</p>

            <div v-if="step.deleted" class="flex justify-between items-center w-full">
              <p>Alerta excluído</p>
              <OutlineButton
                @click="() => deleteAlert({ value: i, boolean: false })"
                width="119px"
                text="Desfazer"
              />
            </div>

            <div v-else class="text-left">
              <p>{{ step.title }}</p>

              <p>{{ step.description }}</p>

              <p class="mt-2">{{ step.date }}</p>

              <a :href="step.link" class="flex py-3 text-primary text-sm font-bold hover:text-gray">
                ir para alerta
              </a>
            </div>
          </div>

          <svg
            v-if="!step.deleted"
            @click="() => deleteAlert({ value: i, boolean: true })"
            class="cursor-pointer"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.5 5.5C5.5 4.94772 5.94772 4.5 6.5 4.5H17.5C18.0523 4.5 18.5 4.94772 18.5 5.5C18.5 6.05228 18.0523 6.5 17.5 6.5H6.5C5.94772 6.5 5.5 6.05228 5.5 5.5Z"
              stroke="#68688D"
            />
            <path
              d="M9.5 4C9.5 3.17157 10.1716 2.5 11 2.5H13C13.8284 2.5 14.5 3.17157 14.5 4V4.5H9.5V4Z"
              stroke="#68688D"
            />
            <path
              d="M6.5 20V8.5H17.5V20C17.5 20.8239 16.8239 21.5 16 21.5H8C7.17614 21.5 6.5 20.8239 6.5 20Z"
              stroke="#68688D"
            />
            <path d="M9 12V17.5" stroke="#68688D" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 12V17.5" stroke="#68688D" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15 12V17.5" stroke="#68688D" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'

const notificationOpen = ref(false)

const store = useStore()

const alerts = computed(() => store.getters.getAlerts)

const deleteAlert = (alert) => {
  store.dispatch('softDeleteAlert', alert)
}
</script>
