<template>
  <span
    class="filter-btn"
    :class="hasFilter ? 'border border-primary text-primary' : ''"
    @click="toggleFilterModal"
  >
    <v-icon
      size="small"
      :icon="mdiFilterOutline"
      :color="hasFilter ? 'primary' : 'iconGrey'"
    />Filtros
  </span>
  <teleport to="#app">
    <filter-dashboard-modal />
  </teleport>
</template>

<script setup lang="ts">
import { mdiFilterOutline } from '@mdi/js'
import filterDashboardModal from '../components/filter.dashboard.modal.vue'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import { computed } from 'vue'
import { filterDashboardStates } from '../components/useFilterDashboard'

const modalStore = useModalStore()

const hasFilter = computed(() => {
  let isFiltred = false
  Object.values(filterDashboardStates).forEach((v) => {
    if (v) {
      isFiltred = true
    }
  })
  return isFiltred
})
const toggleFilterModal = () => {
  modalStore.toggleModal(EModals.FILTER_STATION)
}
</script>
