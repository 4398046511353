<template>
  <div>
    <div
      v-show="modalActive"
      :class="`modal z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>

      <div
        class="container-modal z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container"
      >
        <div class="px-6 py-4 text-left modal-content">
          <div class="flex items-center justify-between pb-3">
            <p class="titleBold">Criar protocolo</p>
            <div class="z-50 cursor-pointer modal-close" @click="closeCreateModal">
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <div class="input w-full flex flex-col input w-full mt-4 gap-5">
            <Input
              :isError="!newProtocolValidation.name"
              name="name"
              @input="onChangeNewProtocol"
              errorMessage="Nome de protocolo inválido"
              width="100%"
              label="Nome do protocolo"
              pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
              :value="newProtocol.name"
              required
            />

            <Input
              :isError="!newProtocolValidation.speciality"
              name="speciality"
              @input="onChangeNewProtocol"
              errorMessage="Especialidade do protocolo inválido"
              width="100%"
              label="Especialidade"
              pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
              :value="newProtocol.speciality"
              required
            />

            <p class="titleBold">Observação do protocolo</p>

            <Input
              name="notes"
              @input="onChangeNewProtocolNotes"
              width="100%"
              label="Digite suas observações..."
              pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
              :value="newProtocol.notes"
              multiline
            />

            <div class="text-right">{{ newProtocol.notes.length }}/{{ notesLimitCharacters }}</div>
          </div>

          <div class="mt-8">
            <AttachFile
              :fileUploadPercentage="fileUploadPercentage"
              :uploadStatus="uploadStatus"
              @validateField="validateField"
              @setFileUploadPercentage="setFileUploadPercentage"
              @setUploadedStatus="setUploadedStatus"
            />
          </div>

          <div class="flex justify-end pt-8">
            <OutlineButton @click="closeCreateModal" class="mr-4" text="Cancelar" width="120px" />
            <FilledButton
              text="Criar protocolo"
              :disabled="!isNewProtocolValid"
              width="150px"
              @click="createProtocol"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/designSystem/Inputs/Input.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import { computed, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { isEmpty } from '@/utils/validation'
import AttachFile from '../AttachFile/AttachFile.vue'

export default {
  name: 'CreateProtocolModal',
  components: {
    Input,
    OutlineButton,
    FilledButton,
    AttachFile
  },
  props: ['modalActive'],
  emits: ['closeCreateModal'],
  setup(props, { emit }) {
    const store = useStore()

    const newProtocol = computed(() => store.state.protocol.newProtocol)
    const notesLimitCharacters = 2000

    const uploadStatus = ref('idle')
    const fileUploadPercentage = ref(0)

    const validationInitialState = {
      name: false,
      speciality: false,
      file: false
    }
    const newProtocolValidation = reactive({ ...validationInitialState })
    const isNewProtocolValid = computed(() =>
      Object.values(newProtocolValidation).every((value) => value === true)
    )

    const validateField = (field, value = null) => {
      newProtocolValidation[field] = value ?? !isEmpty(newProtocol.value[field])
    }

    const clearValidation = () => {
      Object.assign(newProtocolValidation, { ...validationInitialState })
    }

    const setUploadedStatus = (status) => {
      uploadStatus.value = status
    }

    const setFileUploadPercentage = (value) => {
      fileUploadPercentage.value = value
    }

    const onChangeNewProtocol = (e) => {
      const payload = { property: e.target.name, value: e.target.value }
      store.dispatch('setNewProtocol', payload)
      validateField(e.target.name)
    }

    const onChangeNewProtocolNotes = (e) => {
      if (e.target.value.length <= notesLimitCharacters) {
        onChangeNewProtocol(e)
      } else {
        e.target.value = newProtocol.value.notes
      }
    }

    const closeCreateModal = () => {
      emit('closeCreateModal')
      clearValidation()
      setUploadedStatus('idle')
      setFileUploadPercentage(0)
    }

    const createProtocol = () => {
      store.dispatch('createProtocol')
      closeCreateModal()
    }

    return {
      closeCreateModal,
      onChangeNewProtocol,
      onChangeNewProtocolNotes,
      createProtocol,
      newProtocol,
      setUploadedStatus,
      setFileUploadPercentage,
      uploadStatus,
      fileUploadPercentage,
      newProtocolValidation,
      isNewProtocolValid,
      validateField,
      clearValidation,
      notesLimitCharacters
    }
  }
}
</script>

<style scoped>
.modal {
  transition: opacity 0.25s ease;
}

.container-modal {
  width: 700px;
}

.titleBold {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 700;
}
</style>
