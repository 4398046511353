<template>
  <Popper>
    <span class="filter-btn">
      <component :is="icon"> </component>
      <p>{{ title }}</p>
    </span>
    <template #content>
      <div class="max-h-[285px] py-[16px]">
        <div
          v-if="withSearch"
          class="flex gap-1 items-center w-full text-[16px] font-normal px-[16px] pb-[16px]"
        >
          <search-icon class="absolute left-[25px]" />
          <input
            :placeholder="`Buscas ${title}`"
            class="py-[16px] pr-[5px] pl-[40px] w-full rounded-md bg-[#F0F0F4] focus-visible:outline-0"
            v-model="searchValue"
          />
        </div>
        <div
          class="text-[14px] scrolbar-filter overflow-y-scroll pl-[16px] pr-[8px] max-h-[190px] text-left w-full h-full flex flex-col items-center font-normal"
        >
          <span
            v-for="item of itemsFiltered"
            :key="item.value"
            @click="$emit('clicked', item)"
            class="w-full cursor-pointer p-[6px] hover:bg-gray-200"
          >
            {{ item.title }}
          </span>
        </div>
      </div>
    </template>
  </Popper>
</template>

<script lang="ts">
import { computed, PropType, defineComponent, ref } from 'vue'
import SearchIcon from '@/assets/icons/search.vue'
import Popper from 'vue3-popper'

export default defineComponent({
  components: {
    SearchIcon,
    Popper
  },
  props: {
    withSearch: {
      type: Boolean,
      default: true
    },
    icon: {},
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array as PropType<{ title: string; value: string }[]>,
      required: true
    }
  },
  setup(props) {
    const searchValue = ref('')
    const filterSearchFn = (item: { title: string; value: string }) =>
      item.title.toLowerCase().match(searchValue.value.toLowerCase())
    const itemsFiltered = computed(() => props.items.filter(filterSearchFn))
    return {
      searchValue,
      itemsFiltered
    }
  }
})
</script>

<style></style>
