import request from '@/Network/Requests/request'
import Service from '@/Network/Services/Service'
import { CreateHealthUnit, FiltersHealthUnits } from '../Types/Requests/HealthUnitRequests'
import { HealthUnitResponse, ListHealthUnitsResponse } from '../Types/Responses/HealthUnitResponses'
export default class HealthUnitService extends Service {
  prefix: string

  constructor() {
    super()

    this.prefix = '/healthUnits'
  }

  async create(companyId: string, payload: CreateHealthUnit): Promise<HealthUnitResponse> {
    try {
      const { data } = await request.post<HealthUnitResponse>(
        `${this.getCompletePath()}/company/${companyId}`,
        payload
      )
      return data
    } catch (e) {
      throw new Error('Ocorreu um erro ao cadastrar a empresa, por favor tente novamente.')
    }
  }

  async update(healthUnitId: number, payload: CreateHealthUnit): Promise<void> {
    try {
      await request.patch(`${this.getCompletePath()}/${healthUnitId}`, payload)
    } catch (e) {
      throw new Error('Ocorreu um erro ao cadastrar a empresa, por favor tente novamente.')
    }
  }

  async list(filters?: FiltersHealthUnits): Promise<ListHealthUnitsResponse> {
    try {
      const { data } = await request.get<ListHealthUnitsResponse>(this.getCompletePath(), filters)
      return data
    } catch (e) {
      return {
        count: 0,
        data: []
      }
    }
  }
}
