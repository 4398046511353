<template>
  <v-autocomplete
    @update:model-value="$emit('update:modelValue', String($event))"
    :model-value="modelValue"
    class="min-w-[230px]"
    color="primary20"
    label="Mês de referência"
    theme="light"
    variant="outlined"
    :items="monthList"
  />
</template>

<script lang="ts" setup>
import { ref, onMounted, defineProps, defineEmits } from 'vue'

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])
const monthList = ref<{ title: string; value: string }[]>([])
onMounted(() => {
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ]
  const currentMonth = new Date().getMonth()
  const currentYear = new Date().getFullYear()

  monthList.value = months.map((month, index) => ({
    title: `${month} de ${currentYear}`,
    value: String(index)
  }))
  if (!props.modelValue) {
    emit('update:modelValue', `${currentYear}-${currentMonth + 1}-01`)
  }
})
</script>
