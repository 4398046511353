<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.74951 12.0002C2.74951 6.89348 6.8847 2.75024 11.9895 2.75024C17.1058 2.75024 21.2495 6.89495 21.2495 12.0002C21.2495 17.1055 17.1058 21.2502 11.9895 21.2502C6.8847 21.2502 2.74951 17.107 2.74951 12.0002Z"
      :stroke="color"
      stroke-width="1.5"
    />
    <path d="M7.75781 12H16.2431" :stroke="color" stroke-width="1.5" stroke-linecap="round" />
    <path d="M12 7.75739V16.2427" :stroke="color" stroke-width="1.5" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#0043CE'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
