// eslint-disable-next-line no-shadow
enum PATHS {
  SIGNIN = '/',
  CHOOSECOMPANY = '/choose-company',
  FORGOTPASSWORD = '/forgot-password',
  RECOVER_PASSWORD_DOCTOR = '/recuperar-senha',
  SIGNUP = '/signup',
  SIGNUPCONTRACTOR = '/signup-contractor',
  SIGNUPCOMPANY = '/signup-company',
  SIGNUPDOCTOR = '/signup-doctor',
  DESIGN_SYSTEM = '/design-system',
  DASHBOARD = '/dashboard',
  UNITS = '/unidades',
  CONSULT = '/consultar',
  PROTOCOL = '/protocolo',
  CONTRACT = '/contrato',
  CONSULTSCALE = '/consulta',
  DOCTORS = '/medicos',
  USERS = '/usuarios',
  MANAGEMENT = '/gestao',
  PROFILE = '/perfil',
  MANAGE_SCALE = '/gerenciar-escala',
  CONTRACTS = '/contratos',
  EDIT_HOSPITAL = '/editar-hospital',
  MANAGE_DOCTOR_INVITE = '/convite'
}

export default PATHS
