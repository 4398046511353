<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.9902 37L10.9998 11"
      :stroke="color"
      stroke-width="4.03767"
      stroke-linecap="round"
    />
    <path d="M11.0095 37L37 11" :stroke="color" stroke-width="4.03767" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: '48',
      type: String
    },
    height: {
      default: '48',
      type: String
    },
    color: {
      default: '#9E9EB7',
      type: String
    }
  }
}
</script>

<style></style>
