<template>
  <div class="container-administrative-data">
    <div class="flex justify-center">
      <form
        id="formCompanyAdministrativeData"
        class="flex items-center flex-col rounded overflow-hidden"
      >
        <h1 class="titleBold text-left">Dados administrativos</h1>
        <p>
          O e-mail informado em "Dados Pessoais" será utilizado para realizar o acesso a nossa
          Plataforma e o e-mail em "Dados Administrativos" será utilizado para contato.
        </p>
        <div class="input w-full">
          <Input
            :v-model="fantasyName"
            @input="onChangeFantasyName"
            :isError="isErrorFantasyName"
            errorMessage="Nome da empresa inválido!"
            width="100%"
            label="Nome da Empresa"
            required
          />
        </div>

        <div class="input w-full">
          <Input
            :isError="isErrorCompanyName"
            @input="onChangeCompanyName"
            errorMessage="Razão Social inválido"
            width="100%"
            label="Razão Social"
            :value="companyName"
            required
          />
        </div>

        <div class="input w-full">
          <Input
            id="cnpj-2323"
            :v-model="cnpj"
            @input="onChangeCnpj"
            :isError="isErrorCnpj"
            errorMessage="CNPJ inválido!"
            width="100%"
            pattern="^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$"
            mask="##.###.###/####-##"
            label="CNPJ"
            required
          />
        </div>

        <div class="input w-full">
          <Input
            :v-model="corporateEmail"
            @input="onChangeCorporateEmail"
            :isError="isErrorCorporateEmail"
            errorMessage="E-mail inválido!"
            pattern="[a-z0-9]+@[a-z+\.[a-z]{2,3}"
            type="email"
            width="100%"
            label="E-mail"
            required
          />
        </div>

        <div class="input w-full">
          <Input
            :v-model="corporatePhone1"
            @input="onChangeCorporatePhone1"
            :isError="isErrorCorporatePhone1"
            errorMessage="Telefone inválido"
            width="100%"
            type="tel"
            pattern="(\(\d{2}\)\s)(\d{4,5}-\d{4})"
            label="Telefone"
            mask="(##) #####-####"
            required
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import Input from '@/components/designSystem/Inputs/Input.vue'

export default defineComponent({
  name: 'ChooseCompany',
  components: {
    Input
  },
  setup() {
    const store = useStore()

    const companyName = computed(() => store.state.signUp.company.companyName)

    const fantasyName = computed(() => store.state.signUp.company.fantasyName)

    const corporatePhone1 = computed(() => store.state.signUp.company.corporatePhone1)

    const cnpj = computed(() => store.state.signUp.company.cnpj)

    const corporateEmail = computed(() => store.state.signUp.company.corporateEmail)

    const isErrorCompanyName = ref(false)
    const isErrorFantasyName = ref(false)
    const isErrorCorporatePhone1 = ref(false)
    const isErrorCorporateEmail = ref(false)
    const isErrorCnpj = ref(false)
    const isValid = ref(false)

    // const formPesonalData = ref({
    //   // eslint-disable-next-line @typescript-eslint/no-empty-function
    //   reportValidity: () => {},
    // });

    function validateCompanyName() {
      // const regex = new RegExp(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/);

      if (companyName.value === '') {
        isValid.value = false
        isErrorCompanyName.value = true
      } else {
        isErrorCompanyName.value = false
        isValid.value = true
      }
    }

    function validateFantasyName() {
      // const regex = new RegExp(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/);

      if (fantasyName.value === '') {
        isValid.value = false
        isErrorFantasyName.value = true
      } else {
        isValid.value = true
        isErrorFantasyName.value = false
      }
    }

    function validateCorporatePhone1() {
      const regex = new RegExp(/(\(\d{2}\)\s)(\d{4,5}-\d{4})/)

      if (corporatePhone1.value === '' || !regex.test(corporatePhone1.value)) {
        isValid.value = false
        isErrorCorporatePhone1.value = true
      } else {
        isValid.value = true
        isErrorCorporatePhone1.value = false
      }
    }

    function validateCnpj() {
      // eslint-disable-next-line no-useless-escape
      const regex = new RegExp(/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/)
      const input = document.getElementById('cnpj-2323') as HTMLFormElement
      if (cnpj.value === '' || !regex.test(cnpj.value)) {
        isValid.value = false
        isErrorCnpj.value = true
        input.setCustomValidity('CNPJ Inválido!')
      } else {
        isValid.value = true
        isErrorCnpj.value = false
        input.setCustomValidity('')
      }
    }

    function validateCorporateEmail() {
      const regex = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/)

      if (corporateEmail.value === '' || !regex.test(corporateEmail.value)) {
        isValid.value = false
        isErrorCorporateEmail.value = true
      } else {
        isValid.value = true
        isErrorCorporateEmail.value = false
      }
    }

    function onChangeCompanyName(e: any) {
      store.dispatch('setCompanyCompanyName', e.target.value)
      validateCompanyName()
    }

    function onChangeFantasyName(e: any) {
      store.dispatch('setCompanyFantasyName', e.target.value)
      validateFantasyName()
    }

    function onChangeCorporatePhone1(e: any) {
      store.dispatch('setCompanyCorporatePhone1', e.target.value)
      validateCorporatePhone1()
    }

    function onChangeCorporateEmail(e: any) {
      store.dispatch('setCompanyCorporateEmail', e.target.value)
      validateCorporateEmail()
    }

    function onChangeCnpj(e: any) {
      store.dispatch('setCompanyCnpj', e.target.value)
      validateCnpj()
    }

    return {
      isErrorCompanyName,
      isErrorFantasyName,
      isErrorCorporatePhone1,
      isErrorCorporateEmail,
      companyName,
      fantasyName,
      corporatePhone1,
      corporateEmail,
      onChangeCompanyName,
      onChangeFantasyName,
      onChangeCorporatePhone1,
      onChangeCorporateEmail,
      validateFantasyName,
      validateCompanyName,
      onChangeCnpj,
      cnpj,
      isErrorCnpj,
      validateCnpj
    }
  }
})
</script>

<style scoped>
.input {
  margin-bottom: 25px;
}

.container-signup {
  background-color: #fafafa;
  height: 100vh;
}

.card {
  background-color: #fff;
  border-radius: 0.2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  min-height: 280px;
}

.card > h2 {
  font-weight: 700;
  font-size: 18px;
}
.card > h5 {
  margin: 1rem 0;
  font-size: 16px;
}

p {
  text-align: justify;
  margin-bottom: 15px;
}

@media (max-width: 556px) {
  .buttons {
    display: none;
  }
  .container-cards {
    flex-direction: column;
    margin: 2rem;
  }

  .card {
    max-width: 100%;
    margin-bottom: 2rem;
    min-height: auto;
  }
}

.titleBold {
  font-family: 'Inter';
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 25px;
  margin-top: 35px;
}

.titleBold {
  font-family: 'Inter';
  font-size: 1.5rem;
  font-weight: 700;
}

h1 {
  text-align: left;
  width: 100%;
}
</style>
