import * as yup from 'yup'

export const getInitialsChar = (name: string, quantity: number = 2) => {
  return name.substring(0, quantity).toUpperCase()
}

export const getQueryString = (data: Record<string, any>) => {
  const query = new URLSearchParams()
  Object.entries(data).forEach(([key, value]) => {
    if (value) {
      query.append(key, value.toString())
    }
  })

  return query.toString()
}

export const validateCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, '')
  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false
  let sum: number, remainder: number
  sum = 0
  for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i)
  remainder = (sum * 10) % 11
  if (remainder === 10 || remainder === 11) remainder = 0
  if (remainder !== parseInt(cpf.substring(9, 10))) return false
  sum = 0
  for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i)
  remainder = (sum * 10) % 11
  if (remainder === 10 || remainder === 11) remainder = 0
  if (remainder !== parseInt(cpf.substring(10, 11))) return false
  return true
}

export const validateDate = (data) => {
  try {
    new Date(data)
    return true
  } catch (error) {
    return false
  }
}

const emailSchema = yup.object().shape({
  email: yup.string().email('Email inválido').required('Email é obrigatório')
})

export const validateEmail = async (email: string): Promise<boolean> => {
  try {
    await emailSchema.validate({ email })
    return true
  } catch {
    return false
  }
}

export function parseBoolToString(data?: boolean): string | undefined {
  if (data === undefined || data === null) return undefined
  if (data) return 'true'
  return 'false'
}

export function parseStringToBool(data?: string): boolean | undefined {
  if (data === undefined) return undefined
  return data === 'true'
}
