import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center mt-5" }
const _hoisted_2 = { class: "text-base font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_success_icon = _resolveComponent("success-icon")!
  const _component_modal_vue = _resolveComponent("modal-vue")!

  return (_openBlock(), _createBlock(_component_modal_vue, {
    onClose: $setup.handleToggleAlert,
    modalActive: $setup.showAlert
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_success_icon, { width: "65px" })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_2, _toDisplayString($setup.alertDescription), 1)
    ]),
    _: 1
  }, 8, ["onClose", "modalActive"]))
}