<template>
  <div class="flex justify-start gap-1 flex-wrap">
    <Card
      v-for="(companyDoctor, index) in companyDoctors"
      :key="index"
      :companyDoctor="companyDoctor"
    />
  </div>
</template>

<script lang="ts">
import { DoctorCompanyResponse } from '@/Network/Types/Responses/CompanyResponses'
import { Card } from '../atoms'

export default {
  components: {
    Card
  },
  props: {
    companyDoctors: {
      type: Array<DoctorCompanyResponse>,
      default: () => {
        return []
      }
    }
  },
  setup() {}
}
</script>
