<template>
  <div class="flex h-full bg-gray-200 font-roboto">
    <Sidebar />

    <div class="flex-1 flex flex-col overflow-hidden">
      <Header />

      <main class="flex-1 overflow-x-hidden overflow-y-auto bg-white">
        <div class="container-dashboard">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import Header from './Header.vue'

export default {
  components: {
    Sidebar,
    Header
  }
}
</script>
<style scoped>
.container-dashboard {
  height: 100%;
  padding: 2rem;
}
</style>
