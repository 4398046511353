<template>
  <div class="station-form-category-container">
    <span class="station-form-category-title">Horário e data</span>
    <div class="station-form-category-content">
      <div class="station-form-category-columns">
        <v-text-field
          @update:model-value="setDate"
          :model-value="date"
          v-maska="'##/##/####'"
          label="Dia"
          class="w-full"
          color="primary20"
          variant="outlined"
          density="comfortable"
        />
        <v-autocomplete
          @update:model-value="setTimezone"
          :auto-select-first="true"
          :items="timezones"
          :model-value="timezone"
          class="w-full"
          color="primary20"
          label="Fuso Horário"
          theme="light"
          variant="outlined"
          density="comfortable"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props" :title="item?.raw?.title" :subtitle="item?.raw?.subtitle" />
          </template>
        </v-autocomplete>
      </div>
      <div class="station-form-category-columns">
        <v-text-field
          @update:model-value="setStartHour"
          :model-value="startHour"
          v-maska="'##:##'"
          label="Início"
          class="w-full"
          color="primary20"
          variant="outlined"
          density="comfortable"
        />
        <v-text-field
          @update:model-value="setEndHour"
          :model-value="endHour"
          v-maska="'##:##'"
          label="Fim"
          class="w-full"
          color="primary20"
          variant="outlined"
          density="comfortable"
        />
      </div>
      <div v-if="!isUpdateMode">
        <div class="station-form-category-columns justify-center mb-3">
          <v-radio-group
            @update:model-value="setConfigurationRepetition"
            :model-value="configurationRepetition"
            inline
            class="flex justify-center"
          >
            <v-radio label="Apenas uma vez" color="primary" value="no-repeat"></v-radio>
            <v-radio label="Repetir Diariamente" color="primary" value="daily"></v-radio>
            <v-radio label="Personalizado" color="primary" value="custom"></v-radio>
          </v-radio-group>
        </div>
        <v-row dense v-if="configurationRepetition === 'custom'">
          <v-col cols="12">
            <v-autocomplete
              class="w-full"
              @update:model-value="setValueRepetition"
              :model-value="valueRepetition"
              :items="optionsRepeatStation"
              color="primary20"
              :disabled="String(configurationRepetition) !== 'custom'"
              density="comfortable"
              label="Repetições"
              multiple
              chips
              :error-messages="repetitionErr"
            />
          </v-col>
        </v-row>
        <v-row dense v-if="configurationRepetition !== 'no-repeat'">
          <v-col cols="12" class="text-left">
            <span class="text-sm"> Quantidade de plantões: </span>
          </v-col>
          <v-col cols="12" class="flex gap-1 justify-between">
            <v-text-field
              v-if="validateRepetion('0')"
              @update:model-value="(v) => setRepetitionDays(v, 0)"
              :model-value="repetitionDays[0]"
              hide-details
              label="Domingo"
              class="w-[50px]"
              color="primary20"
              type="number"
              density="compact"
            />
            <v-text-field
              v-if="validateRepetion('1')"
              hide-details
              @update:model-value="(v) => setRepetitionDays(v, 1)"
              :model-value="repetitionDays[1]"
              label="Segunda"
              class="w-[50px]"
              color="primary20"
              type="number"
              density="compact"
            />
            <v-text-field
              v-if="validateRepetion('2')"
              @update:model-value="(v) => setRepetitionDays(v, 2)"
              :model-value="repetitionDays[2]"
              hide-details
              label="Terça"
              class="w-[50px]"
              color="primary20"
              type="number"
              density="compact"
            />
            <v-text-field
              v-if="validateRepetion('3')"
              hide-details
              @update:model-value="(v) => setRepetitionDays(v, 3)"
              :model-value="repetitionDays[3]"
              label="Quarta"
              class="w-[50px]"
              color="primary20"
              type="number"
              density="compact"
            />
            <v-text-field
              v-if="validateRepetion('4')"
              hide-details
              @update:model-value="(v) => setRepetitionDays(v, 4)"
              :model-value="repetitionDays[4]"
              label="Quinta"
              class="w-[50px]"
              color="primary20"
              type="number"
              density="compact"
            />
            <v-text-field
              v-if="validateRepetion('5')"
              @update:model-value="(v) => setRepetitionDays(v, 5)"
              :model-value="repetitionDays[5]"
              hide-details
              label="Sexta"
              class="w-[50px]"
              color="primary20"
              type="number"
              density="compact"
            />
            <v-text-field
              v-if="validateRepetion('6')"
              @update:model-value="(v) => setRepetitionDays(v, 6)"
              :model-value="repetitionDays[6]"
              hide-details
              label="Sábado"
              class="w-[50px]"
              color="primary20"
              type="number"
              density="compact"
            />
          </v-col>
        </v-row>
        <v-row dense v-if="configurationRepetition !== 'no-repeat'">
          <v-col cols="5">
            <v-select
              label="Repetir"
              @update:model-value="setFinishedAt"
              :items="finishedAtOptions"
              :model-value="finishedAt"
              color="primary20"
              density="comfortable"
            />
          </v-col>
          <v-col cols="7">
            <DatePicker
              @update:model-value="setFinishedAtUntil"
              :model-value="finishedAtUntil"
              :masks="{ input: `DD MMM YYYY` }"
            >
              <template #default="{ inputValue, inputEvents }">
                <v-text-field
                  v-if="finishedAt === 'until'"
                  placeholder="Range"
                  :prepend-inner-icon="mdiCalendar"
                  :value="inputValue"
                  v-on="inputEvents"
                  density="comfortable"
                  class="w-full"
                  color="primary20"
                ></v-text-field>
              </template>
            </DatePicker>
            <v-text-field
              v-if="finishedAt === 'occurrence'"
              @update:model-value="setFinishedAtAfter"
              :model-value="finishedAtAfter"
              type="number"
              density="comfortable"
              placeholder="Nº de ocorrências"
              class="w-full"
              color="primary20"
              suffix="Ocorrencias"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue'
import { DatePicker } from 'v-calendar'
import { mdiCalendar } from '@mdi/js'
import { timezones } from '@/hooks/useCommon'
import { optionsRepeatStation, finishedAtOptions } from '../../helpers'

const props = defineProps([
  'isUpdateMode',
  'date',
  'timezone',
  'startHour',
  'endHour',
  'configurationRepetition',
  'valueRepetition',
  'repetitionDays',
  'finishedAt',
  'finishedAtUntil',
  'finishedAtAfter',
  'repetitionErr'
])
const emit = defineEmits([
  'update:date',
  'update:timezone',
  'update:startHour',
  'update:endHour',
  'update:configurationRepetition',
  'update:valueRepetition',
  'update:repetitionDays',
  'update:finishedAt',
  'update:finishedAtUntil',
  'update:finishedAtAfter'
])

const validateRepetion = (dayNumber: string): boolean => {
  if (props.configurationRepetition == 'no-repeat') return false
  if (props.configurationRepetition == 'daily') return true
  if (props.valueRepetition?.find((r: string) => r === dayNumber)) return true
  return false
}

const setDate = (v: string) => emit('update:date', v)
const setTimezone = (v: string) => emit('update:timezone', v)
const setStartHour = (v: string) => emit('update:startHour', v)
const setEndHour = (v: string) => emit('update:endHour', v)
const setConfigurationRepetition = (v: string) => {
  emit('update:configurationRepetition', v)
}
const setValueRepetition = (v) => {
  emit('update:valueRepetition', v)
}

const setRepetitionDays = (v: string, pos: number) => {
  emit('update:repetitionDays', { ...props.repetitionDays, [pos]: v })
}
const setFinishedAt = (v: string) => {
  emit('update:finishedAt', v)
}
const setFinishedAtUntil = (v: string) => emit('update:finishedAtUntil', v)
const setFinishedAtAfter = (v: string) => emit('update:finishedAtAfter', v)
</script>
