<template>
  <dropdown-filters @clicked="addFilter" title="Unidades" :items="listHealthUnits" :icon="hospitalIcon" />
</template>

<script lang="ts">
import { defineAsyncComponent, onMounted, } from 'vue'
import DropdownFilters from '../components/DropdownFilters.vue'
import { useHealthUnitStore } from '@/pinia-store/useHealthUnitStore'
import { storeToRefs } from 'pinia'
import { useStationStore } from '@/pinia-store/useStationStore'

export default {
  components: {
    DropdownFilters
  },
  setup() {
    const hospitalIcon = defineAsyncComponent(() => import('@/assets/icons/hospital.vue'))
    const healthUnitStore = useHealthUnitStore()
    const stationStore = useStationStore()
    const { listHealthUnits } = storeToRefs(useHealthUnitStore())
    onMounted(async () => {
      await healthUnitStore.getAllHealthUnits()
    })


    const addFilter = async (val: { title: string, value: string }) => {
      await stationStore.setHealthUnitFilter(+val.value, 'add')
    }
    return {
      hospitalIcon,
      listHealthUnits,
      addFilter
    }
  }
}
</script>
