<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 19C7.28287 19 3.24223 16.1091 1.53928 12C3.24223 7.89095 7.28287 5 12 5C16.7171 5 20.7578 7.89095 22.4607 12C20.7578 16.1091 16.7171 19 12 19ZM15 12C15 10.3439 13.6561 9 12 9C10.3439 9 9 10.3439 9 12C9 13.6561 10.3439 15 12 15C13.6561 15 15 13.6561 15 12Z"
      :stroke="color"
    />
    <path d="M3 3L21 21" :stroke="color" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: '24',
      type: String
    },
    height: {
      default: '24',
      type: String
    },
    color: {
      default: '#323232',
      type: String
    }
  }
}
</script>

<style></style>
