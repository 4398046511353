import { validateCPF, validateDate } from '@/utils/string'
import * as yup from 'yup'

export const UserDataSchema = yup.object({
  email: yup.string().email().required('O e-mail é obrigatório.'),
  password: yup
    .string()
    .required('A senha é obrigatória.')
    .min(6, 'A senha deve ter no mínimo 6 caracteres.')
    .matches(/[a-z]/, 'A senha deve conter pelo menos uma letra minúscula.')
    .matches(/[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula.')
    .matches(/[0-9]/, 'A senha deve conter pelo menos um número.')
    .matches(/[^a-zA-Z0-9]/, 'A senha deve conter pelo menos um símbolo.'),
  confirmPassword: yup
    .string()
    .required('A confirmação da senha é obrigatória.')
    .oneOf([yup.ref('password'), null], 'As senhas devem coincidir.'),
  phone: yup
    .string()
    .required('O telefone é obrigatório.')
    .transform((value) => value.replace(/[()\-\s]/g, ''))
    .matches(
      /^\(?\d{2}\)?[\s-]?(\d{4,5})[\s-]?(\d{4})$/,
      'O telefone deve estar no formato (xx) xxxxx-xxxx.'
    )
})
export type UserData = yup.InferType<typeof UserDataSchema>

const formatData = (value: string) => {
  const [day, month, year] = value.split('/')
  return `${year}-${month}-${day}`
}

export const PersonalDataSchema = yup.object({
  rg: yup
    .string()
    .transform((value) => value.replace(/\D/g, ''))
    .required('RG é obrigatório.'),
  issuingAuthority: yup
    .string()
    .min(2, 'A autoridade emissora deve ter no mínimo 2 caracteres.')
    .required('A autoridade emissora é obrigatória.'),
  issueDate: yup
    .string()
    .transform(formatData)
    .test('valid-date', 'Data de nascimento inválida.', (value) => validateDate(value))
    .required('A data de emissão é obrigatória.'),
  cpf: yup
    .string()
    .required('CPF é obrigatório.')
    .transform((value) => value.replace(/\D/g, ''))
    .test('valid-cpf', 'CPF inválido.', (value) => validateCPF(value)),
  birthDate: yup
    .string()
    .transform(formatData)
    .test('valid-date', 'Data de nascimento inválida.', (value) => validateDate(value))
    .required('A data de nascimento é obrigatória.'),
  motherName: yup
    .string()
    .min(3, 'O nome da mãe deve ter no mínimo 3 caracteres.')
    .required('O nome da mãe é obrigatório.'),
  fatherName: yup
    .string()
    .min(3, 'O nome do pai deve ter no mínimo 3 caracteres.')
    .required('O nome do pai é obrigatório.'),
  civilStates: yup
    .string()
    .nullable()
    .oneOf(['solteiro', 'casado'], 'Estado civil deve ser "solteiro" ou "casado".')
    .required('Estado civil é obrigatório.'),
  nationality: yup
    .string()
    .min(2, 'A nacionalidade deve ter no mínimo 2 caracteres.')
    .required('A nacionalidade é obrigatória.')
})
export type PersonalData = yup.InferType<typeof PersonalDataSchema>

export const AddressSchema = yup.object({
  street: yup.string().required('Rua é obrigatória'),
  district: yup.string().required('Bairro é obrigatório'),
  number: yup.string().required('Número é obrigatório'),
  complement: yup.string().nullable().optional(),
  state: yup.string().required('Estado é obrigatório').length(2, 'Estado deve ter 2 caracteres'),
  city: yup.string().required('Cidade é obrigatória'),
  cep: yup
    .string()
    .transform((value) => value.replace(/\D/g, ''))
    .required('CEP é obrigatório')
    .matches(/^\d{8}$/, 'CEP deve ter 8 dígitos numéricos')
})

export type Address = yup.InferType<typeof AddressSchema>

export const ProfessionalDataSchema = yup.object({
  crm: yup.string().required(),
  crmUf: yup.string().required('Estado é obrigatório').length(2, 'Estado deve ter 2 caracteres'),
  name: yup.string().required()
})
export type ProfessionalData = yup.InferType<typeof ProfessionalDataSchema>
