<template>
  <div class="flex flex-col mt-5">
    <div class="w-full flex justify-end flex-wrap md:flex-nowrap md:gap-4 gap-0">
      <MonthSelect class="max-w-[350px] w-full" v-model="month" density="comfortable" />
      <div class="max-w-[350px] w-full">
        <SearchInput
          class="max-w-[350px] w-full"
          density="comfortable"
          v-model="search"
          width="100%"
          label="Buscar unidade"
        />
      </div>
    </div>
    <v-data-table
      :loading="isLoading"
      :search="search"
      :hover="true"
      class="text-left"
      :headers="[
        {
          key: 'healthUnitName',
          align: 'start',
          title: 'Unidades'
        },
        {
          title: 'Gastos com profissionais',
          key: 'expense',
          value(item) {
            return `R$ ${formatCurrency(item['totalExpense'] ?? 0)}`
          }
        }
      ]"
      :items="hospitals"
      :items-per-page="ITEMS_PER_PAGE"
    >
      <template v-slot:bottom />
    </v-data-table>
  </div>
</template>

<script lang="ts" setup>
import { formatCurrency } from '@/utils/formatters'
import { ref, onMounted } from 'vue'
import Network from '@/Network'
import { MedicalExpensesRow } from '@/Network/Types/Responses/HealthUnitResponses'
import SearchInput from '@/components/designSystem/Inputs/SearchInput.vue'
import MonthSelect from '@/components/Autocompletes/MonthSelect.vue'
import { watch } from 'vue'

const ITEMS_PER_PAGE = 5
const hospitals = ref<MedicalExpensesRow[]>([])
const search = ref('')
const month = ref(new Date().getMonth().toString())
const isLoading = ref(false)

const getRelatory = async () => {
  try {
    isLoading.value = true
    const response = await Network.station.relatoryMonthlyExpenses({ month: +month.value })
    hospitals.value = response
  } finally {
    isLoading.value = false
  }
}
onMounted(async () => {
  await getRelatory()
})

watch(month, async () => {
  await getRelatory()
})
</script>

<style scoped>
.unit-row {
  box-shadow: inset 0px -1px 0px #e6e6e6;
}
</style>
