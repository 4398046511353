export default {
  state() {
    return {
      name: ''
    }
  },
  mutations: {
    setName(state: { name: string }, name: string) {
      state.name = name
    }
  },
  actions: {
    setName(context: any, param: any) {
      context.commit('setUserName', param)
    }
  }
}
