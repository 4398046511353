<template>
  <img
    :style="{ width: width }"
    v-if="logo == 'small'"
    src="@/assets/logos/logo_small.png"
    alt="Logo"
  />

  <img
    :style="{ width: width }"
    v-if="logo == 'full'"
    src="@/assets/logos/logo_full.png"
    alt="Logo"
  />

  <img
    :style="{ width: width }"
    v-if="logo == 'variant'"
    src="@/assets/logos/logo_variant.png"
    alt="Logo"
  />

  <img
    :style="{ width: width }"
    v-if="logo == 'outlined'"
    src="@/assets/logos/logo_outlined.png"
    alt="Logo"
  />

  <img
    :style="{ width: width }"
    v-if="logo == 'horizontal'"
    src="@/assets/logos/logo_horizontal.png"
    alt="Logo"
  />

  <img
    :style="{ width: width }"
    v-if="logo == 'medium'"
    src="@/assets/logos/logo_medium.png"
    alt="Logo"
  />
</template>

<script>
export default {
  name: 'Logos',
  props: {
    logo: {
      default: 'full'
    },
    width: {
      default: '160px'
    }
  }
}
</script>

<style scoped></style>
