import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a8d0e8d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-signup" }
const _hoisted_2 = { class: "flex w-full justify-between" }
const _hoisted_3 = { class: "logo flex justify-start" }
const _hoisted_4 = { class: "buttons" }
const _hoisted_5 = { class: "steps flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_close_icon = _resolveComponent("close-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_personal_data = _resolveComponent("personal-data")!
  const _component_administrative_data = _resolveComponent("administrative-data")!
  const _component_Address = _resolveComponent("Address")!
  const _component_steps = _resolveComponent("steps")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Logo, {
          width: "200px",
          logo: "horizontal"
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_link, { to: "/signup" }, {
          default: _withCtx(() => [
            _createVNode(_component_close_icon)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_steps, {
        onChangeStep: _ctx.changeStep,
        stepSelected: _ctx.stepSelected,
        title: "Cadastro Empresa"
      }, {
        "step-0": _withCtx(() => [
          _createVNode(_component_personal_data)
        ]),
        "step-1": _withCtx(() => [
          _createVNode(_component_administrative_data)
        ]),
        "step-2": _withCtx(() => [
          _createVNode(_component_Address)
        ]),
        footer: _withCtx(() => []),
        _: 1
      }, 8, ["onChangeStep", "stepSelected"])
    ])
  ]))
}