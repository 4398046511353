<!-- eslint-disable vue/no-v-for-template-key-on-child -->
<template>
  <div>
    <v-text-field
      v-bind="{ ...$props, ...$attrs }"
      class="generic-input text-neutral50"
      :color="colors.primary20"
      :value="modelValue"
      @blur="$emit('validate', modelValue)"
      @keyup="$emit('validate', modelValue)"
      @update:modelValue="$emit('update:modelValue', $event)"
      :label="required ? `* ${label}` : label"
      :rules="required ? [...rules, inputRules.required] : [...rules]"
    >
      <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
        <slot :key="slot" :name="slot" v-bind="scope" />
      </template>
    </v-text-field>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { VTextField } from 'vuetify/components'
import { getColorVariable } from '@/utils/tailwindcss'

export default defineComponent({
  extends: VTextField,
  components: {
    VTextField
  },
  inheritAttrs: false,
  props: {
    variant: { type: String, default: 'outlined' },
    label: { type: String, default: 'Label' },
    modelValue: { type: [String, Number] },
    required: { type: Boolean, default: false },
    rules: { type: Array<any>, default: [] }
  },
  emits: ['update:modelValue', 'validate'],
  setup() {
    const colors = {
      primary20: getColorVariable('primary20')?.default ?? '#3CA2B9'
    }
    const inputRules = {
      required: (v: any) => !!v || 'Campo obrigatório'
    }

    return {
      inputRules,
      colors
    }
  }
})
</script>
<style lang="scss">
// @use 'vuetify/styles';
#app div.v-input--error.generic-input {
  color: red;
}
</style>
