import { useToast } from 'vue-toastification'

const toast = useToast()

interface BasicData {
  sector: string
  occupation: string
  phone: string
  email: string
}
interface Duty {
  speciality: string
  sector: string
  initialHour: string
  endHour: string
  value: string
  payer: string
  closeDay: string
  paymentDate: string
  fiscalDeducationName: string
  fiscalDeducationPercentage: string
  isActive: boolean
}

interface Contract {
  name: string
  duties: Duty[]
}

interface State {
  basicData: BasicData
  newContractName: string
  editContractName: string
  newDuty: Duty
  editedDutyIndex: number
  contracts: Contract[]
}

const initialDuty: Duty = {
  speciality: '',
  sector: '',
  initialHour: '',
  endHour: '',
  value: '',
  payer: '',
  closeDay: '',
  paymentDate: '',
  fiscalDeducationName: '',
  fiscalDeducationPercentage: '',
  isActive: true
}

export default {
  state() {
    return {
      basicData: {
        sector: '',
        occupation: '',
        phone: '',
        email: ''
      },
      newContractName: '',
      editContractName: '',
      newDuty: { ...initialDuty },
      editedDutyIndex: -1,
      contracts: []
    }
  },
  mutations: {
    setBasicData(state: State, basicData: BasicData) {
      state.basicData = basicData
    },
    setNewDuty(state: State, newDuty: Duty) {
      state.newDuty = newDuty
    },
    createContract(state: State, contracts: Contract[]) {
      state.contracts = contracts
      state.newContractName = ''
    },
    setNewContractName(state: State, contractName: string) {
      state.newContractName = contractName
    },
    setEditContractName(state: State, contractName: string) {
      state.editContractName = contractName
    },
    initEditedDuty(state: State, payload: { contractIndex: number; dutyIndex: number }) {
      state.newDuty = state.contracts[payload.contractIndex].duties[payload.dutyIndex]
      state.editedDutyIndex = payload.dutyIndex
    },
    editContract(state: State, contractIndex: number) {
      state.contracts[contractIndex].name = state.editContractName
      state.editContractName = ''
    },
    createDuty(state: State, payload: { contractIndex: number; contractDuties: Duty[] }) {
      state.contracts[payload.contractIndex].duties = payload.contractDuties
      state.newDuty = { ...initialDuty }
    },
    editDuty(state: State, contractIndex: number) {
      state.contracts[contractIndex].duties[state.editedDutyIndex] = state.newDuty
      state.newDuty = { ...initialDuty }
      state.editedDutyIndex = -1
    },
    clearNewDuty(state: State) {
      state.newDuty = { ...initialDuty }
      state.editedDutyIndex = -1
    },
    toggleActiveContract(state: State, contractIndex: number) {
      if (state.contracts[contractIndex].duties.length === 0) {
        toast.error('Não é possível ativar contrato sem plantão!')
        return
      }

      const isContractActive = state.contracts[contractIndex].duties.some((duty) => duty.isActive)

      state.contracts[contractIndex].duties = state.contracts[contractIndex].duties.map((duty) => {
        const newDuty = duty
        newDuty.isActive = !isContractActive
        return duty
      })

      if (!isContractActive) {
        toast.success('Contrato ativado com sucesso!')
      } else {
        toast.error('Contrato desativado com sucesso!')
      }
    },
    toggleActiveDuty(state: State, payload: { contractIndex: number; dutyIndex: number }) {
      state.contracts[payload.contractIndex].duties[payload.dutyIndex].isActive =
        !state.contracts[payload.contractIndex].duties[payload.dutyIndex].isActive
    }
  },
  actions: {
    setBasicData(context: any, basicDataPayload: { property: string; value: string }) {
      const basicData = {
        ...context.state.basicData,
        [basicDataPayload.property]: basicDataPayload.value
      }
      context.commit('setBasicData', basicData)
    },
    setNewDuty(context: any, newDutyPayload: { property: string; value: string }) {
      const newDuty = { ...context.state.newDuty, [newDutyPayload.property]: newDutyPayload.value }
      context.commit('setNewDuty', newDuty)
    },
    setNewContractName(context: any, contractName: string) {
      context.commit('setNewContractName', contractName)
    },
    setEditContractName(context: any, contractName: string) {
      context.commit('setEditContractName', contractName)
    },
    initEditedDuty(context: any, dutyIndex: number) {
      context.commit('initEditedDuty', dutyIndex)
    },
    editContract(context: any, contractIndex: number) {
      context.commit('editContract', contractIndex)
    },
    createContract(context: any) {
      const newContract = {
        name: context.state.newContractName,
        duties: []
      }
      const contracts = [...context.state.contracts]
      contracts.push(newContract)
      context.commit('createContract', contracts)
    },
    createDuty(context: any, contractIndex: number) {
      const newDuty = { ...context.state.newDuty }
      const contractDuties = context.state.contracts[contractIndex].duties
      contractDuties.push(newDuty)
      context.commit('createDuty', { contractIndex, contractDuties })
    },
    editDuty(context: any, contractIndex: number) {
      context.commit('editDuty', contractIndex)
    },
    toggleActiveContract(context: any, contractIndex: number) {
      context.commit('toggleActiveContract', contractIndex)
    },
    toggleActiveDuty(context: any, payload: { contractIndex: number; dutyIndex: number }) {
      context.commit('toggleActiveDuty', payload)
    },
    clearNewDuty(context: any) {
      context.commit('clearNewDuty')
    }
  }
}
