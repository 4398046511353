import { router } from '@/router'
import { getQueryString } from '@/utils/string'
import { defineStore } from 'pinia'
import { reactive } from 'vue'
import { parseQuery, useRoute } from 'vue-router'
import { format, addDays } from 'date-fns'
import {
  useDoctorReportsStore,
  useStationsApproveStore,
  useStationStore
} from '@/pinia-store/useStationStore'
export type FilterStates = {
  company?: number | null
  healthUnits?: number[] | null
  schedules?: number[] | null
  doctors?: number[] | null
  startDate?: Date | string
  endDate?: Date | string
  crmStates?: string[] | null
  crms?: string[] | null
  valueMin?: number | null
  valueMax?: number | null
  paymentValueMin?: number | null
  paymentValueMax?: number | null
  withCheckin?: boolean | null
  withCheckout?: boolean | null
  stationType?: string | null
  inCash?: boolean
  advanceRequested?: boolean
  advancePaid?: boolean
  ourTeam?: boolean
  status?: string[] | null
  days?: number[] | null
}

const DEFAULT_VALUES = {
  company: undefined,
  healthUnits: undefined,
  schedules: undefined,
  doctors: undefined,
  startDate: undefined,
  endDate: undefined,
  crmStates: undefined,
  crms: undefined,
  valueMin: undefined,
  valueMax: undefined,
  paymentValueMin: undefined,
  paymentValueMax: undefined,
  withCheckin: undefined,
  withCheckout: undefined,
  inCash: undefined,
  advanceRequested: undefined,
  advancePaid: undefined,
  status: undefined,
  stationType: undefined,
  days: undefined
}

export const filterStates: FilterStates = reactive({ ...DEFAULT_VALUES })

export type FilterStationsState = {
  filters: FilterStates
}
export const useFilterStationsStore = defineStore('filterStations', {
  state: (): FilterStationsState => ({
    filters: {}
  }),
  actions: {
    async initialRequest() {
      this.setFiltersFromQuery()
      await this.updateAllStores()
    },
    async setFilters() {
      // to-do atualizar todos os estados que dependem do filtro.
      this.filters = {
        ...filterStates,
        startDate:
          filterStates.startDate && typeof filterStates.startDate !== 'string'
            ? format(filterStates.startDate, 'yyyy-MM-dd')
            : undefined,
        endDate:
          filterStates.endDate && typeof filterStates.endDate !== 'string'
            ? format(filterStates.endDate, 'yyyy-MM-dd')
            : undefined
      }
      const qs = getQueryString(this.filters)
      router.replace({ query: parseQuery(qs) })
      await this.updateAllStores()
    },
    setFiltersFromQuery() {
      const route = useRoute()
      this.filters = route.query ?? {}
      Object.assign(filterStates, {
        ...this.filters,
        startDate: this.filters.startDate
          ? addDays(new Date(this.filters.startDate), 1)
          : undefined,
        endDate: this.filters.endDate ? addDays(new Date(this.filters.endDate), 1) : undefined
      })
    },
    async clearFilters() {
      router.replace({ query: {} })
      this.filters = { ...DEFAULT_VALUES }
      Object.assign(filterStates, DEFAULT_VALUES)
      await this.updateAllStores()
    },
    async updateAllStores() {
      const useStationsApprove = useStationsApproveStore()
      const useDoctorReports = useDoctorReportsStore()
      const useStation = useStationStore()

      await Promise.allSettled([
        useStation.getListConsultingStations({
          page: 1,
          perPage: 10,
          sort: [],
          columnFilters: this.filters
        }),
        useStationsApprove.getStationsToApprove(this.filters),
        useDoctorReports.getDoctorReports({
          page: 1,
          perPage: 10,
          sort: [],
          columnFilters: this.filters
        })
      ])
    }
  },
  getters: {
    getFilters(): FilterStates {
      return this.filters
    }
  }
})
