<template>
  <div class="container-signup">
    <div class="logo flex justify-center">
      <router-link to="/">
        <Logo width="160px" logo="full" />
      </router-link>
    </div>

    <h1 class="title">
      Para começar o seu cadastro,
      <span class="titleBold">selecione o seu perfil.</span>
    </h1>

    <div class="container-cards">
      <div class="card shadow-lg">
        <h2>Empresa</h2>
        <h5>Gerenciamento de equipes, protocolos, escalas de plantão e controle financeiro</h5>
        <router-link to="/signup-company">
          <OutlineButton text="Selecionar" width="100%" />
        </router-link>
      </div>

      <div class="card shadow-lg">
        <h2>Médico</h2>
        <h5>Visualização de escalas, busca por vagas e gerenciador de tempo</h5>
        <router-link to="/signup-doctor">
          <OutlineButton text="Selecionar" width="100%" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import Logo from '@/components/designSystem/Logo/index.vue'
import OutlineButton from '../../../components/designSystem/Buttons/OutlineButton/index.vue'

export default defineComponent({
  name: 'ChooseCompany',
  components: {
    Logo,
    OutlineButton
  },
  setup() {
    const store = useStore()
    const userName = computed(() => store.state.user.name)
    const isErrorUserName = ref(false)

    return {
      userName,
      isErrorUserName
    }
  }
})
</script>

<style scoped>
.buttons {
  position: absolute;
  top: 2rem;
  right: 5rem;
  display: flex;
}
.container-signup {
  background-color: #fafafa;
  height: 100vh;
}

.container-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.card {
  background-color: #fff;
  border-radius: 0.2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 18rem;
  height: 13.5rem;
}

.card > h2 {
  font-weight: 700;
  font-size: 18px;
}
.card > h5 {
  margin: 1rem 0;
  font-size: 16px;
}

@media (max-width: 556px) {
  .buttons {
    display: none;
  }
  .container-cards {
    flex-direction: column;
    margin: 2rem;
  }

  .card {
    max-width: 100%;
    margin-bottom: 2rem;
    min-height: auto;
  }
}

.title {
  font-family: 'Inter';
  color: theme('colors.secondary50');
  font-style: normal;
  font-size: 1.5rem;
  line-height: 26px;
  margin-bottom: 25px;
  margin-top: 35px;
}

.titleBold {
  font-weight: 700;
}
.logo {
  padding-top: 40px;
  padding-bottom: 12%;
}
</style>
