<template>
  <div class="w-full mt-4 overflow-y-auto overflow-x-hidden" style="grid-area: table">
    <TableResults v-for="user in users" :key="user.id" :user="user" />
  </div>
</template>

<script setup lang="ts">
import TableResults from '@/components/designSystem/Table/TableResults.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const filteredUsers = computed(() => store.getters.getFilteredUsers)
const users = computed(() =>
  filteredUsers.value.length > 0 ? filteredUsers.value : store.state.userCrud.users
)
</script>
