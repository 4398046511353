<template>
  <ModalVue
    @close="$emit('closeDetailsModal')"
    :modalActive="showDetailsModal"
    :isFilterModal="true"
  >
    <template #header>
      <p class="font-bold px-[8px] mb-5 text-left">Detalhes da vaga</p>
    </template>
    <div>
      <div class="border-bottom-gray flex flex-row text-left gap-4 pb-4">
        <p class="basis-1/2 text-base font-bold">Valor proporcional a hora</p>
        <p class="basis-2/2 text-base font-normal">
          R$ {{ formatCurrency(currentDoctor?.valueProposed ?? '') }}
        </p>
      </div>
      <div class="border-bottom-gray flex flex-row text-left gap-4 py-4">
        <p class="basis-1/2 text-base font-bold">Pagamento</p>
        <p class="basis-2/2 text-base font-normal">{{ currentDoctor?.payment }}</p>
      </div>
      <div class="border-bottom-gray flex flex-row text-left gap-4 py-4">
        <p class="basis-1/2 text-base font-bold">Observações</p>
        <p class="basis-2/2 text-base font-normal">
          {{ currentDoctor?.observations !== '' ? currentDoctor?.observations : '-' }}
        </p>
      </div>
    </div>
  </ModalVue>
</template>

<script lang="ts">
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import { StationsToApproveResponse } from '@/Network/Types/Responses/StationResponses'
import { formatCurrency } from '@/utils/formatters'

export default {
  emits: ['closeDetailsModal'],
  props: {
    showDetailsModal: Boolean,
    currentDoctor: Object as () => StationsToApproveResponse
  },
  components: { ModalVue },
  setup: () => {
    return { formatCurrency }
  }
}
</script>

<style scoped>
.border-bottom-gray {
  box-shadow: inset 0px -1px 0px #e6e6e6;
}
</style>
