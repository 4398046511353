import { defineStore } from 'pinia'

export enum EModals {
  UPDATE_BULK_STATION = 'UPDATE_BULK_STATION',
  REMOVE_BULK_STATION = 'REMOVE_BULK_STATION',
  FILTER_STATION = 'FILTER_STATION',
  FILTER_DASHBOARD = 'FILTER_DASHBOARD',
  UPDATE_SCHEDULE = 'UPDATE_SCHEDULE',
  REMOVE_SCHEDULE = 'REMOVE_SCHEDULE',
  EXPORT_DASHBOARD_EXCEL = 'EXPORT_DASHBOARD_EXCEL',
  EXPORT_DASHBOARD_PDF = 'EXPORT_DASHBOARD_PDF',
  DOCTOR_SIGNUP = 'DOCTOR_SIGNUP',
  LEGENDS = 'LEGENDS'
}

export type ModalState = {
  modals: Record<EModals, boolean>
  showAlert: boolean
  alertDescription: string
}

export const useModalStore = defineStore('modal', {
  state: (): ModalState => ({
    modals: {
      [EModals.REMOVE_SCHEDULE]: false,
      [EModals.UPDATE_SCHEDULE]: false,
      [EModals.FILTER_STATION]: false,
      [EModals.UPDATE_BULK_STATION]: false,
      [EModals.REMOVE_BULK_STATION]: false,
      [EModals.EXPORT_DASHBOARD_EXCEL]: false,
      [EModals.EXPORT_DASHBOARD_PDF]: false,
      [EModals.DOCTOR_SIGNUP]: false,
      [EModals.FILTER_DASHBOARD]: false,
      [EModals.LEGENDS]: false
    },
    alertDescription: '',
    showAlert: false
  }),
  actions: {
    toggleModal(modal: EModals) {
      this.modals = { ...this.modals, [modal]: !this.modals[modal] }
    },
    toggleShowAlert() {
      this.showAlert = !this.showAlert
    }
  }
})
