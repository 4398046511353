export interface IFilters {
  text: string
  type: 'hours' | 'scale' | 'sector' | 'unity'
}
interface IStates {
  listFilters: IFilters[]
}

export default {
  state(): IStates {
    return {
      listFilters: []
    }
  },
  mutations: {
    setFilter(state: IStates, value: IFilters) {
      const exist = state.listFilters.find((f) => f.text === value.text && f.type === value.type)
      if (!exist) {
        state.listFilters = [value, ...state.listFilters]
      }
    },
    unsetFilter(state: IStates, value: string) {
      state.listFilters = state.listFilters.filter((item) => item.text !== value)
    }
  },
  actions: {
    addFilter(context: any, param: IFilters) {
      context.commit('setFilter', param)
    },
    removeFilter(context: any, param: string) {
      context.commit('unsetFilter', param)
    }
  }
}
