<template>
  <modal-vue
    @close="handleToggleModal"
    :modalActive="modals[modalName]"
    :hasFooterBorder="true"
    isFilterModal
  >
    <template #header>
      <div class="text-left px-[16px] flex">
        <strong>Aplicar Filtros</strong>
      </div>
    </template>
    <div
      class="text-left input w-full flex flex-col gap-2 input mt-2 max-h-[420px] px-[8px] overflow-y-scroll"
    >
      <div class="flex justify-between gap-2 mt-3">
        <v-text-field
          v-model="filterDashboardStates.valueMin"
          class="w-full"
          prefix="R$"
          type="number"
          label="Valor Mínimo"
          color="primary20"
          variant="outlined"
          density="compact"
          hint="Valor da vaga"
        />
        <v-text-field
          v-model="filterDashboardStates.valueMax"
          class="w-full"
          prefix="R$"
          type="number"
          label="Valor Máximo"
          color="primary20"
          variant="outlined"
          density="compact"
          hint="Valor da vaga"
        />
      </div>

      <div class="flex md:flex-nowrap flex-wrap justify-between gap-2">
        <v-text-field
          v-model="filterDashboardStates.paymentValueMin"
          class="w-full"
          prefix="R$"
          type="number"
          label="Valor aprovado mínimo"
          color="primary20"
          variant="outlined"
          density="compact"
          hint="Valor aprovado pelo médico"
        />
        <v-text-field
          v-model="filterDashboardStates.paymentValueMax"
          class="w-full"
          prefix="R$"
          type="number"
          label="Valor aprovado máximo"
          color="primary20"
          variant="outlined"
          hint="Valor aprovado pelo médico"
          density="compact"
        />
      </div>

      <div class="flex flex-col gap-2">
        <div class="flex flex-wrap md:flex-nowrap justify-between max-w-2xl gap-2">
          <v-autocomplete
            v-model="filterDashboardStates.advanceRequested"
            clearable
            class="w-full"
            color="primary20"
            label="Antecipação Solicitada"
            :items="[
              { title: 'Sim', value: true },
              { title: 'Não', value: false }
            ]"
            variant="outlined"
            density="compact"
          />
          <v-autocomplete
            v-model="filterDashboardStates.advancePaid"
            clearable
            class="w-full"
            color="primary20"
            label="Antecipação Paga"
            :items="[
              { title: 'Sim', value: true },
              { title: 'Não', value: false }
            ]"
            variant="outlined"
            density="compact"
          />
        </div>
        <div class="flex justify-between max-w-2xl gap-2">
          <v-autocomplete
            v-model="filterDashboardStates.inCash"
            clearable
            class="w-full"
            color="primary20"
            label="Pagamento á vista"
            :items="[
              { title: 'Sim', value: true },
              { title: 'Não', value: false }
            ]"
            variant="outlined"
            density="compact"
          />
          <v-autocomplete
            v-model="filterDashboardStates.ourTeam"
            clearable
            class="w-full"
            color="primary20"
            label="Nosso plantão?"
            :items="stationOur"
            variant="outlined"
            density="compact"
          />
        </div>
      </div>
      <div class="flex gap-2">
        <v-autocomplete
          v-model="filterDashboardStates.stationType"
          clearable
          class="w-full"
          color="primary20"
          label="Periodicidade"
          :items="stationTypes"
          multiple
          variant="outlined"
          density="compact"
        />
      </div>
      <div class="flex gap-2">
        <v-autocomplete
          clearable
          class="w-full"
          color="primary20"
          label="Vaga do plantão"
          v-model="filterDashboardStates.openedStation"
          :items="openedStationOpts"
          variant="outlined"
          density="compact"
        />
        <v-autocomplete
          v-model="filterDashboardStates.status"
          clearable
          class="w-full"
          color="primary20"
          label="Status do plantão"
          :items="[
            { title: 'Pendente', value: 'waiting' },
            { title: 'Confirmado', value: 'confirmed' }
          ]"
          variant="outlined"
          density="compact"
        />
      </div>
      <!-- <div class="flex gap-2">
        <v-autocomplete
          v-model="filterDashboardStates.withCheckin"
          clearable
          class="w-full"
          color="primary20"
          label="Checkin"
          :items="[
            { title: 'Com checkin', value: true },
            { title: 'Sem checkin', value: false }
          ]"
          variant="outlined"
          density="compact"
        />
        <v-autocomplete
          v-model="filterDashboardStates.withCheckout"
          clearable
          class="w-full"
          color="primary20"
          label="Checkout"
          :items="[
            { title: 'Com checkout', value: true },
            { title: 'Sem checkout', value: false }
          ]"
          variant="outlined"
          density="compact"
        />
      </div> -->
    </div>

    <template #footer>
      <v-row class="pt-4">
        <v-col cols="6">
          <ghost-button
            @click="handleClearFilters"
            width="100%"
            text="Limpar filtros"
            :loading="isLoadingClear"
            :disabled="isLoading || isLoadingClear"
          />
        </v-col>
        <v-col cols="6">
          <filled-button
            :loading="isLoading"
            @click="handleSubmit"
            text="Aplicar Filtros"
            height="52px"
            width="100%"
            :disabled="isLoading || isLoadingClear"
          />
        </v-col>
      </v-row>
    </template>
  </modal-vue>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import GhostButton from '@/components/designSystem/Buttons/GhostButton/index.vue'
import { stationTypes, stationOur, openedStationOpts } from '@/hooks/useCommon'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import { filterDashboardStates, clearFilterDashboardOpt } from './useFilterDashboard'
import { useStationStore } from '@/pinia-store/useStationStore'
import { useToast } from 'vue-toastification'

const toast = useToast()
const isLoading = ref(false)
const isLoadingClear = ref(false)
/* ******** Modal Props and Actions Default *********** */
const modalName = EModals.FILTER_STATION
const { modals } = storeToRefs(useModalStore())
const modalStore = useModalStore()

const stationsStore = useStationStore()

const handleToggleModal = () => {
  modalStore.toggleModal(modalName)
}
/* **************************************************** */
const handleSubmit = async () => {
  isLoading.value = true
  await setFilters()
}

const handleClearFilters = async () => {
  isLoadingClear.value = true
  clearFilterDashboardOpt()
  await setFilters()
}

const setFilters = async () => {
  try {
    await stationsStore.setFilters(filterDashboardStates)
  } catch (error) {
    toast.error('Erro ao listar os plantões')
  } finally {
    isLoading.value = false
    isLoadingClear.value = false
    handleToggleModal()
  }
}
</script>
