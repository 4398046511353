<template>
  <div class="station-form-category-container">
    <div class="flex justify-between items-center">
      <span class="station-form-category-title">Observações</span>
      <span>
        <v-tooltip text="Adicionar Observação">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              @click="handleAddDraft"
              color="primary"
              :icon="mdiPlus"
              density="compact"
            />
          </template>
        </v-tooltip>
      </span>
    </div>
    <div class="station-form-category-content mt-2">
      <div class="station-form-category-columns flex-col gap-2">
        <div
          v-if="toggleDraft"
          class="flex flex-col w-full bg-gray-200 text-left p-2 rounded gap-2"
        >
          <div class="flex">
            <div class="flex flex-col gap-2 justify-between mr-2 py-1">
              <v-tooltip text="Adicionar">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    @click="handleSaveDraft"
                    color="success"
                    variant="text"
                    :icon="mdiCheckBold"
                    density="compact"
                    size="small"
                  />
                </template>
              </v-tooltip>

              <v-tooltip text="Remover">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    @click="handleRemoveDraft"
                    color="error"
                    variant="text"
                    :icon="mdiTrashCanOutline"
                    density="compact"
                    size="small"
                  />
                </template>
              </v-tooltip>
            </div>
            <v-textarea
              v-model="drafObservations"
              label="Escreva a observação"
              density="compact"
              flat
              rows="2"
              hide-details
            ></v-textarea>
          </div>
        </div>
        <div
          v-for="(obs, i) of observations"
          :key="i"
          class="flex flex-col w-full bg-gray-200 text-left p-2 rounded gap-2"
        >
          <div v-if="obs.userName" class="flex justify-between">
            <span class="text-sm text-primary">{{ obs.userName }}</span>
            <span class="text-sm text-primary flex gap-2 items-center">{{ obs.date }}</span>
          </div>
          <span class="flex justify-between items-end">
            <span>{{ obs.description }}</span>
            <v-tooltip text="Remover">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  @click="handleDelete(i)"
                  color="error"
                  variant="text"
                  :icon="mdiTrashCanOutline"
                  density="compact"
                  size="small"
                />
              </template>
            </v-tooltip>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { mdiCheckBold, mdiPlus, mdiTrashCanOutline } from '@mdi/js'
import { defineProps, defineEmits, ref } from 'vue'
const props = defineProps(['observations'])
const emit = defineEmits(['update:observations'])

const drafObservations = ref<string>('')
const toggleDraft = ref<boolean>(false)
const handleAddDraft = () => {
  toggleDraft.value = true
}

const handleRemoveDraft = () => {
  toggleDraft.value = false
  drafObservations.value = ''
}

const handleSaveDraft = () => {
  if (!drafObservations.value) return
  toggleDraft.value = false
  emit('update:observations', [...props.observations, { description: drafObservations.value }])
  drafObservations.value = ''
}

const handleDelete = (i: number) => {
  const newObservations = props.observations.filter((_, index) => index !== i)
  emit('update:observations', newObservations)
}
</script>
