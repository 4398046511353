<template>
  <modal-vue @close="handleToggleAlert" :modalActive="showAlert">
    <template #header>
      <div class="flex justify-center mt-5">
        <success-icon width="65px" />
      </div>
    </template>
    <p class="text-base font-bold">{{ alertDescription }}</p>
  </modal-vue>
</template>
<script lang="ts">
import { storeToRefs } from 'pinia'
import { useModalStore } from '@/pinia-store/useModalStore'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import SuccessIcon from '@/assets/icons/success.vue'
export default {
  props: {
    modalType: String,
    description: String
  },
  components: {
    ModalVue,
    SuccessIcon
  },
  setup() {
    const modalStore = useModalStore()
    const { showAlert, alertDescription } = storeToRefs(useModalStore())
    const handleToggleAlert = () => {
      modalStore.toggleShowAlert()
    }

    return { showAlert, alertDescription, handleToggleAlert }
  }
}
</script>
