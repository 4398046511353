<template>
  <div class="container-login w-full h-screen">
    <div class="logo flex justify-center">
      <Logo width="160px" logo="full" />
    </div>
    <div class="flex justify-center">
      <v-progress-circular
        v-if="isLoading"
        indeterminate
        :size="128"
        :width="7"
        color="primary"
      ></v-progress-circular>

      <div v-else class="card flex items-center flex-col rounded overflow-hidden shadow-lg gap-4">
        <InvalidTokenPage v-if="invalidToken" />
        <steps
          v-else
          @changeStep="changeStep"
          :stepSelected="stepSelected"
          :stepsLength="2"
          title="Resetar senha"
          btnText="Alterar senha"
          :hiddenBtn="stepSelected === 1"
        >
          <template v-slot:step-0>
            <div class="flex flex-col gap-2 my-5 items-center">
              <h1 class="title">Criar nova senha de acesso!</h1>
              <h5 class="subtitle">
                Preenche os campos a seguir, para criar uma nova senha de acesso ao aplicativo Minha
                Escala!
              </h5>
              <div class="flex flex-col gap-4 mt-5">
                <Input
                  v-model="password"
                  :isError="!!errors.password"
                  :errorMessage="errors.password"
                  width="329px"
                  label="Nova Senha"
                  type="password"
                />
                <Input
                  v-model="repeatPassword"
                  :isError="!!errors.repeatPassword"
                  :errorMessage="errors.repeatPassword"
                  width="329px"
                  label="Repetir nova senha"
                  type="password"
                />
              </div>
            </div>
          </template>
          <template v-slot:step-1>
            <div class="flex flex-col gap-2 my-5 items-center">
              <h1 class="title">Sua senha foi alterada com sucesso!</h1>
              <h5 class="subtitle">Clique para abrir o aplicativo!</h5>
              <div class="flex gap-2 mt-5">
                <a :href="androidUrl" target="_blank" rel="noopener noreferrer">
                  <div
                    class="w-[175px] h-[60px] bg-white border rounded border-gray-500 p-3 cursor-pointer"
                  >
                    <img width="150" height="33px" src="/google play.png" />
                  </div>
                </a>
                <a :href="iosUrl" target="_blank" rel="noopener noreferrer">
                  <div class="w-[175px] h-[60px] bg-white border rounded border-gray-500 p-3">
                    <img width="150" height="33" src="/app_store.png" />
                  </div>
                </a>
              </div>
            </div>
          </template>
        </steps>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from 'vue'
import Logo from '@/components/designSystem/Logo/index.vue'
import Steps from '@/components/designSystem/Steps/Index.vue'
import { useRoute } from 'vue-router'
import InvalidTokenPage from './components/invalidTokenPage.vue'
import Network from '@/Network'
import Input from '@/components/designSystem/Inputs/Input.vue'
import { useToast } from 'vue-toastification'
const toast = useToast()

const androidUrl = ref('')
const iosUrl = ref('')
const password = ref('')
const repeatPassword = ref('')
const token = ref('')
const errors = ref({
  password: '',
  repeatPassword: ''
})
const invalidToken = ref(false)
const isLoading = ref(false)
const route = useRoute()
onBeforeMount(async () => {
  isLoading.value = true
  try {
    if (!route.query['token']) {
      invalidToken.value = true
      return
    }
    token.value = String(route.query['token'])
    await Network.user.validateToken({ token: token.value })
  } catch {
    toast.error('Token inválido ou expirado')
    token.value = ''
    invalidToken.value = true
  } finally {
    isLoading.value = false
  }
})

const stepSelected = ref(0)

const validateForm = (): boolean => {
  if (!password.value) {
    errors.value.password = 'Campo obrigatório'
    return false
  }
  if (!repeatPassword.value) {
    errors.value.repeatPassword = 'Campo obrigatório'
    return false
  }

  if (password.value !== repeatPassword.value) {
    errors.value.password = 'As senhas não coincidem'
    errors.value.repeatPassword = 'As senhas não coincidem'
    return false
  }
  return true
}
async function changeStep(step: any) {
  if (step === 0) {
    const isValid = validateForm()
    if (!isValid) return
    try {
      const res = await Network.user.changePassword({
        password: password.value,
        repeatPassword: repeatPassword.value,
        token: token.value
      })
      androidUrl.value = res.androidUrl
      iosUrl.value = res.iosUrl
      stepSelected.value = 1
    } catch (error) {
      toast.error(
        'Aconteceu um erro ao tentar alterar a senha, repita o processo de recuperação de senha novamente!'
      )
    }
  }
}
</script>

<style scoped>
.container-login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #2f7f91;
}
.card {
  width: 508px;
  padding: 3rem;
  background-color: #fff;
}

@media (max-width: 556px) {
  .card {
    width: 90%;
    background-color: #fff;
  }
}

.title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}
.subtitle {
  font-family: 'Inter';
  font-style: normal;
  max-width: 80%;
}
.input {
  margin: 3rem 0;
}
.logo {
  padding-bottom: 3rem;
}
</style>
