<template>
  <div class="flex flex-col gap-2 my-5 items-center">
    <h1 class="title">Atenção convite inválido ou expirado!</h1>
    <div class="flex flex-col gap-4 mt-5">
      <img width="150" src="/alert.png" />
    </div>
    <h5 class="subtitle">
      Este token pode ser inválido/expirado, qualquer dificuldade entre em contato conosco!
    </h5>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InvalidTokenPage',
  setup() {}
})
</script>

<style scoped>
.title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}
.subtitle {
  font-family: 'Inter';
  font-style: normal;
  max-width: 80%;
}
</style>
