import { defineStore } from 'pinia'
import { LoginType } from '@/modules/public/Signin/schemas'
import { AuthResponse } from '@/Network/Types/Responses/AuthResponses'
import Network from '@/Network'
import { useCompanyStore } from './useCompanyStore'

export type AuthState = AuthResponse

const companyUserToken = Network.auth.getLocalToken();
const doctorUserToken = Network.auth.getLocalDoctorToken();

const DEFAULT_ACCESS_TOKEN = companyUserToken ? companyUserToken : doctorUserToken;

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    access_token: DEFAULT_ACCESS_TOKEN,
    needChangePassword: false,
    permissions: [],
    name: ''
  }),
  actions: {
    async login(data: LoginType) {
      if (data) {
        try {
          const companyStore = useCompanyStore()
          const authData: AuthState = await Network.auth.loginUserCompany(data)
          this.access_token = authData.access_token
          this.needChangePassword = authData.needChangePassword
          this.permissions = authData.permissions
          this.name = authData.name
          Network.auth.persistAuthResponse(authData)
          await companyStore.getMyCompanies()
        } catch (e) {
          console.error('Erro ao realizar login')
          throw e
        }
      }
    },
    async loginDoctor(data: LoginType) {
      if (data) {
        try {
          const authData: AuthState = await Network.auth.loginDoctor(data)
          this.access_token = authData.access_token
          this.needChangePassword = authData.needChangePassword
          this.permissions = authData.permissions
          this.name = authData.name
          Network.auth.persistDoctorAuthResponse(authData)
        } catch (e) {
          console.error('Erro ao realizar login')
          throw e
        }
      }
    },
    logout() {
      Network.auth.logout()
    }
  },
  getters: {
    getUserName(): string {
      return this.name || JSON.parse(localStorage.getItem('@ME:name') ?? '') || 'Minha Escala'
    }
  }
})
