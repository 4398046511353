<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 41 1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="0.726074" y1="0.5" x2="40.7261" y2="0.5" :stroke="color" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      default: '41',
      type: String
    },
    height: {
      default: '1',
      type: String
    },
    color: {
      default: '#BCBCCD',
      type: String
    }
  }
}
</script>

<style></style>
