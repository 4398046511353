import CheckinRealizado from '@/assets/icons/checks/checkin_realizado.vue'
import CheckinAtrasado from '@/assets/icons/checks/checkin_atrasado.vue'
import CheckinNaoCoberto from '@/assets/icons/checks/checkin_nao_coberto.vue'
import CheckinPendente from '@/assets/icons/checks/checkin_pendente.vue'
import CheckoutAntecipado from '@/assets/icons/checks/checkout_antecipado.vue'
import CheckoutNaoRealizado from '@/assets/icons/checks/checkout_nao_realizado.vue'
import CheckoutPendente from '@/assets/icons/checks/checkout_pendente.vue'
import CheckoutRealizado from '@/assets/icons/checks/checkout_realizado.vue'

export enum DoctorStationStatus {
  SEM_STATUS = 'SEM_STATUS',
  DISPONIBILIZADO_PARA_CHECKIN = 'DISPONIBILIZADO_PARA_CHECKIN',
  CHECKIN_NAO_REALIZADO = 'CHECKIN_NAO_REALIZADO',
  CHECKIN_REALIZADO = 'CHECKIN_REALIZADO',
  CHECKIN_REALIZADO_COM_ATRASO = 'CHECKIN_REALIZADO_COM_ATRASO',
  DISPONIBILIZADO_PARA_CHECKOUT = 'DISPONIBILIZADO_PARA_CHECKOUT',
  CHECKOUT_REALIZADO = 'CHECKOUT_REALIZADO',
  CHECKOUT_REALIZADO_COM_ANTECEDENCIA = 'CHECKOUT_REALIZADO_COM_ANTECEDENCIA',
  CHECKOUT_NAO_REALIZADO = 'CHECKOUT_NAO_REALIZADO'
}

export const doctorStationStatusTranslate = {
  [DoctorStationStatus.DISPONIBILIZADO_PARA_CHECKIN]: {
    text: 'Disponibilizado para check-in',
    icon: CheckinPendente
  },
  [DoctorStationStatus.CHECKIN_NAO_REALIZADO]: {
    text: 'Check-in não realizado',
    icon: CheckinNaoCoberto
  },
  [DoctorStationStatus.CHECKIN_REALIZADO]: {
    text: 'Check-in realizado',
    icon: CheckinRealizado
  },
  [DoctorStationStatus.CHECKIN_REALIZADO_COM_ATRASO]: {
    text: 'Check-in realizado com atraso',
    icon: CheckinAtrasado
  },
  [DoctorStationStatus.DISPONIBILIZADO_PARA_CHECKOUT]: {
    text: 'Disponibilizado para check-out',
    icon: CheckoutPendente
  },
  [DoctorStationStatus.CHECKOUT_REALIZADO]: {
    text: 'Check-out realizado',
    icon: CheckoutRealizado
  },
  [DoctorStationStatus.CHECKOUT_REALIZADO_COM_ANTECEDENCIA]: {
    text: 'Check-out realizado com antecedência',
    icon: CheckoutAntecipado
  },
  [DoctorStationStatus.CHECKOUT_NAO_REALIZADO]: {
    text: 'Check-out não realizado',
    icon: CheckoutNaoRealizado
  }
}
